import React, { useState, useEffect } from "react";
import "./certificados.css";
import {
  IconButton,
  TextField,
  InputAdornment,
  ListItem,
  MenuItem,
  LinearProgress,
  Hidden
} from "@material-ui/core";
import { Close, CloudUpload } from "@material-ui/icons";
import { CustomInput, CustomSelectB, Tooltip } from "../../../../components";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import { useSelector, useDispatch } from "react-redux";
import { paises } from "../../../../assets/paises";
import { agregarCertificadoAction, obtenerCertificadosAction } from "../../../../redux/actions/certificadoAction";
import { estudios_data } from "../../../../assets/carreras";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { CardInit, Spinner, ModalEliminar } from "../../../../components";
import { Card, Modal, ModalEditar } from "./components";
import Loader from "react-loader-spinner";
import clientAxios from "../../../../config/axios";



const Formulario = (props) => {

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.certificado.cargando);
  // let loading = true;
  const { id: idUserParam } = useParams();


  //data
  const [idusuario] = useState(idUserParam);
  const [certificacion, setCertificacion] = useState("");
  const [universidad, setUniversidad] = useState(null);
  const [pais, setPais] = useState(null);
  const [obs, setObs] = useState("");
  const [fecha, setValueI] = useState(null);
  const [active, setActive] = useState("Egresado");
  //errores
  const [errorCert, setErrorCert] = useState(false);
  const [errorUniver, setErrorUniver] = useState(false);
  const [errorPais, setErrorPais] = useState(false);

  const [errorFecha, setErrorFecha] = useState(false);

  //file
  const [file, setFile] = useState(null);
  const certificadoURL = file;


  const [usuario, setUsuario] = useState('');
  const certificados = useSelector((state) => state.certificado.certificados);
  const [cardT1, setCardT1] = useState("");




  const [idEliminar, setIdEliminar] = useState("");
  const [openModalEliminar, setOpenModalEliminar] = useState(false);
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [dataEditar, setDataEditar] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const fileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const guardarCertificado = () => {
    if (certificacion.trim() === "") {
      setErrorCert(true);
      return;
    }
    if (universidad === null) {
      setErrorUniver(true);
      return;
    }
    if (pais === null) {
      setErrorPais(true);
      return;
    }

    if (fecha === null) {
      setErrorFecha(true);
      return;
    }

    dispatch(
      agregarCertificadoAction({
        idusuario,
        certificacion,
        universidad,
        pais,
        obs,
        //fecha,
        estado: active,
        certificadoURL,
      })
    ).then((res) => Swal.fire('Listo!', 'Certificado agregado!', 'success'));
  };

  const changeInit = (value) => {
    if (errorFecha) {
      setErrorFecha(false);
    }
    setValueI(value);
  };

  useEffect(() => {
    clientAxios.get(`/api/usuarios/${idUserParam}`)
      .then(res => {
        setUsuario(res.data)
      }).catch(err => console.log(err));
  }, []);


  useEffect(() => {
    if (usuario) {
      if (usuario.sexo === "Femenino") {
        setCardT1("typeB2");
      } else {
        setCardT1("typeB1");
      }
    }
  }, [usuario]);

  useEffect(() => {
    const cargarCertificados = () =>
      dispatch(obtenerCertificadosAction(idUserParam));
    cargarCertificados();
    // eslint-disable-next-line
  }, [usuario]);


  const datasort = certificados.sort(function (a, b) {
    a = new Date(a.fecha);
    b = new Date(b.fecha);
    return a > b ? -1 : a < b ? 1 : 0;
  });

  return (
    <>


      <CustomInput
        label="Certificación"
        defaultValue={certificacion}
        type="text"
        name="certificado"
        error={errorCert}
        onChange={(e) => {
          if (errorCert) {
            setErrorCert(false);
          }
          setCertificacion(e.target.value);
        }}
        helpertext="Certificado no puede estar vacio"
      />


      <CustomSelectB
        label="Institución de certificación"
        onChange={(e) => {
          setErrorUniver(false);
          setUniversidad(e.target.value);
        }}
        name="university"
        value={universidad}
        error={errorUniver}
        helpertext="Seleccione un item"
      >
        {estudios_data.map((item, index) => (
          <MenuItem
            className="custom-menu-item"
            key={index}
            value={item.institucion}
          >
            {item.institucion}
          </MenuItem>
        ))}
      </CustomSelectB>


      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>

        <KeyboardDatePicker
          error={errorFecha}
          fullWidth
          size="small"
          label="Fecha Término"
          openTo="year"
          views={["year", "month"]}
          value={fecha}
          helperText={errorFecha ? "Seleccione una fecha" : null}
          maxDate={new Date()}
          onChange={(newValue) => changeInit(newValue)}
          InputProps={{
            className: "input-date-picker-inicio",
            readOnly: true,
          }}
          className="date-picker-inicio"
          InputLabelProps={{ className: "input-label-date-form" }}
        />

      </MuiPickersUtilsProvider>

      <CustomSelectB
        label="País"
        onChange={(e) => {
          setErrorPais(false);
          setPais(e.target.value);
        }}
        name="country"
        value={pais}
        error={errorPais}
        helpertext="Seleccione un país"
      >
        {paises.map((item, index) => (
          <MenuItem
            className="custom-menu-item"
            key={index}
            value={item.name}
          >
            {item.name}
          </MenuItem>
        ))}
      </CustomSelectB>


      <CustomSelectB
        label="Estado"
        onChange={(e) => {
          setActive(e.target.value);
        }}
        name="estado"
        value={active}
        helpertext="Tipo de estudio no puede estar vacio"
      >

        <MenuItem className="custom-menu-item" value="Finalizado">
          Finalizado
        </MenuItem>
        <MenuItem className="custom-menu-item" value="En Curso">
          En Curso
        </MenuItem>
       {/* <MenuItem className="custom-menu-item" value="Egresado">
          Egresado
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Titulado">
          Titulado
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Estudiando">
          Estudiando
        </MenuItem>
        <MenuItem className="custom-menu-item" value="No Finalizado">
          No Finalizado
        </MenuItem>*/}
      </CustomSelectB>




      <TextField

        fullWidth
        id="standard-multiline-static"
        label="Observaciones"
        multiline
        rows={2}
        onChange={(e) => {
          setObs(e.target.value);
        }}
        InputLabelProps={{ className: "multiline-form-estudios" }}
        InputProps={{ className: "multiline-form-estudios" }}
      />


      <input
        type="file"
        id="raised-button-file2"
        onChange={(e) => fileChange(e)}
        style={{ display: "none" }}
        accept="application/pdf, image/png, .jpeg, .jpg, image/gif, .doc, .docx"
      />
      <TextField
        fullWidth
        size="small"
        id="input-with-icon-textfield"
        label="Certificado"
        value={file ? file.name : null}
        defaultValue="Selecciona un archivo"
        InputLabelProps={{
          className: "input-file-estudios",
        }}
        InputProps={{
          readOnly: true,
          className: "input-file-estudios",
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Subir archivo pdf">
                <IconButton>
                  <label
                    htmlFor="raised-button-file2"
                    style={{ cursor: "pointer" }}
                  >
                    <CloudUpload className="icon-file-estudios" />
                  </label>
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />

      <br />
      <br />
      <div className="bottom-form-cert">
        <ListItem
          button
          className="btn-form-estudio"
          onClick={guardarCertificado}
        >
          <p style={{ color: "white" }}>Guardar</p>
        </ListItem>
      </div>
      {loading ? (
        <>
          <div className="overlay-loading"></div>
          <div className="linear-progres-global">
            <LinearProgress className="progres-editar-perfil" />
          </div>
        </>
      ) : null}




      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Loader
            type="Oval"
            color="#00BFFF"
            height={100}
            width={100}
            visible={loading}
          //  timeout={3000} //3 secs
          />
        </div>
      ) : certificados.length === 0 ? (
        <div className="cont-card-init-estudios">

        </div>
      ) : (
        <>
          <div class="lista-certificados">
            {certificados.map((item, index) => (
              <Card
                key={index}
                data={item}
                setIdEliminar={setIdEliminar}
                setOpenModalEliminar={setOpenModalEliminar}
                setOpenModalEditar={setOpenModalEditar}
                setDataEditar={setDataEditar}
              />
            ))}
            <Modal setOpenModal={setOpenModal} openModal={openModal} />
            <ModalEliminar
              certificado
              openModalEliminar={openModalEliminar}
              setOpenModalEliminar={setOpenModalEliminar}
              idEliminar={idEliminar}
              setIdEliminar={setIdEliminar}
            />
            <ModalEditar
              setOpenModalEditar={setOpenModalEditar}
              openModalEditar={openModalEditar}
              setDataEditar={setDataEditar}
              data={dataEditar}
            />
          </div>
        </>
      )}


    </>
  );
};

export default Formulario;
