import React, { useState, useEffect } from "react";
import "./CardPerfil.css";
import {
  Settings,
  Publish,
  Edit,
  Person,
  Email,
  PhoneAndroid,
  LocationOn,
  Flag,
  Fingerprint,
  People,
  MonetizationOn,
  Description,
  CloudUpload,
  CalendarToday,
  Event
} from "@material-ui/icons";
import { IconButton, Button } from "@material-ui/core";
import { Tooltip } from "../../../../components";
import imgUser from "../../../../resources/ZAPTalent-Icon-Empty.svg";
import { useSelector, useDispatch } from "react-redux";
import { subirImagenAction } from "../../../../redux/actions/authAction";
import Footer from "./footer/Footer";
import NumberFormat from "react-number-format";
import moment from "moment";

const CardPerfilEmpNew = ({
  openModalRRSS,
  setOpenModalRRSS,
  setOpenModal,
  setActive,
  setActiveStep,
  habilidades,
  setOpenModalHab,
  empresas,
  porcentaje,
  usuarioemp,
  setOpenEditEmp,
  setStep
}) => {
  const dispatch = useDispatch();
  let usuario; 
  const usuarioA = useSelector((state) => state.auth.usuario);
  const usuarioB = useSelector((state) => state.userInfo.usuario);


  if (empresas) {
    usuario = usuarioB;
  } else {
    usuario = usuarioA;
  }
  const [active2, setActive2] = useState(true);
  let profesion;
  if (usuario) {
    profesion = usuario.profesion ? usuario.profesion.name : null;
  }
  const [image, setImage] = useState({
    preimage: usuario ? usuario.imageURL : null,
    name: "",
  });
  const [file, setFile] = useState(null);

  const fileChange = (e) => {
    if (e.target.value) {
      setImage({
        preimage: URL.createObjectURL(e.target.files[0]),
        name: e.target.value,
      });
      setFile(e.target.files[0]);
    }
  };
  const imageURL = file;

  useEffect(() => {
    const subirImage = () => {
      dispatch(
        subirImagenAction({ _id: usuario._id, imageURL, type: "image" })
      );
    };
    if (imageURL) {
      subirImage();
    }
  }, [file]);

  useEffect(() => {
    setImage({ preimage: usuarioemp ? usuarioemp.logoURL : null, name: "" });
  }, [usuarioemp]);
  return (
    <div className="new-card-perfil-emp">
      <div className="item-1">
        <div>
          <Settings className="icon-header-perfil-new" />{" "}
          <p>Perfil completado en {porcentaje}%</p>
        </div>
      </div>
      <div className="item-2">
        <div>
          {image.preimage ? (
            <div className="cont-img-perfil-perfil-new">
              <img
                className="user-img-perfil-perfil-new"
                alt="user-img"
                src={image.preimage}
              ></img>
            </div>
          ) : (
            <div className="cont-img-perfil-perfil-new">
              <img
                src={imgUser}
                className="user-img-perfil-new"
                alt="user-imge"
              />
            </div>
          )}
        </div>
        <p className="p1">
          {usuarioemp ? usuarioemp.razonSocial : "Indefinido"}
        </p>
        
        <div className="btn-perfil-new">
          <Button
            className={active2 ? "btn-active" : "btn-inactive"}
            onClick={() => setActive2(true)}
          >
            <p>Datos</p>
          </Button>
        </div>
      </div>
      <div className="item-3">
        {active2 ? (
          <>
            <DatosPersonales usuario={usuarioemp} />
            {
              (usuarioemp && usuarioemp.tipoPerfil==="admin")?
              <Tooltip title="Editar perfil">
                <IconButton
                  className="btn-edit-perfil-new"
                  onClick={() => {
                    setOpenEditEmp(true)
                    setStep('one')
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>:null
            }
            
          </>
        ) : (
          <Habilidades
            habilidades={habilidades}
            setOpenModalHab={setOpenModalHab}
          />
        )}
      </div>
    </div>
  );
};

export default CardPerfilEmpNew;

const DatosPersonales = ({ usuario }) => {
 
  const fechainicioMostrar = moment(usuario?usuario.fechaInicioEmp:'').format("DD/MM/YYYY");
  const fechaTerminoMostrar = moment(usuario?usuario.fechaTerminoEmp:'').format("DD/MM/YYYY");



  return (
    <>
      <div className="item-card-perfil-b">
        <div className="sub-item-pefil-left">
          <div className="cont-img-perfil-new">
            <Person className="icon-perfil-new" />
          </div>
        </div>
        <div className="sub-item-pefil-left-2">
          <div className="sub-item-pefil-right-top">
            <p className="p1-perfil-b">Razón Social</p>
          </div>
          <div className="sub-item-pefil-right-bottom">
            <p className="p2-perfil-b">
              {usuario
                ? usuario.razonSocial
                : "Indefinido"}
            </p>
          </div>
        </div>
      </div>
      <div className="item-card-perfil-b">
        <div className="sub-item-pefil-left">
          <div className="cont-img-perfil-new">
            <Fingerprint className="icon-perfil-new" />
          </div>
        </div>
        {usuario ? (
          (
            <div className="sub-item-pefil-left-2">
              <div className="sub-item-pefil-right-top">
                <p className="p1-perfil-b">Rut</p>
              </div>
              <div className="sub-item-pefil-right-bottom">
                <p className="p2-perfil-b">
                  {usuario ? usuario.rut : "Indefinido"}
                </p>
              </div>
            </div>
          )
        ) : null}
      </div>
      <div className="item-card-perfil-b">
        <div className="sub-item-pefil-left">
          <div className="cont-img-perfil-new">
            <Email className="icon-perfil-new" />
          </div>
        </div>
        <div className="sub-item-pefil-left-2">
          <div className="sub-item-pefil-right-top">
            <p className="p1-perfil-b">Email</p>
          </div>
          <div className="sub-item-pefil-right-bottom">
            <p className="p2-perfil-b">
              {usuario ? usuario.email : "Indefinido"}
            </p>
          </div>
        </div>
      </div>
      <div className="item-card-perfil-b">
        <div className="sub-item-pefil-left">
          <div className="cont-img-perfil-new">
            <PhoneAndroid className="icon-perfil-new" />
          </div>
        </div>
        <div className="sub-item-pefil-left-2">
          <div className="sub-item-pefil-right-top">
            <p className="p1-perfil-b">Número móvil</p>
          </div>
          <div className="sub-item-pefil-right-bottom">
            <p className="p2-perfil-b">
              {usuario ? usuario.telefonos[0].telefono : "Indefinido"}
            </p>
          </div>
        </div>
      </div>
      <div className="item-card-perfil-b">
        <div className="sub-item-pefil-left">
          <div className="cont-img-perfil-new">
            <People className="icon-perfil-new" />
          </div>
        </div>
        <div className="sub-item-pefil-left-2">
          <div className="sub-item-pefil-right-top">
            <p className="p1-perfil-b">Reseña</p>
          </div>
          <div className="sub-item-pefil-right-bottom">
            <p className="p2-perfil-b">
              {usuario ? usuario.resena : "Indefinido"}
            </p>
          </div>
        </div>
      </div>
      <div className="item-card-perfil-b">
        <div className="sub-item-pefil-left">
          <div className="cont-img-perfil-new">
            <CalendarToday className="icon-perfil-new" />
          </div>
        </div>
        <div className="sub-item-pefil-left-2">
          <div className="sub-item-pefil-right-top">
            <p className="p1-perfil-b">Fecha Inicio</p>
          </div>
          <div className="sub-item-pefil-right-bottom">
            <p className="p2-perfil-b">
              {usuario ? fechainicioMostrar : "Indefinido"}
            </p>
          </div>
        </div>
      </div>
      <div className="item-card-perfil-b">
        <div className="sub-item-pefil-left">
          <div className="cont-img-perfil-new">
            <Event className="icon-perfil-new" />
          </div>
        </div>
        <div className="sub-item-pefil-left-2">
          <div className="sub-item-pefil-right-top">
            <p className="p1-perfil-b">Fecha Termino</p>
          </div>
          <div className="sub-item-pefil-right-bottom">
            <p className="p2-perfil-b">
              {usuario ? fechaTerminoMostrar : "Indefinido"}
            </p>
          </div>
        </div>
      </div>
      {usuario ? (
        usuario.pretencion ? (
          <div className="item-card-perfil-b">
            <div className="sub-item-pefil-left">
              <div className="cont-img-perfil-new">
                <MonetizationOn className="icon-perfil-new" />
              </div>
            </div>
            <div className="sub-item-pefil-left-2">
              <div className="sub-item-pefil-right-top">
                <p className="p1-perfil-b">Pretensión de renta</p>
              </div>
              <div className="sub-item-pefil-right-bottom">
                <p className="p2-perfil-b">
                  {usuario ? (
                    <>
                      {"$ "}
                      <NumberFormat
                        value={usuario.pretencion}
                        displayType={"text"}
                        thousandSeparator={true}
                        // prefix={"$"}
                      /> {usuario.moneda?usuario.moneda:"CLP"}
                    </>
                  ) : (
                    "Indefinido"
                  )}
                </p>
              </div>
            </div>
          </div>
        ) : null
      ) : null}
      <div className="item-card-perfil-b">
        <div className="sub-item-pefil-left">
          <div className="cont-img-perfil-new">
            <LocationOn className="icon-perfil-new" />
          </div>
        </div>
        <div className="sub-item-pefil-left-2">
          <div className="sub-item-pefil-right-top">
            <p className="p1-perfil-b">País</p>
          </div>
          <div className="sub-item-pefil-right-bottom">
            <p className="p2-perfil-b">
              {usuario
                ? (usuario.direcciones[0].pais ?  
                  usuario.direcciones[0].pais  : '') 
                  : "Indefinido"}
            </p>
          </div>
        </div>
      </div>

      <div className="item-card-perfil-b">
        <div className="sub-item-pefil-left">
          <div className="cont-img-perfil-new">
            <LocationOn className="icon-perfil-new" />
          </div>
        </div>
        <div className="sub-item-pefil-left-2">
          <div className="sub-item-pefil-right-top">
            <p className="p1-perfil-b">Dirección</p>
          </div>
          <div className="sub-item-pefil-right-bottom">
            <p className="p2-perfil-b">
              {usuario
                ? (usuario.direcciones[0].direccion ?  
                  usuario.direcciones[0].direccion  : '') +
                  (usuario.direcciones[0].comuna ? 
                   ", " +usuario.direcciones[0].comuna + ", " : '') +
                  usuario.direcciones[0].region
                : "Indefinido"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const Habilidades = ({ habilidades }) => {
  return (
    <div className="cont-habilidades-perfil-new">
      <div className="item-1-b">
        {habilidades
          ? habilidades.map((item, index) => (
              <div className="habilidad">
                <p>{item.name}</p>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};
