import React from "react";
import "./Header.css";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    backgroundColor: "#187ce2",
    color: "white",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.2)",
    "&:hover": {
      backgroundColor: "#105296",
    },
  },
});
const Header = () => {
  const classes = useStyles();
  return (
    <div className="header-home-admin">
      <p>Usuarios</p>
    </div>
  );
};

export default Header;
