import React, { useState, useEffect } from "react";
import "./Item.css";
import { Tooltip } from "../../../../../components";
import { IconButton, makeStyles } from "@material-ui/core";
import { Edit, Delete, SendRounded, Description, Warning, CheckCircleOutline, LinkOff } from "@material-ui/icons";
import logouser from "../../../../../resources/ZAPTalent-Icon-Empty.svg";
import { useHistory } from "react-router-dom";
import clientAxios from "../../../../../config/axios";
import Swal from 'sweetalert2';
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles({
  iconButton: {
    backgroundColor: "#187ce2",
    color: "white",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.2)",
    transition: "0.5s all ease-in-out",
    "&:hover": {
      backgroundColor: "#105296",
      transition: "0.5s all ease-in-out",
      transform: "scale(1.1)",
    },
  },
});




const Item = ({
  data,
  setOpenEditEmp,
  setDataEditar,
  setOpenViewEmp,
  setDataView,
  setOpenAddPerfil,
  setDataAddPerfil,
  setIdEliminar,
  setOpenModalEliminar,
  setOpenModalRep,
}) => {

  const {
    razonSocial,
    rut,
    giro,
    tipoPlan,
    direcciones,
    telefonos,
    fechaInicio,
    fechaTermino,
    logoURL,
    _id,
  } = data;
  const [estado, setEstado] = useState(null);
  const [color, setColor] = useState("#187ce2");

  const lastLogin = new Date(data.lastLogin);
  const _fechaTermino = new Date(fechaTermino);
  const _fechaTermino_ = new Date(fechaTermino);

  const now = new Date();

  const sevenDays = sumarDias(_fechaTermino_, -7);

  function sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }
  const classes = useStyles();

  const editarEmpresa = () => {
    setDataEditar(data);
    setOpenEditEmp(true);
  };

  const history = useHistory();

  const editarUsuario = (id) => {
    history.push('/admin/edit-user/' + id);
  }

  const verEmpresa = () => {
    setOpenViewEmp(true);
    setDataView(data);
  };
  const initDelete = () => {
    setIdEliminar(data._id);
    setOpenModalEliminar(true);
  };

  const agregarPerfil = () => {
    setDataAddPerfil(_id);
    setOpenAddPerfil(true);
  };

  const republicar = () => {
    setDataEditar(data);
    setOpenModalRep(true);
  };

  const setearEstado = () => {
    if (now <= _fechaTermino && now >= sevenDays) {
      setEstado("Activo");
      setColor("#F7B217");
    } else if (_fechaTermino >= now) {
      setEstado("Activo");
      setColor("#187ce2");
    } else if (_fechaTermino <= now) {
      setEstado("Caducado");
      setColor("#f44336");
    }
  };


  const enviarEmail = {
    email: data.email,
    name: data.nombres
  }

  const enviarConfirmacion = (e) => {
    clientAxios.put('/api/usuarios/enviarEmail/datos', enviarEmail)
      .then(resp => {
        Swal.fire('Listo!', 'Usuario Notificado', 'success');
      }).catch(err => {
        Swal.fire('Error!', err, 'error');
      });

  }


  useEffect(() => {
    if (fechaTermino) {
      setearEstado();
    }
  }, [fechaTermino]);
  return (
    <div className="item-table-users-admin">
      <div className="item-1">
        <div style={data.activo == 1 ? { backgroundColor: color, width: '80px' } : { backgroundColor: '#F89F0D', width: '80px' }}>
          <p>{data.activo == 1 ? 'Activo' : 'Inactivo'}</p>
        </div>
      </div>
      <div className="item-2">
        <img
          src={logouser}
          alt="perfil"
          className="foto-perfil-mini"
        />
      </div>
      <div className="item-3">
        <p>{data.nombres}</p>
        <p>{`ID: ${data._id}`}</p>
      </div>
      <div className="item-4">
        <p>Email</p>
        <p>{data.email}</p>
      </div>
      <div className="item-4">
        <p>Teléfono</p>
        <p>{data.phone}</p>
      </div>
      <div className="item-5">
        <p>Último Ingreso</p>
        <p>
          {data.lastLogin
            ? lastLogin.getDate() +
            "/" +
            (lastLogin.getMonth() + 1) +
            "/" +
            lastLogin.getFullYear() +
            " " +
            lastLogin.getHours() +
            ":" +
            lastLogin.getMinutes() +
            ":" +
            lastLogin.getSeconds()
            : null}
        </p>
      </div>


      <div className="item-1">
        {data ? (
          data.completarPerfil ? (
            <Tooltip title="El usuario SI ha autorizado actualizar su CV">
              <IconButton
                className={classes.iconButton}
                fontSize="small"
              >
                <CheckCircleOutline />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="El usuario NO ha autorizado actualizar su CV">
              <IconButton
                className={classes.iconButton}
                fontSize="small"
                style={{ backgroundColor: red[500] }}
              >
                <Warning />
              </IconButton>
            </Tooltip>
          )
        ) : null}
      </div>


      <div className="item-1">
        {data ? (
          data.cvURL ? (
            <Tooltip title="Ver CV.">
              <IconButton
                className={classes.iconButton}
                href={data.cvURL}
                target="_blank"
                fontSize="small"
              >
                <Description />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Sin CV.">
              <IconButton
                className={classes.iconButton}
                fontSize="small"
              >
                <LinkOff />
              </IconButton>
            </Tooltip>
          )
        ) : null}
      </div>
      <div className="item-9">
        <Tooltip title="Editar usuario" placement="top">
          <IconButton className={classes.iconButton} onClick={e => editarUsuario(data._id)}>
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar usuario" placement="top">
          <IconButton className={classes.iconButton} onClick={initDelete}>
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Enviar notificación" placement="top">
          <IconButton className={classes.iconButton}>
            <SendRounded fontSize="small" className={classes.icon} onClick={enviarConfirmacion} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default Item;
