import React from "react";
import { IconButton, ListItem } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import logo from "../../../../resources/img/ZAPTalent-Logotipo-Vertical-Original.svg";
import imageEmail from "../../../../resources/img/zaptalent-email.png";

import "./ModalConfirm.css";

const ModalFinished = ({ handleClose }) => {
  return (
    <div className="contentModal">
      <div className="btn-close-registro">
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </div>
      <div className="top-reg-A">
        <img src={logo} alt="logo" className="logo-login-re" />
        <img
          src={imageEmail}
          className="contentModal-image "
          alt="Imagen email"
        />
        <p className="p-descp">
          Y por último, hemos enviado un email de confirmación a tu cuenta de
          correo. Activa comienza a ser parte de ZAPTalent
        </p>
        <div className="btnControlsEmail">
          <ListItem
            button
            className="btn-step-one-emp btnModal"
            onClick={() => handleClose()}
          >
            <p>Aceptar</p>
          </ListItem>
        </div>
      </div>
    </div>
  );
};

export default ModalFinished;
