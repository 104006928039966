export const naciones = [
  "Afgano",
  "Alemán",
  "Árabe",
  "Argentino",
  "Australiano",
  "Belga",
  "Boliviano",
  "Brasileño",
  "Camboyano",
  "Canadiense",
  "Chileno",
  "Chino",
  "Colombiano",
  "Coreano",
  "Costarricense",
  "Cubano",
  "Danés",
  "Ecuatoriano",
  "Egipcio",
  "Salvadoreño",
  "Escocés",
  "Español",
  "Estadounidense",
  "Estonio",
  "Etiope",
  "Filipino",
  "Finlandés",
  "Francés",
  "Galés",
  "Griego",
  "Guatemalteco",
  "Haitiano",
  "Holandés",
  "Hondureño",
  "Indonés",
  "Inglés",
  "Iraquí",
  "Iraní",
  "Irlandés",
  "Israelí",
  "Italiano",
  "Japonés",
  "Jordano",
  "Laosiano",
  "Letón",
  "Letonés",
  "Malayo",
  "Marroquí",
  "Mexicano",
  "Nicaragüense",
  "Noruego",
  "Neozelandés",
  "Panameño",
  "Paraguayo",
  "Peruano",
  "Polaco",
  "Portugués",
  "Puertorriqueño",
  "Dominicano",
  "Rumano",
  "Ruso",
  "Sueco",
  "Suizo",
  "Tailandés",
  "Taiwanes",
  "Turco",
  "Ucraniano",
  "Uruguayo",
  "Venezolano",
  "Vietnamita",
];
