import React, { useState, useEffect } from "react";
import "./Perfil.css";

import { IconButton, makeStyles } from "@material-ui/core";
import { VpnKey, PhoneIphone } from "@material-ui/icons";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import shortid from "shortid";
import validator from "validator";
import { CustomInput, Tooltip } from "../../../../../components";
import clientAxios from "../../../../../config/axios";
import moment from "moment";

const useStyles = makeStyles({
  iconButton: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  icon: {
    color: "#9A99A1",
    marginRight: 10,
  },
});

const Perfil = ({ perfiles, setPerfiles, data, errores2,tipoPlan, origen }) => {
  const {
    id,
    tipoPerfil,
    rut,
    nombres,
    apellidos,
    email,
    password,
    fechaInicio,
    fechaTermino,
  } = data;
  //console.log(data);
  const classes = useStyles();
  const [tipoPlanA, setTipoPlan] = useState(tipoPerfil);
  const [rutA, setRut] = useState(rut);
  const [nombresA, setNombres] = useState(nombres);
  const [apellidosA, setApellidos] = useState(apellidos);
  const [emailA, setEmail] = useState(email);
  const [telefono, setTelefono] = useState("");
  const [passwordA, setPassword] = useState(password);
  //const [fechaInicioA, setFechaInicioA] = useState(fechaInicio);
  //const [fechaTerminoA, setFechaTerminoA] = useState(fechaTermino);
  const [initDefault, setInitDefault] = useState(true);
  const [error, setError] = useState(false);

  //ERRORES
  const [tipoPerfilError,setTipoPerfilError] = useState(false);
  const [rutError, setRutError] = useState(false);
  const [nombresError, setNombresError] = useState(false);
  const [apellidosError, setApellidosError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [telefonoError, setTelefonoError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  //const [fechaInicioError, setFechaInicioError] = useState(false);
  //const [fechaTerminoError, setFechaTerminoError] = useState(false);
  const [emailmsg, setEmailMsg] = useState("");
  const [telefonoMsg, setTelefonoMsg] = useState("");

  //const [fechaMsg, setFechaMsg] = useState("");
  const [rutMsg, setRutMsg] = useState("");
  const [passMsg, setPassMsg] = useState("");

  const inicio = moment().format();
  const termino = moment().add(tipoPlan.duracionPlan,"months").format();

  useEffect(() => {
    perfiles.map((item) => {
        item.fechaInicio = inicio;
        item.fechaTermino=termino;
        item.telefono = telefono;
        item.tipoPerfil="admin"
      });
  })


  const formatRut2 = () => {
    try {
      document.getElementById(
        `input-rut-empresa-usuario-admin-${id}`
      ).value = rutA;
      perfiles.map((item) => {
        if (item.id === id) {
          item.rut = rutA;
        }
      });
      console.log(perfiles);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    if (perfiles.length === 1) {
      return;
    } else {
      await setPerfiles(perfiles.filter((i) => i.id !== id));
    }
  };
  const generateKey = () => {
    let key = shortid();
    perfiles.map((item) => {
      if (item.id === id) {
        item.password = key;
      }
    });
    setPassword(key);
  };
  const validarErrores = async () => {
    //let rutvalidado = validateRut(rutA.toLocaleLowerCase());
    const _email = await clientAxios.put("/api/usuarioEmpresa/validar/email", {
      email: emailA.toLocaleLowerCase(),
    });
    const _rut = await clientAxios.put("/api/usuarioEmpresa/validar/rut", {
      rut: rutA,
    });
    const emailv = validator.isEmail(emailA);
    setTipoPlan(data.tipoPerfil);
    if (tipoPlanA === "") {
      setTipoPerfilError(true);
    }
    if (rutA.trim() === "") {
      setRutMsg("No puede estar vacío");
      setRutError(true);
    } /*else if (rutvalidado === false) {
      setRutMsg("Ingrese un rut válido");
      setRutError(true);
    }*/
    else if (_rut.data._rut === true) {
      setRutMsg("El RUT / DNI ya se encuentra registrado");
      setRutError(true);
    }
    if (nombresA === "") {
      setNombresError(true);
    }
    if (apellidosA === "") {
      setApellidosError(true);
    }
    if (emailA.trim() === "") {
      setEmailMsg("No puede estar vacío");
      setEmailError(true);
    } else if (emailv === false) {
      setEmailMsg("El formato es inválido");
      setEmailError(true);
    } else if (_email.data._email === true) {
      setEmailMsg("El email ya se encuentra registrado");
      setEmailError(true);
    }
    if (telefono.trim() === "") {
      setTelefonoMsg("No puede estar vacío");
      setTelefonoError(true);
    } else if (telefono.length > 15) {
      setTelefonoMsg("Verifique la longitud de su número de teléfono");
      setTelefonoError(true);
    } 
    if (passwordA.trim() === "") {
      setPassMsg("No puede estar vacío");
      setPasswordError(true);
    } else if (passwordA.length < 6) {
      setPassMsg("Debe contener al menos 6 caracteres");
      setPasswordError(true);
    }
 
  };
  
  useEffect(() => {
    if (initDefault === false) {
      if (errores2.length > 0) {
        console.log(errores2);
        const valor = errores2.filter((item) => item === data.id);
        if (valor.length > 0) {
          setError(true);
          document.getElementById(id).scrollIntoView();
          validarErrores();
        } else {
          setError(false);
        }
      }
    }
    setInitDefault(false);
    //console.log(errores2);
  }, [errores2]);

  return (
    <div
      id={id}
    >
      <div className="item-doble">
        <div>
          <CustomInput
            label="Nombre"
            helpertext="No puede estar vacio"
            error={nombresError}
            type="text"
            value={nombresA}
            defaultValue={nombresA}
            onChange={(e) => {
              setNombresError(false);
              perfiles.map((item) => {
                if (item.id === id) {
                  item.nombres = e.target.value;
                }
              });
              //console.log(perfiles);
              setNombres(e.target.value);
            }}
          />
        </div>
        <div>
          <CustomInput
            label="Apellidos"
            helpertext="No puede estar vacio"
            error={apellidosError}
            type="text"
            value={apellidosA}
            defaultValue={apellidosA}
            onChange={(e) => {
              setApellidosError(false);
              perfiles.map((item) => {
                if (item.id === id) {
                  item.apellidos = e.target.value;
                }
              });
              setApellidos(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="item-doble">
        <div>
          {origen == 'login'?
            <CustomInput
              label="RUT / DNI"
              id={`input-rut-empresa-usuario-admin-${id}`}
              error={rutError}
              helpertext={rutMsg}
              type="text"
              // value={rut}
              defaultValue={rut}
              //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              onChange={(e) => {
                setRutError(false);
                //setRut(formatRut(e.target.value, RutFormat.DOTS_DASH));
                setRut(e.target.value);
              }}
              onBlur={formatRut2}
            />
          :
            <CustomInput
            label="Rut"
            id={`input-rut-empresa-usuario-admin-${id}`}
            error={rutError}
            helpertext={rutMsg}
            type="text"
            // value={rut}
            defaultValue={rut}
            onChange={(e) => {
              setRutError(false);
              setRut(e.target.value);
            }}
            //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            onBlur={formatRut2}
          />
          }
        </div>
        <div>
          <CustomInput
            label="Email"
            helpertext={emailmsg}
            error={emailError}
            type="text"
            value={emailA}
            defaultValue={emailA}
            onChange={(e) => {
              setEmailError(false);
              perfiles.map((item) => {
                if (item.id === id) {
                  item.email = e.target.value;
                }
              });
              setEmail(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="item-doble">
        <div>
          <CustomInput
            label="Contraseña provisoria"
            helpertext={passMsg}
            error={passwordError}
            type="text"
            value={passwordA}
            defaultValue={passwordA}
            onChange={(e) => {
              setPasswordError(false);
              perfiles.map((item) => {
                if (item.id === id) {
                  item.password = e.target.value;
                }
              });
              setPassword(e.target.value);
            }}
            endAdornment={
              <Tooltip title="Generar contraseña aleatoria" placement="top">
                <IconButton size="small" onClick={generateKey}>
                  <VpnKey fontSize="small" />
                </IconButton>
              </Tooltip>
            }
          />
        </div>
        <div class="telefono-add-emp-admin">
        <PhoneIphone  fontSize="small" className={classes.icon} />
        <span className="plus">+</span>
        <CustomInput
            label="Teléfono (+56900000000)"
            helpertext={telefonoMsg}
            error={telefonoError}
            type="number"
            value={telefono}
            defaultValue={telefono}
            onChange={(e) => {
              setTelefonoError(false);
              perfiles.map((item) => {
                if (item.id === id) {
                  item.telefono = e.target.value;
                }
              });
              setTelefono(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Perfil;
