import React, { useState, useEffect } from "react";
import "./estudios.css";
import { CustomInput, CustomSelectB, Tooltip } from "../../../../components";
import {
  IconButton,
  ListItem,
  TextField,
  InputAdornment,
  MenuItem,
  LinearProgress,
  Hidden
} from "@material-ui/core";
import { Close, CloudUpload } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import { useSelector, useDispatch } from "react-redux";
import { paises } from "../../../../assets/paises";
import { agregarEstudioAction } from "../../../../redux/actions/estudioAction";
import { estudios_data } from "../../../../assets/carreras";
import { areaEstudio } from "../../../../assets/areaEstudio";
import { useParams } from "react-router-dom";
import clientAxios from "../../../../config/axios";
import Swal from 'sweetalert2';
import { obtenerEstudiosAction } from "../../../../redux/actions/estudioAction";
import { Modal, CardEstudio, ModalEditar } from "./components";
import { CardInit, Spinner, ModalEliminar } from "../../../../components";
import { Add } from "@material-ui/icons";
import Loader from "react-loader-spinner";


const Estudios = (props) => {
  const dispatch = useDispatch();
  //const usuario = useSelector((state) => state.auth.usuario);
  const loading = useSelector((state) => state.estudio.cargando);
  const [file, setFile] = useState(null);
  const { id: idUserParam } = useParams();
  const [usuario, setUsuario] = useState('');

  //data
  const [idusuario] = useState(idUserParam);
  const [tipoestudio, setTipoEstudio] = useState(null);
  const [carrera, setCarrera] = useState("");
  const [institucion, setInstitucion] = useState(null);
  const [pais, setPais] = useState(null);
  const [observacion, setObservacion] = useState("");
  const [active, setActive] = useState("");
  const [diafin, setValueT] = useState(null);
  const [nombreInstitucion, setNombreInstitucion] = useState(null);
  const [nombreCarrera, setNombreCarrera] = useState(null);
  // errores
  const [tipoestudioError, setTipoEstudioError] = useState(false);
  const [carreraError, setCarreraError] = useState(false);
  const [institucionError, setInstitucionError] = useState(false);
  const [paisError, setPaisError] = useState(false);
  const [observacionError, setObservacionError] = useState(false);
  const [diafinError, setDiaFinError] = useState(false);
  const [fechamsg, setFechaMsg] = useState("");
  const [errorInstitucion, setErrorInstitucion] = useState(false);
  const [errorCarrera, setErrorCarrera] = useState(false);

  const usuario_ = useSelector((state) => state.auth.usuario);
  const loading_ = useSelector((state) => state.estudio.loading);
  const estudios = useSelector((state) => state.estudio.estudios);
  const [cardT1, setCardT1] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalEliminar, setOpenModalEliminar] = useState(false);
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [dataEditar, setDataEditar] = useState(null);
  const [idEliminar, setIdEliminar] = useState("");
  


  const fileChange = (e) => {
    setFile(e.target.files[0]);
  };


  useEffect(() => {
    clientAxios.get(`/api/usuarios/${idUserParam}`)
      .then(res => {
        setUsuario(res.data)
      }).catch(err => console.log(err));
  }, []);

  const estudioURL = file;
  const guardarEstudio = () => {
    if (tipoestudio === null) {
      setTipoEstudioError(true);
      return;
    }
    if (carrera === "") {
      setCarreraError(true);
      return;
    }
    if (carrera=== 'Otros' && nombreCarrera === null || carrera=== 'Otros' && nombreCarrera === '') {
      setErrorCarrera(true);
      return;
    }
    if (institucion === null) {
      setInstitucionError(true);
      return;
    }
    if (institucion=== 'Otros' && nombreInstitucion === null || institucion=== 'Otros' && nombreInstitucion === '') {
      setErrorInstitucion(true);
      return;
    }
    if (diafin === null) {
      setDiaFinError(true);
      setFechaMsg("Fecha termino no puede estar vacio");
      return;
    }
    if (pais === null) {
      setPaisError(true);
      return;
    }
    // if (observacion.trim() === "") {
    //   setObservacionError(true);
    //   return;
    // }




    let carreras = usuario.carreras;
    carreras.push({ carrera, tipoestudio });

    dispatch(
      agregarEstudioAction({
        idusuario,
        tipoestudio,
        carrera,
        institucion,
        pais,
        observacion,
        estado: active,
        diafin,
        estudioURL,
        nombreInstitucion,
        nombreCarrera
      })
    ).then(
      (res) => {
        Swal.fire('Listo!', 'Estudio agregado!', 'success')
        dispatch(obtenerEstudiosAction(usuario._id));

      }

    );
  };

  
  const changeFin = (value) => {
    setDiaFinError(false);
    setValueT(value);
  };
  let data_carrera = estudios_data.find(
    (item) => item.institucion === institucion
  );


  useEffect(() => {
    if (usuario) {
      if (usuario.sexo === "Femenino") {
        setCardT1("typeA2");
      } else {
        setCardT1("typeA1");
      }
    }
  }, [usuario]);

  useEffect(() => {
    const cargarEstudios = () =>
      dispatch(obtenerEstudiosAction(idUserParam));
    cargarEstudios();

    // eslint-disable-next-line
  }, [usuario]);

  const datasort = estudios.sort(function (a, b) {
    a = new Date(a.diafin);
    b = new Date(b.diafin);
    return a > b ? -1 : a < b ? 1 : 0;
  });



  return (
    <>
      <CustomSelectB
        label="Tipo de estudio"
        onChange={(e) => {
          setTipoEstudioError(false);
          setTipoEstudio(e.target.value);
        }}
        name="tipo-estudio"
        value={tipoestudio}
        error={tipoestudioError}
        helpertext="Tipo de estudio no puede estar vacio"
      >
        <MenuItem
          className="custom-menu-item"
          value="Técnico/Universitario"
        >
          Tecnico/Universitario
        </MenuItem>
        <MenuItem className="custom-menu-item" value="PostGrado">
          PostGrado
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Master">
          Master
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Doctorado">
          Doctorado
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Otros">
          Otros
        </MenuItem>
      </CustomSelectB>


      <CustomSelectB
        label="Universidad/Instituto/Colegio"
        onChange={(e) => {
          setInstitucionError(false);
          setInstitucion(e.target.value);
        }}
        name="university"
        value={institucion}
        error={institucionError}
        helpertext="Universidad, Instituto no puede estar vacio"
      >
        {estudios_data.map((item, index) => (
          <MenuItem
            className="custom-menu-item"
            key={index}
            value={item.institucion}
          >
            {item.institucion}
          </MenuItem>
        ))}
      </CustomSelectB>

      {institucion === "Otros" ?
          <div className="campo-form-estudio">
            <CustomInput
            label="Institución"
            type="text"
            onChange={(e) => {
              if (institucionError) {
                setInstitucionError(false);
              }
              setNombreInstitucion(e.target.value);
            }}
            name="institucion"
            value={nombreInstitucion}
            error={errorInstitucion}
            helpertext="Institución no puede estar vacio"
            /> </div>: null           
        }


      {tipoestudio != "PostGrado" && tipoestudio != "Master" && tipoestudio != "Doctorado" ?
        (<CustomSelectB
          label="Carrera/Título"
          onChange={(e) => {
            setCarreraError(false);
            setCarrera(e.target.value);
          }}
          name="carrera"
          value={carrera}
          error={carreraError}
          helpertext="Carrera no puede estar vacio"
        >
          {data_carrera ? (
            data_carrera.carreras.map((item, index) => (
              <MenuItem className="custom-menu-item" key={index} value={item}>
                {item}
              </MenuItem>
            ))
          ) : (
            <MenuItem className="custom-menu-item" value={null}>
              Seleccione una institución
            </MenuItem>
          )}
        </CustomSelectB>) :

        <CustomInput
          label="Carrera/Título"
          type="text"
          onChange={(e) => {
            if (carreraError) {
              setCarreraError(false);
            }
            setCarrera(e.target.value);
          }}
          name="carrera"
          value={carrera}
          error={carreraError}
          helpertext="Carrera no puede estar vacio"
        />
      }

      {carrera === "Otros" ?
          <div className="campo-form-estudio">
            <CustomInput
            label="Carrera"
            type="text"
            onChange={(e) => {
              if (carreraError) {
                setCarreraError(false);
              }
              setNombreCarrera(e.target.value);
            }}
            name="carrera"
            value={nombreCarrera}
            error={errorCarrera}
            helpertext="Carrera no puede estar vacio"
            /> </div>: null           
        }




      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>


        <KeyboardDatePicker
          error={diafinError}
          size="small"
          fullWidth
          label="Término"
          openTo="year"
          helperText={diafinError ? fechamsg : null}
          views={["year", "month"]}
          value={diafin}
          maxDate={new Date()}
          onChange={(newValue) => changeFin(newValue)}
          InputProps={{
            className: "input-date-picker-inicio",
            readOnly: true,
          }}
          className="date-picker-inicio"
          InputLabelProps={{ className: "input-label-date-form" }}
        />

      </MuiPickersUtilsProvider>



      <CustomSelectB
        label="País"
        onChange={(e) => {
          setPaisError(false);
          setPais(e.target.value);
        }}
        name="country"
        value={pais}
        error={paisError}
        helpertext="País no puede estar vacio"
      >
        {paises.map((item, index) => (
          <MenuItem
            className="custom-menu-item"
            key={index}
            value={item.name}
          >
            {item.name}
          </MenuItem>
        ))}
      </CustomSelectB>



      <CustomSelectB
        label="Estado"
        onChange={(e) => {
          setActive(e.target.value);
        }}
        name="estado"
        value={active}
        helpertext="Tipo de estudio no puede estar vacio"
      >
        <MenuItem className="custom-menu-item" value="Egresado">
          Egresado
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Titulado">
          Titulado
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Estudiando">
          Estudiando
        </MenuItem>
        <MenuItem className="custom-menu-item" value="No Finalizado">
          No Finalizado
        </MenuItem>
      </CustomSelectB>



      <TextField
        error={observacionError}
        fullWidth
        id="standard-multiline-static"
        label="Observaciones"
        multiline
        helperText={
          observacionError ? "Observaciones no puede estar vacio" : null
        }
        rows={2}
        onChange={(e) => {
          if (observacionError) {
            setObservacionError(false);
          }
          setObservacion(e.target.value);
        }}
        InputLabelProps={{ className: "multiline-form-estudios" }}
        InputProps={{ className: "multiline-form-estudios" }}
      />


      <input
        type="file"
        id="raised-button-file"
        onChange={(e) => fileChange(e)}
        style={{ display: "none" }}
        accept="application/pdf, image/png, .jpeg, .jpg, image/gif, .doc, .docx"
      />
      <TextField
        fullWidth
        size="small"
        id="input-with-icon-textfield"
        label="Certificado"
        value={file ? file.name : null}
        defaultValue="Selecciona un archivo"
        InputLabelProps={{
          className: "input-file-estudios",
        }}
        InputProps={{
          readOnly: true,
          className: "input-file-estudios",
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Subir archivo">
                <IconButton>
                  <label
                    htmlFor="raised-button-file"
                    style={{ cursor: "pointer" }}
                  >
                    <CloudUpload className="icon-file-estudios" />
                  </label>
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />

      <br />
      <br />
      <div className="bottom-form-estudios">
        <ListItem button className="btn-form-estudio" onClick={guardarEstudio}>
          <p style={{ color: "white" }}>Guardar</p>
        </ListItem>
      </div>
      {loading ? (
        <>
          <div className="overlay-loading"></div>
          <div className="linear-progres-global">
            <LinearProgress className="progres-editar-perfil" />
          </div>
        </>
      ) : null}


      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Loader
            type="Oval"
            color="#00BFFF"
            height={100}
            width={100}
            visible={loading}
          //  timeout={3000} //3 secs
          />
        </div>
      ) : estudios.length === 0 ? (
        <div className="cont-card-init-estudios">


        </div>
      ) : (
        <>
          <div className="conteiner-cards-estudio_mantenedor">

            {datasort.map((item, index) => (
              <CardEstudio
                key={index}
                data={item}
                setIdEliminar={setIdEliminar}
                setOpenModalEliminar={setOpenModalEliminar}
                setOpenModalEditar={setOpenModalEditar}
                setDataEditar={setDataEditar}
              />
            ))}
            <ModalEditar
              setOpenModalEditar={setOpenModalEditar}
              openModalEditar={openModalEditar}
              setDataEditar={setDataEditar}
              data={dataEditar}
            />
            <Modal setOpenModal={setOpenModal} openModal={openModal} />
            <ModalEliminar
              estudio
              openModalEliminar={openModalEliminar}
              setOpenModalEliminar={setOpenModalEliminar}
              idEliminar={idEliminar}
              setIdEliminar={setIdEliminar}
            />
          </div>
        </>
      )}

    </>
  );
};

export default Estudios;
