import React, { useState, useEffect } from "react";
import "./Item.css";
import { Tooltip } from "../../../../components";
import { IconButton, makeStyles } from "@material-ui/core";
import { Edit, Delete, SendRounded, Description, Warning, CheckCircleOutline, LinkOff } from "@material-ui/icons";
import logouser from "../../../../resources/ZAPTalent-Icon-Empty.svg";
import { useHistory } from "react-router-dom";
import clientAxios from '../../../../config/axios';
import Swal from 'sweetalert2';
import { red } from "@material-ui/core/colors";
import moment from "moment";
import { useSelector } from "react-redux";


const useStyles = makeStyles({
  iconButton: {
    backgroundColor: "#187ce2",
    color: "white",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.2)",
    transition: "0.5s all ease-in-out",
    "&:hover": {
      backgroundColor: "#105296",
      transition: "0.5s all ease-in-out",
      transform: "scale(1.1)",
    },
  },
});




const Item = ({
  data,
  setOpenEditPerfil,
  setDataEditar,
  setOpenViewEmp,
  setDataView,
  setOpenAddPerfil,
  setDataAddPerfil,
  setIdEliminar,
  setOpenModalEliminar,
  setOpenModalRep,
  setStep,
  setUsuarioSelected,
}) => {

  const {
    razonSocial,
    rut,
    giro,
    tipoPlan,
    direcciones,
    telefonos,
    fechaInicio,
    fechaTermino,
    logoURL,
    _id
  } = data;
  const usuarioemp = useSelector((state) => state.authEmp.usuario);
  const [estado, setEstado] = useState(null);
  const [color, setColor] = useState("#187ce2");

  const lastLogin = new Date(data.lastLogin);
  const _fechaTermino = new Date(fechaTermino);
  const _fechaTermino_ = new Date(fechaTermino);
  const fechaTerminoMostrar = moment(fechaTermino).format("DD/MM/YYYY");

  const now = new Date();

  const sevenDays = sumarDias(_fechaTermino_, -7);

  function sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }
  const classes = useStyles();

  const editarEmpresa = (data) => {
    setUsuarioSelected(data)
    setOpenEditPerfil(true);
  };

  const history = useHistory();

  const editarUsuario = (id) => {
    setStep("four")
  }

  const verEmpresa = () => {
    setOpenViewEmp(true);
    setDataView(data);
  };
  const initDelete = () => {
    setIdEliminar(data._id);
    setOpenModalEliminar(true);
  };

  const agregarPerfil = () => {
    setDataAddPerfil(_id);
    setOpenAddPerfil(true);
  };

  const republicar = () => {
    setDataEditar(data);
    setOpenModalRep(true);
  };

  const setearEstado = () => {
    if (now <= _fechaTermino && now >= sevenDays) {
      setEstado("Activo");
      setColor("#F7B217");
    } else if (_fechaTermino >= now) {
      setEstado("Activo");
      setColor("#187ce2");
    } else if (_fechaTermino <= now) {
      setEstado("Caducado");
      setColor("#f44336");
    }
  };


  const enviarEmail = {
    email: data.email,
    name: data.nombres
  }

  const enviarConfirmacion = (e) => {
    clientAxios.put('/api/usuarios/enviarEmail/datos', enviarEmail)
      .then(resp => {
        Swal.fire('Listo!', 'Usuario Notificado', 'success');
      }).catch(err => {
        Swal.fire('Error!', err, 'error');
      });

  }


  useEffect(() => {
    if (fechaTermino) {
      setearEstado();
    }
  }, [fechaTermino]);
  return (
    <div className="item-table-users-admin">
      <div className="item-3">
        <p className="text-overflow">{data.nombres} {data.apellidos}</p>
      </div>
      <div className="item-4">
        <p>Email</p>
        <p className="text-overflow">{data.email}</p>
      </div>
      <div className="item-5">
        <p>Fecha fin</p>
        <p className="text-overflow">
          {fechaTerminoMostrar}
        </p>
      </div>


      <div className="item-9">
      {
        (data.esCuentaPrincipal && !usuarioemp.esCuentaPrincipal)? null:
          (usuarioemp.tipoPerfil==="admin")?
        <Tooltip title="Editar usuario" placement="top">
          <IconButton className={classes.iconButton} onClick={e => editarEmpresa(data)}>
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
        :(usuarioemp.tipoPerfil==="gest" && usuarioemp._id===data._id)?
        <Tooltip title="Editar usuario" placement="top">
          <IconButton className={classes.iconButton} onClick={e => editarEmpresa(data)}>
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>:
        null
    }
        {
          (usuarioemp.esCuentaPrincipal && usuarioemp._id!==data._id)?
          <Tooltip title="Eliminar usuario" placement="top">
            <IconButton className={classes.iconButton} onClick={initDelete} >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>:
          (usuarioemp.tipoPerfil==="admin" && !data.esCuentaPrincipal && usuarioemp._id!==data._id)?
          <Tooltip title="Eliminar usuario" placement="top">
            <IconButton className={classes.iconButton} onClick={initDelete} >
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>:
          null
        }
        
      </div>
    </div>
  );
};

export default Item;
