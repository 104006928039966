import React from "react";
import { Modal, Fade, Backdrop, makeStyles } from "@material-ui/core";
import "./ModalPostular.css";
import { ListItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  crearPostulacionAction
} from "../../../../redux/actions/postAction";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const CustomModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const usuario = useSelector((state) => state.auth.usuario);
  const {
    setOpenModalPostular,
    openModalPostular,
    idaviso,
    iduser,
    idemp,
    titulo,
  } = props;

  const postularCancel = () => {
    setOpenModalPostular(false);
  };

  const postularTrabajo = async () => {
    setOpenModalPostular(false);
    if (usuario) {
      dispatch(
        crearPostulacionAction({
          idaviso: idaviso,
          iduser: iduser,
          idemp: idemp,
          titulo: titulo,
        })
      )
    }
   
  };
  const PostularComponent = () => {
    return (
      <div className="cont-modal-postular">
        <div className="top-modal-postular">
          <div>
            <p>¿Está seguro que desea postular a la oferta {titulo}?</p>
          </div>
        </div>
        <div className="bottom-modal-postular">
          <ListItem
            button
            className="btn-borrar-modal-postular"
            onClick={() => postularTrabajo()}
          >
            <p>Postular</p>
          </ListItem>

          <ListItem
            button
            className="btn-cancelar-modal-postular"
            onClick={() => postularCancel()}
          >
            <p>Cancelar</p>
          </ListItem>
        </div>
      </div>
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModalPostular}
      // open={true}
      onClose={() => postularCancel()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalPostular}>
        <PostularComponent />
      </Fade>
    </Modal>
  );
};

export default CustomModal;
