/* eslint-disable no-lone-blocks */
import React, { useRef, useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { AccountCircle, Add } from "@material-ui/icons";
import "./Perfil.css";
import {  Tooltip } from "../../components";
import {
  CardPerfilNew,
  CardJobNew,
  CardCert,
  CardAdnNew,
  CardPost,
  CardProNew,
  CardEst,
  Drawer,
} from "../../containers/perfil/components";
import logo from "../../resources/images/SPAimages/icon-card-cv-saptalent.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  obtenerUserInfoAction,
  obtenerEstudiosUserInfoAction,
  obtenertrabajosUserInfoAction,
  obtenerCertificadosUserInfoAction,
  obtenerAdnUserInfoAction,
} from "../../redux/actions/actions-emp/infoUserAction";
import { obtenerPostulacionesEmpresaAction, obtenerTotalUsuariosAction } from "../../redux/actions/actions-emp/postuladosAction";
import Loader from "react-loader-spinner";
import CardPerfilEmpNew from "../../containers/perfil/components/cardPerfilEmpNew/CardPerfil";

import {
  ModalAddPerfil,
  ModalEditar, ModalEditPerfil, ModalEliminar, Table,
} from "./index";

import { obtenerUsersAction, obtenerUsersEmpresaAction } from "../../redux/actions/actions-admin/usersAction";


const Perfil = (props) => {
  const {
    match: { params },
  } = props;
  const { id } = params;

  const componentRef = useRef();

  const dispatch = useDispatch();

  const [openModalRRSS, setOpenModalRRSS] = useState({
    open: false,
    type: "Instagram",
  });
  /** */
  const [openDrawer, setOpenDrawer] = useState(false);
  const [cardSexo, setCardSexo] = useState("");
  const [dataProfesion, setDataProfesion] = useState(null);
  const [_switch, setSwitch] = useState(false);
  const [active, setActive] = useState("one");
  const [activeStep, setActiveStep] = useState(0);

 

  /*DATOS REDUX**/
  const usuarioemp = useSelector((state) => state.authEmp.usuario);
  const postulaciones = useSelector((state) => state.userInfo.postulaciones);
  const loadingPost = useSelector((state) => state.userInfo.loadingPost);
  const usuario = useSelector((state) => state.userInfo.usuario);
  const loading = useSelector((state) => state.userInfo.loading);
  const estudios = useSelector((state) => state.userInfo.estudios);
  const loadingEst = useSelector((state) => state.userInfo.loadingEst);
  const trabajos = useSelector((state) => state.userInfo.trabajos);
  const loadingTrab = useSelector((state) => state.userInfo.loadingTrab);
  const certificados = useSelector((state) => state.userInfo.certificados);
  const loadingCert = useSelector((state) => state.userInfo.loadingCert);
  const adns = useSelector((state) => state.userInfo.adns);
  const loadingAdn = useSelector((state) => state.userInfo.loadingAdn);

  const [openEditEmp, setOpenEditEmp] = useState(false);
  const [dataEditar, setDataEditar] = useState(null);
  const [step, setStep] = useState("one");

  //ELIMINAR USUARIO
  const [idEliminar, setIdEliminar] = useState("");
  const [openModalEliminar, setOpenModalEliminar] = useState(false);

  //FILTROS
  const [skip, setSkip] = useState(0);
  const [query, setQuery] = useState({ state: "activos" });
  const [value, setValue] = useState(0);

  const [usuarioSelected, setUsuarioSelected] = useState({})

  const [openAddPerfil, setOpenAddPerfil] = useState(false);

  const [openEditPerfil, setOpenEditPerfil] = useState(false);
  const [dataEditPerfil, setDataEditPerfil] = useState(null);
  const [refreshPerfiles, setRefreshPerfiles] = useState(false);



  const usuarios = useSelector((state) => state.usuarios.usuarios);
  const totalusers = useSelector((state) => state.postulados.totalusers);

  const handleScroll = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;

    if (offsetHeight + scrollTop === scrollHeight) {
      setSkip(usuarios.length);
    }
  };



  useEffect(() => {
    setDataEditar(usuarioemp)
  })
  
  // const estudios = [];
  //console.log(usuario);
  useEffect(() => {
    if (usuario) {
      if (usuario.sexo === "Femenino") {
        setCardSexo("typeFeme");
      } else {
        setCardSexo("typeMasc");
      }
    }
  }, [usuario]);
  useEffect(() => {
    if (!usuario) {
      dispatch(obtenerUserInfoAction(id));
    }
  }, []);

  useEffect(() => {
    if (estudios.length === 0) {
      if (usuario) {
        const cargarEstudios = () =>
          dispatch(obtenerEstudiosUserInfoAction(usuario._id));
        cargarEstudios();
      }
    }
    // eslint-disable-next-line
  }, [usuario]);
  useEffect(() => {
    if (certificados.length === 0) {
      if (usuario) {
        const cargarCertificados = () =>
          dispatch(obtenerCertificadosUserInfoAction(usuario._id));
        cargarCertificados();
      }
    }
    // eslint-disable-next-line
  }, [usuario]);

  useEffect(() => {
    if (adns.length === 0) {
      if (!_switch) {
        if (usuario) {
          dispatch(obtenerAdnUserInfoAction(usuario._id));
        }
      }
    }
  }, [usuario, _switch]);

  useEffect(() => {
    if (trabajos.length === 0) {
      if (usuario) {
        const cargarTrabajos = () =>
          dispatch(obtenertrabajosUserInfoAction(usuario._id));
        cargarTrabajos();
      }
    }

    //eslint-disable-next-line
  }, [usuario]);
  useEffect(() => {
    if (usuario && usuarioemp) {
      if (postulaciones.length === 0) {
        if (usuario) {
          const cargarPostulaciones = () =>
            dispatch(
              obtenerPostulacionesEmpresaAction({
                iduser: usuario._id,
                idemp: usuarioemp._id,
              })
            );
          cargarPostulaciones();
        }
      }
    }
    //eslint-disable-next-line
  }, [usuario]);

  //   useEffect(() => {
  //     if (postulaciones.length === 0) {
  //       if (usuario) {
  //         dispatch(obtenerPostulacionesAction(usuario._id));
  //       }
  //     }
  //   }, [usuario]);

  useEffect(() => {
    if (estudios) {
      estudios.map((item) => {
        if (usuario.profesion) {
          if (usuario.profesion._id === item._id) {
            setDataProfesion(item);
          }
        }
      });
    }
  }, [usuario, estudios]);

  useEffect(() => {
    if(usuarioemp){
      const idEmpresa=usuarioemp.idemp;
      dispatch(obtenerUsersEmpresaAction({ skip: skip, query},idEmpresa));
      dispatch(obtenerTotalUsuariosAction());
    }
    
  }, [skip, _switch,usuarioemp]);

  const recargarPerfilesfun=()=>{
    const idEmpresa=usuarioemp.idemp;
    dispatch(obtenerUsersEmpresaAction({ skip: skip, query},idEmpresa));
    dispatch(obtenerTotalUsuariosAction());
  }

  const recargarEmpresa=()=>{
    dispatch(obtenerUserInfoAction(usuarioemp._id));
  }

  return (
    
    <div className="cont-new-perfil">
      {
        (usuarioemp && usuarioemp.tipoPerfil==="admin")?
          <Tooltip title="Agregar perfil">
          <IconButton
                    className="icon-btn-add-perfiles"
                    onClick={() => setOpenAddPerfil(true)}
                  >
                    <Add />
                  </IconButton>
        </Tooltip>:
        null
      }
      
      <Drawer
        empresa
        open={openDrawer}
        setOpen={setOpenDrawer}
        openModalRRSS={openModalRRSS}
        setOpenModalRRSS={setOpenModalRRSS}
        setActive={setActive}
        setActiveStep={setActiveStep}
        habilidades={usuario ? usuario.habilidades : null}
      />
        <CardPerfilEmpNew
          empresas
          openModalRRSS={openModalRRSS}
          setOpenModalRRSS={setOpenModalRRSS}
          setActive={setActive}
          setActiveStep={setActiveStep}
          habilidades={usuario ? usuario.habilidades : null}
          usuarioemp={usuarioemp}
          setOpenEditEmp={setOpenEditEmp}
          setStep={setStep}
        />
        <ModalEditar
        
        setOpen={setOpenEditEmp}
        open={openEditEmp}
        setDataEditar={setDataEditar}
        dataEditar={dataEditar}
        step={step}
        setStep={setStep}
        usuarioSelected={usuarioSelected}
        recargarPerfilesfun={recargarPerfilesfun}
        recargarEmpresa={recargarEmpresa}
        usuarioemp={usuarioemp}
      />
      <ModalEliminar
        usuarios
        openModalEliminar={openModalEliminar}
        setOpenModalEliminar={setOpenModalEliminar}
        idEliminar={idEliminar}
        setIdEliminar={setIdEliminar}
        usuarioemp={usuarioemp}
      />
      <ModalAddPerfil
        usuarioemp={usuarioemp}
        open={openAddPerfil}
        setOpen={setOpenAddPerfil}
        idemp={usuarioemp}
        recargarPerfilesfun={recargarPerfilesfun}

      />
      <ModalEditPerfil
        open={openEditPerfil}
        setOpen={setOpenEditPerfil}
        data={usuarioSelected}
        refreshPerfiles={refreshPerfiles}
        setRefreshPerfiles={setRefreshPerfiles}
        recargarPerfilesfun={recargarPerfilesfun}
      />
      <div className="bottom-perfil">
        <Table
          usuarios={usuarios}
          setIdEliminar={setIdEliminar}
          setOpenModalEliminar={setOpenModalEliminar}
          handleScroll={handleScroll}
          loading={loading}
          value={value}
          setValue={setValue}
          setQuery={setQuery}
          _switch={_switch}
          setSkip={setSkip}
          setSwitch={setSwitch}
          query={query}
          step={step}
          setStep={setStep}
          setOpenEditPerfil={setOpenEditPerfil}
          openEditEmp={openEditEmp}
          setUsuarioSelected={setUsuarioSelected}
          setOpenAddPerfil={setOpenAddPerfil}
          usuarioemp={usuarioemp}
        />
      </div>

    </div>
    
  );
};

export default Perfil;

const datapost = [
  {
    tipoContrato: {
      value: "Otro",
      desc: "denase",
    },
    fechaInicio: "2021-06-20T01:49:00.000Z",
    fechaTermino: "2021-10-17T00:49:00.000Z",
    adns: [
      {
        id: "pCQPyAsMjP",
        modulo: "CO",
        /*submodulos: [
          {
            submodulo: "PC",
          },
          {
            submodulo: "CEL",
          },
        ],*/
      },
      {
        id: "Yc9qy7xb6",
        modulo: "QM",
        /*submodulos: [
          {
            submodulo: "QC",
          },
          {
            submodulo: "PT",
          },
        ],*/
      },
      {
        id: "X2ycq907A",
        modulo: "HCM",
        /*submodulos: [
          {
            submodulo: "CM",
          },
        ],*/
      },
      {
        id: "Pa4-CaM4q",
        modulo: "BASIS",
        /*submodulos: [
          {
            submodulo: "BASIS",
          },
        ],*/
      },
      {
        id: "XRHKLvLk6",
        modulo: "PM",
        /*submodulos: [
          {
            submodulo: "WCM",
          },
        ],*/
      },
    ],
    fechaContratacion: "2021-05-22T01:49:00.000Z",
    beneficios: [
      {
        id: "fzgoXC8pt",
        beneficio: "tercero",
      },
      {
        id: "IEQP7-6De",
        beneficio: "quintuple beneficio",
      },
      {
        id: "0yXWaHGFl",
        beneficio: "2536",
      },
      {
        id: "TXeUAYzLW",
        beneficio: "Lindo pueblo",
      },
      {
        id: "HDZNLnuhA",
        beneficio: "2363",
      },
      {
        id: "JZkpN1u5z",
        beneficio: "sds",
      },
    ],
    creacion: "2021-01-23T20:35:38.000Z",
    _id: "6009e42416e2ea0b0cad4fe4",
    idusuario: "5ff2847d8b24b60880de835a",
    titulo: "Programador Javascript",
    profesion: "Ingeniero en Informatica",
    area: "Desarrollo Tecnologico",
    anosExp: "5",
    tipoConsultor: "Training",
    jornadaLaboral: "Part-time",
    cantidadVacantes: "3",
    pais: "Chile",
    region: "Ñuble",
    ciudad: "Quillón",
    dispResidencia: true,
    dispViajar: false,
    renta: 1035000,
    descripcion:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    estado: "Activo",
    __v: 0,
  },
];
