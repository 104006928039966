import React, { useState, useEffect } from "react";
import "./Perfil.css";

import { IconButton, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { formatRut, RutFormat, validateRut } from "@fdograph/rut-utilities";
import shortid from "shortid";
import validator from "validator";
import { CustomInput } from "../../../../../../components";

import moment from "moment";
import clientAxios from "../../../../../../config/axios";


const useStyles = makeStyles({
  iconButton: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  icon: {
    color: "#9A99A1",
    marginRight: 10,
  },
});

const Perfil = ({ perfiles, setPerfiles, recargar, data, errores2,tipoPlan }) => {
  const {
    id,
    tipoPerfil,
    rut,
    nombres,
    apellidos,
    email,
    password,
    fechaInicio,
    fechaTermino,
    telefono
  } = data;
  const classes = useStyles();
  const [tipoPlanA, setTipoPlan] = useState(tipoPerfil);
  const [rutA, setRut] = useState(rut);
  const [nombresA, setNombres] = useState(nombres);
  const [apellidosA, setApellidos] = useState(apellidos);
  const [emailA, setEmail] = useState(email);
  const [telefonoA, setTelefono] = useState(telefono);
  const [passwordA, setPassword] = useState(password);
  //const [fechaInicioA, setFechaInicio] = useState(fechaInicio);
  //const [fechaTerminoA, setFechaTermino] = useState(fechaTermino);
  const [initDefault, setInitDefault] = useState(true);
  const [error, setError] = useState(false);

  //ERRORES
  const [ setTipoPerfilError] = useState(false);
  const [rutError, setRutError] = useState(false);
  const [nombresError, setNombresError] = useState(false);
  const [apellidosError, setApellidosError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [telefonoError, setTelefonoError] = useState(false);
  const [ setPasswordError] = useState(false);
  //const [fechaInicioError, setFechaInicioError] = useState(false);
  //const [fechaTerminoError, setFechaTerminoError] = useState(false);
  const [emailmsg, setEmailMsg] = useState("");
  const [telefonoMsg, setTelefonoMsg] = useState("");

  //const [fechaMsg, setFechaMsg] = useState("");
  const [rutMsg, setRutMsg] = useState("");
  const [setPassMsg] = useState("");

  //const inicio=moment().format();
  //const termino=moment().add(tipoPlan.duracionPlan,"months").format();



  /*const formatRut2 = () => {
    try {
      document.getElementById(
        `input-rut-empresa-usuario-admin-${id}`
      ).value = rutA;
      perfiles.map((item) => {
        if (item.id === id) {
          item.rut = rutA;
        }
      });
      console.log(perfiles);
    } catch (error) {
      console.log(error);
    }
  };*/

  const handleDelete = async () => {
    if (perfiles.length === 1) {
      return;
    } else {
      await setPerfiles(perfiles.filter((i) => i.id !== id));
      recargar();
    }
  };
  const generateKey = () => {
    let key = shortid();
    perfiles.map((item) => {
      if (item.id === id) {
        item.password = key;
      }
    });
    setPassword(key);
  };
  const validarErrores = async () => {
    let rutvalidado = validateRut(rutA.toLocaleLowerCase());
    const _email = await clientAxios.put("/api/usuarioEmpresa/validar/email", {
      email: emailA.toLocaleLowerCase(),
    });
    const emailv = validator.isEmail(emailA);
    console.log(tipoPlanA);
    if (tipoPlanA === "") {
      setTipoPerfilError(true);
    }
    if (rutA.trim() === "") {
      setRutMsg("No puede estar vacio");
      setRutError(true);
    } else if (rutvalidado === false) {
      setRutMsg("Ingrese un rut valido");
      setRutError(true);
    }
    if (nombresA === "") {
      setNombresError(true);
    }
    if (apellidosA === "") {
      setApellidosError(true);
    }
    if (emailA.trim() === "") {
      setEmailMsg("No puede estar vacio");
      setEmailError(true);
    } else if (emailv === false) {
      setEmailMsg("El formato es invalido");
      setEmailError(true);
    } else if (_email.data._email === true) {
      setEmailMsg("El email ya se encuentra registrado");
      setEmailError(true);
    }
    if (telefonoA.trim() === "") {
      setTelefonoMsg("No puede estar vacio");
      setTelefonoError(true);
    } else if (telefonoA.length >15) {
      setTelefonoMsg("Verificar la longitud de su número de teléfono");//setTelefonoMsg("El teléfono debe tener 11 digitos");
      setTelefonoError(true);
    } 
    if (passwordA.trim() === "") {
      setPassMsg("No puede estar vacio");
      setPasswordError(true);
    } else if (passwordA.lenth < 6) {
      setPassMsg("Debe contener almenos 6 caracteres");
      setPasswordError(true);
    }
 
  };
  
  useEffect(() => {
    if (initDefault === false) {
      if (errores2.length > 0) {
        console.log(errores2);
        const valor = errores2.filter((item) => item === data.id);
        if (valor.length > 0) {
          setError(true);
          document.getElementById(id).scrollIntoView();
          validarErrores();
        } else {
          setError(false);
        }
      }
    }
    setInitDefault(false);
    console.log(errores2);
  }, [errores2]);

  return (
    <div
      className="perfil-add-emp-admin"
      id={id}
      style={{
        border: error ? "1px solid #f44336" : null,
        backgroundColor: error ? "#f4433605" : null,
      }}
    >
      <div className="item-doble">
        <div style={{ paddingRight: 5 }}>
          <CustomInput
            label="Nombre"
            helpertext="No puede estar vacio"
            error={nombresError}
            type="text"
            value={nombresA}
            defaultValue={nombresA}
            onChange={(e) => {
              setNombresError(false);
              perfiles.map((item) => {
                if (item.id === id) {
                  item.nombres = e.target.value;
                }
              });
              console.log(perfiles);
              setNombres(e.target.value);
            }}
          />
        </div>
        <div style={{ paddingLeft: 5 }}>
          <CustomInput
            label="Apellidos"
            helpertext="No puede estar vacio"
            error={apellidosError}
            type="text"
            value={apellidosA}
            defaultValue={apellidosA}
            onChange={(e) => {
              setApellidosError(false);
              perfiles.map((item) => {
                if (item.id === id) {
                  item.apellidos = e.target.value;
                }
              });
              setApellidos(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="item-doble">
        <div style={{ paddingRight: 5 }}>
          <CustomInput
            label="Rut"
            id={`input-rut-empresa-usuario-admin-${id}`}
            error={rutError}
            helpertext={rutMsg}
            type="text"
            // value={rut}
            defaultValue={rut}
            onChange={(e) => {
              setRutError(false);
              setRut(e.target.value);
            }}
            //onBlur={formatRut2}
            //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          />
        </div>
        <div style={{ paddingLeft: 5 }}>
          <CustomInput
            label="Email"
            helpertext={emailmsg}
            error={emailError}
            type="text"
            value={emailA}
            defaultValue={emailA}
            onChange={(e) => {
              setEmailError(false);
              perfiles.map((item) => {
                if (item.id === id) {
                  item.email = e.target.value;
                }
              });
              setEmail(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="item-doble">
        <div style={{ paddingRight: 5 }}>
        <CustomInput
            label="Telefono"
            helpertext={telefonoMsg}
            error={telefonoError}
            type="number"
            value={telefonoA}
            defaultValue={telefonoA}
            onChange={(e) => {
              setTelefonoError(false);
              perfiles.map((item) => {
                if (item.id === id) {
                  item.telefono = e.target.value;
                }
              });
              setTelefono(e.target.value);
            }}
          />
        </div>
      </div>
      
      <IconButton
        size="small"
        onClick={handleDelete}
        className={classes.iconButton}
      >
        <Close fontSize="small" />
      </IconButton>
    </div>
  );
};

export default Perfil;
