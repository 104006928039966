import {
  AGREGAR_USUARIO,
  AGREGAR_USUARIO_ERROR,
  AGREGAR_USUARIO_EXITO,
  SHOW_ALERT_ADMIN,
  COMENZAR_DESCARGA_USUARIO,
  DESCARGA_USUARIO_EXITO,
  DESCARGA_USUARIO_EXITO_INIT,
  DESCARGA_USUARIO_ERROR,
  DETENER_CARGA_USUARIO,
  USUARIO_EDITADO_ERROR,
  USUARIO_EDITADO_EXITO,
  COMENZAR_EDICION_USUARIO,
  OBTENER_USUARIO_ELIMINAR,
  USUARIO_ELIMINADO_ERROR,
  USUARIO_ELIMINADO_EXITO,
} from "../../types/typesAdmin";

const initialState = {
  usuarios: [],
  loading: false,
  error: false,
  empresaeliminar: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMENZAR_EDICION_USUARIO:
    case COMENZAR_DESCARGA_USUARIO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AGREGAR_USUARIO:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DESCARGA_USUARIO_ERROR:
    case AGREGAR_USUARIO_ERROR:
    case USUARIO_EDITADO_ERROR:
    case USUARIO_ELIMINADO_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case OBTENER_USUARIO_ELIMINAR:
      return {
        ...state,
        loading: true,
        empresaeliminar: action.payload,
      };
    case USUARIO_ELIMINADO_EXITO:
      return {
        ...state,
        usuarios: state.usuarios.filter(
          (usuario) => usuario._id !== state.empresaeliminar
        ),
        empresaeliminar: null,
        loading: false,
      };
    case AGREGAR_USUARIO_EXITO:
      return {
        ...state,
        loading: false,
        usuarios: [action.payload, ...state.usuarios],
      };
    case DESCARGA_USUARIO_EXITO_INIT:
      return {
        ...state,
        loading: false,
        error: null,
        usuarios: action.payload,
      };
    case DETENER_CARGA_USUARIO:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DESCARGA_USUARIO_EXITO:
      return {
        ...state,
        loading: false,
        error: null,
        usuarios: [...state.usuarios, action.payload],
      };
    case USUARIO_EDITADO_EXITO:
      return {
        ...state,
        loading: false,
        usuarios: state.usuarios.map((usuario) =>
          usuario._id === action.payload._id
            ? (usuario = action.payload)
            : usuario
        ),
      };
    default:
      return state;
  }
}
