import React, { useState, useEffect } from "react";
import "./RegistroA.css";
import logo from "../../../../resources/img/ZAPTalent-Logotipo-Vertical-Original.svg";
import {
  ListItem,
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  IconButton,
  TextField,
  InputAdornment,
  LinearProgress,
  MenuItem,
  withStyles,
  Select,
} from "@material-ui/core";
import { Close, CloudUpload } from "@material-ui/icons";
import { formatRut, RutFormat, validateRut } from "@fdograph/rut-utilities";
import InputMask from "react-input-mask";
import validator from "validator";
import { OutInput, Tooltip } from "../../../../components";
import clienteAxios from "../../../../config/axios";
import { useSelector } from "react-redux";
import { CustomSelect } from "../../../../components";
import { naciones } from "../../../../assets/nacionalidades";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import NumberFormat from "react-number-format";

const CssTextField = withStyles({
  root: {
    backgroundColor: "#f3f8fe",
    maxHeight: "35px ",
    "& label": {
      fontSize: "12px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      maxHeight: "35px",
      fontSize: "12px",
    },
    "& .MuiFormHelperText-root": {
      backgroundColor: "#fff3f2",
      border: "1px solid#FEB0A9",
      fontSize: "10px",
      width: "100%",
      marginLeft: 0,
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
      height: "20px",
      paddingLeft: "15px",
      marginTop: "-1px",
    },
  },
})(TextField);

const RegistroA = (props) => {
  const {
    rut,
    passport,
    dni,
    nombres,
    apellidos,
    phone,
    email,
    password,
    rpassword,
    setRut,
    setPassport,
    inputPassport,
    setInputPassport,
    setDni,
    inputDni,
    setInputDni,
    setNombres,
    setApellidos,
    setPhone,
    setEmail,
    setPassword,
    setRpassword,
    handleClose,
    inputRut,
    setInputRut,
    registrarUsuario,
    fileCV,
    setfileCV,
    nacion,
    setNacion,
    fechaNacimiento,
    setFechaNacimiento,
    pretencion,
    setPretencion,
    moneda,
    setMoneda
  } = props;

  const [rutmsg, setRutMsg] = useState("");
  const [passportMsg, setPassportMsg] = useState("");
  const [dniMsg, setDniMsg] = useState("");
  const [rutError, setRutError] = useState(false);
  const [dniError, setDniError] = useState(false);
  const [passportError, setPassportError] = useState(false);
  const [errornombre, setErrorNombre] = useState(false);
  const [errorapellido, setErrorApellido] = useState(false);
  const [errorpassword, setErrorPassword] = useState(false);
  const [passwordmsg, setPasswordMsg] = useState("");
  const [errorrpassword, setErrorRpassword] = useState(false);
  const [rpasswordmsg, setRpasswordMsg] = useState("");
  const [erroremail, setErrorEmail] = useState(false);
  const [emailmsg, setEmailMsg] = useState("");
  const [errorphone, setErrorPhone] = useState(false);
  const [phonemsg, setPhoneMsg] = useState("");
  const [_switch, setSwitch] = useState(false);
  const [initDefault, setInitDefault] = useState(true);
  const [nombresmsg, setNombresMsg] = useState("");
  const [apellidomsg, setApellidoMsg] = useState("");
  const [errorFileCv, setErrorFileCv] = useState(false);
  const [fileCvmsg, setFileCvMsg] = useState("");
  const [errornacion, setErrorNacion] = useState(false);
  const [errorFecha, setErrorFecha] = useState(false);
  const [errorpretencion, setErrorPretencion] = useState(false);
  const [pretencionMsg, setPretencionMsg] = useState("");
  const [active, setActive] = useState("RUT");

  const formatRut2 = () => {
    document.getElementById("input-rut").value = rut;
  };
  const pattern = new RegExp(
    /^[a-zA-ZÀ-ÿ\-\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\-\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\-\u00f1\u00d1]+$/g
  );
  const pattern2 = new RegExp(
    /^[a-zA-ZÀ-ÿ\-\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\-\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\-\u00f1\u00d1]+$/g
  );

  const loading = useSelector((state) => state.auth.cargando);

  //Validacion
  const validacion = async () => {
    //validacion rut

    if (inputRut === true) {
      const rutvalidado = validateRut(rut.toLocaleLowerCase());
      if (rut.trim() === "") {
        setRutMsg("Rut no puede estar vacío");
        setRutError(true);

        // return;
      } else if (rutvalidado === false) {
        setRutMsg("El rut no es válido");
        setRutError(true);
      }
    } else if (inputPassport  === true && passport.trim() === "") {  //validacion pasaporte
      setPassportError(true);
      setPassportMsg("Pasaporte no puede estar vacío");
    }else if (inputDni  === true && dni.trim() === ""){ 
        setDniError(true);
        setDniMsg("DNI no puede estar vacío");
    }

    //validacion nombre
    if (nombres.trim() === "") {
      setErrorNombre(true);
      setNombresMsg("Nombres no puede estar vacío");
      // return;
    } else if (pattern.test(nombres.trim()) === false) {
      setErrorNombre(true);
      setNombresMsg("Nombres no puede contener numeros");
    }
    //validacion apellido
    if (apellidos.trim() === "") {
      setErrorApellido(true);
      setApellidoMsg("Apellidos no puede estar vacío");
      // return;
    } else if (pattern2.test(apellidos.trim()) === false) {
      setErrorApellido(true);
      setApellidoMsg(`Apellidos no puede contener numeros`);
    }
    //validacion password
    if (password.trim() === "") {
      setErrorPassword(true);
      setPasswordMsg("Password no puede estar vacío");
      // return;
    } else if (password.length < 6) {
      setErrorPassword(true);
      setPasswordMsg("Password debe ser de almenos 6 caracteres");
      // return;
    }
    //validacion repetir password
    if (rpassword.trim() === "") {
      setErrorRpassword(true);
      setRpasswordMsg("Password no puede estar vacío");
      // return;
    } else if (rpassword !== password) {
      setErrorRpassword(true);
      setRpasswordMsg("Password no coincide");
      // return;
    }
    //validar teléfono
    if (phone.trim() === "") {
      setErrorPhone(true);
      setPhoneMsg("Ingrese un número de teléfono");
      // return;
    } else {
      let numberPhone = phone.replace(/\D/g, "");
      if (numberPhone.length > 16) {
        setErrorPhone(true);
        setPhoneMsg("Ingrese un número de teléfono válido no mayor de 16 digitos");
      }
    }
    //validar email
    const emailv = validator.isEmail(email);
    if (email.trim() === "") {
      setErrorEmail(true);
      setEmailMsg("Email no puede estar vacío");
      // return;
    } else if (emailv === false) {
      setErrorEmail(true);
      setEmailMsg("El formato de email es erroneo");
      // return;
    }

    //validar CV
    if (!fileCV?.name) {
      setErrorFileCv(true);
      setFileCvMsg("Debe subir CV");
    }

    if (inputRut && rut.trim() !== "" && !rutError) {
      // Rut flow
      const res = await validarEmailRut({
        email,
        rut,
      });
      if (res?._email) {
        setErrorEmail(res._email ? true : false);
        setEmailMsg("El email ya se encuentra registrado");
      }
      if (res?._rut) {
        setRutError(res._rut ? true : false);
        setRutMsg("El rut ya pertenece a un usuario");
      }
    } else if(passport && passport.trim() !== "" && !passportError) {
      // Passport flow
      const res = await validarEmailPassport({
        email,
        passport,
      });
      if (res?._email) {
        setErrorEmail(res._email ? true : false);
        setEmailMsg("El email ya se encuentra registrado");
      }
      if (res?._passport) {
        setPassportError(res._passport ? true : false);
        setPassportMsg("El número de Pasaporte ya pertenece a un usuario");
      }
    }else if(dni && dni.trim() !== "" && !dniError) {
      // Dni flow
      const res = await validarEmailDni({
        email,
        dni,
      });
      if (res?._email) {
        setErrorEmail(res._email ? true : false);
        setEmailMsg("El email ya se encuentra registrado");
      }
      if (res?._dni) {
        setDniError(res._dni ? true : false);
        setDniMsg("El número de DNI ya pertenece a un usuario");
      }
    }

    //validar nacion

    if (nacion.trim() === "") {
      setErrorNacion(true);
    
    }
    if (fechaNacimiento === null) {
      setErrorFecha(true);
    }
    if (pretencion.trim() === "") {
      setErrorPretencion(true);
      setPretencionMsg("Pretensión de renta no puede estar vacío");
    } else if (pretencion < 0) {
      setErrorPretencion(true);
      setPretencionMsg("Ingrese un número válido");
    }
    setInitDefault(false);
    setSwitch(!_switch);
  };

  const changeRut = (e) => {
    if (rutError === true) {
      setRutError(false);
    }
    //setRut(formatRut(e.target.value, RutFormat.DOTS_DASH));
    setRut(e.target.value);
  };

  const changePassport = (e) => {
    if (passportError === true) {
      setPassportError(false);
    }
    setPassport(e.target.value);
  };

  const changeDni = (e) => {
    if (dniError === true) {
      setDniError(false);
    }
    setDni(e.target.value);
  };

  const changePhone = (e) => {
    setErrorPhone(false);
    setPhone(e.target.value);
  };

  const validarEmailRut = async ({ email, rut }) => {
    let data;
    const lowerEmail = email.toLocaleLowerCase();
    try {
      await clienteAxios
        .post("/api/usuarios/validacion/rut-email", { email: lowerEmail, rut })
        .then((res) => (data = res.data));

      return data;
    } catch (error) {
      console.log(error);
    }

    if (data) {
      await clienteAxios
        .post("/api/usuarios/enviarEmail/activacion", { email: lowerEmail })
        .then((res) => console.log(res));
    }
  };

  const validarEmailPassport = async ({ email, passport }) => {
    let data;
    const lowerEmail = email.toLocaleLowerCase();
    try {
      await clienteAxios
        .post("/api/usuarios/validacion/passport-email", {
          email: lowerEmail,
          passport,
        })
        .then((res) => (data = res.data));

      return data;
    } catch (error) {
      console.log(error);
    }

    if (data) {
      await clienteAxios
        .post("/api/usuarios/enviarEmail/activacion", { email: lowerEmail })
        .then((res) => console.log(res));
    }
  };

  const validarEmailDni = async ({ email, dni }) => {
    let data;
    const lowerEmail = email.toLocaleLowerCase();
    try {
      await clienteAxios
        .post("/api/usuarios/validacion/dni-email", {
          email: lowerEmail,
          dni,
        })
        .then((res) => (data = res.data));

      return data;
    } catch (error) {
      console.log(error);
    }

    if (data) {
      await clienteAxios
        .post("/api/usuarios/enviarEmail/activacion", { email: lowerEmail })
        .then((res) => console.log(res));
    }
  };

  const fileChange = (e) => {
    setErrorFileCv(false);
    setfileCV(e.target.files[0]);
  };

  useEffect(() => {
    if (initDefault === false) {
      if (
        errornombre === true ||
        erroremail === true ||
        errorapellido === true ||
        errorpassword === true ||
        errorrpassword === true ||
        errorphone === true ||
        rutError === true ||
        passportError === true ||
        errorFileCv === true ||
        dniError === true
      ) {
        return;
      } else {
        registrarUsuario();
      }
    }
  }, [_switch]);

  const setMonedaSelectNivel = (e) => {
    setMoneda(e.nativeEvent.target.outerText);
  }

  return (
    <div className="cont-reg-A">
      <div className="btn-close-registro">
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </div>
      <div className="top-reg-A-msj">
        <img src={logo} alt="logo" className="logo-login-re" />
        <p className="p-top-reg-a">
          Para comenzar, necesitamos tus datos básicos:
        </p>
        <div>
          <ListItem
            button
            className="btn-rut-reg-a boton-registro-usuario"
            disabled={active === "RUT" ? true : false}
            onClick={() => {
              setActive('RUT');
              setInputRut(true);
              setInputPassport(false);
              setInputDni(false);
              setPassport("");
              setPassportError(false);
              setDni("");
              setDniError(false);
            }}
          >
            <p style={{ color: "white" }}>Rut</p>
          </ListItem>
          <ListItem
            button
            className="btn-rut-reg-a boton-registro-usuario"
            disabled={active === "PASSPORT"  ? true : false}
            onClick={() => {
              setActive('PASSPORT');
              setInputRut(false);
              setInputPassport(true);
              setInputDni(false);
              setRut("");
              setRutError(false);
              setDni("");
              setDniError(false);
            }}
          >
            <p style={{ color: "white" }}>Pasaporte</p>
          </ListItem>

          <ListItem
            button
            className="btn-rut-reg-a boton-registro-usuario"
            disabled={active === "DNI"  ? true : false}
            onClick={() => {
              setActive('DNI');
              setInputRut(false);
              setInputPassport(false);
              setInputDni(true);
              setRut("");
              setRutError(false);
              setPassport("");
              setPassportError(false);
            }}
          >
            <p style={{ color: "white" }}>DNI</p>
          </ListItem>
          
        </div>
      </div>
      <div className="center-reg-A">
        <div className="center-1">
          {active=='RUT' ? (
            <div
              className={!rutError ? "contCustomInput" : "contCustomInputError"}
            >
              <FormHelperText
                id="helper-email"
                className={
                  rutError ? "helper-text-error" : "helper-text-error-inact"
                }
              >
                {rutmsg}
              </FormHelperText>
              <FormControl
                variant="outlined"
                className="form-control-login form-control-small"
                size="small"
                fullWidth
                error={rutError}
              >
                <InputLabel htmlFor="my-input" className="input-label-login ">
                  Rut
                </InputLabel>
                <OutlinedInput
                  className="text-field-login "
                  id="input-rut"
                  aria-describedby="helper-email"
                  label="Rut"
                  name="rut"
                  defaultValue={rut}
                  //onBlur={formatRut2}
                  onChange={(e) => {
                    changeRut(e);
                    // setInitDefault(true);
                  }}
                />
              </FormControl>
            </div>
          ) : null}
          {active=='PASSPORT' ? (
            <div
              className={
                !passportError ? "contCustomInput" : "contCustomInputError"
              }
            >
              <FormHelperText
                id="helper-passport"
                className={
                  passportError
                    ? "helper-text-error"
                    : "helper-text-error-inact"
                }
              >
                {passportMsg}
              </FormHelperText>
              <FormControl
                variant="outlined"
                className="form-control-login form-control-small"
                size="small"
                fullWidth
                error={passportError}
              >
                <InputLabel htmlFor="my-input" className="input-label-login ">
                  Pasaporte
                </InputLabel>
                <OutlinedInput
                  className="text-field-login "
                  id="input-passport"
                  aria-describedby="helper-email"
                  label="Pasaporte"
                  name="passport"
                  defaultValue={passport}
                  onChange={(e) => {
                    changePassport(e);
                    // setInitDefault(true);
                  }}
                />
              </FormControl>
            </div>
          ) : null}
          {active=='DNI' ? (
            <div
              className={
                !dniError ? "contCustomInput" : "contCustomInputError"
              }
            >
              <FormHelperText
                id="helper-dni"
                className={
                  dniError
                    ? "helper-text-error"
                    : "helper-text-error-inact"
                }
              >
                {dniMsg}
              </FormHelperText>
              <FormControl
                variant="outlined"
                className="form-control-login form-control-small"
                size="small"
                fullWidth
                error={dniError}
              >
                <InputLabel htmlFor="my-input" className="input-label-login ">
                  DNI
                </InputLabel>
                <OutlinedInput
                  className="text-field-login "
                  id="input-dni"
                  aria-describedby="helper-email"
                  label="DNI"
                  name="dni"
                  defaultValue={dni}
                  onChange={(e) => {
                    changeDni(e);
                    // setInitDefault(true);
                  }}
                />
              </FormControl>
            </div>
          ) : null}
        </div>
        <div className="box-2">
          <div className="center-1">
            <OutInput
              label="Nombres"
              helpertext={nombresmsg}
              funcOnChange={setNombres}
              defaultValue={nombres}
              name="names"
              size="small"
              type="text"
              error={errornombre}
              funcionError={setErrorNombre}
            />
          </div>
          <div className="center-1">
            <OutInput
              label="Apellidos"
              helpertext={apellidomsg}
              funcOnChange={setApellidos}
              defaultValue={apellidos}
              name="lastnames"
              size="small"
              error={errorapellido}
              funcionError={setErrorApellido}
            />
          </div>
        </div>
        <div className="box-2">
          <div className="center-1">
            <OutInput
              label="Password"
              helpertext={passwordmsg}
              funcOnChange={setPassword}
              defaultValue={password}
              type="password"
              name="passwordR"
              size="small"
              error={errorpassword}
              funcionError={setErrorPassword}
            />
          </div>
          <div className="center-1">
            <OutInput
              label="Repite el password"
              helpertext={rpasswordmsg}
              funcOnChange={setRpassword}
              defaultValue={rpassword}
              type="password"
              name="rpasswordR"
              size="small"
              error={errorrpassword}
              funcionError={setErrorRpassword}
            />
          </div>
        </div>
        <div className="box-2">
          
          <div className="center-1">
          <div
              className={
                !errorphone ? "cont-phone-reg-a" : "cont-phone-reg-a-error"
              }
            >
    
              <FormHelperText
                id="helper-phone"
                className={
                  errorphone ? "helper-text-error" : "helper-text-error-inact"
                }
              >
                {phonemsg}
              </FormHelperText>
              <FormControl
                variant="outlined"
                className="form-control-login form-control-small"
                size="small"
                fullWidth
                error={errorphone}
              >
                <OutlinedInput
                  className="text-field-login "
                  id="input-phone"
                  placeholder="Teléfono"
                  aria-describedby="helper-phone"
                  label="Teléfono"
                  name="Telefono"
                  defaultValue={phone}
                  onChange={(e) => {
                    changePhone(e);
                    // setInitDefault(true);
                  }}
                />
              </FormControl>
            </div>
            
          </div>
          <div className="center-1">
            <OutInput
              label="Correo electrónico"
              helpertext={emailmsg}
              funcOnChange={setEmail}
              defaultValue={email}
              name="correo"
              size="small"
              error={erroremail}
              funcionError={setErrorEmail}
            />
          </div>
        </div>
        <div className="box-2">
          <div className="center-1">
            <CustomSelect
              placeholder="Nacionalidad"
              size="small"
              onChange={setNacion}
              helpertext="Selecciona una nacionalidad"
              value={nacion}
              name="nacion"
              error={errornacion}
              funcionError={setErrorNacion}
            >
              {naciones.map((item, index) => (
                <MenuItem className="custom-menu-item" value={item}>
                  {item}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          <div className="center-1">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                error={errorFecha}
                inputVariant="outlined"
                fullWidth
                size="small"
                label="Fecha de nacimiento"
                // minDate={new Date("2010-01-01")}
                maxDate={new Date()}
                helperText={
                  errorFecha ? "Fecha de nacimiento no puede estar vacio" : null
                }
                openTo="year"
                format="dd/MM/yyyy"
                value={fechaNacimiento}
                // maxDate={new Date()}
                onChange={(newValue) => {
                  setErrorFecha(false);
                  setFechaNacimiento(newValue);
                }}
                style={{ backgroundColor: "#F3F8FE" }}
                InputProps={{
                  className: "input-date-picker-inicio",
                  readOnly: true,
                  style: { paddingRight: 0 },
                }}
                className="date-picker-inicio"
                InputLabelProps={{ className: "input-label-date-form" }}
                FormHelperTextProps={{
                  className: "helper-text-fecha-nacimiento",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="center-3">
          <CssTextField
            fullWidth
            variant="outlined"
            size="small"
            error={errorpretencion}
            label="Pretensión de renta"
            helperText={pretencionMsg}
            value={pretencion}
            // onChange={handleChange}
            onChange={(e) => {
              setErrorPretencion(false);
              setPretencion(e.target.value);
            }}
            name="numberformat"
            id="formatted-numberformat-input-b"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          <Select
                  style={{ marginTop: "0px", width: "20%" }}
                  value={moneda}
                  displayEmpty
                  onChange={setMonedaSelectNivel}
              >
                  <MenuItem value="CLP">
                    CLP
                  </MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
          </Select>
        </div>
        <div className="center-1">
          <input
            type="file"
            id="raised-button-file-cv"
            onChange={(e) => fileChange(e)}
            style={{ display: "none" }}
            accept="application/pdf, image/png, .jpeg, .jpg, image/gif, .doc, .docx"
          />
          <TextField
            error={errorFileCv}
            funcionError={setErrorFileCv}
            helpertext={fileCvmsg}
            disabled={true}
            fullWidth
            size="small"
            id="input-with-icon-textfield"
            label={errorFileCv ? "Debe subir su CV" : "Subir CV*"}
            value={fileCV && fileCV.name}
            defaultValue="Selecciona un archivo"
            InputLabelProps={{
              className: "input-file-estudios",
            }}
            InputProps={{
              readOnly: true,
              className: "input-file-estudios",
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Subir archivo">
                    <IconButton disabled={false}>
                      <label
                        htmlFor="raised-button-file-cv"
                        style={{ cursor: "pointer" }}
                      >
                        <CloudUpload className="icon-file-estudios" />
                      </label>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      {loading ? (
        <>
          <div className="overlay-loading"></div>
          <div className="linear-progres-global">
            <LinearProgress className="progres-editar-perfil" />
          </div>
        </>
      ) : null}
      <div className="bottom-reg-A">
        <ListItem button className="btn-rut-reg-a" onClick={validacion}>
          <p style={{ color: "white" }}>Finalizar</p>
        </ListItem>
      </div>
    </div>
  );
};

export default RegistroA;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // onChange={(e) => onChange(e.target.value)}
      thousandSeparator
      isNumericString
      prefix="$ "
    />
  );
}
