import React, { useState, useEffect } from "react";
import "./trabajos.css";
import {
  IconButton,
  TextField,
  ListItem,
  MenuItem,
  FormControl,
  Input,
  FormHelperText,
  LinearProgress,
  Hidden,
  Fab
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { CustomInput, CustomSelectB } from "../../../../components";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import { useSelector, useDispatch } from "react-redux";
import { paises } from "../../../../assets/paises";
import { actEmpresa } from "../../../../assets/actEmpresa";
import { agregarTrabajoAction } from "../../../../redux/actions/trabajoAction";
import { data_cargo } from "../../../../assets/cargos";
import validator from "validator";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import clientAxios from "../../../../config/axios";
import Swal from 'sweetalert2';
import { CardInit, ModalEliminar } from "../../../../components";
import { Modal, CardJob, ModalEditar } from "./components";
import { Add } from "@material-ui/icons";
import { obtenerTrabajosAction } from "../../../../redux/actions/trabajoAction";
import Loader from "react-loader-spinner";




const Trabajos = (props) => {

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.trabajo.cargando);
  const [_switch, setSwitch] = useState(false);
  // const loading = true;
  const { id: idUserParam } = useParams();
  const [usuario, setUsuario] = useState('');


  //data
  const [inidate, setValueI] = useState(null);
  const [findate, setValueT] = useState(null);
  const [nomempresa, setEmpresa] = useState("");
  const [actempresa, setActEmpresa] = useState("");
  const [cargo, setCargo] = useState("");
  const [idusuario] = useState(idUserParam);
  const [pais, setPais] = useState("");
  const [expzap, setExpZap] = useState(null);
  const [refnombre, setRefNombre] = useState(null);
  const [email, setEmail] = useState(null);
  const [refphone, setRefPhone] = useState("");
  const [refrelacion, setRefRelacion] = useState(null);
  const [reflogros, setRefLogros] = useState(null);
  //errores
  const [empresaError, setEmpresaError] = useState(false);
  const [actEmpresaError, setActEmpresaError] = useState(false);
  const [cargoError, setCargoError] = useState(false);
  const [iniDateError, setIniDateError] = useState(false);
  const [finDateError, setFinDateError] = useState(false);
  const [paisError, setPaisError] = useState(false);
  const [fechamsg, setFechaMsg] = useState("");
  const [refNombreError, setRefNombreError] = useState(false);
  const [refRelaError, setRefRelaError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorphone, setErrorPhone] = useState(false);

  const pattern = new RegExp(
    /^[a-zA-ZÀ-ÿ\-\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\-\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\-\u00f1\u00d1]+$/g
  );
  const pattern2 = new RegExp(
    /^[a-zA-ZÀ-ÿ\-\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\-\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\-\u00f1\u00d1]+$/g
  );


  const trabajos = useSelector((state) => state.trabajo.trabajos);
  const [cardT1, setCardT1] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [idEliminar, setIdEliminar] = useState("");
  const [openModalEliminar, setOpenModalEliminar] = useState(false);
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [dataEditar, setDataEditar] = useState(null);

  useEffect(() => {
    clientAxios.get(`/api/usuarios/${idUserParam}`)
      .then(res => {
        setUsuario(res.data)
      }).catch(err => console.log(err));
  }, []);

  const validacion = () => {
    let numberPhone = refphone.replace(/\D/g, "");
    if (nomempresa === "") {
      setEmpresaError(true);
      return;
    }
    if (actempresa === "") {
      setActEmpresaError(true);
      return;
    }
    if (cargo === "") {
      setCargoError(true);
      return;
    }

    if (inidate === null) {
      setIniDateError(true);
      return;
    }
    if (!_switch) {
      if (findate === null) {
        setFinDateError(true);
        setFechaMsg("Fecha termino no puede estar vacio");
        return;
      }
      if (Date.parse(inidate) > Date.parse(findate)) {
        setFinDateError(true);
        setFechaMsg("Fecha Termino no puede ser menor a la fecha Inicial");
        return;
      }
    }

    if (pais === "") {
      setPaisError(true);
      return;
    }

    if (refnombre !== null) {
      if (refnombre !== "") {
        if (pattern.test(refnombre.trim()) === false) {
          setRefNombreError(true);
          return;
        }
      }
    }
    if (email !== null) {
      if (email !== "") {
        const emailv = validator.isEmail(email);
        if (emailv === false) {
          setErrorEmail(true);
          return;
        }
      }
    }

    if (refphone !== "") {
      if (numberPhone.length < 11) {
        setErrorPhone(true);
        return;
      }
    }

    if (refrelacion !== null) {
      if (refrelacion !== "") {
        if (pattern2.test(refrelacion.trim()) === false) {
          setRefRelaError(true);
          return;
        }
      }
    }

    guardarTrabajo();
  };

  const guardarTrabajo = () => {
    let fechaTermino;
    if (_switch) {
      fechaTermino = "2100-11-07T23:38:52.946Z";
    } else {
      fechaTermino = findate;
    }
    let industria = usuario.industria;
    industria.push(actempresa);
    dispatch(
      agregarTrabajoAction({
        idusuario,
        nomempresa,
        actempresa,
        cargo,
        pais,
        expzap,
        refnombre,
        email,
        refphone,
        refrelacion,
        reflogros,
        inidate,
        findate: fechaTermino,
      })
    ).then((res) => Swal.fire('Listo!', 'Trabajo agregado!', 'success'));
  };

  const changeInit = (value) => {
    setIniDateError(false);
    setValueI(value);
  };

  const changeFin = (value) => {
    setFinDateError(false);
    setValueT(value);
  };


  useEffect(() => {
    if (usuario) {
      if (usuario.sexo === "Femenino") {
        setCardT1("typeD2");
      } else {
        setCardT1("typeD1");
      }
    }
  }, [usuario]);

  useEffect(() => {
    const cargarTrabajos = () =>
      dispatch(obtenerTrabajosAction(idUserParam));
    cargarTrabajos();
    // eslint-disable-next-line
  }, [usuario, openModalEditar, openModal]);

  const datasort = trabajos.sort(function (a, b) {
    a = new Date(a.findate);
    b = new Date(b.findate);
    return a > b ? -1 : a < b ? 1 : 0;
  });

  return (
    <>

      <CustomInput
        label="Empresa"
        onChange={(e) => {
          setEmpresaError(false);
          setEmpresa(e.target.value);
        }}
        name="company"
        type="text"
        defaultValue={nomempresa}
        error={empresaError}
        helpertext="Empresa no puede estar vacio"
      />


      <CustomSelectB
        label="Rubro de la empresa"
        onChange={(e) => {
          setActEmpresaError(false);
          setActEmpresa(e.target.value);
        }}
        name="company-activity"
        value={actempresa}
        error={actEmpresaError}
        helpertext="Actividad empresa no puede estar vacio"
      >
        {actEmpresa.map((item, index) => (
          <MenuItem
            className="custom-menu-item"
            key={index}
            value={item.Seleccionar}
          >
            {item.Seleccionar}
          </MenuItem>
        ))}
      </CustomSelectB>


      <CustomInput
        label="Cargo"
        onChange={(e) => {
          setCargoError(false);
          setCargo(e.target.value);
        }}
        name="cargo"
        defaultValue={cargo}
        value={cargo}
        error={cargoError}
        helperText="Cargo no puede estar vacio"
      />




      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>

        <KeyboardDatePicker
          error={iniDateError}
          fullWidth
          size="small"
          label="Inicio"
          openTo="year"
          helperText={
            iniDateError ? "Fecha inicio no puede estar vacio" : null
          }
          views={["year", "month"]}
          value={inidate}
          maxDate={new Date()}
          onChange={(newValue) => changeInit(newValue)}
          InputProps={{
            className: "input-date-picker-inicio",
            readOnly: true,
          }}
          className="date-picker-inicio"
          InputLabelProps={{ className: "input-label-date-form" }}
        />


        <KeyboardDatePicker
          error={finDateError}
          size="small"
          fullWidth
          label="Término"
          openTo="year"
          views={["year", "month"]}
          value={findate}
          maxDate={new Date()}
          helperText={finDateError ? fechamsg : null}
          onChange={(newValue) => changeFin(newValue)}
          InputProps={{
            className: "input-date-picker-inicio",
            readOnly: true,
          }}
          disabled={_switch}
          className="date-picker-inicio"
          InputLabelProps={{ className: "input-label-date-form" }}
        />

        <br />
        <br />
        <div className="cont-trabajo-actual">
          <input
            type="checkbox"
            name="recordarme"
            id=""
            onChange={() => setSwitch(!_switch)}
          />
          <label>Trabajo actual.</label>
        </div>

      </MuiPickersUtilsProvider>


      <CustomSelectB
        label="País"
        onChange={(e) => {
          setPaisError(false);
          setPais(e.target.value);
        }}
        name="country"
        value={pais}
        error={paisError}
        helperText="País no puede estar vacio"
      >
        {paises.map((item, index) => (
          <MenuItem
            className="custom-menu-item"
            key={index}
            value={item.name}
          >
            {item.name}
          </MenuItem>
        ))}
      </CustomSelectB>


      <CustomInput
        label="Experiencia en SAP. (opcional)"
        onChange={(e) => setExpZap(e.target.value)}
        name="exp-zap"
        type="text"
        defaultValue={expzap}
      />
      <br />
      <br />
      <br />
      <div className="item-form-trabajo-sub">
        <p>Referencias</p>
      </div>


      <CustomInput
        label="Nombre (opcional)"
        onChange={(e) => {
          setRefNombreError(false);
          setRefNombre(e.target.value);
        }}
        name="name"
        type="text"
        defaultValue={refnombre}
        value={refnombre}
        error={refNombreError}
        helpertext="Nombre no puede contener numeros"
      />


      <CustomInput
        label="Email (opcional)"
        onChange={(e) => {
          setErrorEmail(false);
          setEmail(e.target.value);
        }}
        name="email"
        type="text"
        defaultValue={email}
        value={email}
        error={errorEmail}
        helpertext="Email no valido"
      />


      <br />
      <br />

      <FormControl
        fullWidth
        size="small"
        error={errorphone}
      >
        <Input
          label="Telefono"
          value={refphone}
          defaultValue={refphone}
          onChange={(e) => {
            setErrorPhone(false);
            setRefPhone(e.target.value);
          }}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={InputMaskCustom}
        />
        {errorphone ? (
          <FormHelperText className="helper-text-custom-input">
            Número de teléfono invalido
          </FormHelperText>
        ) : null}
      </FormControl>


      <CustomInput
        label="Relación (opcional)"
        onChange={(e) => {
          setRefRelaError(false);
          setRefRelacion(e.target.value);
        }}
        name="ref-relacion"
        type="text"
        defaultValue={refrelacion}
        value={refrelacion}
        error={refRelaError}
        helpertext="Relación no puede contener numeros"
      />



      <TextField
        fullWidth
        id="standard-multiline-static"
        label="Funciones (opcional)"
        onChange={(e) => setRefLogros(e.target.value)}
        name="logros"
        type="text"
        defaultValue={reflogros}
        multiline
        rows={2}
        InputLabelProps={{ className: "multiline-form-estudios" }}
        InputProps={{ className: "multiline-form-estudios" }}
      />


      <div className="bottom-form-trabajo">
        <ListItem button className="btn-form-estudio" onClick={validacion}>
          <p style={{ color: "white" }}>Guardar</p>
        </ListItem>
      </div>
      {loading ? (
        <>
          <div className="overlay-loading"></div>
          <div className="linear-progres-global">
            <LinearProgress className="progres-editar-perfil" />
          </div>
        </>
      ) : null}


      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Loader
            type="Oval"
            color="#00BFFF"
            height={100}
            width={100}
            visible={loading}
          //  timeout={3000} //3 secs
          />
        </div>
      ) : trabajos.length === 0 ? (
        <div className="cont-card-init-estudios">

        </div>
      ) : (
        <>
          <div className="conteiner-cards-job-man">

            {datasort.map((item, index) => (
              <CardJob
                key={index}
                data={item}
                setIdEliminar={setIdEliminar}
                setOpenModalEliminar={setOpenModalEliminar}
                setOpenModalEditar={setOpenModalEditar}
                setDataEditar={setDataEditar}
              />
            ))}
            <ModalEditar
              setOpenModalEditar={setOpenModalEditar}
              openModalEditar={openModalEditar}
              setDataEditar={setDataEditar}
              data={dataEditar}
            />
            <Modal setOpenModal={setOpenModal} openModal={openModal} />
            <ModalEliminar
              trabajo
              openModalEliminar={openModalEliminar}
              setOpenModalEliminar={setOpenModalEliminar}
              idEliminar={idEliminar}
              setIdEliminar={setIdEliminar}
            />
          </div>
        </>
      )}



    </>
  );
};

export default Trabajos;

const InputMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      className="input-mask-phone-perfil"
      mask="+(999) 9999 9999"
      placeholder="+(569) 7890 7890"
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue >= 0 && floatValue <= 1000000000;
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

function NumberFormatCustom2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue >= 0 && floatValue <= 1000000000;
      }}
      thousandSeparator
      isNumericString
    />
  );
}
