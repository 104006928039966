import React, { useState, useEffect } from "react";
import "./EditUser.css";
import { useHistory, useParams } from "react-router-dom";
import { Estudios, DatosPersonales, Certificados, Trabajos, Header, AdnSap } from "./components";
import { ListItem } from "@material-ui/core";
import { editarAdnAction, obtenerAdnAction, agregarAdnAction } from "../../redux/actions/adnAction";
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import clientAxios from "../../config/axios";

const EditUser = () => {

  const { id: idUserParam } = useParams();
  const history = useHistory();


  const back = (e) => {
    history.push('/admin/users');
  }

  const [userName, setUserName] = useState(null);
  const [cvURL, setCvURL] = useState(null);
  const [user, setUser] = useState(null);

  //console.log("usuario:")
  //console.log(user)


  useEffect(() => {
    clientAxios.get(`/api/usuarios/${idUserParam}`)
      .then(res => {
        setUserName(res.data.nombres);
        setCvURL(res.data.cvURL)
        setUser(res.data);
      }).catch(err => console.log(err));
  }, []);

  return (
    <div className="container">
      <Header userName={userName} cvURL={cvURL}></Header>
      <div className="datosPersonales">
        <p>Datos Personales</p>
        <DatosPersonales></DatosPersonales>
      </div>
      <div className="estudios">
        <p>Estudios</p>
        <Estudios></Estudios>
      </div>
      <div className="adnSap">
        <p>Adn SAP</p>
        <AdnSap usuario={user}></AdnSap>
      </div>
      <div className="certificados">
        <p>Certificados</p>
        <Certificados usuario={user}></Certificados>
      </div>
      <div className="trabajos">
        <p>Trabajos</p>
        <Trabajos usuario={user}></Trabajos>
      </div>

      <br />
      <ListItem button className="btn-form-estudio" onClick={back}>
        <p style={{ color: "white" }}>Volver</p>
      </ListItem>

    </div>
  );
}

export default EditUser;