import React, { useState, useEffect } from "react";
import "./Users.css";
import {
  Header,
  Table
} from "./components";
import { CardA, ModalEliminar } from "../../components";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { obtenerUsersAction } from "../../redux/actions/actions-admin/usersAction";
import { obtenerTotalUsuariosAction } from "../../redux/actions/actions-emp/postuladosAction";
import { useDispatch, useSelector } from "react-redux";
import clientAxios from "../../config/axios";

const Users = () => {
  const dispatch = useDispatch();

  //ELIMINAR USUARIO
  const [idEliminar, setIdEliminar] = useState("");
  const [openModalEliminar, setOpenModalEliminar] = useState(false);

  //FILTROS
  const [skip, setSkip] = useState(0);
  const [query, setQuery] = useState({ state: "activos" });
  const [value, setValue] = useState(0);
  const [_switch, setSwitch] = useState(false);

  const usuarios = useSelector((state) => state.usuarios.usuarios);
  const loading = useSelector((state) => state.usuarios.loading);
  const totalusers = useSelector((state) => state.postulados.totalusers);
  const handleScroll = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;

    if (offsetHeight + scrollTop === scrollHeight) {
      setSkip(usuarios.length);
    }
  };

  useEffect(() => {
    dispatch(obtenerUsersAction({ skip: skip, query }));
    dispatch(obtenerTotalUsuariosAction());
  }, [skip, _switch]);


  useEffect(() => {
    if (value === 0) {
      setSkip(0);
      setQuery({ state: "todos" });
    } else if (value === 1) {
      setSkip(0);
      setQuery({ state: "activos" });
    } else if (value === 2) {
      setSkip(0);
      setQuery({ state: "inactivos" });
    }
    setSwitch(!_switch);
  }, [value]);


  return (
    <div className="container-users-admin">
      <ModalEliminar
        usuarios
        openModalEliminar={openModalEliminar}
        setOpenModalEliminar={setOpenModalEliminar}
        idEliminar={idEliminar}
        setIdEliminar={setIdEliminar}
      />

      <div className="top">
        <Header />
      </div>
      <div className="center">
        <div className="item">
          <CardA white titulo="Total Usuarios" value={totalusers} />
        </div>
      </div>
      <div className="bottom">
        <Table
          usuarios={usuarios}
          setIdEliminar={setIdEliminar}
          setOpenModalEliminar={setOpenModalEliminar}
          handleScroll={handleScroll}
          loading={loading}
          value={value}
          setValue={setValue}
          setQuery={setQuery}
          _switch={_switch}
          setSkip={setSkip}
          setSwitch={setSwitch}
          query={query}
        />
      </div>

    </div>
  );
};

export default Users;
