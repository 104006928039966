import React from "react";
import "./SectionOne.css";

const SectionOne = () => {
  return (
    <div className="cont-section-one" id="section-one">
     
    </div>
  );
};

export default SectionOne;
