import React from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import image from "../../../resources/empresas/ZAPTalent-Empresa-Logotipo-1.png";
import image2 from "../../../resources/ZAPTalent-Logotipo-horizontal-Blanco.png";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MailOutlineIcon from '@material-ui/icons/MailOutline';


const Footer = () => {
  return (
    <View style={styles.contFooter} className="footer-plan">
      <img style={styles.image2} src={image2} />
      <View style={styles.contP2}>
        <Text style={styles.p3}>
          Contacto:
        </Text>
        <Text style={styles.p4}>
        <LocationOnIcon />Barros Borgoño # 110 - Of 1003 Providencia, Santiago de Chile.
        </Text>
        <Text style={styles.p4}>
         <PhoneAndroidIcon /> +56 9 7667 2851
        </Text>
        <Text style={styles.p4}>
         <MailOutlineIcon /> contacto@zaptalent.cl
        </Text>
      </View>
      <View style={styles.contP}>
        <Text style={styles.p1}>
          &copy; ZAPTalent 2020 - Todos los derechos reservados
        </Text>
        <Text style={styles.p1}>
          Barros Borgoño #110 - Of 1003 Providencia, Santiago
        </Text>
        <Text style={styles.p1}>Teléfono +56 9 7667 2851</Text>
        <Text style={styles.p2}>https://zaptalent.cl/</Text>
      </View>
    </View>
  );
};

export default Footer;

export const styles = StyleSheet.create({
  contFooter: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 80,
    fontFamily: "Roboto",
    paddingBottom: 0,
    position: "absolute",
    bottom: 0,
    backgroundColor: "rgba(24, 124, 226,0.5)",
    alignItems: "center",
  },

  /**FOOTER */
  logo: { width: 45, height: 45, marginTop: 15 },
  contP: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: 15,
    marginRight:10
  },
  contP2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 15,
  },
  p1: {
    fontSize: 8,
    color: "white",
    // textAlign: "right",
  },
  p2: {
    fontSize: 7,
    color: "white",
    // textAlign: "right",
    fontWeight: 600,
  },
  p3: {
    fontSize: 20,
    color: "white",
    // textAlign: "right",
    fontWeight: 600,
  },
  p4: {
    fontSize: 14,
    color: "white",
    fontWeight: 200,
  },
  image2: {
    width: 220,
    height: 70,
    marginTop: 20,
  },
});
