import React from "react";
import { Home } from "../../containersAdmin";
import { Users } from "../../containersAdmin";
import { Route, Switch } from "react-router-dom";
import { EditUser } from "../../containersAdmin";
import {
  HOME,
  USERS,
  EDIT_USER,
} from "./paths";

const Routes2 = () => (
  <Switch>
    <Route path={HOME} component={Home} />
    <Route path={USERS} component={Users} />
    <Route path={EDIT_USER} component={EditUser} />
  </Switch>
);

export default Routes2;
