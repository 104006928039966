import _ from 'lodash';
import React, { useState, useEffect, createRef } from "react";
import "./AdnSap.css";
import { CardInit, ModalEliminar } from "../../../../components";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Card, ModalEditar, NewModal, One } from "./components";
import { editarAdnAction, obtenerAdnAction, agregarAdnAction } from "../../../../redux/actions/adnAction";
import { IconButton, Hidden } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { useParams } from "react-router-dom";
import clientAxios from "../../../../config/axios";


const AdnSap = (props) => {
  const dispatch = useDispatch();

  const { id: idUserParam } = useParams();
  const [usuario, setUsuario] = useState('');

  const loading = useSelector((state) => state.adn.loading);
  const { adns, adneditar } = useSelector((state) => state.adn);
  const [cardT1, setCardT1] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [idEliminar, setIdEliminar] = useState("");
  const [openModalEliminar, setOpenModalEliminar] = useState(false);
  const [dataEditar, setDataEditar] = useState(null);
  const [dataAdnUser, setDataAdnUser] = useState({
    modulos: [],
    //submodulos: [],
  });

  /*const [submodulos, setSubmodulos] = useState(
    dataEditar ? dataEditar.submodulos : null
  );*/

  /*const [submodulosuser, setSubmodulosUser] = useState(
    usuario ? usuario.submodulos : null
  );*/
  const [view, setView] = useState("default");

  const [_switch, setSwitch] = useState(false);


  useEffect(() => {
    clientAxios.get(`/api/usuarios/${idUserParam}`)
      .then(res => {
        setUsuario(res.data)
      }).catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (usuario) {
      if (usuario.sexo === "Masculino") {
        setCardT1("typeC1");
      } else if (usuario.sexo === "Femenino") {
        setCardT1("typeC2");
      }
    }
  }, [usuario]);

  useEffect(() => {
    dispatch(obtenerAdnAction(idUserParam));
    setDataAdnUser({
      modulos: usuario.modulos,
      //submodulos: usuario.submodulos,
    });
  }, [usuario]);

  const evitarBug = () => {
    if (usuario) {
      dispatch(obtenerAdnAction(usuario._id));
    }
  };

  // Actualizar un ANDSAP
  const updateAdnSapData = (data) => {
    if (data) {
      const updateData = { ...data, iduser: usuario._id };
      dispatch(editarAdnAction(updateData, usuario));
      setOpenModalEditar(false);
    }
  };

  // Crear nuevo ANDSAP
  const createAdnsSapData = (data) => {
    if (data) {
      const createData = { ...data, iduser: usuario._id };
      dispatch(agregarAdnAction([createData]));
      // TODO: refactorizar funcion evitarBug por udpate ANDSAP
      evitarBug();
      setOpenModal(false);
    }
  };

  const getFilterSubmodules = (item) => ({
    ...item,
    //submodulos: item.submodulos.filter(i => i.nivel !== 'No Maneja' && !_.isNil(i.nivel)),
  });
  const modal = createRef();
  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Loader
            type="Oval"
            color="#00BFFF"
            height={100}
            width={100}
            visible={loading}
          //  timeout={3000} //3 secs
          />
        </div>
      ) : (
        <>
          <One
            setView={setView}
            setDataEditar={createAdnsSapData}
            //submodulos={submodulos}
            //setSubmodulos={setSubmodulos}
            //submodulosuser={submodulosuser}
          ></One>

          <div className="cont-cards-adn no-padding" ref={modal}>

            {adns.map((item, index) => (
              <Card
                data={getFilterSubmodules(item)}
                key={index}
                setIdEliminar={setIdEliminar}
                setOpenModalEliminar={setOpenModalEliminar}
                setOpenModalEditar={setOpenModalEditar}
                setDataEditar={setDataEditar}
                setSwitch={setSwitch}
                setDataAdnUser={setDataAdnUser}
                dataAdnUser={dataAdnUser}
                usuario={usuario}
              />
            ))}

            <ModalEliminar
              adnsap
              openModalEliminar={openModalEliminar}
              setOpenModalEliminar={setOpenModalEliminar}
              idEliminar={idEliminar}
              setIdEliminar={setIdEliminar}
              dataAdnUser={dataAdnUser}
            />
            <ModalEditar
              setOpenModalEditar={setOpenModalEditar}
              openModalEditar={openModalEditar}
              dataEditar={dataEditar}
              setDataEditar={updateAdnSapData}
              setSwitch={setSwitch}
              evitarBug={evitarBug}
            />
            {/* <Modal
              setOpenModal={setOpenModal}
              openModal={openModal}
              adns={adns}
            /> */}
            <NewModal
              setOpenModal={setOpenModal}
              openModal={openModal}
              adns={adns}
              setData={createAdnsSapData}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AdnSap;