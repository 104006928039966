import _ from 'lodash';
import React, { useCallback, useEffect, useState } from "react";
import { modulos } from "../../../../../../assets/modulos";
import {
  ListItem,
  Snackbar,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  ListItemAvatar,
} from "@material-ui/core";
import "./adnSapForm.css";
import { Alert } from "@material-ui/lab";
import { CloudUpload } from "@material-ui/icons";
import { useSelector } from 'react-redux';

const LEVEL_INIT_VALUE = 'No Maneja';

const One = (props) => {
  const { setView, dataEditar, setDataEditar } = props;
  const { adns } = useSelector((state) => state.adn);
  const nombreModulosRepetidos = adns.map((i) => i.name);
  // get modulos no creados
  const modulosNoRepeat = _.filter(modulos, (i) => !nombreModulosRepetidos.includes(i.modulo));
  const [selectModulo, setSelectModulo] = useState(null);
  const [selectModuloLabelValue, setSelectModuloLabelValue] = useState("");
  const [idCertificacion, setIdCertificacion] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [desc, setDesc] = useState('');
  const [_alert, setAlert] = useState(false);
  const [msg, setMsg] = useState("");
  const [nivel, setNivel] = useState('');

  const [file, setFile] = useState(
    dataEditar ? (dataEditar.adnURL ? dataEditar.adnURL : null) : null
  );

  const fileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  // INIT 
  useEffect(() => {
    if (_.isEmpty(dataEditar))
      return;

    setSelectModulo(dataEditar);
    setDesc(dataEditar.desc);
    setIdCertificacion(dataEditar.idcert);
    setObservaciones(dataEditar.obs);
    setNivel(dataEditar.nivel);

  }, [dataEditar]);


  const handleClose = () => {
    setAlert(false);
  };

  const handleSubmitForm = (e) => validateForm();

  const validateForm = () => {


    let selectValue = false;
    modulosNoRepeat.filter((product, i)=>{
      if (product.isChecked){
        selectValue = true;
      }
    });

    if (!selectValue && _.isEmpty(dataEditar)) {
      setAlert(true);
      setMsg("Debes seleccionar al menos un módulo");
    } else {
      setAlert(false);
      setMsg("");
      sendForm();
    }
  };

  const setValueSelectModulos = (e) => {
    setSelectModuloLabelValue(e.target.value);
    setSelectModulo({
      name: e.target.value,
      desc: e.nativeEvent.target.outerText,
      id: "",
      obs: "",
      nivel: "",
      file: "",
    });

  };

  const setValueSelectNivel = (e) => {
    setNivel(e.nativeEvent.target.outerText);
  }

  const sendForm = useCallback(() => {
    //const submodulos = dataSelectSubModulos;
    const modulosChecked = modulosNoRepeat.filter((modulo)=> modulo.isChecked==true);
    
    let data = {modulosList: modulosChecked, type:'Lista'};
    
    if(_.isEmpty(dataEditar)){
      //console.log(data);
      setDataEditar(data);
    }

    if(!_.isEmpty(dataEditar)){
      data = {
        ...dataEditar,
        //submodulos: _.filter(submodulos, (i) => i.nivel !== LEVEL_INIT_VALUE && !!i.nivel),
        adnURL: file,
        name: selectModulo.name,
        desc: selectModulo.desc,
        nivel:nivel,
        idcert: idCertificacion,
        obs: observaciones,
  
      };
      setDataEditar(data);
    }

    //console.log(data);
    setView("default");

  }, [idCertificacion, observaciones, file, selectModulo, nivel]);  //, dataSelectSubModulos

  const addItem = (selectedValue) => {
    modulosNoRepeat.filter((product, i)=>{
      if (product.modulo == selectedValue){
        modulosNoRepeat[i].isChecked = true;
        return;
      }
   });
  }


  const setValueSelectNivelModulo = (e, modulo) => {
    setNivel(e);
  
    modulosNoRepeat.filter((product, i)=>{
      
      if (e == 'Nivel' && product.modulo == modulo){
        modulosNoRepeat[i].isChecked = false;
        document.getElementById(i).checked = false;
      }else if (e != 'Nivel' && product.modulo == modulo) {
        modulosNoRepeat[i].isChecked = true;
        document.getElementById(i).checked = true;
      }
      if (product.modulo == modulo){
        modulosNoRepeat[i].nivel = e;
        return;
      }
    });
  }

  return (
    <div className="cont-four-adn-formeditar-edit">
      <div className={`cont-one-adn-edit-man cont-four-adn-editar-edit ${_.isEmpty(dataEditar) ? 'no-margin' : ''}`}>
        <div className="stepper-one-adn">


          <h1>{_.isEmpty(dataEditar) ? '¿En qué eres consultor?' : 'Editar Módulo SAP'}</h1>
          
              {_.isEmpty(dataEditar) ? (
              <>
              <div className="cont-a-convenir-actual" style={{height: '450px', overflowY: 'scroll', display: 'flex', alignItems: 'flex-start', flex: 'auto'}}>
                <>
                  <table>
                    <tbody>
                      {modulosNoRepeat.map((item, index) => <>
                        <tr key={index}>
                          <td><input
                            type="checkbox"
                            name={item.modulo}
                            value={item.modulo}
                            id={index}

                            onChange={(e) => addItem(e.target.value)} />
                            <label>{item.modulo + ' - ' + item.desc}</label>
                          </td>
                          <td>
                            <Select
                              id = {"item_"+item.modulo}
                              style={{ fontSize: '12px', color: '#7d7f80', padding: '5px 0 5px 5px', marginTop: "0px", width: "100%", minWidth: "103px" }}
                              //value={nivel}
                              defaultValue={"Nivel"}
                              onChange={(e) => setValueSelectNivelModulo(e.target.value, item.modulo)}
                            >
                              <MenuItem value={"Nivel"}>
                                Nivel
                              </MenuItem>
                              <MenuItem value="No Maneja">No Maneja</MenuItem>
                              <MenuItem value="Básico">Básico</MenuItem>
                              <MenuItem value="Medio">Medio</MenuItem>
                              <MenuItem value="Avanzado">Avanzado</MenuItem>
                            </Select>
                          </td>
                        </tr>
                      </>
                      )}
                    </tbody>
                  </table>
                </>
              </div></>
              
              
              ) : <>
                  <p className='p1-card-adn-desc-left'>
                    {selectModulo?.name}
                  </p><Select
                    style={{ marginTop: "12px", width: "100%" }}
                    value={nivel}
                    displayEmpty
                    onChange={setValueSelectNivel}
                    >
                      <MenuItem value="" disabled>
                        Nivel
                      </MenuItem>
                      <MenuItem value="No Maneja">No Maneja</MenuItem>
                      <MenuItem value="Básico">Básico</MenuItem>
                      <MenuItem value="Medio">Medio</MenuItem>
                      <MenuItem value="Avanzado">Avanzado</MenuItem>
                    </Select></>
              }
            
        </div>

        <div className="cont-btns-one-adn-edit">
          <ListItem
            button
            className="btn-adnzap-modal"
            style={{ color: "white" }}
            onClick={handleSubmitForm}
          >
            <p>Guardar</p>
          </ListItem>
        </div>
        <Snackbar open={_alert} onClose={handleClose} autoHideDuration={6000}>
          <Alert severity="error">{msg}</Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default One;
