export const estudios_data = [
  {
    institucion: "Cft Accioma",
    carreras: [
      "Tecnico En Administracion En Seguridad Privada",
      "Tecnico En Arte Aplicado",
      "Tecnico En Conservacion Y Restauracion",
    ],
  },
  {
    institucion: "Cft Alfa",
    carreras: [
      "Administracion De Empresas",
      "Contabilidad",
      "Procesos De La Madera",
      
    ],
  },
  {
    institucion: "Cft Alpes",
    carreras: [
      "Fotografia",
      "Fotografia Especialidades Fotografia Publicitaria Y Fotografia Periodistica",
      "Ilustracion Artistica",
      "Tecnico De Nivel Superior En Fotografia",
      
    ],
  },
  {
    institucion: "Cft Camara De Comercio De Santiago",
    carreras: [
      "Administracion Comercial",
      "Administracion En Comercio Exterior",
      "Administracion En Recursos Humanos",
      "Contabilidad General",
      "Control De Gestion",
      "Finanzas",
      "Logistica Operativa",
      "Marketing Digital",
      "Marketing Y Gestion Comercial",
     
    ],
  },
  {
    institucion: "Cft Ceduc - Ucn",
    carreras: [
      "Administracion Bilingüe",
      "Administracion De Empresas",
      "Computacion E Informatica",
      "Construccion",
      "Educacion De Parvulos",
      "Educacion General",
      "Electricidad Y Eficiencia Energetica",
      "Electricidad Y Electronica Industrial",
      "Electromecanica",
      "Enfermeria Y Telemedicina",
      "Geomineria",
      "Instrumentacion Y Control Industrial",
      "Intervencion Y Rehabilitacion Psicosocial",
      "Mantenimiento Industrial Y Mecatronica",
      "Maquinaria Pesada",
      "Mecanica De Equipo Pesado",
      "Operacion De Plantas Mineras",
      "Tecnico Nivel Superior En Educacion General",
     
    ],
  },
  {
    institucion: "Cft Ceitec",
    carreras: [
      "Administracion De Seguridad Privada",
      "Administracion Logistica",
      "Administracion Publica",
      "Asistente Tecnico De Educacion Parvularia Y Del Primer Nivel De Educacion General Basica",
      "Asistente Tecnico Superior De Educacion Parvularia Y Del Primer Nivel",
      "Orientacion Y Mediacion Familiar Y Social",
      "Prevencion De Riesgos",
      "Tecnico En Enfermeria",
      "Tecnico En Mineria",
      "Tecnico En Trabajo Social",
      
    ],
  },
  {
    institucion: "Cft Cenco",
    carreras: [
      "Inspector Educacional",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion De Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion Logistica",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Asistente De Aula",
      "Tecnico De Nivel Superior En Contabilidad General Mencion Contabilidad Publica",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Farmacia",
      "Tecnico De Nivel Superior En Gestion De Instituciones De Salud",
      "Tecnico De Nivel Superior En Gestion Turistica",
      "Tecnico De Nivel Superior En Multimedia",
      "Tecnico De Nivel Superior En Operacion De Transporte Y Transito",
      "Tecnico De Nivel Superior En Prevencion De Riesgos",
      "Tecnico De Nivel Superior En Soporte Informatico Y Redes",
      "Tecnico De Nivel Superior En Trabajo Social",
    
    ],
  },
  {
    institucion: "Cft Centro De Enseñanza De Alta Costura Paulina Diard",
    carreras: [
      "Diseño De Vestuario, Moldaje Y Confeccion",
      
    ],
  },
  {
    institucion: "Cft Centro Tecnologico Superior Infomed",
    carreras: [
      "Tecnico En Optica Con Mencion En Contactologia",
      "Tecnico Superior En Farmacia",
      "Tecnico Superior En Informatica Con Mencion Desarrollo De Tecnologias De Informacion",
      "Tecnico Superior En Informatica Con Mencion En Gestion Biomedica",
      "Tecnico Superior En Informatica Con Mencion En Programacion Educativa Escolar Y Tics",
      "Visitador Medico",
    
    ],
  },
  {
    institucion: "Cft De Enac",
    carreras: [
      "Tecnico Educador Social Infanto Juvenil",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Alimentacion Y Dietetica",
      "Tecnico En Automatizacion Y Control Industrial",
      "Tecnico En Bibliotecologia Y Documentacion",
      "Tecnico En Comercio Exterior Y Mercados Digitales",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Construccion",
      "Tecnico En Contabilidad Y Normativa Tributaria",
      "Tecnico En Cosmetologia Y Estetica Integral",
      "Tecnico En Educacion Especial",
      "Tecnico En Educacion Parvulos 1 Y 2 Basico",
      "Tecnico En Electricidad Industrial",
      "Tecnico En Enfermeria",
      "Tecnico En Farmacia",
      "Tecnico En Gastronomia",
      "Tecnico En Gestion De Recursos Humanos",
      "Tecnico En Hoteleria Y Turismo",
      "Tecnico En Imagenologia Y Radioterapia",
      "Tecnico En Informatica Mencion Ciberseguridad",
      "Tecnico En Laboratorio Clinico Y Banco De Sangre",
      "Tecnico En Logistica",
      "Tecnico En Masoterapia",
      "Tecnico En Odontologia",
      "Tecnico En Podologia Clinica",
      "Tecnico En Prevencion Y Rehabilitacion De Adicciones",
      "Tecnico En Terapias Complementarias De Salud",
      "Tecnico En Trabajo Social",
     
    ],
  },
  {
    institucion: "Cft De La Region De Antofagasta",
    carreras: [
      "Tecnico De Nivel Superior En Administracion De Sistemas Logisticos",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Administracion De Sistemas Logisticos",
      "Tecnico De Nivel Superior En Educacion Parvularia",
    ],
  },
  {
    institucion: "Cft De La Region De Coquimbo",
    carreras: [
      "Tecnico De Nivel Superior En Administracion",
      "Tecnico De Nivel Superior En Agroindustrias",
      "Tecnico De Nivel Superior En Energias Renovables Y Eficiencia Energetica",
      "Tecnico De Nivel Superior En Gestion De Recursos Hidricos",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior En Mantenimiento Industrial",
      "Tecnico De Nivel Superior En Tecnologia De La Informacion",
      "Tecnico De Nivel Superior En Administracion",
      "Tecnico De Nivel Superior En Agroindustrias",
      "Tecnico De Nivel Superior En Energias Renovables Y Eficiencia Energetica",
      "Tecnico De Nivel Superior En Gestion De Recursos Hidricos",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior En Mantenimiento Industrial",
      "Tecnico De Nivel Superior En Tecnologia De La Informacion",
    ],
  },
  {
    institucion: "Cft De La Region De La Araucania",
    carreras: [
      "Tecnico De Nivel Superior Analista Programador",
      "Tecnico De Nivel Superior En Climatizacion Y Refrigeracion",
      "Tecnico En Electricidad Y Automatizacion",
      "Tecnico En Gestion De Empresas",
      "Tecnico En Logistica Empresarial",
      "Tecnico En Mantenimiento Industrial",
      "Tecnico De Nivel Superior Analista Programador",
      "Tecnico De Nivel Superior En Climatizacion Y Refrigeracion",
      "Tecnico En Electricidad Y Automatizacion",
      "Tecnico En Gestion De Empresas",
      "Tecnico En Logistica Empresarial",
      "Tecnico En Mantenimiento Industrial",
    ],
  },
  {
    institucion: "Cft De La Region De Los Lagos",
    carreras: [
      "Edificacion",
      "Mantenimiento Industrial",
      "Montaje Industrial",
      "Operaciones Logisticas",
      "Edificacion",
      "Mantenimiento Industrial",
      "Montaje Industrial",
      "Operaciones Logisticas",
    ],
  },
  {
    institucion: "Cft De La Region De Los Rios",
    carreras: [
      "Tecnico De Nivel Superior En Administracion Publica Privada",
      "Tecnico De Nivel Superior En Agroindustria E Innovacion Alimentaria",
      "Tecnico De Nivel Superior En Automatizacion",
      "Tecnico De Nivel Superior En Electricidad Mencion Energias Renovables",
      "Tecnico De Nivel Superior En Turismo De Intereses Especiales",
      "Tecnico De Nivel Superior En Administracion Publica Privada",
      "Tecnico De Nivel Superior En Agroindustria E Innovacion Alimentaria",
      "Tecnico De Nivel Superior En Automatizacion",
      "Tecnico De Nivel Superior En Electricidad Mencion Energias Renovables",
      "Tecnico De Nivel Superior En Turismo De Intereses Especiales",
    ],
  },
  {
    institucion: "Cft De La Region De Magallanes Y Antartica Chilena",
    carreras: [
      "Tecnico De Nivel Superior En Administracion De Sistemas Logisticos",
      "Tecnico De Nivel Superior En Turismo",
      "Tecnico De Nivel Superior En Administracion De Sistemas Logisticos",
      "Tecnico De Nivel Superior En Turismo",
    ],
  },
  {
    institucion: "Cft De La Region De Tarapaca",
    carreras: [
      "Tecnico De Nivel Superior Electrico En Energias Renovables",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior Mecanico En Mantenimiento Predictivo",
      "Tecnico De Nivel Superior Electrico En Energias Renovables",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior Mecanico En Mantenimiento Predictivo",
    ],
  },
  {
    institucion: "Cft De La Region De Valparaiso",
    carreras: [
      "Administracion De Empresas Control De Gestion",
      "Administracion De Empresas Gestion Financiera",
      "Comercio Exterior",
      "Gestion De Empresas",
      "Gestion Logistica",
      "Gestion Publica",
      "Logistica",
      "Mantenimiento Industrial",
      "Mecatronica",
      "Tecnico De Nivel Superior En Enfermeria",
      "Administracion De Empresas Control De Gestion",
      "Administracion De Empresas Gestion Financiera",
      "Comercio Exterior",
      "Gestion De Empresas",
      "Gestion Logistica",
      "Gestion Publica",
      "Logistica",
      "Mantenimiento Industrial",
      "Mecatronica",
      "Tecnico De Nivel Superior En Enfermeria",
    ],
  },
  {
    institucion: "Cft De La Region Del Maule",
    carreras: [
      "Tecnico De Nivel Superior En Administracion De Las Organizaciones",
      "Tecnico De Nivel Superior En Contabilidad General",
      "Tecnico De Nivel Superior En Educacion Diferencial E Inclusiva",
      "Tecnico De Nivel Superior En Fruticultura",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior En Mantencion Y Reparacion De Maquinaria Pesada",
      "Tecnico De Nivel Superior En Mecanica Automotriz",
      "Tecnico De Nivel Superior En Mecanizacion Y Automatizacion Agricola",
      "Tecnico De Nivel Superior En Operacion Y Mantencion De Sistemas De Riego",
      "Tecnico De Nivel Superior En Turismo",
      "Tecnico De Nivel Superior En Administracion De Las Organizaciones",
      "Tecnico De Nivel Superior En Contabilidad General",
      "Tecnico De Nivel Superior En Educacion Diferencial E Inclusiva",
      "Tecnico De Nivel Superior En Fruticultura",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior En Mantencion Y Reparacion De Maquinaria Pesada",
      "Tecnico De Nivel Superior En Mecanica Automotriz",
      "Tecnico De Nivel Superior En Mecanizacion Y Automatizacion Agricola",
      "Tecnico De Nivel Superior En Operacion Y Mantencion De Sistemas De Riego",
      "Tecnico De Nivel Superior En Turismo",
    ],
  },
  {
    institucion: "Cft De La Region Metropolitana De Santiago",
    carreras: [
      "Tecnico De Nivel Superior En Administracion De Sistemas Logisticos",
      "Tecnico De Nivel Superior En Administracion De Sistemas Logisticos",
    ],
  },
  {
    institucion: "Cft De Tarapaca",
    carreras: [
      "Administracion De Empresas",
      "Agricola",
      "Asistencia Juridica",
      "Deportes Y Recreacion",
      "Proyectos Electricos De Distribucion",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Asistencia Juridica",
      "Tecnico De Nivel Superior En Construcciones Metalicas",
      "Tecnico De Nivel Superior En Control De Gestion Y Logistica",
      "Tecnico De Nivel Superior En Educacion Parvularia Y Primer-segundo Año De Educacion Basica",
      "Tecnico De Nivel Superior En Enfermeria Mencion Gerontologia",
      "Tecnico De Nivel Superior En Fabricacion Y Montaje De Estructuras Metalicas",
      "Tecnico De Nivel Superior En Geologia",
      "Tecnico De Nivel Superior En Gestion Turistica",
      "Tecnico De Nivel Superior En Informatica Y Aplicaciones Tecnologicas",
      "Tecnico De Nivel Superior En Prevencion De Riesgos",
      "Tecnico De Nivel Superior En Redes Computacionales",
      "Tecnico De Nivel Superior En Telecomunicaciones Y Conectividad",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico En Administracion Publica",
      "Tecnico En Educacion Especial",
      "Administracion De Empresas",
      "Agricola",
      "Asistencia Juridica",
      "Deportes Y Recreacion",
      "Proyectos Electricos De Distribucion",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Asistencia Juridica",
      "Tecnico De Nivel Superior En Construcciones Metalicas",
      "Tecnico De Nivel Superior En Control De Gestion Y Logistica",
      "Tecnico De Nivel Superior En Educacion Parvularia Y Primer-segundo Año De Educacion Basica",
      "Tecnico De Nivel Superior En Enfermeria Mencion Gerontologia",
      "Tecnico De Nivel Superior En Fabricacion Y Montaje De Estructuras Metalicas",
      "Tecnico De Nivel Superior En Geologia",
      "Tecnico De Nivel Superior En Gestion Turistica",
      "Tecnico De Nivel Superior En Informatica Y Aplicaciones Tecnologicas",
      "Tecnico De Nivel Superior En Prevencion De Riesgos",
      "Tecnico De Nivel Superior En Redes Computacionales",
      "Tecnico De Nivel Superior En Telecomunicaciones Y Conectividad",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico En Administracion Publica",
      "Tecnico En Educacion Especial",
    ],
  },
  {
    institucion: "Cft Del Medio Ambiente",
    carreras: [
      "Construccion Sustentable",
      "Ecoturismo",
      "Paisajismo Sustentable",
      "Salud Y Cosmetologia Natural",
      "Salud Y Terapias Naturales",
      "Salud, Terapia Floral Y Plantas Medicinales",
      "Tecnico De Nivel Superior En Turismo Y Hoteleria Sustentable",
      "Tecnico En Agricultura Ecologica",
      "Tecnico En Construccion Sustentable",
      "Tecnico En Energias Renovables Y Eficiencia Energetica",
      "Tecnico En Manejo De Areas Silvestres Protegidas",
      "Tecnico En Medio Ambiente",
      "Tecnico Veterinario",
      "Construccion Sustentable",
      "Ecoturismo",
      "Paisajismo Sustentable",
      "Salud Y Cosmetologia Natural",
      "Salud Y Terapias Naturales",
      "Salud, Terapia Floral Y Plantas Medicinales",
      "Tecnico De Nivel Superior En Turismo Y Hoteleria Sustentable",
      "Tecnico En Agricultura Ecologica",
      "Tecnico En Construccion Sustentable",
      "Tecnico En Energias Renovables Y Eficiencia Energetica",
      "Tecnico En Manejo De Areas Silvestres Protegidas",
      "Tecnico En Medio Ambiente",
      "Tecnico Veterinario",
    ],
  },
  {
    institucion: "Cft Educap",
    carreras: [
      "Administracion De Operaciones Logisticas",
      "Contabilidad General",
      "Prevencion De Riesgos",
      "Secretariado Ejecutivo",
      "Tecnico En Administracion De Empresas Mencion Finanzas",
      "Tecnico En Educacion Especial",
      "Tecnico En Educacion Parvularia Y Basica",
      "Tecnico En Gestion Financiera",
      "Tecnico En Logistica",
      "Tecnico Juridico",
      "Administracion De Operaciones Logisticas",
      "Contabilidad General",
      "Prevencion De Riesgos",
      "Secretariado Ejecutivo",
      "Tecnico En Administracion De Empresas Mencion Finanzas",
      "Tecnico En Educacion Especial",
      "Tecnico En Educacion Parvularia Y Basica",
      "Tecnico En Gestion Financiera",
      "Tecnico En Logistica",
      "Tecnico Juridico",
    ],
  },
  {
    institucion: "Cft Escuela Culinaria Francesa",
    carreras: [
      "Diplome Superieur Ecole Culinaire Francaise",
      "Diplome Superieur Ecole Culinaire Francaise",
    ],
  },
  {
    institucion: "Cft Estudio Profesor Valero",
    carreras: [
      "Instructor En Gimnasia Jazz",
      "Interprete E Instructor En Danza Espectaculo",
      "Instructor En Gimnasia Jazz",
      "Interprete E Instructor En Danza Espectaculo",
    ],
  },
  {
    institucion: "Cft Icel",
    carreras: [
      "Administracion Y Gestion De Empresas",
      "Contabilidad General",
      "Tecnico En Educacion Parvularia",
      "Tecnico En Enfermeria",
      "Tecnico En Odontologia",
      "Administracion Y Gestion De Empresas",
      "Contabilidad General",
      "Tecnico En Educacion Parvularia",
      "Tecnico En Enfermeria",
      "Tecnico En Odontologia",
    ],
  },
  {
    institucion: "Cft Inacap",
    carreras: [
      "Administracion De Empresas",
      "Analista Programador",
      "Automatizacion Y Control Industrial",
      "Climatizacion",
      "Comercio Exterior",
      "Contabilidad General",
      "Diseño & Produccion De Moda",
      "Diseño Web & Mobile",
      "Diseño Y Produccion Grafica",
      "Edificacion",
      "Electricidad Industrial Mencion Instalaciones Electricas",
      "Electromecanica",
      "Electronica",
      "Fabricacion Y Montaje Industrial",
      "Gastronomia Internacional",
      "Hoteleria Y Servicios",
      "Laboratorista Clinico, Banco De Sangre E Imagenologia",
      "Logistica Y Operaciones Industriales",
      "Mantenimiento Industrial",
      "Mantenimiento Mecanico De Plantas Mineras",
      "Mecanica Automotriz En Maquinaria Pesada",
      "Mecanica Automotriz En Sistemas Electronicos",
      "Mecanica En Produccion Industrial",
      "Prevencion De Riesgos",
      "Refrigeracion",
      "Tecnico En Enfermeria",
      "Tecnico En Farmacia",
      "Tecnico En Metalurgia Extractiva",
      "Tecnico En Mineria",
      "Tecnico En Odontologia",
      "Tecnologia Agricola",
      "Tecnologia En Analisis Quimico",
      "Tecnologia En Produccion Ganadera",
      "Tecnologia En Sonido",
      "Telecomunicaciones, Conectividad Y Redes",
      "Topografia",
      "Turismo",
      "Administracion De Empresas",
      "Analista Programador",
      "Automatizacion Y Control Industrial",
      "Climatizacion",
      "Comercio Exterior",
      "Contabilidad General",
      "Diseño & Produccion De Moda",
      "Diseño Web & Mobile",
      "Diseño Y Produccion Grafica",
      "Edificacion",
      "Electricidad Industrial Mencion Instalaciones Electricas",
      "Electromecanica",
      "Electronica",
      "Fabricacion Y Montaje Industrial",
      "Gastronomia Internacional",
      "Hoteleria Y Servicios",
      "Laboratorista Clinico, Banco De Sangre E Imagenologia",
      "Logistica Y Operaciones Industriales",
      "Mantenimiento Industrial",
      "Mantenimiento Mecanico De Plantas Mineras",
      "Mecanica Automotriz En Maquinaria Pesada",
      "Mecanica Automotriz En Sistemas Electronicos",
      "Mecanica En Produccion Industrial",
      "Prevencion De Riesgos",
      "Refrigeracion",
      "Tecnico En Enfermeria",
      "Tecnico En Farmacia",
      "Tecnico En Metalurgia Extractiva",
      "Tecnico En Mineria",
      "Tecnico En Odontologia",
      "Tecnologia Agricola",
      "Tecnologia En Analisis Quimico",
      "Tecnologia En Produccion Ganadera",
      "Tecnologia En Sonido",
      "Telecomunicaciones, Conectividad Y Redes",
      "Topografia",
      "Turismo",
    ],
  },
  {
    institucion: "Cft Instituto Central De Capacitacion Educacional Icce",
    carreras: [
      "Administracion De Empresas",
      "Administracion De Seguridad",
      "Administracion Publica",
      "Asistente De La Educacion",
      "Contabilidad General",
      "Estetica Integral",
      "Podologia Clinica",
      "Prevencion De Riesgos",
      "Tecnico En Asistente De La Educacion",
      "Tecnico En Atencion Geriatrica",
      "Tecnico En Construccion",
      "Tecnico En Educacion De Parvulos",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Electricidad",
      "Tecnico En Enfermeria",
      "Tecnico En Farmacia",
      "Tecnico En Logistica",
      "Tecnico En Mineria",
      "Tecnico En Odontologia",
      "Tecnico Juridico",
      "Administracion De Empresas",
      "Administracion De Seguridad",
      "Administracion Publica",
      "Asistente De La Educacion",
      "Contabilidad General",
      "Estetica Integral",
      "Podologia Clinica",
      "Prevencion De Riesgos",
      "Tecnico En Asistente De La Educacion",
      "Tecnico En Atencion Geriatrica",
      "Tecnico En Construccion",
      "Tecnico En Educacion De Parvulos",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Electricidad",
      "Tecnico En Enfermeria",
      "Tecnico En Farmacia",
      "Tecnico En Logistica",
      "Tecnico En Mineria",
      "Tecnico En Odontologia",
      "Tecnico Juridico",
    ],
  },
  {
    institucion: "Cft Instituto Superior Aleman De Comercio Insalco",
    carreras: [
      "Comercio Mayorista Y Exterior Bilingüe",
      "Comercio Naviero Bilingüe",
      "Tecnico Bilingüe En Comercio Y Transporte Internacional",
      "Comercio Mayorista Y Exterior Bilingüe",
      "Comercio Naviero Bilingüe",
      "Tecnico Bilingüe En Comercio Y Transporte Internacional",
    ],
  },
  {
    institucion: "Cft Instituto Superior De Estudios Juridicos Canon",
    carreras: [
      "Secretariado Ejecutivo",
      "Tecnico En Administracion",
      "Tecnico En Educacion Basica",
      "Tecnico En Educacion De Parvulos",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Enfermeria",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico Juridico",
      "Secretariado Ejecutivo",
      "Tecnico En Administracion",
      "Tecnico En Educacion Basica",
      "Tecnico En Educacion De Parvulos",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Enfermeria",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico Juridico",
    ],
  },
  {
    institucion: "Cft Instituto Tecnologico De Chile - I.t.c.",
    carreras: ["Tecnico En Naturopatia", "Tecnico En Naturopatia"],
  },
  {
    institucion: "Cft Iprosec",
    carreras: [
      "Administracion De Empresas Mencion Recursos Humanos",
      "Asistente De Parvulo Y Primer Ciclo Educacion Basica Mencion Necesidades Educativas Especiales",
      "Construccion Mencion Topografia",
      "Electricidad",
      "Enfermeria, Mencion Geriatria",
      "Enfermeria, Mencion Terapias Complementarias",
      "Peluqueria Y Estetica",
      "Prevencion De Riesgos",
      "Trabajo Social",
      "Administracion De Empresas Mencion Recursos Humanos",
      "Asistente De Parvulo Y Primer Ciclo Educacion Basica Mencion Necesidades Educativas Especiales",
      "Construccion Mencion Topografia",
      "Electricidad",
      "Enfermeria, Mencion Geriatria",
      "Enfermeria, Mencion Terapias Complementarias",
      "Peluqueria Y Estetica",
      "Prevencion De Riesgos",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Cft Juan Bohon",
    carreras: [
      "Administracion De Empresas",
      "Administracion Y Gestion En Salud",
      "Analista Programador",
      "Asistente De Educador De Parvulos",
      "Asistente De Parvulos",
      "Atencion Del Adulto Mayor",
      "Ciberseguridad",
      "Contabilidad General Mencion Tributaria",
      "Electricidad Y Electronica Industrial",
      "Energias Renovables Y Eficiencia Energetica",
      "Estetica Integral",
      "Gastronomia Y Cocina Internacional",
      "Instrumentacion, Automatizacion Y Control Industrial",
      "Logistica Industrial",
      "Mantenimiento Mecanico Industrial",
      "Tecnico En Construccion",
      "Tecnico En Operacion Y Mantencion De Maquinaria Pesada",
      "Tecnico Mecanico En Maquinaria Pesada",
      "Topografia",
      "Administracion De Empresas",
      "Administracion Y Gestion En Salud",
      "Analista Programador",
      "Asistente De Educador De Parvulos",
      "Asistente De Parvulos",
      "Atencion Del Adulto Mayor",
      "Ciberseguridad",
      "Contabilidad General Mencion Tributaria",
      "Electricidad Y Electronica Industrial",
      "Energias Renovables Y Eficiencia Energetica",
      "Estetica Integral",
      "Gastronomia Y Cocina Internacional",
      "Instrumentacion, Automatizacion Y Control Industrial",
      "Logistica Industrial",
      "Mantenimiento Mecanico Industrial",
      "Tecnico En Construccion",
      "Tecnico En Operacion Y Mantencion De Maquinaria Pesada",
      "Tecnico Mecanico En Maquinaria Pesada",
      "Topografia",
    ],
  },
  {
    institucion: "Cft Laplace",
    carreras: [
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Construccion Y Obras Civiles",
      "Tecnico De Nivel Superior En Educacion De Parvulos",
      "Tecnico De Nivel Superior En Electricidad Y Electronica Industrial",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Mantenimiento Mecanico Industrial",
      "Tecnico De Nivel Superior En Prevencion De Riesgos",
      "Tecnico De Nivel Superior Veterinario",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Construccion Y Obras Civiles",
      "Tecnico De Nivel Superior En Educacion De Parvulos",
      "Tecnico De Nivel Superior En Electricidad Y Electronica Industrial",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Mantenimiento Mecanico Industrial",
      "Tecnico De Nivel Superior En Prevencion De Riesgos",
      "Tecnico De Nivel Superior Veterinario",
    ],
  },
  {
    institucion: "Cft Los Lagos",
    carreras: [
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion Publica",
      "Tecnico En Atencion Del Adulto Mayor",
      "Tecnico En Construccion",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Educacion Parvularia Y Primer Ciclo De Enseñanza General Basica",
      "Tecnico En Enfermeria",
      "Tecnico En Gestion De Recursos Humanos",
      "Tecnico En Gestion Logistica",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Rehabilitacion De Drogodependiente",
      "Tecnico En Rehabilitacion De Drogodependientes",
      "Tecnico En Topografia",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion Publica",
      "Tecnico En Atencion Del Adulto Mayor",
      "Tecnico En Construccion",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Educacion Parvularia Y Primer Ciclo De Enseñanza General Basica",
      "Tecnico En Enfermeria",
      "Tecnico En Gestion De Recursos Humanos",
      "Tecnico En Gestion Logistica",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Rehabilitacion De Drogodependiente",
      "Tecnico En Rehabilitacion De Drogodependientes",
      "Tecnico En Topografia",
    ],
  },
  {
    institucion: "Cft Lota-arauco",
    carreras: [
      "Administracion De Empresas",
      "Administracion Publica",
      "Computacion E Informatica",
      "Construccion",
      "Educacion De Parvulo",
      "Educacion De Parvulos",
      "Educacion Diferencial",
      "Gestion Portuaria Y Pesquera",
      "Instrumentacion Y Automatizacion Industrial",
      "Logistica",
      "Mecanica Industrial",
      "Prevencion De Riesgos",
      "Refrigeracion Industrial Y Climatizacion",
      "Trabajo Social",
      "Turismo Y Gastronomia",
      "Administracion De Empresas",
      "Administracion Publica",
      "Computacion E Informatica",
      "Construccion",
      "Educacion De Parvulo",
      "Educacion De Parvulos",
      "Educacion Diferencial",
      "Gestion Portuaria Y Pesquera",
      "Instrumentacion Y Automatizacion Industrial",
      "Logistica",
      "Mecanica Industrial",
      "Prevencion De Riesgos",
      "Refrigeracion Industrial Y Climatizacion",
      "Trabajo Social",
      "Turismo Y Gastronomia",
    ],
  },
  {
    institucion: "Cft Manpower",
    carreras: [
      "Administracion De Empresas Mencion Marketing Y Ventas O Comercio Exterior O Gestion Del Capital Humano",
      "Asistente Ejecutivo Bilingüe",
      "Asistente Ejecutivo Y De Gestion",
      "Contabilidad Tributaria",
      "Contabilidad Y Analisis Financiero",
      "Gestion Logistica Integral",
      "Administracion De Empresas Mencion Marketing Y Ventas O Comercio Exterior O Gestion Del Capital Humano",
      "Asistente Ejecutivo Bilingüe",
      "Asistente Ejecutivo Y De Gestion",
      "Contabilidad Tributaria",
      "Contabilidad Y Analisis Financiero",
      "Gestion Logistica Integral",
    ],
  },
  {
    institucion: "Cft Massachusetts",
    carreras: [
      "Prevencion De Riesgos Y Medio Ambiente",
      "Secretariado Ejecutivo Computacional",
      "Secretariado Ejecutivo Computacional Mencion Juridica O Relaciones Publicas",
      "Tecnico De Nivel Superior En Parvulos Y Primer Ciclo Basico",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Construccion",
      "Tecnico En Enfermeria",
      "Tecnico Financiero Bancario",
      "Tecnico Juridico",
      "Prevencion De Riesgos Y Medio Ambiente",
      "Secretariado Ejecutivo Computacional",
      "Secretariado Ejecutivo Computacional Mencion Juridica O Relaciones Publicas",
      "Tecnico De Nivel Superior En Parvulos Y Primer Ciclo Basico",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Construccion",
      "Tecnico En Enfermeria",
      "Tecnico Financiero Bancario",
      "Tecnico Juridico",
    ],
  },
  {
    institucion: "Cft Proandes",
    carreras: [
      "Administracion De Redes Mencion Seguridad Informatica",
      "Analista Programador",
      "Analista Programador Mencion Aplicaciones Moviles Y Web",
      "Asistente Tecnico Para La Educacion Parvularia Y Basica Mencion En Educacion Diferencial",
      "Comercio Exterior",
      "Contabilidad",
      "Contabilidad General",
      "Contabilidad General Mencion Auditoria",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion De Empresas Mencion Finanzas O Marketing",
      "Tecnico En Administracion Y Finanzas",
      "Tecnico En Administracion Y Marketing",
      "Tecnico En Computacion E Informatica",
      "Tecnico En Construccion",
      "Tecnico En Edificacion Y Terminaciones",
      "Tecnico En Educacion De Parvulos",
      "Tecnico En Educacion De Parvulos Con Especializacion En Aula Inclusiva",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Electricidad",
      "Tecnico En Electricidad Industrial",
      "Tecnico En Electricidad Y Electronica",
      "Tecnico En Electricidad Y Electronica, Con Especializacion En Fotovoltaico",
      "Tecnico En Enfermeria",
      "Tecnico En Fabricacion Y Montaje Industrial",
      "Tecnico En Farmacia",
      "Tecnico En Gestion De Recursos Humanos",
      "Tecnico En Gestion Logistica",
      "Tecnico En Instalaciones Fotovoltaicas Y Termicas",
      "Tecnico En Operaciones Logistica",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Prevencion De Riesgos Industriales",
      "Tecnico En Topografia",
      "Tecnico En Topografia Y Geomensura",
      "Tecnico En Trabajo Social",
      "Administracion De Redes Mencion Seguridad Informatica",
      "Analista Programador",
      "Analista Programador Mencion Aplicaciones Moviles Y Web",
      "Asistente Tecnico Para La Educacion Parvularia Y Basica Mencion En Educacion Diferencial",
      "Comercio Exterior",
      "Contabilidad",
      "Contabilidad General",
      "Contabilidad General Mencion Auditoria",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion De Empresas Mencion Finanzas O Marketing",
      "Tecnico En Administracion Y Finanzas",
      "Tecnico En Administracion Y Marketing",
      "Tecnico En Computacion E Informatica",
      "Tecnico En Construccion",
      "Tecnico En Edificacion Y Terminaciones",
      "Tecnico En Educacion De Parvulos",
      "Tecnico En Educacion De Parvulos Con Especializacion En Aula Inclusiva",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Electricidad",
      "Tecnico En Electricidad Industrial",
      "Tecnico En Electricidad Y Electronica",
      "Tecnico En Electricidad Y Electronica, Con Especializacion En Fotovoltaico",
      "Tecnico En Enfermeria",
      "Tecnico En Fabricacion Y Montaje Industrial",
      "Tecnico En Farmacia",
      "Tecnico En Gestion De Recursos Humanos",
      "Tecnico En Gestion Logistica",
      "Tecnico En Instalaciones Fotovoltaicas Y Termicas",
      "Tecnico En Operaciones Logistica",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Prevencion De Riesgos Industriales",
      "Tecnico En Topografia",
      "Tecnico En Topografia Y Geomensura",
      "Tecnico En Trabajo Social",
    ],
  },
  {
    institucion: "Cft Prodata",
    carreras: [
      "Tecnico En Administracion De Empresas Mencion Instituciones Financieras",
      "Tecnico En Educacion Parvularia Mencion Integracion Escolar",
      "Tecnico En Enfermeria",
      "Tecnico En Informatica Con Especializacion En Desarrollo De Software Y Multimedios",
      "Tecnico En Prevencion De Riesgos Y Medio Ambiente",
      "Tecnico En Programacion Y Analisis De Sistemas",
      "Tecnico En Trabajo Social",
      "Tecnico En Administracion De Empresas Mencion Instituciones Financieras",
      "Tecnico En Educacion Parvularia Mencion Integracion Escolar",
      "Tecnico En Enfermeria",
      "Tecnico En Informatica Con Especializacion En Desarrollo De Software Y Multimedios",
      "Tecnico En Prevencion De Riesgos Y Medio Ambiente",
      "Tecnico En Programacion Y Analisis De Sistemas",
      "Tecnico En Trabajo Social",
    ],
  },
  {
    institucion: "Cft Profasoc",
    carreras: [
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Atencion De Parvulos Y Estudiantes De Primer Ciclo Basico Con Mencion En Apoyo A Las Necesidades Educativas Especiales Transitorias",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Atencion De Parvulos Y Estudiantes De Primer Ciclo Basico Con Mencion En Apoyo A Las Necesidades Educativas Especiales Transitorias",
      "Tecnico De Nivel Superior En Enfermeria",
    ],
  },
  {
    institucion: "Cft Pucv",
    carreras: [
      "Administracion De Empresas",
      "Administracion Publica",
      "Agricola",
      "Comercio Exterior",
      "Computacion E Informatica",
      "Construccion",
      "Contabilidad General",
      "Educacion Parvularia",
      "Electricidad",
      "Enfermeria",
      "Gastronomia Internacional",
      "Instrumentacion, Electronica Y Control Industrial",
      "Logistica",
      "Mantencion Industrial",
      "Mecanica Automotriz",
      "Odontologia",
      "Operaciones Mineras",
      "Operaciones Portuarias",
      "Prevencion De Riesgos Industriales",
      "Quimica Y Farmacia",
      "Trabajo Social",
      "Turismo Y Hoteleria",
      "Administracion De Empresas",
      "Administracion Publica",
      "Agricola",
      "Comercio Exterior",
      "Computacion E Informatica",
      "Construccion",
      "Contabilidad General",
      "Educacion Parvularia",
      "Electricidad",
      "Enfermeria",
      "Gastronomia Internacional",
      "Instrumentacion, Electronica Y Control Industrial",
      "Logistica",
      "Mantencion Industrial",
      "Mecanica Automotriz",
      "Odontologia",
      "Operaciones Mineras",
      "Operaciones Portuarias",
      "Prevencion De Riesgos Industriales",
      "Quimica Y Farmacia",
      "Trabajo Social",
      "Turismo Y Hoteleria",
    ],
  },
  {
    institucion: "Cft San Agustin",
    carreras: [
      "Administracion De Empresas",
      "Analista Programador",
      "Contabilidad General",
      "Prevencion De Riesgos",
      "Tecnico Agricola",
      "Tecnico En Automatizacion Y Control Industrial",
      "Tecnico En Ciberseguridad",
      "Tecnico En Comunicacion Digital",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Cosmetologia Y Estetica Integral",
      "Tecnico En Desarrollo De Videojuegos",
      "Tecnico En Enfermeria",
      "Tecnico En Logistica",
      "Tecnico En Mantencion De Maquinaria Pesada",
      "Tecnico En Masoterapia",
      "Tecnico En Mecanica Automotriz Y Autotronica",
      "Tecnico En Obras Civiles",
      "Tecnico En Odontologia",
      "Tecnico En Parvulos Y Basica",
      "Tecnico En Procesos Y Controles De Calidad Alimentaria",
      "Tecnico En Redes Electricas",
      "Tecnico En Trabajo Social",
      "Tecnico En Turismo",
      "Tecnico En Vinicultura",
      "Topografia",
      "Administracion De Empresas",
      "Analista Programador",
      "Contabilidad General",
      "Prevencion De Riesgos",
      "Tecnico Agricola",
      "Tecnico En Automatizacion Y Control Industrial",
      "Tecnico En Ciberseguridad",
      "Tecnico En Comunicacion Digital",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Cosmetologia Y Estetica Integral",
      "Tecnico En Desarrollo De Videojuegos",
      "Tecnico En Enfermeria",
      "Tecnico En Logistica",
      "Tecnico En Mantencion De Maquinaria Pesada",
      "Tecnico En Masoterapia",
      "Tecnico En Mecanica Automotriz Y Autotronica",
      "Tecnico En Obras Civiles",
      "Tecnico En Odontologia",
      "Tecnico En Parvulos Y Basica",
      "Tecnico En Procesos Y Controles De Calidad Alimentaria",
      "Tecnico En Redes Electricas",
      "Tecnico En Trabajo Social",
      "Tecnico En Turismo",
      "Tecnico En Vinicultura",
      "Topografia",
    ],
  },
  {
    institucion: "Cft Santo Tomas",
    carreras: [
      "Cocina Internacional Y Tradicional Chilena",
      "Contabilidad General",
      "Gastronomia Internacional Y Tradicional Chilena",
      "Laboratorista Dental",
      "Preparador Fisico",
      "Servicios Aerocomerciales Y Transportes Turisticos",
      "Tecnico Agricola",
      "Tecnico Agricola Y Ganadero",
      "Tecnico En Administracion",
      "Tecnico En Administracion De Recursos Humanos",
      "Tecnico En Administracion Logistica",
      "Tecnico En Analisis Quimico",
      "Tecnico En Comercio Exterior",
      "Tecnico En Construcciones Civiles",
      "Tecnico En Deportes",
      "Tecnico En Educacion Especial",
      "Tecnico En Educacion Parvularia 1° Y 2° Basico",
      "Tecnico En Enfermeria",
      "Tecnico En Enfermeria Gineco-obstetrica Y Neonatal",
      "Tecnico En Enfermeria Mencion Terapias Complementarias",
      "Tecnico En Enfermeria Y Procedimientos De Apoyo A La Rehabilitacion",
      "Tecnico En Farmacia",
      "Tecnico En Gestion De Medio Ambiente",
      "Tecnico En Hoteleria Y Turismo",
      "Tecnico En Laboratorio Clinico Banco De Sangre E Imagenologia",
      "Tecnico En Odontologia Mencion Higienista Dental",
      "Tecnico En Podologia Clinica",
      "Tecnico En Produccion Acuicola",
      "Tecnico En Registros E Informacion Biomedica",
      "Tecnico En Registros Y Estadisticas De Salud",
      "Tecnico En Veterinaria Y Produccion Pecuaria",
      "Tecnico Juridico",
      "Topografia",
      "Turismo Aventura",
      "Cocina Internacional Y Tradicional Chilena",
      "Contabilidad General",
      "Gastronomia Internacional Y Tradicional Chilena",
      "Laboratorista Dental",
      "Preparador Fisico",
      "Servicios Aerocomerciales Y Transportes Turisticos",
      "Tecnico Agricola",
      "Tecnico Agricola Y Ganadero",
      "Tecnico En Administracion",
      "Tecnico En Administracion De Recursos Humanos",
      "Tecnico En Administracion Logistica",
      "Tecnico En Analisis Quimico",
      "Tecnico En Comercio Exterior",
      "Tecnico En Construcciones Civiles",
      "Tecnico En Deportes",
      "Tecnico En Educacion Especial",
      "Tecnico En Educacion Parvularia 1° Y 2° Basico",
      "Tecnico En Enfermeria",
      "Tecnico En Enfermeria Gineco-obstetrica Y Neonatal",
      "Tecnico En Enfermeria Mencion Terapias Complementarias",
      "Tecnico En Enfermeria Y Procedimientos De Apoyo A La Rehabilitacion",
      "Tecnico En Farmacia",
      "Tecnico En Gestion De Medio Ambiente",
      "Tecnico En Hoteleria Y Turismo",
      "Tecnico En Laboratorio Clinico Banco De Sangre E Imagenologia",
      "Tecnico En Odontologia Mencion Higienista Dental",
      "Tecnico En Podologia Clinica",
      "Tecnico En Produccion Acuicola",
      "Tecnico En Registros E Informacion Biomedica",
      "Tecnico En Registros Y Estadisticas De Salud",
      "Tecnico En Veterinaria Y Produccion Pecuaria",
      "Tecnico Juridico",
      "Topografia",
      "Turismo Aventura",
    ],
  },
  {
    institucion: "Cft Teodoro Wickel Kluwen",
    carreras: [
      "Industrias De Alimentos",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Control, Instrumentacion Y Automatizacion Industrial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Gestion Agropecuaria",
      "Tecnico De Nivel Superior En Informatica",
      "Tecnico De Nivel Superior En Odontologia",
      "Tecnico De Nivel Superior En Preparacion Fisica Para El Deporte Y La Vida Saludable",
      "Tecnico En Psicoeducacion Y Educacion Social",
      "Industrias De Alimentos",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Control, Instrumentacion Y Automatizacion Industrial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Gestion Agropecuaria",
      "Tecnico De Nivel Superior En Informatica",
      "Tecnico De Nivel Superior En Odontologia",
      "Tecnico De Nivel Superior En Preparacion Fisica Para El Deporte Y La Vida Saludable",
      "Tecnico En Psicoeducacion Y Educacion Social",
    ],
  },
  {
    institucion: "Ip Agrario Adolfo Matthei",
    carreras: [
      "Ingenieria De Ejecucion En Agronegocios",
      "Ingenieria De Ejecucion En Agronomia",
      "Tecnico Experto Agrario",
      "Ingenieria De Ejecucion En Agronegocios",
      "Ingenieria De Ejecucion En Agronomia",
      "Tecnico Experto Agrario",
    ],
  },
  {
    institucion: "Ip Aiep",
    carreras: [
      "Administracion Publica",
      "Asistente De Parvulos",
      "Auditoria",
      "Comunicacion Y Relaciones Publicas",
      "Construccion Civil",
      "Contabilidad General",
      "Cosmetologia",
      "Dibujo De Arquitectura Y Obras Civiles",
      "Direccion Y Produccion De Television",
      "Diseño De Vestuario Profesional Mencion Alta Costura",
      "Diseño Grafico",
      "Diseño Grafico Profesional",
      "Estetica Profesional",
      "Gastronomia Internacional",
      "Ingenieria De Ejecucion De Empresas Mencion Finanzas",
      "Ingenieria De Ejecucion En Administracion De Empresas Mencion Finanzas",
      "Ingenieria De Ejecucion En Administracion De Empresas Mencion Marketing",
      "Ingenieria De Ejecucion En Administracion De Empresas Mencion Recursos Humanos",
      "Ingenieria De Ejecucion En Administracion De Redes",
      "Ingenieria De Ejecucion En Gestion De Empresas Mencion Finanzas",
      "Ingenieria De Ejecucion En Gestion Deportiva",
      "Ingenieria De Ejecucion En Hoteleria Y Turismo Mencion En Turismo",
      "Ingenieria De Ejecucion En Informatica",
      "Ingenieria De Ejecucion En Informatica Mencion Administracion De Bases De Datos",
      "Ingenieria De Ejecucion En Informatica Mencion Desarrollo De Sistemas",
      "Ingenieria De Ejecucion En Informatica Mencion Seguridad",
      "Ingenieria De Ejecucion En Sonido",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Ciberseguridad",
      "Ingenieria En Electricidad Y Energias Renovables",
      "Ingenieria En Gestion De Operaciones Logisticas",
      "Ingenieria En Infraestructura Y Seguridad De Redes",
      "Ingenieria En Mecanica Automotriz Y Autotronica",
      "Ingenieria En Negocios Electronicos",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Sonido",
      "Ingenieria En Turismo",
      "Laboratorista Clinico Y Banco De Sangre",
      "Laboratorista Dental",
      "Locucion Y Conduccion De Radio Y Television",
      "Peluqueria",
      "Personal Trainer",
      "Podologia Clinica",
      "Prevencion De Riesgos",
      "Programacion Computacional",
      "Programacion Y Analisis De Sistemas",
      "Psicopedagogia",
      "Publicidad",
      "Relaciones Publicas Corporativas",
      "Teatro",
      "Tecnico Asistente En Educacion Especial",
      "Tecnico De Nivel Superior En Gestion De Empresas Mencion Recursos Humanos",
      "Tecnico Deportivo",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion De Empresas Mencion Comercio Exterior",
      "Tecnico En Administracion De Empresas Mencion Finanzas",
      "Tecnico En Administracion De Empresas Mencion Logistica",
      "Tecnico En Administracion De Empresas Mencion Marketing",
      "Tecnico En Administracion De Empresas Mencion Recursos Humanos",
      "Tecnico En Administracion De Empresas Mencion Sistemas Logisticos",
      "Tecnico En Administracion De La Produccion Agropecuaria Y Agroindustrial",
      "Tecnico En Administracion Hotelera Y Resorts Internacional",
      "Tecnico En Administracion Publica",
      "Tecnico En Arte Y Gestion Cultural",
      "Tecnico En Automatizacion Y Control Industrial",
      "Tecnico En Comercio Electronico",
      "Tecnico En Comunicacion Y Relaciones Publicas",
      "Tecnico En Construccion",
      "Tecnico En Construccion Y Rehabilitacion Sustentable",
      "Tecnico En Cosmetologia",
      "Tecnico En Dibujo De Arquitectura Y Obras Civiles",
      "Tecnico En Diseño De Espacios Y Equipamiento",
      "Tecnico En Diseño De Vestuario",
      "Tecnico En Diseño Grafico",
      "Tecnico En Edificacion Sustentable",
      "Tecnico En Electricidad Y Electronica",
      "Tecnico En Enfermeria",
      "Tecnico En Enfermeria Con Mencion En Anestesiologia",
      "Tecnico En Enfermeria Con Mencion En Geriatria",
      "Tecnico En Enfermeria Con Mencion En Ginecobstetricia",
      "Tecnico En Enfermeria Con Mencion En Oncologia",
      "Tecnico En Enfermeria Con Mencion En Pediatria",
      "Tecnico En Enfermeria E Instrumentacion Quirurgica",
      "Tecnico En Enfermeria En Imagenologia",
      "Tecnico En Enfermeria Mencion En Anestesiologia",
      "Tecnico En Enfermeria Mencion En Ginecobstetricia",
      "Tecnico En Enfermeria Mencion En Oncologia",
      "Tecnico En Enfermeria Mencion En Pediatria",
      "Tecnico En Enfermeria Mencion En Urgencia",
      "Tecnico En Enfermeria Mencion Geriatria",
      "Tecnico En Enfermeria Mencion Salud Mental",
      "Tecnico En Enfermeria Radioterapia E Imagenologia",
      "Tecnico En Gestion De Empresas Mencion Finanzas",
      "Tecnico En Imagenologia",
      "Tecnico En Instalacion Y Mantencion De Redes",
      "Tecnico En Locucion Y Conduccion De Radio Y Television",
      "Tecnico En Mantenimiento Industrial",
      "Tecnico En Mantenimiento Mecanico",
      "Tecnico En Marketing Digital",
      "Tecnico En Masoterapia",
      "Tecnico En Mecanica Automotriz De Motores Pesados",
      "Tecnico En Mecanica Automotriz En Maquinaria Pesada",
      "Tecnico En Mecanica Automotriz Y Autotronica",
      "Tecnico En Mineria",
      "Tecnico En Motores De Alta Potencia",
      "Tecnico En Odontologia",
      "Tecnico En Operaciones Logisticas",
      "Tecnico En Peluqueria",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Produccion Audiovisual",
      "Tecnico En Programacion Y Analisis De Sistemas",
      "Tecnico En Salud Natural Y Terapias Complementarias",
      "Tecnico En Servicio Social",
      "Tecnico En Sonido",
      "Tecnico En Trabajo Social",
      "Tecnico En Turismo",
      "Tecnico Hoteleria Y Turismo Con Mencion En Turismo",
      "Tecnico Juridico",
      "Topografia",
      "Trabajo Social",
      "Administracion Publica",
      "Asistente De Parvulos",
      "Auditoria",
      "Comunicacion Y Relaciones Publicas",
      "Construccion Civil",
      "Contabilidad General",
      "Cosmetologia",
      "Dibujo De Arquitectura Y Obras Civiles",
      "Direccion Y Produccion De Television",
      "Diseño De Vestuario Profesional Mencion Alta Costura",
      "Diseño Grafico",
      "Diseño Grafico Profesional",
      "Estetica Profesional",
      "Gastronomia Internacional",
      "Ingenieria De Ejecucion De Empresas Mencion Finanzas",
      "Ingenieria De Ejecucion En Administracion De Empresas Mencion Finanzas",
      "Ingenieria De Ejecucion En Administracion De Empresas Mencion Marketing",
      "Ingenieria De Ejecucion En Administracion De Empresas Mencion Recursos Humanos",
      "Ingenieria De Ejecucion En Administracion De Redes",
      "Ingenieria De Ejecucion En Gestion De Empresas Mencion Finanzas",
      "Ingenieria De Ejecucion En Gestion Deportiva",
      "Ingenieria De Ejecucion En Hoteleria Y Turismo Mencion En Turismo",
      "Ingenieria De Ejecucion En Informatica",
      "Ingenieria De Ejecucion En Informatica Mencion Administracion De Bases De Datos",
      "Ingenieria De Ejecucion En Informatica Mencion Desarrollo De Sistemas",
      "Ingenieria De Ejecucion En Informatica Mencion Seguridad",
      "Ingenieria De Ejecucion En Sonido",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Ciberseguridad",
      "Ingenieria En Electricidad Y Energias Renovables",
      "Ingenieria En Gestion De Operaciones Logisticas",
      "Ingenieria En Infraestructura Y Seguridad De Redes",
      "Ingenieria En Mecanica Automotriz Y Autotronica",
      "Ingenieria En Negocios Electronicos",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Sonido",
      "Ingenieria En Turismo",
      "Laboratorista Clinico Y Banco De Sangre",
      "Laboratorista Dental",
      "Locucion Y Conduccion De Radio Y Television",
      "Peluqueria",
      "Personal Trainer",
      "Podologia Clinica",
      "Prevencion De Riesgos",
      "Programacion Computacional",
      "Programacion Y Analisis De Sistemas",
      "Psicopedagogia",
      "Publicidad",
      "Relaciones Publicas Corporativas",
      "Teatro",
      "Tecnico Asistente En Educacion Especial",
      "Tecnico De Nivel Superior En Gestion De Empresas Mencion Recursos Humanos",
      "Tecnico Deportivo",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion De Empresas Mencion Comercio Exterior",
      "Tecnico En Administracion De Empresas Mencion Finanzas",
      "Tecnico En Administracion De Empresas Mencion Logistica",
      "Tecnico En Administracion De Empresas Mencion Marketing",
      "Tecnico En Administracion De Empresas Mencion Recursos Humanos",
      "Tecnico En Administracion De Empresas Mencion Sistemas Logisticos",
      "Tecnico En Administracion De La Produccion Agropecuaria Y Agroindustrial",
      "Tecnico En Administracion Hotelera Y Resorts Internacional",
      "Tecnico En Administracion Publica",
      "Tecnico En Arte Y Gestion Cultural",
      "Tecnico En Automatizacion Y Control Industrial",
      "Tecnico En Comercio Electronico",
      "Tecnico En Comunicacion Y Relaciones Publicas",
      "Tecnico En Construccion",
      "Tecnico En Construccion Y Rehabilitacion Sustentable",
      "Tecnico En Cosmetologia",
      "Tecnico En Dibujo De Arquitectura Y Obras Civiles",
      "Tecnico En Diseño De Espacios Y Equipamiento",
      "Tecnico En Diseño De Vestuario",
      "Tecnico En Diseño Grafico",
      "Tecnico En Edificacion Sustentable",
      "Tecnico En Electricidad Y Electronica",
      "Tecnico En Enfermeria",
      "Tecnico En Enfermeria Con Mencion En Anestesiologia",
      "Tecnico En Enfermeria Con Mencion En Geriatria",
      "Tecnico En Enfermeria Con Mencion En Ginecobstetricia",
      "Tecnico En Enfermeria Con Mencion En Oncologia",
      "Tecnico En Enfermeria Con Mencion En Pediatria",
      "Tecnico En Enfermeria E Instrumentacion Quirurgica",
      "Tecnico En Enfermeria En Imagenologia",
      "Tecnico En Enfermeria Mencion En Anestesiologia",
      "Tecnico En Enfermeria Mencion En Ginecobstetricia",
      "Tecnico En Enfermeria Mencion En Oncologia",
      "Tecnico En Enfermeria Mencion En Pediatria",
      "Tecnico En Enfermeria Mencion En Urgencia",
      "Tecnico En Enfermeria Mencion Geriatria",
      "Tecnico En Enfermeria Mencion Salud Mental",
      "Tecnico En Enfermeria Radioterapia E Imagenologia",
      "Tecnico En Gestion De Empresas Mencion Finanzas",
      "Tecnico En Imagenologia",
      "Tecnico En Instalacion Y Mantencion De Redes",
      "Tecnico En Locucion Y Conduccion De Radio Y Television",
      "Tecnico En Mantenimiento Industrial",
      "Tecnico En Mantenimiento Mecanico",
      "Tecnico En Marketing Digital",
      "Tecnico En Masoterapia",
      "Tecnico En Mecanica Automotriz De Motores Pesados",
      "Tecnico En Mecanica Automotriz En Maquinaria Pesada",
      "Tecnico En Mecanica Automotriz Y Autotronica",
      "Tecnico En Mineria",
      "Tecnico En Motores De Alta Potencia",
      "Tecnico En Odontologia",
      "Tecnico En Operaciones Logisticas",
      "Tecnico En Peluqueria",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Produccion Audiovisual",
      "Tecnico En Programacion Y Analisis De Sistemas",
      "Tecnico En Salud Natural Y Terapias Complementarias",
      "Tecnico En Servicio Social",
      "Tecnico En Sonido",
      "Tecnico En Trabajo Social",
      "Tecnico En Turismo",
      "Tecnico Hoteleria Y Turismo Con Mencion En Turismo",
      "Tecnico Juridico",
      "Topografia",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Ip Carlos Casanueva",
    carreras: [
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Recursos Humanos",
    ],
  },
  {
    institucion: "Ip Chileno Britanico De Cultura",
    carreras: [
      "Pedagogia En Ingles",
      "Traduccion Ingles-español",
      "Pedagogia En Ingles",
      "Traduccion Ingles-español",
    ],
  },
  {
    institucion: "Ip Ciisa",
    carreras: [
      "Ingenieria En Automatizacion Y Control Industrial",
      "Ingenieria En Ciberseguridad",
      "Ingenieria En Conectividad Y Redes",
      "Ingenieria En Informatica",
      "Tecnico En Automatizacion Y Control Industrial",
      "Tecnico En Ciberseguridad",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Control Industrial",
      "Tecnico En Programacion Y Analisis De Sistemas",
      "Ingenieria En Automatizacion Y Control Industrial",
      "Ingenieria En Ciberseguridad",
      "Ingenieria En Conectividad Y Redes",
      "Ingenieria En Informatica",
      "Tecnico En Automatizacion Y Control Industrial",
      "Tecnico En Ciberseguridad",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Control Industrial",
      "Tecnico En Programacion Y Analisis De Sistemas",
    ],
  },
  {
    institucion: "Ip De Arte Y Comunicacion Arcos",
    carreras: [
      "Actuacion",
      "Animacion 3d",
      "Cine",
      "Composicion Musical",
      "Comunicacion Audiovisual",
      "Diseño De Video Juegos",
      "Diseño Grafico Multimedia",
      "Fotografia Profesional",
      "Ilustracion",
      "Produccion Musical",
      "Sonido Profesional",
      "Tecnico De Nivel Superior En Audiovisual",
      "Tecnico De Nivel Superior En Comunicacion Digital",
      "Tecnico De Nivel Superior En Fotografia",
      "Tecnico De Nivel Superior En Gestion Y Produccion De Eventos",
      "Tecnico De Nivel Superior En Sonido",
      "Tecnico De Nivel Superior En Turismo Sustentable",
      "Turismo Sustentable",
      "Actuacion",
      "Animacion 3d",
      "Cine",
      "Composicion Musical",
      "Comunicacion Audiovisual",
      "Diseño De Video Juegos",
      "Diseño Grafico Multimedia",
      "Fotografia Profesional",
      "Ilustracion",
      "Produccion Musical",
      "Sonido Profesional",
      "Tecnico De Nivel Superior En Audiovisual",
      "Tecnico De Nivel Superior En Comunicacion Digital",
      "Tecnico De Nivel Superior En Fotografia",
      "Tecnico De Nivel Superior En Gestion Y Produccion De Eventos",
      "Tecnico De Nivel Superior En Sonido",
      "Tecnico De Nivel Superior En Turismo Sustentable",
      "Turismo Sustentable",
    ],
  },
  {
    institucion: "Ip De Artes Escenicas Karen Connolly",
    carreras: ["Interpretacion En Danza", "Interpretacion En Danza"],
  },
  {
    institucion: "Ip De Chile",
    carreras: [
      "Administracion Gastronomica",
      "Analista Programador Computacional",
      "Asistente En Educacion De Parvulos",
      "Asistente Juridico",
      "Auditoria",
      "Comunicacion Audiovisual",
      "Construccion Civil",
      "Contabilidad General",
      "Fonoaudiologia",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion Publica",
      "Ingenieria En Electricidad Y Electronica",
      "Ingenieria En Gestion E Innovacion Digital De Negocios",
      "Ingenieria En Gestion Turistica",
      "Ingenieria En Informatica",
      "Ingenieria En Mecanica Automotriz Y Autotronica",
      "Ingenieria En Minas",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Redes Y Comunicaciones",
      "Kinesiologia",
      "Naturopatia",
      "Nutricion Y Dietetica",
      "Preparacion Fisica",
      "Psicopedagogia",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Farmacia",
      "Tecnico De Nivel Superior En Gestion Ambiental Y Sustentabilidad",
      "Tecnico De Nivel Superior En Gestion De Negocios Digitales",
      "Tecnico De Nivel Superior En Mantenimiento Electromecanico",
      "Tecnico De Nivel Superior En Mecatronica",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion Y Gestion De Salud",
      "Tecnico En Alimentacion Y Nutricion",
      "Tecnico En Comunicacion Audiovisual",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Construccion",
      "Tecnico En Deportes",
      "Tecnico En Diseño Grafico",
      "Tecnico En Educacion Basica",
      "Tecnico En Educacion Diferencial E Inclusion",
      "Tecnico En Electricidad Y Electronica",
      "Tecnico En Enfermeria",
      "Tecnico En Enfermeria Mencion Instrumentacion Quirurgica",
      "Tecnico En Gastronomia",
      "Tecnico En Masoterapia",
      "Tecnico En Mecanica Automotriz Y Autotronica",
      "Tecnico En Mineria",
      "Tecnico En Odontologia",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Salud Natural Y Terapias Complementarias",
      "Tecnico En Sonido",
      "Tecnico En Trabajo Social",
      "Tecnico En Turismo",
      "Tecnico Trabajo Social",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Trabajo Social Con Mencion En Intervencion Social",
      "Administracion Gastronomica",
      "Analista Programador Computacional",
      "Asistente En Educacion De Parvulos",
      "Asistente Juridico",
      "Auditoria",
      "Comunicacion Audiovisual",
      "Construccion Civil",
      "Contabilidad General",
      "Fonoaudiologia",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion Publica",
      "Ingenieria En Electricidad Y Electronica",
      "Ingenieria En Gestion E Innovacion Digital De Negocios",
      "Ingenieria En Gestion Turistica",
      "Ingenieria En Informatica",
      "Ingenieria En Mecanica Automotriz Y Autotronica",
      "Ingenieria En Minas",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Redes Y Comunicaciones",
      "Kinesiologia",
      "Naturopatia",
      "Nutricion Y Dietetica",
      "Preparacion Fisica",
      "Psicopedagogia",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Farmacia",
      "Tecnico De Nivel Superior En Gestion Ambiental Y Sustentabilidad",
      "Tecnico De Nivel Superior En Gestion De Negocios Digitales",
      "Tecnico De Nivel Superior En Mantenimiento Electromecanico",
      "Tecnico De Nivel Superior En Mecatronica",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion Y Gestion De Salud",
      "Tecnico En Alimentacion Y Nutricion",
      "Tecnico En Comunicacion Audiovisual",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Construccion",
      "Tecnico En Deportes",
      "Tecnico En Diseño Grafico",
      "Tecnico En Educacion Basica",
      "Tecnico En Educacion Diferencial E Inclusion",
      "Tecnico En Electricidad Y Electronica",
      "Tecnico En Enfermeria",
      "Tecnico En Enfermeria Mencion Instrumentacion Quirurgica",
      "Tecnico En Gastronomia",
      "Tecnico En Masoterapia",
      "Tecnico En Mecanica Automotriz Y Autotronica",
      "Tecnico En Mineria",
      "Tecnico En Odontologia",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Salud Natural Y Terapias Complementarias",
      "Tecnico En Sonido",
      "Tecnico En Trabajo Social",
      "Tecnico En Turismo",
      "Tecnico Trabajo Social",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Trabajo Social Con Mencion En Intervencion Social",
    ],
  },
  {
    institucion: "Ip De Ciencias De La Computacion Acuario Data",
    carreras: [
      "Administracion De Empresas Con Mencion En Computacion",
      "Analisis De Sistemas",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria De Ejecucion Industrial",
      "Soporte Computacional Y Redes",
      "Administracion De Empresas Con Mencion En Computacion",
      "Analisis De Sistemas",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria De Ejecucion Industrial",
      "Soporte Computacional Y Redes",
    ],
  },
  {
    institucion: "Ip Del Comercio",
    carreras: [
      "Contador Auditor",
      "Contador Auditor (plan Especial)",
      "Contador Auditor Con Mencion En Investigacion De Delitos Economicos",
      "Contador Auditor Con Mencion En Investigacion De Delitos Economicos (plan Especial)",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion De Empresas (plan Especial)",
      "Ingenieria En Administracion De Recursos Humanos",
      "Ingenieria En Administracion De Recursos Humanos (plan Especial)",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Comercio Internacional (plan Especial)",
      "Ingenieria En Finanzas",
      "Ingenieria En Finanzas (plan Especial)",
      "Ingenieria En Logistica",
      "Ingenieria En Logistica (plan Especial)",
      "Contador Auditor",
      "Contador Auditor (plan Especial)",
      "Contador Auditor Con Mencion En Investigacion De Delitos Economicos",
      "Contador Auditor Con Mencion En Investigacion De Delitos Economicos (plan Especial)",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion De Empresas (plan Especial)",
      "Ingenieria En Administracion De Recursos Humanos",
      "Ingenieria En Administracion De Recursos Humanos (plan Especial)",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Comercio Internacional (plan Especial)",
      "Ingenieria En Finanzas",
      "Ingenieria En Finanzas (plan Especial)",
      "Ingenieria En Logistica",
      "Ingenieria En Logistica (plan Especial)",
    ],
  },
  {
    institucion: "Ip Del Valle Central",
    carreras: [
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Administracion De Empresas (continuidad)",
      "Ingenieria De Ejecucion En Administracion De Empresas, Continuidad",
      "Ingenieria De Ejecucion En Administracion Publica",
      "Ingenieria De Ejecucion En Administracion Publica (continuidad)",
      "Ingenieria De Ejecucion En Administracion Publica, Continuidad",
      "Ingenieria De Ejecucion En Gestion De Seguridad Privada",
      "Ingenieria De Ejecucion En Gestion De Seguridad Privada (continuidad)",
      "Podologia",
      "Psicopedagogia",
      "Psicopedagogia (continuidad)",
      "Psicopedagogia(continuidad)",
      "Psicopedagogia, Continuidad",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion En Emprendimiento",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion En Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Finanzas Y Contabilidad",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion En Salud",
      "Tecnico De Nivel Superior En Administracion Mencion Gestion Municipal",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Asistente De La Educacion",
      "Tecnico De Nivel Superior En Asistente En La Educacion",
      "Tecnico De Nivel Superior En Contabilidad Y Analisis De Cuentas",
      "Tecnico De Nivel Superior En Deportes Y Recreacion",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Estetica Profesional Mencion Estetica Integral",
      "Tecnico De Nivel Superior En Estetica Profesional Mencion Estilista",
      "Tecnico De Nivel Superior En Gestion De Seguridad Privada",
      "Tecnico De Nivel Superior En Logistica Y Operaciones Industriales",
      "Tecnico De Nivel Superior En Marketing Y Ventas",
      "Tecnico De Nivel Superior En Masoterapia",
      "Tecnico De Nivel Superior En Odontologia",
      "Tecnico De Nivel Superior En Podologia",
      "Tecnico De Nivel Superior En Terapias Naturales",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Enfermeria",
      "Tecnico Veterinario",
      "Trabajo Social",
      "Trabajo Social (continuidad)",
      "Trabajo Social, Continuidad",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Administracion De Empresas (continuidad)",
      "Ingenieria De Ejecucion En Administracion De Empresas, Continuidad",
      "Ingenieria De Ejecucion En Administracion Publica",
      "Ingenieria De Ejecucion En Administracion Publica (continuidad)",
      "Ingenieria De Ejecucion En Administracion Publica, Continuidad",
      "Ingenieria De Ejecucion En Gestion De Seguridad Privada",
      "Ingenieria De Ejecucion En Gestion De Seguridad Privada (continuidad)",
      "Podologia",
      "Psicopedagogia",
      "Psicopedagogia (continuidad)",
      "Psicopedagogia(continuidad)",
      "Psicopedagogia, Continuidad",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion En Emprendimiento",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion En Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Finanzas Y Contabilidad",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion En Salud",
      "Tecnico De Nivel Superior En Administracion Mencion Gestion Municipal",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Asistente De La Educacion",
      "Tecnico De Nivel Superior En Asistente En La Educacion",
      "Tecnico De Nivel Superior En Contabilidad Y Analisis De Cuentas",
      "Tecnico De Nivel Superior En Deportes Y Recreacion",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Estetica Profesional Mencion Estetica Integral",
      "Tecnico De Nivel Superior En Estetica Profesional Mencion Estilista",
      "Tecnico De Nivel Superior En Gestion De Seguridad Privada",
      "Tecnico De Nivel Superior En Logistica Y Operaciones Industriales",
      "Tecnico De Nivel Superior En Marketing Y Ventas",
      "Tecnico De Nivel Superior En Masoterapia",
      "Tecnico De Nivel Superior En Odontologia",
      "Tecnico De Nivel Superior En Podologia",
      "Tecnico De Nivel Superior En Terapias Naturales",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Enfermeria",
      "Tecnico Veterinario",
      "Trabajo Social",
      "Trabajo Social (continuidad)",
      "Trabajo Social, Continuidad",
    ],
  },
  {
    institucion: "Ip Diego Portales",
    carreras: [
      "Bibliotecologia Distancia",
      "Construccion Civil",
      "Contador Auditor",
      "Contador Auditor A Distancia",
      "Gastronomia",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion De Empresas A Distancia",
      "Ingenieria En Administracion Publica Y Municipal",
      "Ingenieria En Administracion Publica Y Municipal A Distancia",
      "Ingenieria En Computacion E Informatica",
      "Ingenieria En Geomensura",
      "Ingenieria En Informatica",
      "Ingenieria En Informatica Mencion Ciber Seguridad",
      "Ingenieria En Logistica Operativa",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Prevencion De Riesgos A Distancia",
      "Nutricion Y Dietetica",
      "Plan Especial De Tecnico Nivel Superior En Enfermeria",
      "Plan Especial Ingenieria En Construccion",
      "Plan Especial Para Tecnicos En Educacion Parvularia",
      "Plan Especial Para Trabajadores Asistente De La Educacion",
      "Plan Especial Para Trabajadores Contador General",
      "Plan Especial Para Trabajadores Tecnico En Administracion Publica Y Municipal",
      "Plan Especial Para Trabajadores Tecnico En Bibliotecologia",
      "Plan Especial Para Trabajadores Tecnico En Farmacia",
      "Plan Especial Para Trabajadores Tecnico En Servicio Social",
      "Plan Especial Podologia Clinica",
      "Plan Especial Tecnico En Administracion",
      "Plan Especial Tecnico En Enfermeria",
      "Profesional Internacional Gastronomico",
      "Profesional Internacional Gastronomico En Artes Culinarias",
      "Relaciones Publicas",
      "Servicio Social",
      "Servicio Social A Distancia",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Farmacia",
      "Tecnico De Nivel Superior En Podologia",
      "Tecnico Electromecanico Automotriz",
      "Tecnico En Asistente De La Educacion",
      "Tecnico En Bibliotecologia",
      "Tecnico En Educacion Parvularia",
      "Tecnico En Electromecanica Automotriz",
      "Tecnico En Enfermeria",
      "Tecnico En Estetica Integral",
      "Tecnico En Farmacia",
      "Tecnico En Inspectoria Educacional",
      "Tecnico En Logistica Operativa",
      "Tecnico En Logistica Operativa A Distancia",
      "Bibliotecologia Distancia",
      "Construccion Civil",
      "Contador Auditor",
      "Contador Auditor A Distancia",
      "Gastronomia",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion De Empresas A Distancia",
      "Ingenieria En Administracion Publica Y Municipal",
      "Ingenieria En Administracion Publica Y Municipal A Distancia",
      "Ingenieria En Computacion E Informatica",
      "Ingenieria En Geomensura",
      "Ingenieria En Informatica",
      "Ingenieria En Informatica Mencion Ciber Seguridad",
      "Ingenieria En Logistica Operativa",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Prevencion De Riesgos A Distancia",
      "Nutricion Y Dietetica",
      "Plan Especial De Tecnico Nivel Superior En Enfermeria",
      "Plan Especial Ingenieria En Construccion",
      "Plan Especial Para Tecnicos En Educacion Parvularia",
      "Plan Especial Para Trabajadores Asistente De La Educacion",
      "Plan Especial Para Trabajadores Contador General",
      "Plan Especial Para Trabajadores Tecnico En Administracion Publica Y Municipal",
      "Plan Especial Para Trabajadores Tecnico En Bibliotecologia",
      "Plan Especial Para Trabajadores Tecnico En Farmacia",
      "Plan Especial Para Trabajadores Tecnico En Servicio Social",
      "Plan Especial Podologia Clinica",
      "Plan Especial Tecnico En Administracion",
      "Plan Especial Tecnico En Enfermeria",
      "Profesional Internacional Gastronomico",
      "Profesional Internacional Gastronomico En Artes Culinarias",
      "Relaciones Publicas",
      "Servicio Social",
      "Servicio Social A Distancia",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Farmacia",
      "Tecnico De Nivel Superior En Podologia",
      "Tecnico Electromecanico Automotriz",
      "Tecnico En Asistente De La Educacion",
      "Tecnico En Bibliotecologia",
      "Tecnico En Educacion Parvularia",
      "Tecnico En Electromecanica Automotriz",
      "Tecnico En Enfermeria",
      "Tecnico En Estetica Integral",
      "Tecnico En Farmacia",
      "Tecnico En Inspectoria Educacional",
      "Tecnico En Logistica Operativa",
      "Tecnico En Logistica Operativa A Distancia",
    ],
  },
  {
    institucion: "Ip Dr. Virginio Gomez G.",
    carreras: [
      "Auditoria",
      "Ingenieria De Ejecucion En Administracion",
      "Ingenieria De Ejecucion En Administracion-pe",
      "Ingenieria De Ejecucion En Automatizacion Y Control-pe",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria De Ejecucion En Computacion E Informatica-pe",
      "Ingenieria De Ejecucion En Conectividad Y Redes-pe",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Electricidad-pe",
      "Ingenieria De Ejecucion En Energias Renovables Y Sustentabilidad Ambiental",
      "Ingenieria De Ejecucion En Gestion Logistica-pe",
      "Ingenieria De Ejecucion En Industria Alimentaria-pe",
      "Ingenieria De Ejecucion En Informacion Y Control De Gestion",
      "Ingenieria De Ejecucion En Informacion Y Control De Gestion-pe",
      "Ingenieria De Ejecucion En Inteligencia De Negocios",
      "Ingenieria De Ejecucion En Logistica Maritima Portuaria",
      "Ingenieria De Ejecucion En Logistica Maritima Portuaria-pe",
      "Ingenieria De Ejecucion En Mantenimiento Industrial",
      "Ingenieria De Ejecucion En Mantenimiento Industrial-pe",
      "Ingenieria De Ejecucion En Mecanica Automotriz Y Maquinaria Pesada-pe",
      "Ingenieria De Ejecucion En Metalurgia",
      "Ingenieria De Ejecucion En Minas",
      "Ingenieria De Ejecucion En Negocios Internacionales-pe",
      "Ingenieria De Ejecucion En Prevencion De Riesgos",
      "Ingenieria De Ejecucion En Prevencion De Riesgos-pe",
      "Ingenieria De Ejecucion En Quimica Y Medio Ambiente-pe",
      "Ingenieria De Ejecucion En Transito Y Seguridad Vial",
      "Ingenieria De Ejecucion Industrial-pe",
      "Ingenieria De Ejecucion Mecanica",
      "Ingenieria De Ejecucion Mecanica-pe",
      "Ingenieria En Construccion",
      "Ingenieria En Construccion-pe",
      "Tecnico Analista Programador",
      "Tecnico En Administracion",
      "Tecnico En Administracion Financiera",
      "Tecnico En Administracion Publica",
      "Tecnico En Comercio Exterior",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Construccion",
      "Tecnico En Contabilidad Y Legislacion Tributaria",
      "Tecnico En Educacion Basica",
      "Tecnico En Educacion De Parvulos",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Electricidad",
      "Tecnico En Electromecanica",
      "Tecnico En Energias Renovables Y Eficiencia Energetica",
      "Tecnico En Enfermeria",
      "Tecnico En Geometalurgia",
      "Tecnico En Industria Alimentaria",
      "Tecnico En Instrumentacion Y Control",
      "Tecnico En Logistica",
      "Tecnico En Logistica Maritima Portuaria",
      "Tecnico En Mantenimiento Industrial",
      "Tecnico En Mecanica Automotriz Y Maquinaria Pesada",
      "Tecnico En Mecanica Automotriz Y Sistemas Electricos",
      "Tecnico En Medioambiente",
      "Tecnico En Mineria",
      "Tecnico En Prevencion Y Rehabilitacion En Drogodependencia",
      "Tecnico Higienista Dental",
      "Tecnico Laboratorista Clinico Y Banco De Sangre",
      "Tecnico Laboratorista Dental",
      "Tecnico Quimico Analista Industrial",
      "Auditoria",
      "Ingenieria De Ejecucion En Administracion",
      "Ingenieria De Ejecucion En Administracion-pe",
      "Ingenieria De Ejecucion En Automatizacion Y Control-pe",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria De Ejecucion En Computacion E Informatica-pe",
      "Ingenieria De Ejecucion En Conectividad Y Redes-pe",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Electricidad-pe",
      "Ingenieria De Ejecucion En Energias Renovables Y Sustentabilidad Ambiental",
      "Ingenieria De Ejecucion En Gestion Logistica-pe",
      "Ingenieria De Ejecucion En Industria Alimentaria-pe",
      "Ingenieria De Ejecucion En Informacion Y Control De Gestion",
      "Ingenieria De Ejecucion En Informacion Y Control De Gestion-pe",
      "Ingenieria De Ejecucion En Inteligencia De Negocios",
      "Ingenieria De Ejecucion En Logistica Maritima Portuaria",
      "Ingenieria De Ejecucion En Logistica Maritima Portuaria-pe",
      "Ingenieria De Ejecucion En Mantenimiento Industrial",
      "Ingenieria De Ejecucion En Mantenimiento Industrial-pe",
      "Ingenieria De Ejecucion En Mecanica Automotriz Y Maquinaria Pesada-pe",
      "Ingenieria De Ejecucion En Metalurgia",
      "Ingenieria De Ejecucion En Minas",
      "Ingenieria De Ejecucion En Negocios Internacionales-pe",
      "Ingenieria De Ejecucion En Prevencion De Riesgos",
      "Ingenieria De Ejecucion En Prevencion De Riesgos-pe",
      "Ingenieria De Ejecucion En Quimica Y Medio Ambiente-pe",
      "Ingenieria De Ejecucion En Transito Y Seguridad Vial",
      "Ingenieria De Ejecucion Industrial-pe",
      "Ingenieria De Ejecucion Mecanica",
      "Ingenieria De Ejecucion Mecanica-pe",
      "Ingenieria En Construccion",
      "Ingenieria En Construccion-pe",
      "Tecnico Analista Programador",
      "Tecnico En Administracion",
      "Tecnico En Administracion Financiera",
      "Tecnico En Administracion Publica",
      "Tecnico En Comercio Exterior",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Construccion",
      "Tecnico En Contabilidad Y Legislacion Tributaria",
      "Tecnico En Educacion Basica",
      "Tecnico En Educacion De Parvulos",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Electricidad",
      "Tecnico En Electromecanica",
      "Tecnico En Energias Renovables Y Eficiencia Energetica",
      "Tecnico En Enfermeria",
      "Tecnico En Geometalurgia",
      "Tecnico En Industria Alimentaria",
      "Tecnico En Instrumentacion Y Control",
      "Tecnico En Logistica",
      "Tecnico En Logistica Maritima Portuaria",
      "Tecnico En Mantenimiento Industrial",
      "Tecnico En Mecanica Automotriz Y Maquinaria Pesada",
      "Tecnico En Mecanica Automotriz Y Sistemas Electricos",
      "Tecnico En Medioambiente",
      "Tecnico En Mineria",
      "Tecnico En Prevencion Y Rehabilitacion En Drogodependencia",
      "Tecnico Higienista Dental",
      "Tecnico Laboratorista Clinico Y Banco De Sangre",
      "Tecnico Laboratorista Dental",
      "Tecnico Quimico Analista Industrial",
    ],
  },
  {
    institucion: "Ip Duoc Uc",
    carreras: [
      "Actuacion",
      "Administracion De Empresas Mencion Marketing",
      "Administracion De Empresas Mencion Marketing Pev",
      "Administracion De Recursos Humanos",
      "Administracion De Redes Computacionales",
      "Administracion En Infraestructura Y Plataformas Tecnologicas",
      "Administracion Financiera",
      "Administracion Financiera Pev",
      "Administracion Hotelera",
      "Analista Programador Computacional",
      "Animacion Digital",
      "Auditoria",
      "Auditoria Pev",
      "Comercio Exterior",
      "Comunicacion Audiovisual",
      "Contabilidad General Mencion Legislacion Tributaria",
      "Contabilidad General Mencion Legislacion Tributaria Pev",
      "Desarrollo Y Diseño Web",
      "Dibujo Arquitectonico Y Estructural",
      "Dibujo Y Modelamiento Arquitectonico Y Estructural",
      "Diseño De Ambientes",
      "Diseño De Vestuario",
      "Diseño Grafico",
      "Diseño Industrial",
      "Ecoturismo",
      "Gastronomia",
      "Gastronomia Internacional",
      "Ilustracion",
      "Informatica Biomedica",
      "Ingenieria Agricola",
      "Ingenieria En Administracion",
      "Ingenieria En Administracion De Recursos Humanos",
      "Ingenieria En Administracion Mencion Finanzas",
      "Ingenieria En Administracion Mencion Gestion De Personas",
      "Ingenieria En Administracion Mencion Marketing",
      "Ingenieria En Administracion Pev",
      "Ingenieria En Comercio Exterior",
      "Ingenieria En Conectividad Y Redes",
      "Ingenieria En Construccion",
      "Ingenieria En Electricidad Y Automatizacion Industrial",
      "Ingenieria En Electricidad Y Electronica",
      "Ingenieria En Gestion Logistica",
      "Ingenieria En Gestion Logistica Pev",
      "Ingenieria En Informatica",
      "Ingenieria En Infraestructura Y Plataformas Tecnologicas",
      "Ingenieria En Maquinaria Y Vehiculos Pesados",
      "Ingenieria En Marketing",
      "Ingenieria En Mecanica Automotriz Y Autotronica",
      "Ingenieria En Medio Ambiente",
      "Ingenieria En Negocios Internacionales",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Sonido",
      "Preparador Fisico",
      "Produccion Web",
      "Publicidad",
      "Publicidad Tecnica Mencion Produccion Publicitaria",
      "Relaciones Publicas Mencion Marketing",
      "Restauracion De Bienes Patrimoniales",
      "Restauracion Patrimonial",
      "Tecnico Agricola",
      "Tecnico Audiovisual",
      "Tecnico De Enfermeria",
      "Tecnico De Laboratorio Clinico Y Banco De Sangre",
      "Tecnico De Radiodiagnostico Y Radioterapia",
      "Tecnico En Administracion",
      "Tecnico En Calidad Y Seguridad Agroalimentaria",
      "Tecnico En Construccion",
      "Tecnico En Electricidad Y Automatizacion Industrial",
      "Tecnico En Electricidad Y Electronica",
      "Tecnico En Energias Renovables",
      "Tecnico En Energias Renovables Y Eficiencia Energetica",
      "Tecnico En Finanzas",
      "Tecnico En Geologia Y Control En Sondaje",
      "Tecnico En Geomatica",
      "Tecnico En Gestion De Eventos",
      "Tecnico En Gestion De Personas",
      "Tecnico En Gestion Logistica",
      "Tecnico En Gestion Logistica Pev",
      "Tecnico En Instalaciones Electricas",
      "Tecnico En Instalaciones Y Proyectos Electricos",
      "Tecnico En Mantenimiento Electromecanico",
      "Tecnico En Mantenimiento Electromecanico Mencion Industrias",
      "Tecnico En Maquinaria Y Vehiculos Pesados",
      "Tecnico En Marketing",
      "Tecnico En Mecanica Automotriz Y Autotronica",
      "Tecnico En Odontologia",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Quimica Y Farmacia",
      "Tecnico Topografo",
      "Tecnico Veterinario",
      "Tecnologia En Sonido",
      "Tourism And Hospitality",
      "Turismo Aventura",
      "Turismo De Aventura",
      "Turismo Tecnico Mencion Empresas Turisticas",
      "Turismo Y Hoteleria",
      "Turismo Y Hoteleria Mencion En Servicios Aerocomerciales",
      "Actuacion",
      "Administracion De Empresas Mencion Marketing",
      "Administracion De Empresas Mencion Marketing Pev",
      "Administracion De Recursos Humanos",
      "Administracion De Redes Computacionales",
      "Administracion En Infraestructura Y Plataformas Tecnologicas",
      "Administracion Financiera",
      "Administracion Financiera Pev",
      "Administracion Hotelera",
      "Analista Programador Computacional",
      "Animacion Digital",
      "Auditoria",
      "Auditoria Pev",
      "Comercio Exterior",
      "Comunicacion Audiovisual",
      "Contabilidad General Mencion Legislacion Tributaria",
      "Contabilidad General Mencion Legislacion Tributaria Pev",
      "Desarrollo Y Diseño Web",
      "Dibujo Arquitectonico Y Estructural",
      "Dibujo Y Modelamiento Arquitectonico Y Estructural",
      "Diseño De Ambientes",
      "Diseño De Vestuario",
      "Diseño Grafico",
      "Diseño Industrial",
      "Ecoturismo",
      "Gastronomia",
      "Gastronomia Internacional",
      "Ilustracion",
      "Informatica Biomedica",
      "Ingenieria Agricola",
      "Ingenieria En Administracion",
      "Ingenieria En Administracion De Recursos Humanos",
      "Ingenieria En Administracion Mencion Finanzas",
      "Ingenieria En Administracion Mencion Gestion De Personas",
      "Ingenieria En Administracion Mencion Marketing",
      "Ingenieria En Administracion Pev",
      "Ingenieria En Comercio Exterior",
      "Ingenieria En Conectividad Y Redes",
      "Ingenieria En Construccion",
      "Ingenieria En Electricidad Y Automatizacion Industrial",
      "Ingenieria En Electricidad Y Electronica",
      "Ingenieria En Gestion Logistica",
      "Ingenieria En Gestion Logistica Pev",
      "Ingenieria En Informatica",
      "Ingenieria En Infraestructura Y Plataformas Tecnologicas",
      "Ingenieria En Maquinaria Y Vehiculos Pesados",
      "Ingenieria En Marketing",
      "Ingenieria En Mecanica Automotriz Y Autotronica",
      "Ingenieria En Medio Ambiente",
      "Ingenieria En Negocios Internacionales",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Sonido",
      "Preparador Fisico",
      "Produccion Web",
      "Publicidad",
      "Publicidad Tecnica Mencion Produccion Publicitaria",
      "Relaciones Publicas Mencion Marketing",
      "Restauracion De Bienes Patrimoniales",
      "Restauracion Patrimonial",
      "Tecnico Agricola",
      "Tecnico Audiovisual",
      "Tecnico De Enfermeria",
      "Tecnico De Laboratorio Clinico Y Banco De Sangre",
      "Tecnico De Radiodiagnostico Y Radioterapia",
      "Tecnico En Administracion",
      "Tecnico En Calidad Y Seguridad Agroalimentaria",
      "Tecnico En Construccion",
      "Tecnico En Electricidad Y Automatizacion Industrial",
      "Tecnico En Electricidad Y Electronica",
      "Tecnico En Energias Renovables",
      "Tecnico En Energias Renovables Y Eficiencia Energetica",
      "Tecnico En Finanzas",
      "Tecnico En Geologia Y Control En Sondaje",
      "Tecnico En Geomatica",
      "Tecnico En Gestion De Eventos",
      "Tecnico En Gestion De Personas",
      "Tecnico En Gestion Logistica",
      "Tecnico En Gestion Logistica Pev",
      "Tecnico En Instalaciones Electricas",
      "Tecnico En Instalaciones Y Proyectos Electricos",
      "Tecnico En Mantenimiento Electromecanico",
      "Tecnico En Mantenimiento Electromecanico Mencion Industrias",
      "Tecnico En Maquinaria Y Vehiculos Pesados",
      "Tecnico En Marketing",
      "Tecnico En Mecanica Automotriz Y Autotronica",
      "Tecnico En Odontologia",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Quimica Y Farmacia",
      "Tecnico Topografo",
      "Tecnico Veterinario",
      "Tecnologia En Sonido",
      "Tourism And Hospitality",
      "Turismo Aventura",
      "Turismo De Aventura",
      "Turismo Tecnico Mencion Empresas Turisticas",
      "Turismo Y Hoteleria",
      "Turismo Y Hoteleria Mencion En Servicios Aerocomerciales",
    ],
  },
  {
    institucion: "Ip Eatri Instituto Profesional",
    carreras: [
      "Interpretacion De Enlace Ingles-castellano",
      "Tecnico En Ingles, Mencion Hoteleria Y Turismo",
      "Tecnico En Portugues, Mencion Hoteleria Y Turismo",
      "Traduccion E Interpretariado Simultaneo Y Consecutivo Ingles-castellano",
      "Traductor E Interprete De Enlace Mencion Ingles",
      "Traductor E Interprete De Enlace Mencion Ingles-aleman",
      "Traductor E Interprete De Enlace Mencion Ingles-frances",
      "Interpretacion De Enlace Ingles-castellano",
      "Tecnico En Ingles, Mencion Hoteleria Y Turismo",
      "Tecnico En Portugues, Mencion Hoteleria Y Turismo",
      "Traduccion E Interpretariado Simultaneo Y Consecutivo Ingles-castellano",
      "Traductor E Interprete De Enlace Mencion Ingles",
      "Traductor E Interprete De Enlace Mencion Ingles-aleman",
      "Traductor E Interprete De Enlace Mencion Ingles-frances",
    ],
  },
  {
    institucion: "Ip Escuela De Cine De Chile",
    carreras: ["Cine", "Cine"],
  },
  {
    institucion: "Ip Escuela De Contadores Auditores De Santiago",
    carreras: ["Contador Auditor", "Contador Auditor"],
  },
  {
    institucion: "Ip Escuela De Marina Mercante Piloto Pardo",
    carreras: [
      "Ingenieria En Marina Mercante Mencion Maquinas Marinas",
      "Ingenieria En Marina Mercante Mencion Puente",
      "Ingenieria En Marina Mercante Mencion Maquinas Marinas",
      "Ingenieria En Marina Mercante Mencion Puente",
    ],
  },
  {
    institucion: "Ip Escuela Moderna De Musica",
    carreras: [
      "Especialista En Arreglos Instrumentales Y Composicion De Musica Popular",
      "Interprete En Canto Popular",
      "Interprete En Danza",
      "Interprete Instrumental Mencion (instrumento Popular)",
      "Interprete Musical Superior",
      "Produccion Musical Y Gestion Artistica",
      "Especialista En Arreglos Instrumentales Y Composicion De Musica Popular",
      "Interprete En Canto Popular",
      "Interprete En Danza",
      "Interprete Instrumental Mencion (instrumento Popular)",
      "Interprete Musical Superior",
      "Produccion Musical Y Gestion Artistica",
    ],
  },
  {
    institucion: "Ip Esucomex",
    carreras: [
      "Administracion De Redes Mencion Seguridad Informatica",
      "Administracion Publica",
      "Analista Programador Mencion Aplicaciones Moviles Y Web",
      "Auditoria",
      "Comercio Exterior",
      "Contabilidad",
      "Dibujo Arquitectonico Y Estructural",
      "Ingenieria En Administracion De Empresas Mencion Finanzas O Marketing",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Construccion",
      "Ingenieria En Gestion De Centros De Salud",
      "Ingenieria En Gestion De Recursos Humanos",
      "Ingenieria En Gestion Logistica",
      "Ingenieria En Informatica",
      "Ingenieria Industrial",
      "Psicopedagogia",
      "Tecnico En Administracion De Empresas Mencion Finanzas O Marketing",
      "Tecnico En Construccion",
      "Tecnico En Educacion De Parvulos Con Especializacion En Aula Inclusiva",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Electricidad Industrial",
      "Tecnico En Enfermeria",
      "Tecnico En Fabricacion Y Montaje Industrial",
      "Tecnico En Farmacia",
      "Tecnico En Gestion De Recursos Humanos",
      "Tecnico En Gestion Logistica",
      "Tecnico En Mantenimiento Industrial",
      "Tecnico En Trabajo Social",
      "Terapia Ocupacional",
      "Topografia",
      "Administracion De Redes Mencion Seguridad Informatica",
      "Administracion Publica",
      "Analista Programador Mencion Aplicaciones Moviles Y Web",
      "Auditoria",
      "Comercio Exterior",
      "Contabilidad",
      "Dibujo Arquitectonico Y Estructural",
      "Ingenieria En Administracion De Empresas Mencion Finanzas O Marketing",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Construccion",
      "Ingenieria En Gestion De Centros De Salud",
      "Ingenieria En Gestion De Recursos Humanos",
      "Ingenieria En Gestion Logistica",
      "Ingenieria En Informatica",
      "Ingenieria Industrial",
      "Psicopedagogia",
      "Tecnico En Administracion De Empresas Mencion Finanzas O Marketing",
      "Tecnico En Construccion",
      "Tecnico En Educacion De Parvulos Con Especializacion En Aula Inclusiva",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Electricidad Industrial",
      "Tecnico En Enfermeria",
      "Tecnico En Fabricacion Y Montaje Industrial",
      "Tecnico En Farmacia",
      "Tecnico En Gestion De Recursos Humanos",
      "Tecnico En Gestion Logistica",
      "Tecnico En Mantenimiento Industrial",
      "Tecnico En Trabajo Social",
      "Terapia Ocupacional",
      "Topografia",
    ],
  },
  {
    institucion: "Ip Inacap",
    carreras: [
      "Administracion En Hoteleria Y Servicios",
      "Administracion Gastronomica Internacional",
      "Administracion Turistica Internacional",
      "Construccion Civil",
      "Diseño De Moda",
      "Diseño Grafico Profesional",
      "Ingenieria Agricola",
      "Ingenieria Electrica",
      "Ingenieria En Automatizacion Y Control Industrial",
      "Ingenieria En Climatizacion",
      "Ingenieria En Comercio Exterior",
      "Ingenieria En Electronica Y Sistemas Inteligentes",
      "Ingenieria En Geomensura",
      "Ingenieria En Maquinaria Pesada Y Vehiculos Automotrices",
      "Ingenieria En Maquinaria, Vehiculos Automotrices Y Sistemas Electronicos",
      "Ingenieria En Metalurgia",
      "Ingenieria En Minas",
      "Ingenieria En Prevencion De Riesgos, Calidad Y Ambiente",
      "Ingenieria En Produccion Ganadera",
      "Ingenieria En Quimica Industrial",
      "Ingenieria En Refrigeracion",
      "Ingenieria En Telecomunicaciones, Conectividad Y Redes",
      "Ingenieria Mecanica En Mantenimiento Industrial",
      "Ingenieria Mecanica En Produccion Industrial",
      "Administracion En Hoteleria Y Servicios",
      "Administracion Gastronomica Internacional",
      "Administracion Turistica Internacional",
      "Construccion Civil",
      "Diseño De Moda",
      "Diseño Grafico Profesional",
      "Ingenieria Agricola",
      "Ingenieria Electrica",
      "Ingenieria En Automatizacion Y Control Industrial",
      "Ingenieria En Climatizacion",
      "Ingenieria En Comercio Exterior",
      "Ingenieria En Electronica Y Sistemas Inteligentes",
      "Ingenieria En Geomensura",
      "Ingenieria En Maquinaria Pesada Y Vehiculos Automotrices",
      "Ingenieria En Maquinaria, Vehiculos Automotrices Y Sistemas Electronicos",
      "Ingenieria En Metalurgia",
      "Ingenieria En Minas",
      "Ingenieria En Prevencion De Riesgos, Calidad Y Ambiente",
      "Ingenieria En Produccion Ganadera",
      "Ingenieria En Quimica Industrial",
      "Ingenieria En Refrigeracion",
      "Ingenieria En Telecomunicaciones, Conectividad Y Redes",
      "Ingenieria Mecanica En Mantenimiento Industrial",
      "Ingenieria Mecanica En Produccion Industrial",
    ],
  },
  {
    institucion: "Ip Instituto De Estudios Bancarios Guillermo Subercaseaux",
    carreras: [
      "Contador Auditor Con Mencion Banca E Instituciones Financieras",
      "Ingenieria En Administracion De Empresas Financieras",
      "Ingenieria Financiera",
      "Tecnico En Administracion De Empresas Financieras",
      "Tecnico Financiero",
      "Contador Auditor Con Mencion Banca E Instituciones Financieras",
      "Ingenieria En Administracion De Empresas Financieras",
      "Ingenieria Financiera",
      "Tecnico En Administracion De Empresas Financieras",
      "Tecnico Financiero",
    ],
  },
  {
    institucion: "Ip Instituto Internacional De Artes Culinarias Y Servicios",
    carreras: [
      "Administracion De Artes Culinarias Y Servicios",
      "Administracion De Empresas Hoteleras Y Servicios",
      "Direccion Y Produccion De Eventos",
      "Administracion De Artes Culinarias Y Servicios",
      "Administracion De Empresas Hoteleras Y Servicios",
      "Direccion Y Produccion De Eventos",
    ],
  },
  {
    institucion: "Ip Instituto Nacional Del Futbol",
    carreras: [
      "Arbitro De Futbol",
      "Entrenador De Futbol",
      "Entrenador En Deporte Y Actividad Fisica",
      "Programa Especial De Titulacion De Arbitro De Futbol",
      "Programa Especial De Titulacion De Entrenador De Futbol",
      "Tecnico De Futbol",
      "Arbitro De Futbol",
      "Entrenador De Futbol",
      "Entrenador En Deporte Y Actividad Fisica",
      "Programa Especial De Titulacion De Arbitro De Futbol",
      "Programa Especial De Titulacion De Entrenador De Futbol",
      "Tecnico De Futbol",
    ],
  },
  {
    institucion: "Ip Instituto Superior De Artes Y Ciencias De La Comunicacion",
    carreras: [
      "Administracion Publica",
      "Contabilidad General",
      "Contador Auditor",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Gestion De Calidad Y Ambiente",
      "Ingenieria En Informatica",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Recursos Humanos",
      "Ingenieria Industrial",
      "Psicopedagogia",
      "Tecnico De Nivel Superior Educador Social Infantojuvenil",
      "Tecnico De Nivel Superior En Administracion De Redes",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Analisis Y Programacion Computacional",
      "Tecnico De Nivel Superior En Automatizacion Y Control",
      "Tecnico De Nivel Superior En Ciberseguridad",
      "Tecnico De Nivel Superior En Control Industrial",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Gestion De Calidad Y Ambiente",
      "Tecnico De Nivel Superior En Gestion Energetica",
      "Tecnico De Nivel Superior En Informatica",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior En Procesos Mineros",
      "Tecnico De Nivel Superior En Recursos Humanos",
      "Tecnico De Nivel Superior En Telecomunicaciones",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico De Nivel Superior Juridico",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico Superior En Administracion De Empresas",
      "Trabajo Social",
      "Administracion Publica",
      "Contabilidad General",
      "Contador Auditor",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Gestion De Calidad Y Ambiente",
      "Ingenieria En Informatica",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Recursos Humanos",
      "Ingenieria Industrial",
      "Psicopedagogia",
      "Tecnico De Nivel Superior Educador Social Infantojuvenil",
      "Tecnico De Nivel Superior En Administracion De Redes",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Analisis Y Programacion Computacional",
      "Tecnico De Nivel Superior En Automatizacion Y Control",
      "Tecnico De Nivel Superior En Ciberseguridad",
      "Tecnico De Nivel Superior En Control Industrial",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Gestion De Calidad Y Ambiente",
      "Tecnico De Nivel Superior En Gestion Energetica",
      "Tecnico De Nivel Superior En Informatica",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior En Procesos Mineros",
      "Tecnico De Nivel Superior En Recursos Humanos",
      "Tecnico De Nivel Superior En Telecomunicaciones",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico De Nivel Superior Juridico",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico Superior En Administracion De Empresas",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Ip Ipg",
    carreras: [
      "Construccion Civil (plan Continuidad)",
      "Ingenieria De Ejecucion Industrial Plan Continuidad",
      "Ingenieria En Administracion General Nivel Profesional Plan Continuidad",
      "Ingenieria En Minas Plan Continuidad",
      "Ingenieria En Prevencion De Riesgos, Calidad Y Ambiente Plan Continuidad",
      "Psicopedagogia Plan Continuidad",
      "Tecnico De Nivel Superior Ambiental Y Gestion De Calidad",
      "Tecnico De Nivel Superior Comercio Exterior Mencion Comercio Electronico",
      "Tecnico De Nivel Superior En Administracion Agropecuaria",
      "Tecnico De Nivel Superior En Administracion General",
      "Tecnico De Nivel Superior En Atencion De Publico",
      "Tecnico De Nivel Superior En Construccion Civil",
      "Tecnico De Nivel Superior En Contabilidad General",
      "Tecnico De Nivel Superior En Educacion Basica",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Electricidad Y Electronica",
      "Tecnico De Nivel Superior En Energias Renovables Y Eficiencia Energetica",
      "Tecnico De Nivel Superior En Estetica Integral",
      "Tecnico De Nivel Superior En Gastronomia",
      "Tecnico De Nivel Superior En Gestion Documental Y Archivistica",
      "Tecnico De Nivel Superior En Gestion Logistica",
      "Tecnico De Nivel Superior En Gestion Publica Y Municipal",
      "Tecnico De Nivel Superior En Mantenimiento Industrial",
      "Tecnico De Nivel Superior En Maquinaria Naval",
      "Tecnico De Nivel Superior En Masoterapia",
      "Tecnico De Nivel Superior En Minas Mencion Perforacion Y Tronadura",
      "Tecnico De Nivel Superior En Prevencion De Riesgos",
      "Tecnico De Nivel Superior En Servicio Social",
      "Tecnico De Nivel Superior En Terapias Complementarias De Salud",
      "Tecnico De Nivel Superior En Transporte Maritimo",
      "Tecnico En Enfermeria De Nivel Superior",
      "Tecnico En Mecanica Automotriz Mencion Electronica",
      "Tecnico Preparador Fisico De Nivel Superior",
      "Construccion Civil (plan Continuidad)",
      "Ingenieria De Ejecucion Industrial Plan Continuidad",
      "Ingenieria En Administracion General Nivel Profesional Plan Continuidad",
      "Ingenieria En Minas Plan Continuidad",
      "Ingenieria En Prevencion De Riesgos, Calidad Y Ambiente Plan Continuidad",
      "Psicopedagogia Plan Continuidad",
      "Tecnico De Nivel Superior Ambiental Y Gestion De Calidad",
      "Tecnico De Nivel Superior Comercio Exterior Mencion Comercio Electronico",
      "Tecnico De Nivel Superior En Administracion Agropecuaria",
      "Tecnico De Nivel Superior En Administracion General",
      "Tecnico De Nivel Superior En Atencion De Publico",
      "Tecnico De Nivel Superior En Construccion Civil",
      "Tecnico De Nivel Superior En Contabilidad General",
      "Tecnico De Nivel Superior En Educacion Basica",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Electricidad Y Electronica",
      "Tecnico De Nivel Superior En Energias Renovables Y Eficiencia Energetica",
      "Tecnico De Nivel Superior En Estetica Integral",
      "Tecnico De Nivel Superior En Gastronomia",
      "Tecnico De Nivel Superior En Gestion Documental Y Archivistica",
      "Tecnico De Nivel Superior En Gestion Logistica",
      "Tecnico De Nivel Superior En Gestion Publica Y Municipal",
      "Tecnico De Nivel Superior En Mantenimiento Industrial",
      "Tecnico De Nivel Superior En Maquinaria Naval",
      "Tecnico De Nivel Superior En Masoterapia",
      "Tecnico De Nivel Superior En Minas Mencion Perforacion Y Tronadura",
      "Tecnico De Nivel Superior En Prevencion De Riesgos",
      "Tecnico De Nivel Superior En Servicio Social",
      "Tecnico De Nivel Superior En Terapias Complementarias De Salud",
      "Tecnico De Nivel Superior En Transporte Maritimo",
      "Tecnico En Enfermeria De Nivel Superior",
      "Tecnico En Mecanica Automotriz Mencion Electronica",
      "Tecnico Preparador Fisico De Nivel Superior",
    ],
  },
  {
    institucion: "Ip Latinoamericano De Comercio Exterior - Iplacex",
    carreras: [
      "Administracion Publica",
      "Auditoria",
      "Contabilidad",
      "Gastronomia Internacional Y Cultural",
      "Ingenieria De Ejecucion En Administracion Mencion Gestion Municipal",
      "Ingenieria De Ejecucion En Administracion Mencion Gestion Y Desarrollo Local Y Regional",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion Mencion Seguridad Privada",
      "Ingenieria En Comercio Exterior",
      "Ingenieria En Control Automatico Y Gestion Industrial",
      "Ingenieria En Gestion De Centros De Salud",
      "Ingenieria En Informatica",
      "Ingenieria En Logistica",
      "Ingenieria En Prevencion De Riesgos, Calidad Y Medio Ambiente",
      "Ingenieria En Recursos Humanos",
      "Masoterapia",
      "Psicopedagogia",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion Mencion Gestion Municipal",
      "Tecnico En Administracion Publica",
      "Tecnico En Alimentacion Y Nutricion",
      "Tecnico En Comercio Exterior",
      "Tecnico En Desarrollo De Aplicaciones Multiplataforma",
      "Tecnico En Educacion Especial Y Diferencial",
      "Tecnico En Educacion Parvularia Y Educacion Basica",
      "Tecnico En Enfermeria",
      "Tecnico En Generacion Electrica Limpia",
      "Tecnico En Gestion De Recursos Humanos",
      "Tecnico En Gestion Logistica",
      "Tecnico En Informatica",
      "Tecnico En Instrumentacion Y Control Industrial",
      "Tecnico En Logistica",
      "Tecnico En Marketing Digital",
      "Tecnico En Mediacion Escolar",
      "Tecnico En Odontologia Mencion Higienista Dental",
      "Tecnico En Operaciones Mineras",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Prevencion, Tratamiento Y Rehabilitacion De Sustancias",
      "Tecnico En Recursos Humanos",
      "Tecnico En Salud Natural Y Terapias Complementarias",
      "Tecnico En Trabajo Social",
      "Topografia",
      "Trabajo Social",
      "Administracion Publica",
      "Auditoria",
      "Contabilidad",
      "Gastronomia Internacional Y Cultural",
      "Ingenieria De Ejecucion En Administracion Mencion Gestion Municipal",
      "Ingenieria De Ejecucion En Administracion Mencion Gestion Y Desarrollo Local Y Regional",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion Mencion Seguridad Privada",
      "Ingenieria En Comercio Exterior",
      "Ingenieria En Control Automatico Y Gestion Industrial",
      "Ingenieria En Gestion De Centros De Salud",
      "Ingenieria En Informatica",
      "Ingenieria En Logistica",
      "Ingenieria En Prevencion De Riesgos, Calidad Y Medio Ambiente",
      "Ingenieria En Recursos Humanos",
      "Masoterapia",
      "Psicopedagogia",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion Mencion Gestion Municipal",
      "Tecnico En Administracion Publica",
      "Tecnico En Alimentacion Y Nutricion",
      "Tecnico En Comercio Exterior",
      "Tecnico En Desarrollo De Aplicaciones Multiplataforma",
      "Tecnico En Educacion Especial Y Diferencial",
      "Tecnico En Educacion Parvularia Y Educacion Basica",
      "Tecnico En Enfermeria",
      "Tecnico En Generacion Electrica Limpia",
      "Tecnico En Gestion De Recursos Humanos",
      "Tecnico En Gestion Logistica",
      "Tecnico En Informatica",
      "Tecnico En Instrumentacion Y Control Industrial",
      "Tecnico En Logistica",
      "Tecnico En Marketing Digital",
      "Tecnico En Mediacion Escolar",
      "Tecnico En Odontologia Mencion Higienista Dental",
      "Tecnico En Operaciones Mineras",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Prevencion, Tratamiento Y Rehabilitacion De Sustancias",
      "Tecnico En Recursos Humanos",
      "Tecnico En Salud Natural Y Terapias Complementarias",
      "Tecnico En Trabajo Social",
      "Topografia",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Ip Libertador De Los Andes",
    carreras: [
      "Contador Auditor",
      "Ingenieria En Administracion De Empresas Gestion Recursos Humanos",
      "Ingenieria En Administracion Gestion Financiera",
      "Ingenieria En Administracion Publica",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Psicopedagogia",
      "Servicio Social",
      "Tecnico De Nivel Superior Asistente De La Educacion En Aula Y Administracion",
      "Tecnico De Nivel Superior En Administracion De Empresas Gestion Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Administracion Y Logistica",
      "Tecnico De Nivel Superior En Comercio Internacional",
      "Tecnico De Nivel Superior En Computacion E Informatica Gestion En Redes",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Gestion De Calidad Y Medio Ambiente",
      "Tecnico De Nivel Superior En Prevencion De Riesgos Y Medio Ambiente",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico De Nivel Superior En Traduccion Ingles/español",
      "Contador Auditor",
      "Ingenieria En Administracion De Empresas Gestion Recursos Humanos",
      "Ingenieria En Administracion Gestion Financiera",
      "Ingenieria En Administracion Publica",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Psicopedagogia",
      "Servicio Social",
      "Tecnico De Nivel Superior Asistente De La Educacion En Aula Y Administracion",
      "Tecnico De Nivel Superior En Administracion De Empresas Gestion Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Administracion Y Logistica",
      "Tecnico De Nivel Superior En Comercio Internacional",
      "Tecnico De Nivel Superior En Computacion E Informatica Gestion En Redes",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Gestion De Calidad Y Medio Ambiente",
      "Tecnico De Nivel Superior En Prevencion De Riesgos Y Medio Ambiente",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico De Nivel Superior En Traduccion Ingles/español",
    ],
  },
  {
    institucion: "Ip Los Lagos",
    carreras: [
      "Construccion Civil",
      "Contador Auditor",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion Juridica",
      "Ingenieria En Administracion Publica",
      "Ingenieria En Gestion Logistica",
      "Ingenieria En Informatica",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria Industrial",
      "Orientacion Familiar Mencion Relaciones Humanas",
      "Planificacion Social",
      "Preparador Fisico Y Entrenador Deportivo",
      "Psicopedagogia",
      "Psicopedagogia Mencion Desarrollo Infantil",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion Publica",
      "Tecnico En Construccion",
      "Tecnico En Gestion Logistica",
      "Tecnico En Prevencion De Riesgos",
      "Construccion Civil",
      "Contador Auditor",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion Juridica",
      "Ingenieria En Administracion Publica",
      "Ingenieria En Gestion Logistica",
      "Ingenieria En Informatica",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria Industrial",
      "Orientacion Familiar Mencion Relaciones Humanas",
      "Planificacion Social",
      "Preparador Fisico Y Entrenador Deportivo",
      "Psicopedagogia",
      "Psicopedagogia Mencion Desarrollo Infantil",
      "Tecnico En Administracion De Empresas",
      "Tecnico En Administracion Publica",
      "Tecnico En Construccion",
      "Tecnico En Gestion Logistica",
      "Tecnico En Prevencion De Riesgos",
    ],
  },
  {
    institucion: "Ip Los Leones",
    carreras: [
      "Analista Programador",
      "Construccion Civil",
      "Contador Auditor",
      "Contador General",
      "Diseño Y Multimedia Digital",
      "Ingenieria En Administracion De Negocios Y Gestion Comercial",
      "Ingenieria En Informatica Y Ciber Seguridad",
      "Laboratorista Dental",
      "Masoterapia",
      "Naturopatia Y Terapias Naturales",
      "Produccion De Eventos",
      "Programa Para Trabajadoras De Nivel De Educacion Parvularia",
      "Psicopedagogia",
      "Secretariado En Gestion Tecnologica",
      "Servicio Social",
      "Tecnico En Administracion De Rrhh",
      "Tecnico En Ciber Seguridad",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Construccion",
      "Tecnico En Diseño Y Comunicacion Digital",
      "Tecnico En Educacion Parvularia",
      "Tecnico En Estetica Integral",
      "Tecnico En Farmacia",
      "Tecnico En Gestion De Empresas",
      "Tecnico En Hoteleria",
      "Tecnico En Mineria",
      "Tecnico En Naturopatia Y Terapias Naturales",
      "Tecnico En Negocios Y Gestion Comercial",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Servicio Social",
      "Turismo Sustentable",
      "Analista Programador",
      "Construccion Civil",
      "Contador Auditor",
      "Contador General",
      "Diseño Y Multimedia Digital",
      "Ingenieria En Administracion De Negocios Y Gestion Comercial",
      "Ingenieria En Informatica Y Ciber Seguridad",
      "Laboratorista Dental",
      "Masoterapia",
      "Naturopatia Y Terapias Naturales",
      "Produccion De Eventos",
      "Programa Para Trabajadoras De Nivel De Educacion Parvularia",
      "Psicopedagogia",
      "Secretariado En Gestion Tecnologica",
      "Servicio Social",
      "Tecnico En Administracion De Rrhh",
      "Tecnico En Ciber Seguridad",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Construccion",
      "Tecnico En Diseño Y Comunicacion Digital",
      "Tecnico En Educacion Parvularia",
      "Tecnico En Estetica Integral",
      "Tecnico En Farmacia",
      "Tecnico En Gestion De Empresas",
      "Tecnico En Hoteleria",
      "Tecnico En Mineria",
      "Tecnico En Naturopatia Y Terapias Naturales",
      "Tecnico En Negocios Y Gestion Comercial",
      "Tecnico En Prevencion De Riesgos",
      "Tecnico En Servicio Social",
      "Turismo Sustentable",
    ],
  },
  {
    institucion: "Ip Projazz",
    carreras: [
      "Composicion Musical",
      "Interprete En Jazz Y Musica Popular",
      "Tecnico De Nivel Superior En Produccion Musical",
      "Composicion Musical",
      "Interprete En Jazz Y Musica Popular",
      "Tecnico De Nivel Superior En Produccion Musical",
    ],
  },
  {
    institucion: "Ip Providencia",
    carreras: [
      "Contador Auditor",
      "Contador General",
      "Diseño Grafico Digital",
      "Ingenieria En Administracion De Empresas Mencion Finanzas",
      "Ingenieria En Administracion De Empresas Mencion Marketing",
      "Ingenieria En Administracion De Empresas Mencion Recursos Humanos",
      "Ingenieria En Administracion Mencion Marketing O Finanzas O Recursos Humanos",
      "Ingenieria En Administracion Publica Y Municipal",
      "Ingenieria En Control De Gestion",
      "Ingenieria En Informatica",
      "Ingenieria En Informatica Mencion Analitica Avanzada O Ciberseguridad",
      "Ingenieria Industrial",
      "Publicidad En Marketing Digital",
      "Servicio Social",
      "Tecnico De Nivel Superior Analista De Sistemas",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Finanzas",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Marketing",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Marketing O Finanzas O Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Cloud Administration",
      "Tecnico De Nivel Superior En Comercio Exterior",
      "Tecnico De Nivel Superior En Control De Gestion",
      "Tecnico De Nivel Superior En Control De La Produccion Industrial",
      "Tecnico De Nivel Superior En Diseño Grafico Digital",
      "Tecnico De Nivel Superior En Gestion Turistica",
      "Tecnico De Nivel Superior En Informatica",
      "Tecnico De Nivel Superior En Seguridad Informatica",
      "Tecnico De Nivel Superior En Servicio Social",
      "Tecnico En Animacion Digital",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Educacion Parvularia Y Primer Ciclo Basico",
      "Tecnico En Logistica",
      "Tecnico En Publicidad",
      "Tecnico Juridico En Gestion Judicial",
      "Contador Auditor",
      "Contador General",
      "Diseño Grafico Digital",
      "Ingenieria En Administracion De Empresas Mencion Finanzas",
      "Ingenieria En Administracion De Empresas Mencion Marketing",
      "Ingenieria En Administracion De Empresas Mencion Recursos Humanos",
      "Ingenieria En Administracion Mencion Marketing O Finanzas O Recursos Humanos",
      "Ingenieria En Administracion Publica Y Municipal",
      "Ingenieria En Control De Gestion",
      "Ingenieria En Informatica",
      "Ingenieria En Informatica Mencion Analitica Avanzada O Ciberseguridad",
      "Ingenieria Industrial",
      "Publicidad En Marketing Digital",
      "Servicio Social",
      "Tecnico De Nivel Superior Analista De Sistemas",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Finanzas",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Marketing",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Marketing O Finanzas O Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion De Empresas Mencion Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Cloud Administration",
      "Tecnico De Nivel Superior En Comercio Exterior",
      "Tecnico De Nivel Superior En Control De Gestion",
      "Tecnico De Nivel Superior En Control De La Produccion Industrial",
      "Tecnico De Nivel Superior En Diseño Grafico Digital",
      "Tecnico De Nivel Superior En Gestion Turistica",
      "Tecnico De Nivel Superior En Informatica",
      "Tecnico De Nivel Superior En Seguridad Informatica",
      "Tecnico De Nivel Superior En Servicio Social",
      "Tecnico En Animacion Digital",
      "Tecnico En Educacion Diferencial",
      "Tecnico En Educacion Parvularia Y Primer Ciclo Basico",
      "Tecnico En Logistica",
      "Tecnico En Publicidad",
      "Tecnico Juridico En Gestion Judicial",
    ],
  },
  {
    institucion: "Ip Santo Tomas",
    carreras: [
      "Analista Programador",
      "Animacion Digital Y Multimedia",
      "Comunicacion Audiovisual Digital",
      "Construccion Civil",
      "Contador Auditor",
      "Diseño Grafico",
      "Ingenieria Agricola",
      "Ingenieria De Ejecucion Agropecuaria",
      "Ingenieria De Ejecucion En Administracion",
      "Ingenieria De Ejecucion En Administracion De Recursos Humanos",
      "Ingenieria De Ejecucion En Administracion Publica Y Municipal",
      "Ingenieria De Ejecucion En Administracion, Programa Ejecutivo Semipresencial",
      "Ingenieria De Ejecucion En Automatizacion Y Control Industrial",
      "Ingenieria De Ejecucion En Electricidad Y Electronica Industrial",
      "Ingenieria De Ejecucion En Mantenimiento Industrial",
      "Ingenieria De Ejecucion En Quimica Industrial",
      "Ingenieria En Administracion De Recursos Humanos",
      "Ingenieria En Administracion De Rrhh",
      "Ingenieria En Administracion Mencion Gestion Publica",
      "Ingenieria En Administracion Publica Y Municipal",
      "Ingenieria En Automatizacion Y Control Industrial",
      "Ingenieria En Comercio Exterior",
      "Ingenieria En Electricidad Y Electronica Industrial",
      "Ingenieria En Finanzas",
      "Ingenieria En Geomensura",
      "Ingenieria En Informatica",
      "Ingenieria En Informatica, Programa Ejecutivo Semipresencial",
      "Ingenieria En Logistica, Programa Ejecutivo Semipresencial",
      "Ingenieria En Mantenimiento Industrial",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Quimica Industrial",
      "Ingenieria En Sonido",
      "Operacion De Equipos Moviles Mineros",
      "Psicopedagogia",
      "Publicidad",
      "Servicio Social",
      "Tecnico En Automatizacion Y Control Industrial",
      "Tecnico En Comunicacion Audiovisual Digital",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Diseño De Videojuegos",
      "Tecnico En Diseño Digital Y Publicitario",
      "Tecnico En Diseño Publicitario Multimedial",
      "Tecnico En Electricidad Y Electronica Industrial",
      "Tecnico En Energia Solar",
      "Tecnico En Mantenimiento Industrial",
      "Tecnico En Operaciones Mineras",
      "Tecnico En Sonido",
      "Tecnico En Trabajo Social",
      "Tecnico En Turismo",
      "Analista Programador",
      "Animacion Digital Y Multimedia",
      "Comunicacion Audiovisual Digital",
      "Construccion Civil",
      "Contador Auditor",
      "Diseño Grafico",
      "Ingenieria Agricola",
      "Ingenieria De Ejecucion Agropecuaria",
      "Ingenieria De Ejecucion En Administracion",
      "Ingenieria De Ejecucion En Administracion De Recursos Humanos",
      "Ingenieria De Ejecucion En Administracion Publica Y Municipal",
      "Ingenieria De Ejecucion En Administracion, Programa Ejecutivo Semipresencial",
      "Ingenieria De Ejecucion En Automatizacion Y Control Industrial",
      "Ingenieria De Ejecucion En Electricidad Y Electronica Industrial",
      "Ingenieria De Ejecucion En Mantenimiento Industrial",
      "Ingenieria De Ejecucion En Quimica Industrial",
      "Ingenieria En Administracion De Recursos Humanos",
      "Ingenieria En Administracion De Rrhh",
      "Ingenieria En Administracion Mencion Gestion Publica",
      "Ingenieria En Administracion Publica Y Municipal",
      "Ingenieria En Automatizacion Y Control Industrial",
      "Ingenieria En Comercio Exterior",
      "Ingenieria En Electricidad Y Electronica Industrial",
      "Ingenieria En Finanzas",
      "Ingenieria En Geomensura",
      "Ingenieria En Informatica",
      "Ingenieria En Informatica, Programa Ejecutivo Semipresencial",
      "Ingenieria En Logistica, Programa Ejecutivo Semipresencial",
      "Ingenieria En Mantenimiento Industrial",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Quimica Industrial",
      "Ingenieria En Sonido",
      "Operacion De Equipos Moviles Mineros",
      "Psicopedagogia",
      "Publicidad",
      "Servicio Social",
      "Tecnico En Automatizacion Y Control Industrial",
      "Tecnico En Comunicacion Audiovisual Digital",
      "Tecnico En Conectividad Y Redes",
      "Tecnico En Diseño De Videojuegos",
      "Tecnico En Diseño Digital Y Publicitario",
      "Tecnico En Diseño Publicitario Multimedial",
      "Tecnico En Electricidad Y Electronica Industrial",
      "Tecnico En Energia Solar",
      "Tecnico En Mantenimiento Industrial",
      "Tecnico En Operaciones Mineras",
      "Tecnico En Sonido",
      "Tecnico En Trabajo Social",
      "Tecnico En Turismo",
    ],
  },
  {
    institucion: "Pontificia Universidad Catolica De Chile",
    carreras: [
      "Actuacion",
      "Agronomia",
      "Agronomia E Ingenieria Forestal",
      "Antropologia-arqueologia",
      "Arquitectura",
      "Biologia",
      "Biologia Marina",
      "Bioquimica",
      "Ciencia Politica",
      "College Artes Y Humanidades (licenciatura)",
      "College Ciencias Naturales Y Matematicas (licenciatura)",
      "College Ciencias Sociales (licenciatura)",
      "Construccion Civil",
      "Derecho",
      "Direccion Audiovisual",
      "Diseño",
      "Enfermeria",
      "Estadistica",
      "Filosofia",
      "Fonoaudiologia",
      "Geografia",
      "Historia",
      "Ingenieria Civil",
      "Ingenieria Civil De Biotecnologia (ambiental/procesos)",
      "Ingenieria Civil De Computacion",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Ambiental",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De Bioprocesos",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De Computacion",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De La Construccion",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De Mineria",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De Transporte",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Electrica",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria En Tecnologias De Informacion",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Hidraulica",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Matematica",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Mecanica",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Quimica",
      "Ingenieria Civil Electricista",
      "Ingenieria Civil Matematica Y Computacional",
      "Ingenieria Civil Mecanico",
      "Ingenieria Civil, Diploma En Ingenieria Ambiental",
      "Ingenieria Civil, Diploma En Ingenieria De Diseño Y Construccion De Obras",
      "Ingenieria Civil, Diploma En Ingenieria De Mineria",
      "Ingenieria Civil, Diploma En Ingenieria De Transporte",
      "Ingenieria Civil, Diploma En Ingenieria Estructural",
      "Ingenieria Civil, Diploma En Ingenieria Geotecnica",
      "Ingenieria Civil, Diploma En Ingenieria Hidraulica",
      "Ingenieria Civil, Diploma En Ingenieria Y Gestion De La Construccion",
      "Ingenieria Comercial",
      "Ingenieria Forestal",
      "Kinesiologia",
      "Licenciatura En Arte",
      "Licenciatura En Astronomia",
      "Licenciatura En Estetica",
      "Licenciatura En Fisica",
      "Licenciatura En Interpretacion Musical",
      "Licenciatura En Letras Mencion Lingüistica Y Literatura Hispanica",
      "Licenciatura En Letras Mencion Lingüistica Y Literatura Inglesa",
      "Licenciatura En Musica",
      "Licenciatura En Teologia",
      "Matematica",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Odontologia",
      "Pedagogia En Artes Plasticas",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Castellano",
      "Pedagogia En Educacion Fisica Y Salud Para Educacion Basica Y Media",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Media En Ciencias Naturales Y Biologia",
      "Pedagogia En Educacion Media En Fisica",
      "Pedagogia En Educacion Media En Matematica",
      "Pedagogia En Educacion Media En Quimica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Filosofia",
      "Pedagogia En Ingles Para Educacion Basica Y Media",
      "Pedagogia En Matematica",
      "Pedagogia En Religion Catolica",
      "Pedagogia En Religion Catolica Para Educacion Basica",
      "Pedagogia En Religion Catolica Para Educacion Media",
      "Pedagogia En Religion Y Moral",
      "Periodismo - Direccion Audiovisual - Publicidad",
      "Planificacion Urbana",
      "Programa De Formacion Para Periodistas Licenciados En Otras Disciplinas",
      "Programa De Formacion Pedagogica Para Licenciados",
      "Psicologia",
      "Publicidad",
      "Quimica",
      "Quimica Y Farmacia",
      "Sociologia",
      "Teologia Plan Comun",
      "Trabajo Social",
      "Actuacion",
      "Agronomia",
      "Agronomia E Ingenieria Forestal",
      "Antropologia-arqueologia",
      "Arquitectura",
      "Biologia",
      "Biologia Marina",
      "Bioquimica",
      "Ciencia Politica",
      "College Artes Y Humanidades (licenciatura)",
      "College Ciencias Naturales Y Matematicas (licenciatura)",
      "College Ciencias Sociales (licenciatura)",
      "Construccion Civil",
      "Derecho",
      "Direccion Audiovisual",
      "Diseño",
      "Enfermeria",
      "Estadistica",
      "Filosofia",
      "Fonoaudiologia",
      "Geografia",
      "Historia",
      "Ingenieria Civil",
      "Ingenieria Civil De Biotecnologia (ambiental/procesos)",
      "Ingenieria Civil De Computacion",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Ambiental",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De Bioprocesos",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De Computacion",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De La Construccion",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De Mineria",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria De Transporte",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Electrica",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria En Tecnologias De Informacion",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Hidraulica",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Matematica",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Mecanica",
      "Ingenieria Civil De Industrias, Diploma En Ingenieria Quimica",
      "Ingenieria Civil Electricista",
      "Ingenieria Civil Matematica Y Computacional",
      "Ingenieria Civil Mecanico",
      "Ingenieria Civil, Diploma En Ingenieria Ambiental",
      "Ingenieria Civil, Diploma En Ingenieria De Diseño Y Construccion De Obras",
      "Ingenieria Civil, Diploma En Ingenieria De Mineria",
      "Ingenieria Civil, Diploma En Ingenieria De Transporte",
      "Ingenieria Civil, Diploma En Ingenieria Estructural",
      "Ingenieria Civil, Diploma En Ingenieria Geotecnica",
      "Ingenieria Civil, Diploma En Ingenieria Hidraulica",
      "Ingenieria Civil, Diploma En Ingenieria Y Gestion De La Construccion",
      "Ingenieria Comercial",
      "Ingenieria Forestal",
      "Kinesiologia",
      "Licenciatura En Arte",
      "Licenciatura En Astronomia",
      "Licenciatura En Estetica",
      "Licenciatura En Fisica",
      "Licenciatura En Interpretacion Musical",
      "Licenciatura En Letras Mencion Lingüistica Y Literatura Hispanica",
      "Licenciatura En Letras Mencion Lingüistica Y Literatura Inglesa",
      "Licenciatura En Musica",
      "Licenciatura En Teologia",
      "Matematica",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Odontologia",
      "Pedagogia En Artes Plasticas",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Castellano",
      "Pedagogia En Educacion Fisica Y Salud Para Educacion Basica Y Media",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Media En Ciencias Naturales Y Biologia",
      "Pedagogia En Educacion Media En Fisica",
      "Pedagogia En Educacion Media En Matematica",
      "Pedagogia En Educacion Media En Quimica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Filosofia",
      "Pedagogia En Ingles Para Educacion Basica Y Media",
      "Pedagogia En Matematica",
      "Pedagogia En Religion Catolica",
      "Pedagogia En Religion Catolica Para Educacion Basica",
      "Pedagogia En Religion Catolica Para Educacion Media",
      "Pedagogia En Religion Y Moral",
      "Periodismo - Direccion Audiovisual - Publicidad",
      "Planificacion Urbana",
      "Programa De Formacion Para Periodistas Licenciados En Otras Disciplinas",
      "Programa De Formacion Pedagogica Para Licenciados",
      "Psicologia",
      "Publicidad",
      "Quimica",
      "Quimica Y Farmacia",
      "Sociologia",
      "Teologia Plan Comun",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Pontificia Universidad Catolica De Valparaiso",
    carreras: [
      "Agronomia",
      "Arquitectura",
      "Bachiller Canonico En Teologia",
      "Bachillerato En Ciencias",
      "Bioquimica",
      "Contador Auditor",
      "Derecho",
      "Diseño",
      "Educacion Parvularia",
      "Geografia",
      "Ingenieria Civil",
      "Ingenieria Civil Bioquimica",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Metalurgia Extractiva",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria De Alimentos",
      "Ingenieria De Ejecucion En Bioprocesos",
      "Ingenieria De Ejecucion En Informatica",
      "Ingenieria De Transporte",
      "Ingenieria Electrica",
      "Ingenieria Electronica",
      "Ingenieria En Construccion",
      "Ingenieria Estadistica",
      "Ingenieria Mecanica",
      "Interpretacion O Traduccion Ingles-español",
      "Interprete Musical Mencion Instrumento Principal",
      "Kinesiologia",
      "Licenciatura En Arte",
      "Licenciatura En Ciencias Religiosas Y Estudios Pastorales",
      "Licenciatura En Estudios Teologicos, Biblicos Y Dialogo Ecumenico",
      "Oceanografia",
      "Pedagogia De Religion Y Moral Modalidad Consecutiva",
      "Pedagogia Educacion Basica",
      "Pedagogia En Biologia Y Ciencias Naturales Y/o Licenciatura En Biologia",
      "Pedagogia En Castellano Y/o Licenciatura En Lingüistica Y Literatura",
      "Pedagogia En Educacion Especial",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Filosofia Y/o Licenciatura En Filosofia",
      "Pedagogia En Fisica Y/o Licenciatura En Fisica",
      "Pedagogia En Historia, Geografia Y Ciencias Sociales Y/o Licenciatura En Historia",
      "Pedagogia En Ingles Y/o Licenciatura En Lengua Y Literatura Inglesa",
      "Pedagogia En Matematicas Y/o Licenciatura En Matematicas",
      "Pedagogia En Musica O Licenciatura En Ciencias Y Artes Musicales",
      "Pedagogia En Quimica Y Ciencias Naturales",
      "Periodismo",
      "Psicologia",
      "Quimica Industrial",
      "Tecnologia Medica",
      "Trabajo Social",
      "Agronomia",
      "Arquitectura",
      "Bachiller Canonico En Teologia",
      "Bachillerato En Ciencias",
      "Bioquimica",
      "Contador Auditor",
      "Derecho",
      "Diseño",
      "Educacion Parvularia",
      "Geografia",
      "Ingenieria Civil",
      "Ingenieria Civil Bioquimica",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Metalurgia Extractiva",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria De Alimentos",
      "Ingenieria De Ejecucion En Bioprocesos",
      "Ingenieria De Ejecucion En Informatica",
      "Ingenieria De Transporte",
      "Ingenieria Electrica",
      "Ingenieria Electronica",
      "Ingenieria En Construccion",
      "Ingenieria Estadistica",
      "Ingenieria Mecanica",
      "Interpretacion O Traduccion Ingles-español",
      "Interprete Musical Mencion Instrumento Principal",
      "Kinesiologia",
      "Licenciatura En Arte",
      "Licenciatura En Ciencias Religiosas Y Estudios Pastorales",
      "Licenciatura En Estudios Teologicos, Biblicos Y Dialogo Ecumenico",
      "Oceanografia",
      "Pedagogia De Religion Y Moral Modalidad Consecutiva",
      "Pedagogia Educacion Basica",
      "Pedagogia En Biologia Y Ciencias Naturales Y/o Licenciatura En Biologia",
      "Pedagogia En Castellano Y/o Licenciatura En Lingüistica Y Literatura",
      "Pedagogia En Educacion Especial",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Filosofia Y/o Licenciatura En Filosofia",
      "Pedagogia En Fisica Y/o Licenciatura En Fisica",
      "Pedagogia En Historia, Geografia Y Ciencias Sociales Y/o Licenciatura En Historia",
      "Pedagogia En Ingles Y/o Licenciatura En Lengua Y Literatura Inglesa",
      "Pedagogia En Matematicas Y/o Licenciatura En Matematicas",
      "Pedagogia En Musica O Licenciatura En Ciencias Y Artes Musicales",
      "Pedagogia En Quimica Y Ciencias Naturales",
      "Periodismo",
      "Psicologia",
      "Quimica Industrial",
      "Tecnologia Medica",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Academia De Humanismo Cristiano",
    carreras: [
      "Administracion Publica",
      "Antropologia",
      "Ciencia Politica Y Relaciones Internacionales",
      "Cine Y Artes Audiovisuales",
      "Composicion Musical",
      "Danza",
      "Derecho",
      "Geografia",
      "Interpretacion Musical",
      "Licenciatura En Cine Mencion Cine Documental",
      "Licenciatura En Filosofia",
      "Licenciatura En Historia Mencion Estudios Culturales",
      "Licenciatura En Historia Mencion Tiempo Presente",
      "Licenciatura En Lengua Y Literatura",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematica",
      "Pedagogia En Musica",
      "Periodismo",
      "Produccion Musical",
      "Programa De Continuidad De Estudios Administracion Publica",
      "Programa De Formacion Pedagogica En Enseñanza Media Para Profesionales",
      "Programa De Segunda Titulacion En Pedagogia En Educacion Basica",
      "Programa De Segunda Titulacion Pedagogia En Educacion Diferencial Mencion Trastornos Especificos Del Lenguaje Oral Mencion Discapacidad Intelectual",
      "Programa Especial De Titulacion En Periodismo",
      "Psicologia",
      "Sociologia",
      "Teatro",
      "Trabajo Social",
      "Administracion Publica",
      "Antropologia",
      "Ciencia Politica Y Relaciones Internacionales",
      "Cine Y Artes Audiovisuales",
      "Composicion Musical",
      "Danza",
      "Derecho",
      "Geografia",
      "Interpretacion Musical",
      "Licenciatura En Cine Mencion Cine Documental",
      "Licenciatura En Filosofia",
      "Licenciatura En Historia Mencion Estudios Culturales",
      "Licenciatura En Historia Mencion Tiempo Presente",
      "Licenciatura En Lengua Y Literatura",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematica",
      "Pedagogia En Musica",
      "Periodismo",
      "Produccion Musical",
      "Programa De Continuidad De Estudios Administracion Publica",
      "Programa De Formacion Pedagogica En Enseñanza Media Para Profesionales",
      "Programa De Segunda Titulacion En Pedagogia En Educacion Basica",
      "Programa De Segunda Titulacion Pedagogia En Educacion Diferencial Mencion Trastornos Especificos Del Lenguaje Oral Mencion Discapacidad Intelectual",
      "Programa Especial De Titulacion En Periodismo",
      "Psicologia",
      "Sociologia",
      "Teatro",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Adolfo Ibañez",
    carreras: [
      "Bachillerato De Comercial",
      "Bachillerato De Ingenieria",
      "Derecho",
      "Ingenieria Civil",
      "Ingenieria Civil En Bioingenieria",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil En Mineria",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil, Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria Comercial, Plan Comun",
      "Ingenieria En Diseño",
      "Periodismo",
      "Psicologia",
      "Bachillerato De Comercial",
      "Bachillerato De Ingenieria",
      "Derecho",
      "Ingenieria Civil",
      "Ingenieria Civil En Bioingenieria",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil En Mineria",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil, Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria Comercial, Plan Comun",
      "Ingenieria En Diseño",
      "Periodismo",
      "Psicologia",
    ],
  },
  {
    institucion: "Universidad Adventista De Chile",
    carreras: [
      "Agronomia",
      "Contador Auditor",
      "Educacion Parvularia",
      "Enfermeria",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Electronica Y Telecomunicaciones",
      "Licenciatura En Educacion",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Educacion Diferencial Mencion Dificultades Especificas Del Aprendizaje",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematica Y Computacion",
      "Pedagogia En Musica Mencion Educacion Extraescolar",
      "Psicologia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Teologia",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Agronomia",
      "Contador Auditor",
      "Educacion Parvularia",
      "Enfermeria",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Electronica Y Telecomunicaciones",
      "Licenciatura En Educacion",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Educacion Diferencial Mencion Dificultades Especificas Del Aprendizaje",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematica Y Computacion",
      "Pedagogia En Musica Mencion Educacion Extraescolar",
      "Psicologia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Teologia",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Alberto Hurtado",
    carreras: [
      "Administracion Publica",
      "Antropologia",
      "Arqueologia",
      "Bachillerato En Filosofia",
      "Bachillerato En Filosofia Y Humanidades Para Religiosos",
      "Bachillerato En Humanidades",
      "Bachillerato En Matematica",
      "Ciencia Politica Y Relaciones Internacionales",
      "Contador Publico Auditor",
      "Derecho",
      "Educacion Basica",
      "Educacion Parvularia",
      "Geografia",
      "Gestion De La Informacion, Bibliotecologia Y Archivistica",
      "Ingenieria Comercial",
      "Ingenieria Comercial Para Profesionales",
      "Ingenieria Comercial Para Profesionales Plan B",
      "Ingenieria Comercial Para Profesionales Plan C",
      "Interpretacion Superior Mencion Canto",
      "Licenciatura En Filosofia",
      "Licenciatura En Historia",
      "Licenciatura En Lengua Y Literatura",
      "Licenciatura En Teoria E Historia Del Arte",
      "Pedagogia En Artes Visuales",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Filosofia",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematica",
      "Pedagogia En Musica",
      "Pedagogia Para Profesionales",
      "Periodismo",
      "Psicologia",
      "Sociologia",
      "Trabajo Social",
      "Administracion Publica",
      "Antropologia",
      "Arqueologia",
      "Bachillerato En Filosofia",
      "Bachillerato En Filosofia Y Humanidades Para Religiosos",
      "Bachillerato En Humanidades",
      "Bachillerato En Matematica",
      "Ciencia Politica Y Relaciones Internacionales",
      "Contador Publico Auditor",
      "Derecho",
      "Educacion Basica",
      "Educacion Parvularia",
      "Geografia",
      "Gestion De La Informacion, Bibliotecologia Y Archivistica",
      "Ingenieria Comercial",
      "Ingenieria Comercial Para Profesionales",
      "Ingenieria Comercial Para Profesionales Plan B",
      "Ingenieria Comercial Para Profesionales Plan C",
      "Interpretacion Superior Mencion Canto",
      "Licenciatura En Filosofia",
      "Licenciatura En Historia",
      "Licenciatura En Lengua Y Literatura",
      "Licenciatura En Teoria E Historia Del Arte",
      "Pedagogia En Artes Visuales",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Filosofia",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematica",
      "Pedagogia En Musica",
      "Pedagogia Para Profesionales",
      "Periodismo",
      "Psicologia",
      "Sociologia",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Andres Bello",
    carreras: [
      "Administracion De Ecoturismo",
      "Arquitectura",
      "Artes Visuales",
      "Bachillerato En Ciencias",
      "Bachillerato En Humanidades",
      "Biologia",
      "Biologia Marina",
      "Bioquimica",
      "Contador Auditor",
      "Derecho",
      "Diseño De Juegos Digitales",
      "Diseño De Vestuario Y Textil",
      "Diseño Grafico",
      "Educacion General Basica Con Mencion En Orientacion Y Convivencia Escolar",
      "Educacion Musical Para La Educacion Preescolar Y Basica",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil En Metalurgia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion Hotelera Internacional",
      "Ingenieria En Automatizacion Y Robotica",
      "Ingenieria En Biotecnologia",
      "Ingenieria En Computacion E Informatica",
      "Ingenieria En Computacion E Informatica Ejecutivo",
      "Ingenieria En Construccion",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria En Logistica Y Transporte",
      "Ingenieria En Seguridad Y Prevencion De Riesgos",
      "Ingenieria En Turismo Y Hoteleria",
      "Ingenieria Fisica",
      "Ingenieria Industrial",
      "Ingeniero En Marina Mercante",
      "Kinesiologia",
      "Licenciatura En Astronomia",
      "Licenciatura En Fisica",
      "Licenciatura En Historia",
      "Licenciatura En Letras Mencion Literatura",
      "Licenciatura En Trabajo Social",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Dietetica",
      "Nutricion Y Dietetica",
      "Obstetricia",
      "Odontologia",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Ingles Para La Enseñanza Basica Y Media",
      "Periodismo",
      "Programa De Pedagogia En Educacion Media Para Licenciados (plan Especial)",
      "Psicologia",
      "Psicopedagogia",
      "Publicidad",
      "Quimica",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Administracion De Ecoturismo",
      "Arquitectura",
      "Artes Visuales",
      "Bachillerato En Ciencias",
      "Bachillerato En Humanidades",
      "Biologia",
      "Biologia Marina",
      "Bioquimica",
      "Contador Auditor",
      "Derecho",
      "Diseño De Juegos Digitales",
      "Diseño De Vestuario Y Textil",
      "Diseño Grafico",
      "Educacion General Basica Con Mencion En Orientacion Y Convivencia Escolar",
      "Educacion Musical Para La Educacion Preescolar Y Basica",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil En Metalurgia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion Hotelera Internacional",
      "Ingenieria En Automatizacion Y Robotica",
      "Ingenieria En Biotecnologia",
      "Ingenieria En Computacion E Informatica",
      "Ingenieria En Computacion E Informatica Ejecutivo",
      "Ingenieria En Construccion",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria En Logistica Y Transporte",
      "Ingenieria En Seguridad Y Prevencion De Riesgos",
      "Ingenieria En Turismo Y Hoteleria",
      "Ingenieria Fisica",
      "Ingenieria Industrial",
      "Ingeniero En Marina Mercante",
      "Kinesiologia",
      "Licenciatura En Astronomia",
      "Licenciatura En Fisica",
      "Licenciatura En Historia",
      "Licenciatura En Letras Mencion Literatura",
      "Licenciatura En Trabajo Social",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Dietetica",
      "Nutricion Y Dietetica",
      "Obstetricia",
      "Odontologia",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Ingles Para La Enseñanza Basica Y Media",
      "Periodismo",
      "Programa De Pedagogia En Educacion Media Para Licenciados (plan Especial)",
      "Psicologia",
      "Psicopedagogia",
      "Publicidad",
      "Quimica",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Arturo Prat",
    carreras: [
      "Agronomia",
      "Arquitectura",
      "Biologia Marina",
      "Contador Publico Y Auditor",
      "Derecho",
      "Educacion Parvularia",
      "Educacion Parvularia Intercultural",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Industrial Mencion Gestion",
      "Ingenieria Civil Metalurgica",
      "Ingenieria Comercial",
      "Ingenieria Comercial Mencion Administracion De Empresas",
      "Ingenieria Comercial Mencion Control De Gestion",
      "Ingenieria De Ejecucion Agricola",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Administracion Empresas",
      "Ingenieria De Ejecucion En Comercio Internacional",
      "Ingenieria De Ejecucion En Control De Gestion",
      "Ingenieria De Ejecucion En Prevencion De Riesgos",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria En Administracion Logistica",
      "Ingenieria En Biotecnologia",
      "Ingenieria En Informacion Y Control De Gestion",
      "Kinesiologia",
      "Odontologia",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Fisica, Para El Pre Escolar, Basica Y Media",
      "Pedagogia En Ingles Para Enseñanza Pre-escolar, Basica Y Media",
      "Pedagogia En Ingles Para Enseñanza Pre-escolar, Basica Y Media Con Bachiller En Lengua Inglesa Y Licenciatura En Educacion",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematicas Y Fisica",
      "Psicologia",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnico De Nivel Superior Actividad Fisica Y Del Deporte",
      "Tecnico De Nivel Superior Administracion De Empresas Marketing Y Gestion Comercial",
      "Tecnico De Nivel Superior Administracion Y Gestion De Personal",
      "Tecnico De Nivel Superior Agricola",
      "Tecnico De Nivel Superior Agropecuario",
      "Tecnico De Nivel Superior Analisis Quimico",
      "Tecnico De Nivel Superior Asistente En Educacion De Parvulos",
      "Tecnico De Nivel Superior Comercio Exterior",
      "Tecnico De Nivel Superior Computacion E Informatica",
      "Tecnico De Nivel Superior Electricidad Y Electronica Industrial",
      "Tecnico De Nivel Superior Electrico Instrumentista Mantenedor Plantas Mineras",
      "Tecnico De Nivel Superior En Acuicultura",
      "Tecnico De Nivel Superior Enfermeria",
      "Tecnico De Nivel Superior Geologia",
      "Tecnico De Nivel Superior Mecanico Mantenedor De Maquinaria Pesada",
      "Tecnico De Nivel Superior Mineria Metalurgica",
      "Tecnico De Nivel Superior Operaciones Mina",
      "Tecnico De Nivel Superior Traductor Bilingüe Ingles-español",
      "Trabajo Social",
      "Traduccion Ingles Castellano Ingles Mencion Frances",
      "Agronomia",
      "Arquitectura",
      "Biologia Marina",
      "Contador Publico Y Auditor",
      "Derecho",
      "Educacion Parvularia",
      "Educacion Parvularia Intercultural",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Industrial Mencion Gestion",
      "Ingenieria Civil Metalurgica",
      "Ingenieria Comercial",
      "Ingenieria Comercial Mencion Administracion De Empresas",
      "Ingenieria Comercial Mencion Control De Gestion",
      "Ingenieria De Ejecucion Agricola",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Administracion Empresas",
      "Ingenieria De Ejecucion En Comercio Internacional",
      "Ingenieria De Ejecucion En Control De Gestion",
      "Ingenieria De Ejecucion En Prevencion De Riesgos",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria En Administracion Logistica",
      "Ingenieria En Biotecnologia",
      "Ingenieria En Informacion Y Control De Gestion",
      "Kinesiologia",
      "Odontologia",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Fisica, Para El Pre Escolar, Basica Y Media",
      "Pedagogia En Ingles Para Enseñanza Pre-escolar, Basica Y Media",
      "Pedagogia En Ingles Para Enseñanza Pre-escolar, Basica Y Media Con Bachiller En Lengua Inglesa Y Licenciatura En Educacion",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematicas Y Fisica",
      "Psicologia",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnico De Nivel Superior Actividad Fisica Y Del Deporte",
      "Tecnico De Nivel Superior Administracion De Empresas Marketing Y Gestion Comercial",
      "Tecnico De Nivel Superior Administracion Y Gestion De Personal",
      "Tecnico De Nivel Superior Agricola",
      "Tecnico De Nivel Superior Agropecuario",
      "Tecnico De Nivel Superior Analisis Quimico",
      "Tecnico De Nivel Superior Asistente En Educacion De Parvulos",
      "Tecnico De Nivel Superior Comercio Exterior",
      "Tecnico De Nivel Superior Computacion E Informatica",
      "Tecnico De Nivel Superior Electricidad Y Electronica Industrial",
      "Tecnico De Nivel Superior Electrico Instrumentista Mantenedor Plantas Mineras",
      "Tecnico De Nivel Superior En Acuicultura",
      "Tecnico De Nivel Superior Enfermeria",
      "Tecnico De Nivel Superior Geologia",
      "Tecnico De Nivel Superior Mecanico Mantenedor De Maquinaria Pesada",
      "Tecnico De Nivel Superior Mineria Metalurgica",
      "Tecnico De Nivel Superior Operaciones Mina",
      "Tecnico De Nivel Superior Traductor Bilingüe Ingles-español",
      "Trabajo Social",
      "Traduccion Ingles Castellano Ingles Mencion Frances",
    ],
  },
  {
    institucion: "Universidad Austral De Chile",
    carreras: [
      "Administracion De Empresas De Turismo",
      "Administracion Publica",
      "Agronomia",
      "Antropologia",
      "Arqueologia",
      "Arquitectura",
      "Artes Musicales Y Sonoras",
      "Auditoria",
      "Bachillerato En Ciencias De La Ingenieria",
      "Bachillerato En Ciencias De La Ingenieria Plan Comun",
      "Bachillerato En Ciencias Y Recursos Naturales",
      "Biologia Marina",
      "Bioquimica",
      "Creacion Audiovisual",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Fonoaudiologia",
      "Geografia",
      "Geologia",
      "Ingenieria Ambiental",
      "Ingenieria Civil Acustica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Alimentos",
      "Ingenieria En Conservacion De Recursos Naturales - Ingenieria Forestal",
      "Ingenieria En Construccion",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria Naval",
      "Interpretacion Musical",
      "Kinesiologia",
      "Licenciatura En Artes Visuales",
      "Licenciatura En Ciencias Con Mencion",
      "Medicina",
      "Medicina Veterinaria",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Comunicacion En Lengua Inglesa",
      "Pedagogia En Educacion Basica Con Menciones",
      "Pedagogia En Educacion Diferencial Con Mencion",
      "Pedagogia En Educacion Fisica Deportes Y Recreacion",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Lenguaje Y Comunicacion",
      "Pedagogia En Matematicas",
      "Periodismo",
      "Programa De Formacion De Profesores Para Enseñanza Media",
      "Programa De Formacion De Profesores Para La Educacion Tecnico Profesional",
      "Psicologia",
      "Quimica Y Farmacia",
      "Tecnico Universitario Asistente Ejecutivo Y De Gestion",
      "Tecnico Universitario En Administracion Contable Y Financiera",
      "Tecnico Universitario En Construccion Y Obras Civiles",
      "Tecnico Universitario En Mantenimiento Industrial",
      "Tecnico Universitario En Produccion Agropecuaria",
      "Tecnico Universitario En Salmonicultura",
      "Tecnico Universitario En Turismo De Naturaleza",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Administracion De Empresas De Turismo",
      "Administracion Publica",
      "Agronomia",
      "Antropologia",
      "Arqueologia",
      "Arquitectura",
      "Artes Musicales Y Sonoras",
      "Auditoria",
      "Bachillerato En Ciencias De La Ingenieria",
      "Bachillerato En Ciencias De La Ingenieria Plan Comun",
      "Bachillerato En Ciencias Y Recursos Naturales",
      "Biologia Marina",
      "Bioquimica",
      "Creacion Audiovisual",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Fonoaudiologia",
      "Geografia",
      "Geologia",
      "Ingenieria Ambiental",
      "Ingenieria Civil Acustica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Alimentos",
      "Ingenieria En Conservacion De Recursos Naturales - Ingenieria Forestal",
      "Ingenieria En Construccion",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria Naval",
      "Interpretacion Musical",
      "Kinesiologia",
      "Licenciatura En Artes Visuales",
      "Licenciatura En Ciencias Con Mencion",
      "Medicina",
      "Medicina Veterinaria",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Comunicacion En Lengua Inglesa",
      "Pedagogia En Educacion Basica Con Menciones",
      "Pedagogia En Educacion Diferencial Con Mencion",
      "Pedagogia En Educacion Fisica Deportes Y Recreacion",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Lenguaje Y Comunicacion",
      "Pedagogia En Matematicas",
      "Periodismo",
      "Programa De Formacion De Profesores Para Enseñanza Media",
      "Programa De Formacion De Profesores Para La Educacion Tecnico Profesional",
      "Psicologia",
      "Quimica Y Farmacia",
      "Tecnico Universitario Asistente Ejecutivo Y De Gestion",
      "Tecnico Universitario En Administracion Contable Y Financiera",
      "Tecnico Universitario En Construccion Y Obras Civiles",
      "Tecnico Universitario En Mantenimiento Industrial",
      "Tecnico Universitario En Produccion Agropecuaria",
      "Tecnico Universitario En Salmonicultura",
      "Tecnico Universitario En Turismo De Naturaleza",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Autonoma De Chile",
    carreras: [
      "Administracion Publica",
      "Arquitectura",
      "Auditoria E Ingenieria En Control De Gestion",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria En Administracion",
      "Ingenieria En Construccion",
      "Kinesiologia",
      "Licenciatura En Artes Visuales",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Educacion Basica Con Mencion",
      "Pedagogia En Educacion Diferencial Con Mencion En Accesibilidad Al Aprendizaje Y La Participacion",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia, Geografia Y Ciencias Sociales",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Periodismo",
      "Psicologia",
      "Publicista Y Comunicacion Integral",
      "Quimica Y Farmacia",
      "Relaciones Publicas Y Comunicacion Estrategica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Administracion Publica",
      "Arquitectura",
      "Auditoria E Ingenieria En Control De Gestion",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria En Administracion",
      "Ingenieria En Construccion",
      "Kinesiologia",
      "Licenciatura En Artes Visuales",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Educacion Basica Con Mencion",
      "Pedagogia En Educacion Diferencial Con Mencion En Accesibilidad Al Aprendizaje Y La Participacion",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia, Geografia Y Ciencias Sociales",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Periodismo",
      "Psicologia",
      "Publicista Y Comunicacion Integral",
      "Quimica Y Farmacia",
      "Relaciones Publicas Y Comunicacion Estrategica",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Bernardo Ohiggins",
    carreras: [
      "Contador Auditor",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Geografia",
      "Ingenieria Civil En Medioambiente Y Sustentabilidad",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria Comercial Programa De Prosecucion De Estudios",
      "Ingenieria En Control De Gestion",
      "Ingenieria En Geomensura Y Cartografia",
      "Ingenieria En Geomensura Y Cartografia Programa De Prosecucion De Estudios",
      "Ingenieria En Informatica",
      "Ingenieria En Realidad Virtual Y Diseño De Juegos Digitales",
      "Kinesiologia",
      "Licenciatura En Ciencias De La Educacion",
      "Licenciatura En Ciencias De La Salud",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Pedagogia En Educacion Diferencial Con Mencion",
      "Pedagogia En Educacion Fisica, Deporte Y Recreacion",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Geografia En Enseñanza Media",
      "Pedagogia En Ingles En Enseñanza Basica Y Media",
      "Periodismo",
      "Psicologia",
      "Quimica Y Farmacia",
      "Tecnologia Medica Con Mencion",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Contador Auditor",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Geografia",
      "Ingenieria Civil En Medioambiente Y Sustentabilidad",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria Comercial Programa De Prosecucion De Estudios",
      "Ingenieria En Control De Gestion",
      "Ingenieria En Geomensura Y Cartografia",
      "Ingenieria En Geomensura Y Cartografia Programa De Prosecucion De Estudios",
      "Ingenieria En Informatica",
      "Ingenieria En Realidad Virtual Y Diseño De Juegos Digitales",
      "Kinesiologia",
      "Licenciatura En Ciencias De La Educacion",
      "Licenciatura En Ciencias De La Salud",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Pedagogia En Educacion Diferencial Con Mencion",
      "Pedagogia En Educacion Fisica, Deporte Y Recreacion",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Geografia En Enseñanza Media",
      "Pedagogia En Ingles En Enseñanza Basica Y Media",
      "Periodismo",
      "Psicologia",
      "Quimica Y Farmacia",
      "Tecnologia Medica Con Mencion",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Bolivariana",
    carreras: [
      "Derecho",
      "Enfermeria",
      "Ingenieria Civil En Minas Mencion Seguridad Minera - Continuidad",
      "Ingenieria Civil Industrial Mencion Gestion De Operaciones - Continuidad",
      "Ingenieria Comercial (prosecucion)",
      "Ingenieria Comercial (prosecucion) Para Prof.",
      "Ingenieria Comercial Mencion Gestion De Empresas Para Profesionales Del Area - Continuidad",
      "Ingenieria Comercial Mencion Gestion De Empresas Para Tns Del Area - Continuidad",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion De Empresas (prosecucion)",
      "Ingenieria En Administracion Mencion Gestion De Empresas - Continuidad",
      "Ingenieria En Construccion Mencion Gestion De Obras - Continuidad",
      "Ingenieria En Prevencion De Riesgos Mencion Sistemas De Gestion - Continuidad",
      "Ingenieria Industrial Mencion Gestion De Operaciones- Continuidad",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Psicologia",
      "Psicopedagogia",
      "Psicopedagogia (prosecucion)",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion En Salud",
      "Tecnico De Nivel Superior En Administracion Mencion Gestion Municipal",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Asistente De La Educacion",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Logistica Y Operaciones Industriales",
      "Tecnico De Nivel Superior En Masoterapia",
      "Tecnico De Nivel Superior En Odontologia",
      "Tecnico De Nivel Superior En Podologia",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico Universitario En Deporte Y Recreacion",
      "Tecnico Universitario En Educacion De Parvulos",
      "Tecnico Universitario En Educacion Diferencial",
      "Trabajo Social",
      "Trabajo Social (plan De Continuidad)",
      "Trabajo Social (prosecucion)",
      "Trabajo Social - Continuidad",
      "Derecho",
      "Enfermeria",
      "Ingenieria Civil En Minas Mencion Seguridad Minera - Continuidad",
      "Ingenieria Civil Industrial Mencion Gestion De Operaciones - Continuidad",
      "Ingenieria Comercial (prosecucion)",
      "Ingenieria Comercial (prosecucion) Para Prof.",
      "Ingenieria Comercial Mencion Gestion De Empresas Para Profesionales Del Area - Continuidad",
      "Ingenieria Comercial Mencion Gestion De Empresas Para Tns Del Area - Continuidad",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion De Empresas (prosecucion)",
      "Ingenieria En Administracion Mencion Gestion De Empresas - Continuidad",
      "Ingenieria En Construccion Mencion Gestion De Obras - Continuidad",
      "Ingenieria En Prevencion De Riesgos Mencion Sistemas De Gestion - Continuidad",
      "Ingenieria Industrial Mencion Gestion De Operaciones- Continuidad",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Psicologia",
      "Psicopedagogia",
      "Psicopedagogia (prosecucion)",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Administracion En Salud",
      "Tecnico De Nivel Superior En Administracion Mencion Gestion Municipal",
      "Tecnico De Nivel Superior En Administracion Publica",
      "Tecnico De Nivel Superior En Asistente De La Educacion",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Logistica Y Operaciones Industriales",
      "Tecnico De Nivel Superior En Masoterapia",
      "Tecnico De Nivel Superior En Odontologia",
      "Tecnico De Nivel Superior En Podologia",
      "Tecnico De Nivel Superior En Trabajo Social",
      "Tecnico Universitario En Deporte Y Recreacion",
      "Tecnico Universitario En Educacion De Parvulos",
      "Tecnico Universitario En Educacion Diferencial",
      "Trabajo Social",
      "Trabajo Social (plan De Continuidad)",
      "Trabajo Social (prosecucion)",
      "Trabajo Social - Continuidad",
    ],
  },
  {
    institucion: "Universidad Catolica Cardenal Raul Silva Henriquez",
    carreras: [
      "Auditoria",
      "Bachillerato En Ciencias Y Humanidades",
      "Bachillerato En Filosofia",
      "Contador Publico Y Auditor",
      "Derecho",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Comercial",
      "Ingenieria En Administracion",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Kinesiologia",
      "Licenciatura En Educacion Para Titulados Y Profesionales",
      "Pedagogia En Castellano",
      "Pedagogia En Educacion Artistica",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Tecnica Y Formacion Profesional",
      "Pedagogia En Filosofia",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematicas E Informatica Educativa",
      "Pedagogia En Religion En Educacion Basica Y Media",
      "Psicologia",
      "Sociologia",
      "Trabajo Social",
      "Auditoria",
      "Bachillerato En Ciencias Y Humanidades",
      "Bachillerato En Filosofia",
      "Contador Publico Y Auditor",
      "Derecho",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Comercial",
      "Ingenieria En Administracion",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Kinesiologia",
      "Licenciatura En Educacion Para Titulados Y Profesionales",
      "Pedagogia En Castellano",
      "Pedagogia En Educacion Artistica",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Tecnica Y Formacion Profesional",
      "Pedagogia En Filosofia",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematicas E Informatica Educativa",
      "Pedagogia En Religion En Educacion Basica Y Media",
      "Psicologia",
      "Sociologia",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Catolica De La Santisima Concepcion",
    carreras: [
      "Biologia Marina",
      "Contador Auditor",
      "Direccion Audiovisual Y Multimedia",
      "Educacion De Parvulos",
      "Ingenieria Civil",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Geologica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Comercial",
      "Ingenieria En Informacion Y Control De Gestion",
      "Kinesiologia",
      "Licenciatura En Ciencias Religiosas Y Estudios Eclesiasticos",
      "Licenciatura En Derecho",
      "Licenciatura En Enfermeria",
      "Licenciatura En Filosofia",
      "Licenciatura En Historia",
      "Licenciatura En Medicina",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Media En Biologia Y Ciencias Naturales",
      "Pedagogia En Educacion Media En Ingles",
      "Pedagogia En Educacion Media En Lenguaje Y Comunicacion",
      "Pedagogia En Educacion Media En Matematica",
      "Periodismo",
      "Programa De Continuidad De Estudios En Construccion Civil",
      "Programa De Continuidad De Estudios En Ingenieria De Ejecucion En Administracion De Empresas",
      "Programa De Continuidad De Estudios En Ingenieria De Ejecucion Industrial",
      "Programa De Formacion Pedagogica",
      "Quimica Ambiental",
      "Tecnico Universitario Agropecuario",
      "Tecnico Universitario En Actividad Fisica Y Salud",
      "Tecnico Universitario En Administracion",
      "Tecnico Universitario En Alimentos",
      "Tecnico Universitario En Automatizacion Industrial",
      "Tecnico Universitario En Construccion",
      "Tecnico Universitario En Educacion De Parvulos",
      "Tecnico Universitario En Enfermeria",
      "Tecnico Universitario En Gastronomia Intercultural",
      "Tecnico Universitario En Logistica",
      "Tecnico Universitario En Refrigeracion Y Climatizacion Industrial",
      "Tecnico Universitario En Topografia",
      "Tecnologia Medica",
      "Trabajo Social",
      "Biologia Marina",
      "Contador Auditor",
      "Direccion Audiovisual Y Multimedia",
      "Educacion De Parvulos",
      "Ingenieria Civil",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Geologica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Comercial",
      "Ingenieria En Informacion Y Control De Gestion",
      "Kinesiologia",
      "Licenciatura En Ciencias Religiosas Y Estudios Eclesiasticos",
      "Licenciatura En Derecho",
      "Licenciatura En Enfermeria",
      "Licenciatura En Filosofia",
      "Licenciatura En Historia",
      "Licenciatura En Medicina",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Media En Biologia Y Ciencias Naturales",
      "Pedagogia En Educacion Media En Ingles",
      "Pedagogia En Educacion Media En Lenguaje Y Comunicacion",
      "Pedagogia En Educacion Media En Matematica",
      "Periodismo",
      "Programa De Continuidad De Estudios En Construccion Civil",
      "Programa De Continuidad De Estudios En Ingenieria De Ejecucion En Administracion De Empresas",
      "Programa De Continuidad De Estudios En Ingenieria De Ejecucion Industrial",
      "Programa De Formacion Pedagogica",
      "Quimica Ambiental",
      "Tecnico Universitario Agropecuario",
      "Tecnico Universitario En Actividad Fisica Y Salud",
      "Tecnico Universitario En Administracion",
      "Tecnico Universitario En Alimentos",
      "Tecnico Universitario En Automatizacion Industrial",
      "Tecnico Universitario En Construccion",
      "Tecnico Universitario En Educacion De Parvulos",
      "Tecnico Universitario En Enfermeria",
      "Tecnico Universitario En Gastronomia Intercultural",
      "Tecnico Universitario En Logistica",
      "Tecnico Universitario En Refrigeracion Y Climatizacion Industrial",
      "Tecnico Universitario En Topografia",
      "Tecnologia Medica",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Catolica De Temuco",
    carreras: [
      "Administracion Publica",
      "Agronomia",
      "Antropologia",
      "Arqueologia",
      "Arquitectura",
      "Bachiller En Ciencias Y Humanidades",
      "Ciencia Politica",
      "Contador Auditor",
      "Derecho",
      "Diseño",
      "Educacion De Parvulos",
      "Fonoaudiologia",
      "Geografia",
      "Geologia",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Geologica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Recursos Naturales Renovables",
      "Kinesiologia",
      "Licenciatura En Artes Visuales",
      "Licenciatura En Filosofia",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Basica Con Mencion",
      "Pedagogia En Educacion Basica Intercultural En Contexto Mapuche",
      "Pedagogia En Educacion Diferencial Con Especialidad En N.e.e.t. Y Deficiencia Mental",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Historia, Geografia Y Ciencias Sociales",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Religion Modalidad Paralela",
      "Pedagogia Media En Ciencias Naturales Y Biologia",
      "Pedagogia Media En Matematica",
      "Plan Academico De Titulacion Especial De Agronomia, Para Tecnicos Agricolas",
      "Plan Especial Conducente Al Titulo De Ingeniero Forestal",
      "Programa Especial De Ingenieria Informatica Para Tecnicos",
      "Programa Especial De Titulacion De Ingenieria Comercial",
      "Programa Especial De Titulacion De Ingenieria Comercial Para Tecnicos",
      "Programa Especial De Titulacion De Ingenieria En Acuicultura",
      "Programa Especial De Titulacion Pedagogia Media En Artes Visuales",
      "Psicologia",
      "Sociologia",
      "Tecnico Universitario En Acuicultura",
      "Tecnico Universitario En Administracion De Empresas",
      "Tecnico Universitario En Construccion Y Obras Civiles",
      "Tecnico Universitario En Educacion Parvularia",
      "Tecnico Universitario En Preparacion Fisica",
      "Tecnico Universitario En Produccion Agropecuaria",
      "Tecnico Universitario En Redes Y Telecomunicaciones",
      "Tecnico Universitario En Topografia Y Geomensura",
      "Tecnico Universitario En Turismo",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Traduccion Ingles-español",
      "Administracion Publica",
      "Agronomia",
      "Antropologia",
      "Arqueologia",
      "Arquitectura",
      "Bachiller En Ciencias Y Humanidades",
      "Ciencia Politica",
      "Contador Auditor",
      "Derecho",
      "Diseño",
      "Educacion De Parvulos",
      "Fonoaudiologia",
      "Geografia",
      "Geologia",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Geologica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Recursos Naturales Renovables",
      "Kinesiologia",
      "Licenciatura En Artes Visuales",
      "Licenciatura En Filosofia",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Basica Con Mencion",
      "Pedagogia En Educacion Basica Intercultural En Contexto Mapuche",
      "Pedagogia En Educacion Diferencial Con Especialidad En N.e.e.t. Y Deficiencia Mental",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Historia, Geografia Y Ciencias Sociales",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Religion Modalidad Paralela",
      "Pedagogia Media En Ciencias Naturales Y Biologia",
      "Pedagogia Media En Matematica",
      "Plan Academico De Titulacion Especial De Agronomia, Para Tecnicos Agricolas",
      "Plan Especial Conducente Al Titulo De Ingeniero Forestal",
      "Programa Especial De Ingenieria Informatica Para Tecnicos",
      "Programa Especial De Titulacion De Ingenieria Comercial",
      "Programa Especial De Titulacion De Ingenieria Comercial Para Tecnicos",
      "Programa Especial De Titulacion De Ingenieria En Acuicultura",
      "Programa Especial De Titulacion Pedagogia Media En Artes Visuales",
      "Psicologia",
      "Sociologia",
      "Tecnico Universitario En Acuicultura",
      "Tecnico Universitario En Administracion De Empresas",
      "Tecnico Universitario En Construccion Y Obras Civiles",
      "Tecnico Universitario En Educacion Parvularia",
      "Tecnico Universitario En Preparacion Fisica",
      "Tecnico Universitario En Produccion Agropecuaria",
      "Tecnico Universitario En Redes Y Telecomunicaciones",
      "Tecnico Universitario En Topografia Y Geomensura",
      "Tecnico Universitario En Turismo",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Traduccion Ingles-español",
    ],
  },
  {
    institucion: "Universidad Catolica Del Maule",
    carreras: [
      "Administracion Publica",
      "Agronomia",
      "Arquitectura",
      "Auditoria",
      "Construccion Civil",
      "Derecho",
      "Educacion Parvularia Mencion: Ingles, Lenguaje Y Comunicacion Inicial, Matematica Inicial",
      "Enfermeria",
      "Ingenieria Civil",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria En Biotecnologia",
      "Ingenieria En Construccion",
      "Ingenieria En Estadistica",
      "Ingenieria En Recursos Naturales",
      "Kinesiologia",
      "Medicina",
      "Nutricion Y Dietetica",
      "Pedagogia En Ciencias Mencion En Biologia, Fisica, Quimica",
      "Pedagogia En Educacion Especial Con Mencion",
      "Pedagogia En Educacion Fisica Con Mencion",
      "Pedagogia En Educacion General Basica Mencion Matematica, Ciencias Sociales, Ciencias Naturales, Lenguaje Y Comunicacion",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematica Y Computacion",
      "Pedagogia En Religion Y Filosofia",
      "Psicologia",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnologia Medica",
      "Trabajo Social",
      "Administracion Publica",
      "Agronomia",
      "Arquitectura",
      "Auditoria",
      "Construccion Civil",
      "Derecho",
      "Educacion Parvularia Mencion: Ingles, Lenguaje Y Comunicacion Inicial, Matematica Inicial",
      "Enfermeria",
      "Ingenieria Civil",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria En Biotecnologia",
      "Ingenieria En Construccion",
      "Ingenieria En Estadistica",
      "Ingenieria En Recursos Naturales",
      "Kinesiologia",
      "Medicina",
      "Nutricion Y Dietetica",
      "Pedagogia En Ciencias Mencion En Biologia, Fisica, Quimica",
      "Pedagogia En Educacion Especial Con Mencion",
      "Pedagogia En Educacion Fisica Con Mencion",
      "Pedagogia En Educacion General Basica Mencion Matematica, Ciencias Sociales, Ciencias Naturales, Lenguaje Y Comunicacion",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Pedagogia En Matematica Y Computacion",
      "Pedagogia En Religion Y Filosofia",
      "Psicologia",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnologia Medica",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Catolica Del Norte",
    carreras: [
      "Analista Quimico",
      "Arquitectura",
      "Biologia Marina",
      "Contador Auditor/contador Publico",
      "Derecho",
      "Enfermeria",
      "Geologia",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil En Gestion De La Construccion",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Metalurgica",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria En Acuicultura",
      "Ingenieria En Computacion E Informatica",
      "Ingenieria En Construccion",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria En Metalurgia",
      "Ingenieria En Prevencion De Riesgos Y Medioambiente",
      "Ingenieria En Procesos Quimicos",
      "Ingenieria En Tecnologias De Informacion",
      "Kinesiologia",
      "Licenciatura En Ciencias Religiosas",
      "Licenciatura En Ciencias Religiosas Y Eclesiasticas",
      "Licenciatura En Fisica Mencion Astronomia",
      "Licenciatura En Matematica",
      "Medicina",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Basica Con Especializacion",
      "Pedagogia En Filosofia Y Religion",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica En Educacion Media",
      "Periodismo",
      "Psicologia",
      "Quimica Industrial",
      "Quimica Y Farmacia",
      "Analista Quimico",
      "Arquitectura",
      "Biologia Marina",
      "Contador Auditor/contador Publico",
      "Derecho",
      "Enfermeria",
      "Geologia",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil En Gestion De La Construccion",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Metalurgica",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria En Acuicultura",
      "Ingenieria En Computacion E Informatica",
      "Ingenieria En Construccion",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria En Metalurgia",
      "Ingenieria En Prevencion De Riesgos Y Medioambiente",
      "Ingenieria En Procesos Quimicos",
      "Ingenieria En Tecnologias De Informacion",
      "Kinesiologia",
      "Licenciatura En Ciencias Religiosas",
      "Licenciatura En Ciencias Religiosas Y Eclesiasticas",
      "Licenciatura En Fisica Mencion Astronomia",
      "Licenciatura En Matematica",
      "Medicina",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Basica Con Especializacion",
      "Pedagogia En Filosofia Y Religion",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica En Educacion Media",
      "Periodismo",
      "Psicologia",
      "Quimica Industrial",
      "Quimica Y Farmacia",
    ],
  },
  {
    institucion: "Universidad Central De Chile",
    carreras: [
      "Administracion Publica",
      "Arquitectura",
      "Arquitectura Del Paisaje",
      "Ciencia Politica",
      "Contabilidad Y Auditoria",
      "Derecho",
      "Educacion Parvularia",
      "Enfermeria",
      "Geologia",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil En Obras Civiles Vespertino",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Construccion",
      "Ingenieria En Construccion Vespertino",
      "Ingenieria En Control De Gestion",
      "Kinesiologia",
      "Licenciatura En Astronomia",
      "Licenciatura En Ciencias De La Educacion",
      "Licenciatura En Trabajo Social",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Diferencial Con Mencion En Integracion Escolar (segundo Titulo)",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Ingles",
      "Pedagogia En Lenguaje Y Comunicacion",
      "Pedagogia En Matematica Y Estadistica",
      "Periodismo",
      "Personal Trainer",
      "Programa De Segunda Titulacion En Quiropraxia",
      "Prosecucion De Estudios A Ing. Civil Industrial",
      "Prosecucion De Estudios A Ingenieria Civil Industrial (plan Nivelacion)",
      "Prosecucion De Estudios De Contador Auditor",
      "Prosecucion De Estudios De Ingenieria Comercial",
      "Prosecucion De Estudios Ingenieria Comercial",
      "Psicologia",
      "Publicidad",
      "Sociologia",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Construccion",
      "Tecnico De Nivel Superior En Contabilidad General",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Electricidad Y Electronica Industrial",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Mineria",
      "Tecnico De Nivel Superior En Rehabilitacion De Personas Con Dependencia A Drogas",
      "Tecnologia Medica Con Menciones",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Administracion Publica",
      "Arquitectura",
      "Arquitectura Del Paisaje",
      "Ciencia Politica",
      "Contabilidad Y Auditoria",
      "Derecho",
      "Educacion Parvularia",
      "Enfermeria",
      "Geologia",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil En Obras Civiles Vespertino",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Construccion",
      "Ingenieria En Construccion Vespertino",
      "Ingenieria En Control De Gestion",
      "Kinesiologia",
      "Licenciatura En Astronomia",
      "Licenciatura En Ciencias De La Educacion",
      "Licenciatura En Trabajo Social",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Diferencial Con Mencion En Integracion Escolar (segundo Titulo)",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Ingles",
      "Pedagogia En Lenguaje Y Comunicacion",
      "Pedagogia En Matematica Y Estadistica",
      "Periodismo",
      "Personal Trainer",
      "Programa De Segunda Titulacion En Quiropraxia",
      "Prosecucion De Estudios A Ing. Civil Industrial",
      "Prosecucion De Estudios A Ingenieria Civil Industrial (plan Nivelacion)",
      "Prosecucion De Estudios De Contador Auditor",
      "Prosecucion De Estudios De Ingenieria Comercial",
      "Prosecucion De Estudios Ingenieria Comercial",
      "Psicologia",
      "Publicidad",
      "Sociologia",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Construccion",
      "Tecnico De Nivel Superior En Contabilidad General",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Electricidad Y Electronica Industrial",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Mineria",
      "Tecnico De Nivel Superior En Rehabilitacion De Personas Con Dependencia A Drogas",
      "Tecnologia Medica Con Menciones",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad De Aconcagua",
    carreras: [
      "Agronomia (plan De Continuidad)",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Industrial (plan De Continuidad)",
      "Ingenieria Comercial (plan De Continuidad)",
      "Ingenieria En Automatizacion Y Control Industrial (plan De Continuidad)",
      "Ingenieria En Construccion (plan De Continuidad)",
      "Ingenieria En Electricidad (plan De Continuidad)",
      "Ingenieria En Mecanica (plan De Continuidad)",
      "Ingenieria En Minas (plan De Continuidad)",
      "Ingenieria Industrial (plan De Continuidad)",
      "Kinesiologia",
      "Licenciatura En Trabajo Social",
      "Psicologia",
      "Tecnico De Nivel Superior En Administracion De Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion De Turismo",
      "Tecnico De Nivel Superior En Energias Renovables Y Eficiencias Energetica",
      "Tecnico De Nivel Superior En Mecanica Industrial",
      "Tecnico Nivel Superior En Administracion De Empresas",
      "Tecnico Nivel Superior En Agronomia",
      "Tecnico Nivel Superior En Automatizacion Y Control Industrial",
      "Tecnico Nivel Superior En Construccion",
      "Tecnico Nivel Superior En Electricidad",
      "Tecnico Nivel Superior En Enfermeria",
      "Tecnico Nivel Superior En Minas",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Trabajo Social (plan De Continuidad)",
      "Agronomia (plan De Continuidad)",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Industrial (plan De Continuidad)",
      "Ingenieria Comercial (plan De Continuidad)",
      "Ingenieria En Automatizacion Y Control Industrial (plan De Continuidad)",
      "Ingenieria En Construccion (plan De Continuidad)",
      "Ingenieria En Electricidad (plan De Continuidad)",
      "Ingenieria En Mecanica (plan De Continuidad)",
      "Ingenieria En Minas (plan De Continuidad)",
      "Ingenieria Industrial (plan De Continuidad)",
      "Kinesiologia",
      "Licenciatura En Trabajo Social",
      "Psicologia",
      "Tecnico De Nivel Superior En Administracion De Recursos Humanos",
      "Tecnico De Nivel Superior En Administracion De Turismo",
      "Tecnico De Nivel Superior En Energias Renovables Y Eficiencias Energetica",
      "Tecnico De Nivel Superior En Mecanica Industrial",
      "Tecnico Nivel Superior En Administracion De Empresas",
      "Tecnico Nivel Superior En Agronomia",
      "Tecnico Nivel Superior En Automatizacion Y Control Industrial",
      "Tecnico Nivel Superior En Construccion",
      "Tecnico Nivel Superior En Electricidad",
      "Tecnico Nivel Superior En Enfermeria",
      "Tecnico Nivel Superior En Minas",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Trabajo Social (plan De Continuidad)",
    ],
  },
  {
    institucion: "Universidad De Antofagasta",
    carreras: [
      "Administracion Publica",
      "Artes Escenicas",
      "Bachillerato En Ciencias",
      "Bachillerato En Ciencias De La Salud",
      "Bachillerato En Ciencias Sociales Y Artes",
      "Biologia Marina",
      "Bioquimica",
      "Derecho",
      "Diseño Grafico Mencion Diseño Estrategico",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Geomensura Y Geomatica",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil En Procesos Minerales",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial Mencion Negocios Mineros",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Electronica",
      "Ingenieria De Ejecucion En Minas",
      "Ingenieria De Ejecucion Mecanica",
      "Ingenieria En Biotecnologia",
      "Kinesiologia",
      "Medicina",
      "Musica",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Educacion Basica Menciones Lenguaje Y Comunicacion Y Matematica",
      "Pedagogia En Educacion Fisica Damas",
      "Pedagogia En Educacion Fisica Varones",
      "Pedagogia En Educacion Parvularia Mencion Necesidades Educativas Especiales",
      "Pedagogia En Lenguaje Y Comunicacion",
      "Pedagogia En Matematica",
      "Psicologia",
      "Quimica Ambiental",
      "Tecnico De Nivel Superior En Alimentacion",
      "Tecnico De Nivel Superior En Asistente De Parvulos",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Explotacion Minera",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior En Mantenimiento Industrial",
      "Tecnico Nivel Superior En Administracion De Empresas",
      "Tecnico Nivel Superior En Asistente De Parvulos",
      "Tecnico Nivel Superior En Metalurgia",
      "Tecnico Nivel Superior En Prevencion De Riesgos",
      "Tecnologia Medica",
      "Trabajo Social",
      "Administracion Publica",
      "Artes Escenicas",
      "Bachillerato En Ciencias",
      "Bachillerato En Ciencias De La Salud",
      "Bachillerato En Ciencias Sociales Y Artes",
      "Biologia Marina",
      "Bioquimica",
      "Derecho",
      "Diseño Grafico Mencion Diseño Estrategico",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Geomensura Y Geomatica",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil En Procesos Minerales",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial Mencion Negocios Mineros",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Electronica",
      "Ingenieria De Ejecucion En Minas",
      "Ingenieria De Ejecucion Mecanica",
      "Ingenieria En Biotecnologia",
      "Kinesiologia",
      "Medicina",
      "Musica",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Educacion Basica Menciones Lenguaje Y Comunicacion Y Matematica",
      "Pedagogia En Educacion Fisica Damas",
      "Pedagogia En Educacion Fisica Varones",
      "Pedagogia En Educacion Parvularia Mencion Necesidades Educativas Especiales",
      "Pedagogia En Lenguaje Y Comunicacion",
      "Pedagogia En Matematica",
      "Psicologia",
      "Quimica Ambiental",
      "Tecnico De Nivel Superior En Alimentacion",
      "Tecnico De Nivel Superior En Asistente De Parvulos",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Explotacion Minera",
      "Tecnico De Nivel Superior En Logistica",
      "Tecnico De Nivel Superior En Mantenimiento Industrial",
      "Tecnico Nivel Superior En Administracion De Empresas",
      "Tecnico Nivel Superior En Asistente De Parvulos",
      "Tecnico Nivel Superior En Metalurgia",
      "Tecnico Nivel Superior En Prevencion De Riesgos",
      "Tecnologia Medica",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Terapia Ocupacional",
    carreras: ["Terapia Ocupacional", "Terapia Ocupacional"],
  },
  {
    institucion: "Universidad De Artes, Ciencias Y Comunicacion - Uniacc",
    carreras: [
      "Administracion Publica",
      "Administracion Publica - Tecnico",
      "Arquitectura",
      "Artes Visuales",
      "Bibliotecologia Y Gestion De La Informacion",
      "Comunicacion Audiovisual Especialidad Cine",
      "Comunicacion Audiovisual Especialidad Guiones",
      "Comunicacion Audiovisual Especialidad Guiones Para Cine Y Television",
      "Comunicacion Audiovisual Especialidad Television",
      "Comunicacion Digital Especialidad Animacion Digital",
      "Comunicacion Digital Especialidad Diseño Y Desarrollo De Videojuegos",
      "Comunicacion Digital Especialidad Interactividad En Medios Digitales",
      "Comunicacion Multimedia Especialidad Animacion Digital Y Efectos Especiales",
      "Contador Auditor",
      "Contador Auditor - Profesional",
      "Contador Auditor - Tecnico",
      "Danza Y Coreografia",
      "Derecho",
      "Diseño De Imagen Especialidad Moda",
      "Diseño De Interiores Y Ambientes",
      "Diseño Grafico Especialidad Multimedia",
      "Diseño Grafico Y Multimedia",
      "Ingenieria Comercial",
      "Ingenieria Comercial - Profesional",
      "Ingenieria Comercial - Tecnico",
      "Ingenieria En Control De Gestion",
      "Ingenieria En Informatica E-business",
      "Ingenieria En Informatica Multimedia - Tecnico",
      "Ingenieria En Negocios Y Gestion De Empresas",
      "Ingenieria Informatica Multimedia",
      "Licenciatura En Trabajo Social",
      "Musica E Interpretacion",
      "Musica Y Composicion",
      "Musica Y Sonido",
      "Periodismo",
      "Programa Especial De Titulacion En Arquitectura",
      "Psicologia",
      "Publicidad",
      "Relaciones Publicas Corporativas",
      "Teatro Y Comunicacion Escenica",
      "Trabajo Social",
      "Traduccion E Interpretariado Bilingüe Ingles-español",
      "Administracion Publica",
      "Administracion Publica - Tecnico",
      "Arquitectura",
      "Artes Visuales",
      "Bibliotecologia Y Gestion De La Informacion",
      "Comunicacion Audiovisual Especialidad Cine",
      "Comunicacion Audiovisual Especialidad Guiones",
      "Comunicacion Audiovisual Especialidad Guiones Para Cine Y Television",
      "Comunicacion Audiovisual Especialidad Television",
      "Comunicacion Digital Especialidad Animacion Digital",
      "Comunicacion Digital Especialidad Diseño Y Desarrollo De Videojuegos",
      "Comunicacion Digital Especialidad Interactividad En Medios Digitales",
      "Comunicacion Multimedia Especialidad Animacion Digital Y Efectos Especiales",
      "Contador Auditor",
      "Contador Auditor - Profesional",
      "Contador Auditor - Tecnico",
      "Danza Y Coreografia",
      "Derecho",
      "Diseño De Imagen Especialidad Moda",
      "Diseño De Interiores Y Ambientes",
      "Diseño Grafico Especialidad Multimedia",
      "Diseño Grafico Y Multimedia",
      "Ingenieria Comercial",
      "Ingenieria Comercial - Profesional",
      "Ingenieria Comercial - Tecnico",
      "Ingenieria En Control De Gestion",
      "Ingenieria En Informatica E-business",
      "Ingenieria En Informatica Multimedia - Tecnico",
      "Ingenieria En Negocios Y Gestion De Empresas",
      "Ingenieria Informatica Multimedia",
      "Licenciatura En Trabajo Social",
      "Musica E Interpretacion",
      "Musica Y Composicion",
      "Musica Y Sonido",
      "Periodismo",
      "Programa Especial De Titulacion En Arquitectura",
      "Psicologia",
      "Publicidad",
      "Relaciones Publicas Corporativas",
      "Teatro Y Comunicacion Escenica",
      "Trabajo Social",
      "Traduccion E Interpretariado Bilingüe Ingles-español",
    ],
  },
  {
    institucion: "Universidad De Atacama",
    carreras: [
      "Construccion Civil Y Licenciatura En Construccion Civil",
      "Derecho",
      "Enfermeria Y Licenciatura En Enfermeria",
      "Geologia",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil En Metalurgia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Metalurgia",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Computacion",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Instrumentacion Y Automatizacion Industrial",
      "Ingenieria De Ejecucion En Mantenimiento Industrial",
      "Ingenieria De Ejecucion En Metalurgia",
      "Ingenieria De Ejecucion En Minas",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria Ejecucion En Minas",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Kinesiologia",
      "Licenciatura En Educacion Y Pedagogia En Educacion Fisica",
      "Licenciatura En Educacion Y Pedagogia En Educacion General Basica",
      "Licenciatura En Traductologia Y Traductor E Interprete Ingles-español",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Psicologia",
      "Tecnico Universitario Auditor Contable Y Tributario",
      "Tecnico Universitario En Administracion De Empresas",
      "Tecnico Universitario En Asistencia Judicial",
      "Tecnico Universitario En Automatizacion Industrial",
      "Tecnico Universitario En Electricidad",
      "Tecnico Universitario En Energias Renovables Y Eficiencia Energetica",
      "Tecnico Universitario En Instrumentacion Y Automatizacion Industrial",
      "Tecnico Universitario En Mantencion Mecanica De Equipos Industriales",
      "Tecnico Universitario En Metalurgia",
      "Tecnico Universitario En Minas",
      "Tecnico Universitario En Prevencion De Riesgos",
      "Tecnico Y Universitario Asistente De Geologia",
      "Trabajo Social",
      "Construccion Civil Y Licenciatura En Construccion Civil",
      "Derecho",
      "Enfermeria Y Licenciatura En Enfermeria",
      "Geologia",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil En Metalurgia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Metalurgia",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Computacion",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Instrumentacion Y Automatizacion Industrial",
      "Ingenieria De Ejecucion En Mantenimiento Industrial",
      "Ingenieria De Ejecucion En Metalurgia",
      "Ingenieria De Ejecucion En Minas",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria Ejecucion En Minas",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Kinesiologia",
      "Licenciatura En Educacion Y Pedagogia En Educacion Fisica",
      "Licenciatura En Educacion Y Pedagogia En Educacion General Basica",
      "Licenciatura En Traductologia Y Traductor E Interprete Ingles-español",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Psicologia",
      "Tecnico Universitario Auditor Contable Y Tributario",
      "Tecnico Universitario En Administracion De Empresas",
      "Tecnico Universitario En Asistencia Judicial",
      "Tecnico Universitario En Automatizacion Industrial",
      "Tecnico Universitario En Electricidad",
      "Tecnico Universitario En Energias Renovables Y Eficiencia Energetica",
      "Tecnico Universitario En Instrumentacion Y Automatizacion Industrial",
      "Tecnico Universitario En Mantencion Mecanica De Equipos Industriales",
      "Tecnico Universitario En Metalurgia",
      "Tecnico Universitario En Minas",
      "Tecnico Universitario En Prevencion De Riesgos",
      "Tecnico Y Universitario Asistente De Geologia",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad De Aysen",
    carreras: [
      "Agronomia",
      "Enfermeria",
      "Ingenieria Civil Industrial",
      "Ingenieria Forestal",
      "Obstetricia",
      "Psicologia",
      "Trabajo Social",
      "Agronomia",
      "Enfermeria",
      "Ingenieria Civil Industrial",
      "Ingenieria Forestal",
      "Obstetricia",
      "Psicologia",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad De Chile",
    carreras: [
      "Actuacion Teatral",
      "Administracion Publica",
      "Antropologia-arqueologia",
      "Arquitectura",
      "Biologia Con Mencion En Medio Ambiente",
      "Bioquimica",
      "Ciencia Politica",
      "Cine Y Television",
      "Contador Auditor",
      "Danza",
      "Derecho",
      "Diseño",
      "Diseño Teatral",
      "Enfermeria",
      "Estudios Internacionales",
      "Etapa Basica De La Licenciatura En Artes Con Mencion En Artes Plasticas",
      "Etapa Basica De La Licenciatura En Artes Con Mencion En Composicion",
      "Etapa Basica De La Licenciatura En Artes Con Mencion En Danza",
      "Etapa Basica De La Licenciatura En Artes Con Mencion En Interpretacion Musical",
      "Fonoaudiologia",
      "Geografia",
      "Geologia",
      "Ingenieria Agronomica",
      "Ingenieria Civil",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Biotecnologia",
      "Ingenieria Civil En Computacion",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria En Alimentos",
      "Ingenieria En Biotecnologia Molecular",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria En Recursos Naturales Renovables",
      "Ingenieria En Sonido",
      "Ingenieria Forestal",
      "Ingenieria Y Ciencias - Plan Comun",
      "Interpretacion Musical Con Especialidades",
      "Kinesiologia",
      "Licenciatura En Artes Con Mencion En Artes Visuales",
      "Licenciatura En Artes Con Mencion En Composicion",
      "Licenciatura En Ciencias Con Mencion En Astronomia, Geofisica O Fisica",
      "Licenciatura En Ciencias Con Mencion En Biologia",
      "Licenciatura En Ciencias Con Mencion En Fisica",
      "Licenciatura En Ciencias Con Mencion En Matematicas",
      "Licenciatura En Ciencias Con Mencion En Quimica",
      "Licenciatura En Filosofia",
      "Licenciatura En Historia",
      "Licenciatura En Lingüistica Y Literatura",
      "Licenciatura En Lingüistica Y Literatura Inglesas",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Media En Asignaturas Cientifico-humanistas",
      "Pedagogia En Educacion Media En Biologia Y Quimica",
      "Pedagogia En Educacion Media En Matematicas Y Fisica",
      "Pedagogia En Educacion Parvularia",
      "Periodismo",
      "Programa Academico De Bachillerato",
      "Psicologia",
      "Quimica",
      "Quimica Ambiental",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnologia Medica Con Mencion",
      "Teoria De La Musica",
      "Teoria E Historia Del Arte",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Actuacion Teatral",
      "Administracion Publica",
      "Antropologia-arqueologia",
      "Arquitectura",
      "Biologia Con Mencion En Medio Ambiente",
      "Bioquimica",
      "Ciencia Politica",
      "Cine Y Television",
      "Contador Auditor",
      "Danza",
      "Derecho",
      "Diseño",
      "Diseño Teatral",
      "Enfermeria",
      "Estudios Internacionales",
      "Etapa Basica De La Licenciatura En Artes Con Mencion En Artes Plasticas",
      "Etapa Basica De La Licenciatura En Artes Con Mencion En Composicion",
      "Etapa Basica De La Licenciatura En Artes Con Mencion En Danza",
      "Etapa Basica De La Licenciatura En Artes Con Mencion En Interpretacion Musical",
      "Fonoaudiologia",
      "Geografia",
      "Geologia",
      "Ingenieria Agronomica",
      "Ingenieria Civil",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Biotecnologia",
      "Ingenieria Civil En Computacion",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria En Alimentos",
      "Ingenieria En Biotecnologia Molecular",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria En Recursos Naturales Renovables",
      "Ingenieria En Sonido",
      "Ingenieria Forestal",
      "Ingenieria Y Ciencias - Plan Comun",
      "Interpretacion Musical Con Especialidades",
      "Kinesiologia",
      "Licenciatura En Artes Con Mencion En Artes Visuales",
      "Licenciatura En Artes Con Mencion En Composicion",
      "Licenciatura En Ciencias Con Mencion En Astronomia, Geofisica O Fisica",
      "Licenciatura En Ciencias Con Mencion En Biologia",
      "Licenciatura En Ciencias Con Mencion En Fisica",
      "Licenciatura En Ciencias Con Mencion En Matematicas",
      "Licenciatura En Ciencias Con Mencion En Quimica",
      "Licenciatura En Filosofia",
      "Licenciatura En Historia",
      "Licenciatura En Lingüistica Y Literatura",
      "Licenciatura En Lingüistica Y Literatura Inglesas",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Media En Asignaturas Cientifico-humanistas",
      "Pedagogia En Educacion Media En Biologia Y Quimica",
      "Pedagogia En Educacion Media En Matematicas Y Fisica",
      "Pedagogia En Educacion Parvularia",
      "Periodismo",
      "Programa Academico De Bachillerato",
      "Psicologia",
      "Quimica",
      "Quimica Ambiental",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnologia Medica Con Mencion",
      "Teoria De La Musica",
      "Teoria E Historia Del Arte",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad De Concepcion",
    carreras: [
      "Administracion Publica Y Ciencia Politica",
      "Agronomia",
      "Antropologia",
      "Arquitectura",
      "Artes Visuales",
      "Astronomia",
      "Auditoria",
      "Bachillerato En Humanidades",
      "Bioingenieria",
      "Biologia",
      "Biologia Marina",
      "Bioquimica",
      "Ciencias Fisicas",
      "Derecho",
      "Educacion Diferencial",
      "Educacion General Basica",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Geofisica",
      "Geografia",
      "Geologia",
      "Ingenieria Agroindustrial",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil Aeroespacial",
      "Ingenieria Civil Agricola",
      "Ingenieria Civil Biomedica",
      "Ingenieria Civil De Materiales",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Telecomunicaciones",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Metalurgica",
      "Ingenieria Civil Quimica",
      "Ingenieria Civil-plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Biotecnologia Marina Y Acuicultura",
      "Ingenieria En Biotecnologia Vegetal",
      "Ingenieria En Conservacion De Recursos Naturales",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria Estadistica",
      "Ingenieria Forestal",
      "Ingenieria Geomatica",
      "Kinesiologia",
      "Licenciatura En Historia",
      "Licenciatura En Matematica",
      "Licenciatura En Quimica-quimico",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Artes Visuales",
      "Pedagogia En Ciencias Naturales Y Biologia",
      "Pedagogia En Ciencias Naturales Y Fisica",
      "Pedagogia En Ciencias Naturales Y Quimica",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Musical",
      "Pedagogia En Español",
      "Pedagogia En Filosofia",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica",
      "Periodismo",
      "Psicologia",
      "Quimica Y Farmacia",
      "Quimico Analista",
      "Sociologia",
      "Tecnologia Medica",
      "Trabajo Social",
      "Traduccion/interpretacion En Idiomas Extranjeros",
      "Administracion Publica Y Ciencia Politica",
      "Agronomia",
      "Antropologia",
      "Arquitectura",
      "Artes Visuales",
      "Astronomia",
      "Auditoria",
      "Bachillerato En Humanidades",
      "Bioingenieria",
      "Biologia",
      "Biologia Marina",
      "Bioquimica",
      "Ciencias Fisicas",
      "Derecho",
      "Educacion Diferencial",
      "Educacion General Basica",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Geofisica",
      "Geografia",
      "Geologia",
      "Ingenieria Agroindustrial",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil Aeroespacial",
      "Ingenieria Civil Agricola",
      "Ingenieria Civil Biomedica",
      "Ingenieria Civil De Materiales",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Telecomunicaciones",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Metalurgica",
      "Ingenieria Civil Quimica",
      "Ingenieria Civil-plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Biotecnologia Marina Y Acuicultura",
      "Ingenieria En Biotecnologia Vegetal",
      "Ingenieria En Conservacion De Recursos Naturales",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria Estadistica",
      "Ingenieria Forestal",
      "Ingenieria Geomatica",
      "Kinesiologia",
      "Licenciatura En Historia",
      "Licenciatura En Matematica",
      "Licenciatura En Quimica-quimico",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Artes Visuales",
      "Pedagogia En Ciencias Naturales Y Biologia",
      "Pedagogia En Ciencias Naturales Y Fisica",
      "Pedagogia En Ciencias Naturales Y Quimica",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion Musical",
      "Pedagogia En Español",
      "Pedagogia En Filosofia",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica",
      "Periodismo",
      "Psicologia",
      "Quimica Y Farmacia",
      "Quimico Analista",
      "Sociologia",
      "Tecnologia Medica",
      "Trabajo Social",
      "Traduccion/interpretacion En Idiomas Extranjeros",
    ],
  },
  {
    institucion: "Universidad De La Frontera",
    carreras: [
      "Agronomia",
      "Bachillerato En Ciencias Sociales",
      "Bioquimica",
      "Biotecnologia",
      "Contador Publico Y Contador Auditor",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Biotecnologia",
      "Ingenieria Civil Fisica",
      "Ingenieria Civil Industrial Mencion Bioprocesos",
      "Ingenieria Civil Industrial Mencion Informatica",
      "Ingenieria Civil Industrial Mencion Mecanica",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Quimica",
      "Ingenieria Civil Telematica",
      "Ingenieria Comercial",
      "Ingenieria En Construccion",
      "Ingenieria En Recursos Naturales",
      "Ingenieria Informatica",
      "Kinesiologia",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Castellano Y Comunicacion",
      "Pedagogia En Ciencias Mencion Biologia, Quimica O Fisica",
      "Pedagogia En Educacion Fisica, Deportes Y Recreacion",
      "Pedagogia En Historia Y Geografia Y Educacion Civica",
      "Pedagogia En Ingles",
      "Pedagogia En Matematicas",
      "Periodismo",
      "Plan Comun Ingenieria Civil",
      "Psicologia",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnico Guia De Turismo Aventura",
      "Tecnico Superior En Turismo",
      "Tecnico Universitario En Enfermeria",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Agronomia",
      "Bachillerato En Ciencias Sociales",
      "Bioquimica",
      "Biotecnologia",
      "Contador Publico Y Contador Auditor",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Biotecnologia",
      "Ingenieria Civil Fisica",
      "Ingenieria Civil Industrial Mencion Bioprocesos",
      "Ingenieria Civil Industrial Mencion Informatica",
      "Ingenieria Civil Industrial Mencion Mecanica",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Quimica",
      "Ingenieria Civil Telematica",
      "Ingenieria Comercial",
      "Ingenieria En Construccion",
      "Ingenieria En Recursos Naturales",
      "Ingenieria Informatica",
      "Kinesiologia",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Castellano Y Comunicacion",
      "Pedagogia En Ciencias Mencion Biologia, Quimica O Fisica",
      "Pedagogia En Educacion Fisica, Deportes Y Recreacion",
      "Pedagogia En Historia Y Geografia Y Educacion Civica",
      "Pedagogia En Ingles",
      "Pedagogia En Matematicas",
      "Periodismo",
      "Plan Comun Ingenieria Civil",
      "Psicologia",
      "Quimica Y Farmacia",
      "Sociologia",
      "Tecnico Guia De Turismo Aventura",
      "Tecnico Superior En Turismo",
      "Tecnico Universitario En Enfermeria",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad De La Serena",
    carreras: [
      "Arquitectura",
      "Auditoria",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Ingenieria Agronomica",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial",
      "Ingenieria De Minas",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Alimentos",
      "Ingenieria En Computacion",
      "Ingenieria En Construccion",
      "Ingenieria Mecanica",
      "Kinesiologia",
      "Licenciatura En Astronomia",
      "Licenciatura En Fisica Mencion Astronomia/fisica Espacial/fisica Aplicada",
      "Licenciatura En Matematica",
      "Licenciatura En Musica",
      "Odontologia",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Castellano Y Filosofia",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Musical",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica Y Computacion",
      "Pedagogia En Matematica Y Fisica",
      "Pedagogia En Quimica Y Ciencias Naturales",
      "Periodismo",
      "Psicologia",
      "Quimica",
      "Traduccion Ingles Español",
      "Arquitectura",
      "Auditoria",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Ingenieria Agronomica",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial",
      "Ingenieria De Minas",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Alimentos",
      "Ingenieria En Computacion",
      "Ingenieria En Construccion",
      "Ingenieria Mecanica",
      "Kinesiologia",
      "Licenciatura En Astronomia",
      "Licenciatura En Fisica Mencion Astronomia/fisica Espacial/fisica Aplicada",
      "Licenciatura En Matematica",
      "Licenciatura En Musica",
      "Odontologia",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Castellano Y Filosofia",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Musical",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica Y Computacion",
      "Pedagogia En Matematica Y Fisica",
      "Pedagogia En Quimica Y Ciencias Naturales",
      "Periodismo",
      "Psicologia",
      "Quimica",
      "Traduccion Ingles Español",
    ],
  },
  {
    institucion: "Universidad De Las Americas",
    carreras: [
      "Administracion Publica",
      "Agronomia",
      "Animacion Digital",
      "Arquitectura",
      "Comunicacion Audiovisual Y Multimedia",
      "Construccion Civil",
      "Contador Auditor",
      "Derecho",
      "Diseño Grafico",
      "Enfermeria",
      "Fonoaudiologia",
      "Gastronomia Y Negocios Gastronomicos Mencion Cocinas Andinas",
      "Hoteleria Y Turismo",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Informatica",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria En Minas",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Interprete En Danza",
      "Kinesiologia",
      "Licenciatura En Ciencias De La Actividad Fisica",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Ingles",
      "Periodismo",
      "Psicologia",
      "Publicidad",
      "Relaciones Publicas",
      "Tecnico De Nivel Superior En Actividad Fisica Y Deporte",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Agroviticultura Y Enologia",
      "Tecnico De Nivel Superior En Construccion",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Operaciones Mineras",
      "Tecnico De Nivel Superior En Organizacion Y Produccion De Eventos",
      "Tecnico De Nivel Superior Veterinario",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Traduccion E Interpretacion En Ingles",
      "Traduccion E Interprete En Ingles",
      "Administracion Publica",
      "Agronomia",
      "Animacion Digital",
      "Arquitectura",
      "Comunicacion Audiovisual Y Multimedia",
      "Construccion Civil",
      "Contador Auditor",
      "Derecho",
      "Diseño Grafico",
      "Enfermeria",
      "Fonoaudiologia",
      "Gastronomia Y Negocios Gastronomicos Mencion Cocinas Andinas",
      "Hoteleria Y Turismo",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Informatica",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria En Minas",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Interprete En Danza",
      "Kinesiologia",
      "Licenciatura En Ciencias De La Actividad Fisica",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Ingles",
      "Periodismo",
      "Psicologia",
      "Publicidad",
      "Relaciones Publicas",
      "Tecnico De Nivel Superior En Actividad Fisica Y Deporte",
      "Tecnico De Nivel Superior En Administracion De Empresas",
      "Tecnico De Nivel Superior En Agroviticultura Y Enologia",
      "Tecnico De Nivel Superior En Construccion",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Operaciones Mineras",
      "Tecnico De Nivel Superior En Organizacion Y Produccion De Eventos",
      "Tecnico De Nivel Superior Veterinario",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Traduccion E Interpretacion En Ingles",
      "Traduccion E Interprete En Ingles",
    ],
  },
  {
    institucion: "Universidad De Los Andes",
    carreras: [
      "Administracion De Servicios",
      "Bachillerato De Obstetricia Y Puericultura",
      "Bachillerato En Administracion De Servicios",
      "Bachillerato En Derecho",
      "Bachillerato En Enfermeria",
      "Bachillerato En Humanidades",
      "Bachillerato En Ingenieria Civil",
      "Bachillerato En Ingenieria Comercial",
      "Bachillerato En Medicina",
      "Bachillerato En Odontologia",
      "Bachillerato En Psicologia",
      "Bachillerato En Salud",
      "Comunicacion Audiovisual",
      "Derecho",
      "Educacion De Parvulos",
      "Enfermeria",
      "Filosofia",
      "Fonoaudiologia",
      "Historia",
      "Ingenieria Civil (plan Comun)",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Computacion",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Kinesiologia",
      "Licenciatura En Literatura",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia Basica",
      "Pedagogia Basica Bilingüe Ingles/español",
      "Periodismo",
      "Programa De Pedagogia En Educacion Media",
      "Programa Pedagogia Media En Religion Catolica",
      "Psicologia",
      "Publicidad",
      "Terapia Ocupacional",
      "Administracion De Servicios",
      "Bachillerato De Obstetricia Y Puericultura",
      "Bachillerato En Administracion De Servicios",
      "Bachillerato En Derecho",
      "Bachillerato En Enfermeria",
      "Bachillerato En Humanidades",
      "Bachillerato En Ingenieria Civil",
      "Bachillerato En Ingenieria Comercial",
      "Bachillerato En Medicina",
      "Bachillerato En Odontologia",
      "Bachillerato En Psicologia",
      "Bachillerato En Salud",
      "Comunicacion Audiovisual",
      "Derecho",
      "Educacion De Parvulos",
      "Enfermeria",
      "Filosofia",
      "Fonoaudiologia",
      "Historia",
      "Ingenieria Civil (plan Comun)",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Computacion",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Kinesiologia",
      "Licenciatura En Literatura",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia Basica",
      "Pedagogia Basica Bilingüe Ingles/español",
      "Periodismo",
      "Programa De Pedagogia En Educacion Media",
      "Programa Pedagogia Media En Religion Catolica",
      "Psicologia",
      "Publicidad",
      "Terapia Ocupacional",
    ],
  },
  {
    institucion: "Universidad De Los Lagos",
    carreras: [
      "Agronomia",
      "Antropologia",
      "Arquitectura",
      "Biologia Marina",
      "Ciencias Politicas Y Administrativas",
      "Construccion Civil (continuidad Para Tecnicos)",
      "Contador Publico Auditor",
      "Derecho",
      "Educacion Diferencial Con Especialidad En Problemas De Aprendizaje",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion De Empresas (continuidad Para Tecnicos De Nivel Superior)",
      "Ingenieria En Administracion Publica",
      "Ingenieria En Administracion Publica (continuidad Para Tecnicos De Nivel Superior)",
      "Ingenieria En Alimentos",
      "Ingenieria En Informatica (continuidad Para Tecnicos De Nivel Superior)",
      "Kinesiologia",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Media En Artes Mencion Musica Y Artes Visuales",
      "Pedagogia En Educacion Media En Historia Y Geografia",
      "Pedagogia En Educacion Media En Ingles",
      "Pedagogia En Educacion Media En Matematica Y Computacion",
      "Pedagogia En Educacion Media Mencion Educacion Fisica",
      "Pedagogia En Educacion Media Mencion Lengua Castellana Y Comunicaciones",
      "Psicologia",
      "Tecnico Deportivo Universitario",
      "Tecnico Universitario En Administracion De Empresas",
      "Tecnico Universitario En Construccion",
      "Tecnico Universitario En Contabilidad General",
      "Tecnico Universitario En Educacion Parvularia",
      "Tecnico Universitario En Electricidad Y Automatizacion",
      "Tecnico Universitario En Electromecanica Maritima",
      "Tecnico Universitario En Gestion Y Desarrollo De Productos Alimentarios",
      "Tecnico Universitario En Informatica",
      "Tecnico Universitario En Transporte Maritimo Costero",
      "Trabajo Social",
      "Agronomia",
      "Antropologia",
      "Arquitectura",
      "Biologia Marina",
      "Ciencias Politicas Y Administrativas",
      "Construccion Civil (continuidad Para Tecnicos)",
      "Contador Publico Auditor",
      "Derecho",
      "Educacion Diferencial Con Especialidad En Problemas De Aprendizaje",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Administracion De Empresas (continuidad Para Tecnicos De Nivel Superior)",
      "Ingenieria En Administracion Publica",
      "Ingenieria En Administracion Publica (continuidad Para Tecnicos De Nivel Superior)",
      "Ingenieria En Alimentos",
      "Ingenieria En Informatica (continuidad Para Tecnicos De Nivel Superior)",
      "Kinesiologia",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Media En Artes Mencion Musica Y Artes Visuales",
      "Pedagogia En Educacion Media En Historia Y Geografia",
      "Pedagogia En Educacion Media En Ingles",
      "Pedagogia En Educacion Media En Matematica Y Computacion",
      "Pedagogia En Educacion Media Mencion Educacion Fisica",
      "Pedagogia En Educacion Media Mencion Lengua Castellana Y Comunicaciones",
      "Psicologia",
      "Tecnico Deportivo Universitario",
      "Tecnico Universitario En Administracion De Empresas",
      "Tecnico Universitario En Construccion",
      "Tecnico Universitario En Contabilidad General",
      "Tecnico Universitario En Educacion Parvularia",
      "Tecnico Universitario En Electricidad Y Automatizacion",
      "Tecnico Universitario En Electromecanica Maritima",
      "Tecnico Universitario En Gestion Y Desarrollo De Productos Alimentarios",
      "Tecnico Universitario En Informatica",
      "Tecnico Universitario En Transporte Maritimo Costero",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad De Magallanes",
    carreras: [
      "Agronomia",
      "Arquitectura",
      "Auditoria",
      "Biologia Marina",
      "Construccion Civil",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria En Construccion",
      "Ingenieria En Prevencion De Riesgos Y Gestion De Sistemas Integrados",
      "Ingenieria Mecanica Industrial",
      "Ingenieria Plan Comun",
      "Kinesiologia",
      "Medicina",
      "Nutricion Y Dietetica",
      "Pedagogia En Artes Musicales Para Enseñanza Basica Y Media",
      "Pedagogia En Biologia Y Quimica Para Enseñanza Media",
      "Pedagogia En Castellano Y Comunicacion Para Enseñanza Media",
      "Pedagogia En Educacion Basica Con Mencion En Lenguaje Y Matematicas",
      "Pedagogia En Educacion Especial Y Diferencial",
      "Pedagogia En Educacion Fisica Para Enseñanza Basica Y Media",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Ciencias Sociales Para Enseñanza Media",
      "Pedagogia En Ingles Para Enseñanza Basica Y Media",
      "Pedagogia En Matematica Para Enseñanza Media",
      "Psicologia",
      "Tecnico De Nivel Superior En Acuicultura",
      "Tecnico De Nivel Superior En Administracion",
      "Tecnico De Nivel Superior En Analisis De Sistemas Computacionales",
      "Tecnico De Nivel Superior En Construccion",
      "Tecnico De Nivel Superior En Contabilidad General",
      "Tecnico De Nivel Superior En Educacion Especial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Instrumentacion Y Automatizacion Industrial",
      "Tecnico De Nivel Superior En Mantenimiento Industrial Mencion Hidraulica Neumatica - Mencion Electro Mecanica",
      "Tecnico De Nivel Superior En Prevencion De Riesgos Mencion Higiene Industrial Y Salud Ocupacional",
      "Tecnico De Nivel Superior En Procesos Industriales Mencion Gestion De Calidad - Mencion Industria Quimica",
      "Tecnico De Nivel Superior En Redes De Computadores",
      "Tecnico De Nivel Superior En Tecnologia Energetica Y Energias No Convencionales",
      "Tecnico De Nivel Superior En Turismo Mencion Hospitality / Hospitalidad Turistica",
      "Tecnico De Nivel Superior En Turismo Mencion Outdoor / Expediciones Y Turismo - Mencion Hospitality / Hospitalidad Turistica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Agronomia",
      "Arquitectura",
      "Auditoria",
      "Biologia Marina",
      "Construccion Civil",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria En Construccion",
      "Ingenieria En Prevencion De Riesgos Y Gestion De Sistemas Integrados",
      "Ingenieria Mecanica Industrial",
      "Ingenieria Plan Comun",
      "Kinesiologia",
      "Medicina",
      "Nutricion Y Dietetica",
      "Pedagogia En Artes Musicales Para Enseñanza Basica Y Media",
      "Pedagogia En Biologia Y Quimica Para Enseñanza Media",
      "Pedagogia En Castellano Y Comunicacion Para Enseñanza Media",
      "Pedagogia En Educacion Basica Con Mencion En Lenguaje Y Matematicas",
      "Pedagogia En Educacion Especial Y Diferencial",
      "Pedagogia En Educacion Fisica Para Enseñanza Basica Y Media",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Ciencias Sociales Para Enseñanza Media",
      "Pedagogia En Ingles Para Enseñanza Basica Y Media",
      "Pedagogia En Matematica Para Enseñanza Media",
      "Psicologia",
      "Tecnico De Nivel Superior En Acuicultura",
      "Tecnico De Nivel Superior En Administracion",
      "Tecnico De Nivel Superior En Analisis De Sistemas Computacionales",
      "Tecnico De Nivel Superior En Construccion",
      "Tecnico De Nivel Superior En Contabilidad General",
      "Tecnico De Nivel Superior En Educacion Especial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Instrumentacion Y Automatizacion Industrial",
      "Tecnico De Nivel Superior En Mantenimiento Industrial Mencion Hidraulica Neumatica - Mencion Electro Mecanica",
      "Tecnico De Nivel Superior En Prevencion De Riesgos Mencion Higiene Industrial Y Salud Ocupacional",
      "Tecnico De Nivel Superior En Procesos Industriales Mencion Gestion De Calidad - Mencion Industria Quimica",
      "Tecnico De Nivel Superior En Redes De Computadores",
      "Tecnico De Nivel Superior En Tecnologia Energetica Y Energias No Convencionales",
      "Tecnico De Nivel Superior En Turismo Mencion Hospitality / Hospitalidad Turistica",
      "Tecnico De Nivel Superior En Turismo Mencion Outdoor / Expediciones Y Turismo - Mencion Hospitality / Hospitalidad Turistica",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad De Ohiggins",
    carreras: [
      "Administracion Publica",
      "Derecho",
      "Enfermeria",
      "Ingenieria Agronomica",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Computacion",
      "Ingenieria Civil En Modelamiento Matematico De Datos",
      "Ingenieria Civil Geologica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial",
      "Medicina",
      "Medicina Veterinaria",
      "Pedagogia En Ciencias Naturales Con Menciones",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Especial Con Menciones",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Ingles Para Enseñanza Basica Y Media",
      "Pedagogia En Lenguaje Y Comunicacion",
      "Pedagogia En Matematica",
      "Psicologia",
      "Terapia Ocupacional",
      "Administracion Publica",
      "Derecho",
      "Enfermeria",
      "Ingenieria Agronomica",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Computacion",
      "Ingenieria Civil En Modelamiento Matematico De Datos",
      "Ingenieria Civil Geologica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial",
      "Medicina",
      "Medicina Veterinaria",
      "Pedagogia En Ciencias Naturales Con Menciones",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Especial Con Menciones",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Ingles Para Enseñanza Basica Y Media",
      "Pedagogia En Lenguaje Y Comunicacion",
      "Pedagogia En Matematica",
      "Psicologia",
      "Terapia Ocupacional",
    ],
  },
  {
    institucion: "Universidad De Playa Ancha De Ciencias De La Educacion",
    carreras: [
      "Administracion Turistica Multilingüe",
      "Analista Quimico",
      "Bibliotecologia",
      "Diseño",
      "Educacion Parvularia",
      "Enfermeria, Licenciatura En Enfermeria",
      "Fonoaudiologia",
      "Geografia",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil Industrial",
      "Ingenieria En Informatica",
      "Kinesiologia",
      "Licenciatura En Arte",
      "Nutricion Y Dietetica",
      "Pedagogia En Artes Plasticas",
      "Pedagogia En Biologia Y Ciencias",
      "Pedagogia En Castellano",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica Damas",
      "Pedagogia En Educacion Fisica Varones",
      "Pedagogia En Educacion Musical",
      "Pedagogia En Filosofia",
      "Pedagogia En Fisica",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica",
      "Pedagogia En Quimica Y Ciencias",
      "Periodismo",
      "Psicologia",
      "Sociologia",
      "Teatro",
      "Tecnico En Administracion En Recursos Humanos",
      "Tecnico En Administracion Logistica",
      "Tecnico En Construccion",
      "Tecnico En Mineria",
      "Tecnologia En Deporte Y Recreacion",
      "Terapia Ocupacional",
      "Traduccion E Interpretacion Ingles-español",
      "Administracion Turistica Multilingüe",
      "Analista Quimico",
      "Bibliotecologia",
      "Diseño",
      "Educacion Parvularia",
      "Enfermeria, Licenciatura En Enfermeria",
      "Fonoaudiologia",
      "Geografia",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil Industrial",
      "Ingenieria En Informatica",
      "Kinesiologia",
      "Licenciatura En Arte",
      "Nutricion Y Dietetica",
      "Pedagogia En Artes Plasticas",
      "Pedagogia En Biologia Y Ciencias",
      "Pedagogia En Castellano",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica Damas",
      "Pedagogia En Educacion Fisica Varones",
      "Pedagogia En Educacion Musical",
      "Pedagogia En Filosofia",
      "Pedagogia En Fisica",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica",
      "Pedagogia En Quimica Y Ciencias",
      "Periodismo",
      "Psicologia",
      "Sociologia",
      "Teatro",
      "Tecnico En Administracion En Recursos Humanos",
      "Tecnico En Administracion Logistica",
      "Tecnico En Construccion",
      "Tecnico En Mineria",
      "Tecnologia En Deporte Y Recreacion",
      "Terapia Ocupacional",
      "Traduccion E Interpretacion Ingles-español",
    ],
  },
  {
    institucion: "Universidad De Santiago De Chile",
    carreras: [
      "Administracion Publica",
      "Analista En Computacion Cientifica / Licenciatura En Ciencia De La Computacion",
      "Arquitectura",
      "Bachillerato En Ciencias Y Humanidades",
      "Bioquimica Y Licenciatura En Bioquimica",
      "Contador Publico Y Auditor",
      "Derecho",
      "Diseño En Comunicacion Visual",
      "Enfermeria",
      "Ingenieria Civil Biomedica",
      "Ingenieria Civil En Ambiente",
      "Ingenieria Civil En Biotecnologia",
      "Ingenieria Civil En Electricidad",
      "Ingenieria Civil En Geografia",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil En Mecanica",
      "Ingenieria Civil En Metalurgia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil En Quimica",
      "Ingenieria Civil En Telematica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecatronica",
      "Ingenieria Comercial",
      "Ingenieria De Alimentos",
      "Ingenieria De Ejecucion En Climatizacion (calefaccion, Refrigeracion Y Aire Acondicionado)",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Geomensura",
      "Ingenieria De Ejecucion En Mecanica",
      "Ingenieria De Ejecucion En Metalurgia",
      "Ingenieria De Ejecucion En Minas",
      "Ingenieria De Ejecucion En Quimica",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria En Agronegocios",
      "Ingenieria Estadistica",
      "Ingenieria Fisica",
      "Ingenieria Matematica",
      "Kinesiologia",
      "Licenciatura En Ciencias De La Actividad Fisica / Entrenador Deportivo",
      "Licenciatura En Ciencias De La Actividad Fisica / Terapeuta En Actividad Fisica Y Salud",
      "Licenciatura En Estudios Internacionales / Analista En Politicas Y Asuntos Internacionales",
      "Licenciatura En Historia",
      "Licenciatura En Lingüistica Aplicada A La Traduccion Mencion Ingles-japones O Ingles-portugues / Traductor Ingles-japones O Ingles-portugues",
      "Medicina",
      "Obstetricia Y Puericultura",
      "P/e De Diseño Industrial",
      "Pedagogia En Castellano / Licenciatura En Educacion En Castellano",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion General Basica / Licenciatura En Educacion",
      "Pedagogia En Filosofia",
      "Pedagogia En Fisica Y Matematica / Licenciatura En Educacion De Fisica Y Matematica",
      "Pedagogia En Historia Y Ciencias Sociales / Licenciatura En Educacion En Historia Y Ciencias Sociales",
      "Pedagogia En Ingles / Licenciatura En Educacion En Ingles",
      "Pedagogia En Matematica Y Computacion / Licenciatura En Educacion En Matematica Y Computacion",
      "Pedagogia En Quimica Y Biologia /licenciatura En Educacion En Quimica Y Biologia",
      "Periodismo / Licenciatura En Comunicacion Social",
      "Plan Especial Administracion Publica",
      "Plan Especial Construccion Civil",
      "Plan Especial Contador Publico Y Auditor",
      "Plan Especial Ingenieria Civil En Electricidad",
      "Plan Especial Ingenieria Civil En Informatica",
      "Plan Especial Ingenieria Civil En Mecanica",
      "Plan Especial Ingenieria Civil En Minas",
      "Plan Especial Ingenieria Civil En Obras Civiles",
      "Plan Especial Ingenieria Civil Industrial",
      "Plan Especial Ingenieria Comercial",
      "Plan Especial Ingenieria Comercial En Administracion De Empresas",
      "Plan Especial Ingenieria De Ejecucion En Ambiente",
      "Plan Especial Ingenieria De Ejecucion En Computacion E Informatica",
      "Plan Especial Ingenieria De Ejecucion En Electricidad",
      "Plan Especial Ingenieria De Ejecucion En Geomensura",
      "Plan Especial Ingenieria De Ejecucion En Mecanica",
      "Plan Especial Ingenieria De Ejecucion En Quimica",
      "Plan Especial Ingenieria De Ejecucion Industrial",
      "Plan Especial Pedagogia En Quimica Y Biologia /licenciatura En Educacion En Quimica Y Biologia",
      "Plan Especial Tecnico En Prevencion Y Rehabilitacion De Personas Con Dependencia A Drogas",
      "Plan Especial Tecnologia En Construcciones",
      "Programa Especial De Periodismo / Licenciatura En Comunicacion Social",
      "Programa Especial De Regularizacion De Titulo De Profesor De Estado Para La Educacion Tecnico Profesional / Licenciatura En Educacion Tecnico Profesional",
      "Prosecucion Para La Carrera De Pedagogia En Educacion Fisica",
      "Psicologia / Licenciatura En Psicologia",
      "Publicidad",
      "Quimica Y Farmacia",
      "Quimica Y Licenciatura En Quimica",
      "Tecnico Universitario En Analisis Quimico Y Fisico",
      "Tecnologia En Administracion De Personal",
      "Tecnologia En Alimentos",
      "Tecnologia En Automatizacion Industrial",
      "Tecnologia En Construcciones",
      "Tecnologia En Control Industrial",
      "Tecnologia En Diseño Industrial",
      "Tecnologia En Mantenimiento Industrial",
      "Tecnologia En Telecomunicaciones",
      "Terapia Ocupacional",
      "Administracion Publica",
      "Analista En Computacion Cientifica / Licenciatura En Ciencia De La Computacion",
      "Arquitectura",
      "Bachillerato En Ciencias Y Humanidades",
      "Bioquimica Y Licenciatura En Bioquimica",
      "Contador Publico Y Auditor",
      "Derecho",
      "Diseño En Comunicacion Visual",
      "Enfermeria",
      "Ingenieria Civil Biomedica",
      "Ingenieria Civil En Ambiente",
      "Ingenieria Civil En Biotecnologia",
      "Ingenieria Civil En Electricidad",
      "Ingenieria Civil En Geografia",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil En Mecanica",
      "Ingenieria Civil En Metalurgia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil En Quimica",
      "Ingenieria Civil En Telematica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecatronica",
      "Ingenieria Comercial",
      "Ingenieria De Alimentos",
      "Ingenieria De Ejecucion En Climatizacion (calefaccion, Refrigeracion Y Aire Acondicionado)",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Geomensura",
      "Ingenieria De Ejecucion En Mecanica",
      "Ingenieria De Ejecucion En Metalurgia",
      "Ingenieria De Ejecucion En Minas",
      "Ingenieria De Ejecucion En Quimica",
      "Ingenieria De Ejecucion Industrial",
      "Ingenieria En Agronegocios",
      "Ingenieria Estadistica",
      "Ingenieria Fisica",
      "Ingenieria Matematica",
      "Kinesiologia",
      "Licenciatura En Ciencias De La Actividad Fisica / Entrenador Deportivo",
      "Licenciatura En Ciencias De La Actividad Fisica / Terapeuta En Actividad Fisica Y Salud",
      "Licenciatura En Estudios Internacionales / Analista En Politicas Y Asuntos Internacionales",
      "Licenciatura En Historia",
      "Licenciatura En Lingüistica Aplicada A La Traduccion Mencion Ingles-japones O Ingles-portugues / Traductor Ingles-japones O Ingles-portugues",
      "Medicina",
      "Obstetricia Y Puericultura",
      "P/e De Diseño Industrial",
      "Pedagogia En Castellano / Licenciatura En Educacion En Castellano",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion General Basica / Licenciatura En Educacion",
      "Pedagogia En Filosofia",
      "Pedagogia En Fisica Y Matematica / Licenciatura En Educacion De Fisica Y Matematica",
      "Pedagogia En Historia Y Ciencias Sociales / Licenciatura En Educacion En Historia Y Ciencias Sociales",
      "Pedagogia En Ingles / Licenciatura En Educacion En Ingles",
      "Pedagogia En Matematica Y Computacion / Licenciatura En Educacion En Matematica Y Computacion",
      "Pedagogia En Quimica Y Biologia /licenciatura En Educacion En Quimica Y Biologia",
      "Periodismo / Licenciatura En Comunicacion Social",
      "Plan Especial Administracion Publica",
      "Plan Especial Construccion Civil",
      "Plan Especial Contador Publico Y Auditor",
      "Plan Especial Ingenieria Civil En Electricidad",
      "Plan Especial Ingenieria Civil En Informatica",
      "Plan Especial Ingenieria Civil En Mecanica",
      "Plan Especial Ingenieria Civil En Minas",
      "Plan Especial Ingenieria Civil En Obras Civiles",
      "Plan Especial Ingenieria Civil Industrial",
      "Plan Especial Ingenieria Comercial",
      "Plan Especial Ingenieria Comercial En Administracion De Empresas",
      "Plan Especial Ingenieria De Ejecucion En Ambiente",
      "Plan Especial Ingenieria De Ejecucion En Computacion E Informatica",
      "Plan Especial Ingenieria De Ejecucion En Electricidad",
      "Plan Especial Ingenieria De Ejecucion En Geomensura",
      "Plan Especial Ingenieria De Ejecucion En Mecanica",
      "Plan Especial Ingenieria De Ejecucion En Quimica",
      "Plan Especial Ingenieria De Ejecucion Industrial",
      "Plan Especial Pedagogia En Quimica Y Biologia /licenciatura En Educacion En Quimica Y Biologia",
      "Plan Especial Tecnico En Prevencion Y Rehabilitacion De Personas Con Dependencia A Drogas",
      "Plan Especial Tecnologia En Construcciones",
      "Programa Especial De Periodismo / Licenciatura En Comunicacion Social",
      "Programa Especial De Regularizacion De Titulo De Profesor De Estado Para La Educacion Tecnico Profesional / Licenciatura En Educacion Tecnico Profesional",
      "Prosecucion Para La Carrera De Pedagogia En Educacion Fisica",
      "Psicologia / Licenciatura En Psicologia",
      "Publicidad",
      "Quimica Y Farmacia",
      "Quimica Y Licenciatura En Quimica",
      "Tecnico Universitario En Analisis Quimico Y Fisico",
      "Tecnologia En Administracion De Personal",
      "Tecnologia En Alimentos",
      "Tecnologia En Automatizacion Industrial",
      "Tecnologia En Construcciones",
      "Tecnologia En Control Industrial",
      "Tecnologia En Diseño Industrial",
      "Tecnologia En Mantenimiento Industrial",
      "Tecnologia En Telecomunicaciones",
      "Terapia Ocupacional",
    ],
  },
  {
    institucion: "Universidad De Talca",
    carreras: [
      "Administracion Publica",
      "Agronomia",
      "Arquitectura",
      "Auditoria E Ingenieria En Control De Gestion",
      "Bioquimica",
      "Contador Publico Y Auditor",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Bioinformatica",
      "Ingenieria Civil En Computacion",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Mecatronica",
      "Ingenieria Comercial",
      "Ingenieria En Desarrollo De Videojuegos Y Realidad Virtual",
      "Ingenieria En Informatica Empresarial",
      "Kinesiologia",
      "Licenciatura En Interpretacion Y Formacion Musical Especializada",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Educacion General Basica Con Mencion En Aleman",
      "Pedagogia En Educacion General Basica Mencion Ingles",
      "Pedagogia En Educacion Media En Aleman",
      "Pedagogia En Educacion Media En Biologia Y Quimica",
      "Pedagogia En Educacion Media En Ingles",
      "Pedagogia En Educacion Media En Matematica Y Fisica",
      "Pedagogia En Educacion Parvularia Con Mencion En Aleman",
      "Pedagogia En Educacion Parvularia Mencion En Ingles",
      "Psicologia",
      "Tecnico Superior En Administracion",
      "Tecnico Superior En Turismo Enologico",
      "Tecnico Superior En Vinificacion Y Enologia",
      "Tecnico Superior En Viticultura",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Administracion Publica",
      "Agronomia",
      "Arquitectura",
      "Auditoria E Ingenieria En Control De Gestion",
      "Bioquimica",
      "Contador Publico Y Auditor",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Bioinformatica",
      "Ingenieria Civil En Computacion",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Mecatronica",
      "Ingenieria Comercial",
      "Ingenieria En Desarrollo De Videojuegos Y Realidad Virtual",
      "Ingenieria En Informatica Empresarial",
      "Kinesiologia",
      "Licenciatura En Interpretacion Y Formacion Musical Especializada",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Educacion General Basica Con Mencion En Aleman",
      "Pedagogia En Educacion General Basica Mencion Ingles",
      "Pedagogia En Educacion Media En Aleman",
      "Pedagogia En Educacion Media En Biologia Y Quimica",
      "Pedagogia En Educacion Media En Ingles",
      "Pedagogia En Educacion Media En Matematica Y Fisica",
      "Pedagogia En Educacion Parvularia Con Mencion En Aleman",
      "Pedagogia En Educacion Parvularia Mencion En Ingles",
      "Psicologia",
      "Tecnico Superior En Administracion",
      "Tecnico Superior En Turismo Enologico",
      "Tecnico Superior En Vinificacion Y Enologia",
      "Tecnico Superior En Viticultura",
      "Tecnologia Medica",
      "Terapia Ocupacional",
    ],
  },
  {
    institucion: "Universidad De Tarapaca",
    carreras: [
      "Agronomia",
      "Antropologia",
      "Contador Auditor-contador Publico",
      "Derecho",
      "Diseño Multimedia",
      "Educacion Parvularia",
      "Educacion Parvularia Vespertina",
      "Enfermeria",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial",
      "Ingenieria Comercial Con Mencion En Gestion Y Comercio Exterior",
      "Ingenieria De Ejecucion Electrica",
      "Ingenieria De Ejecucion Electronica",
      "Ingenieria De Ejecucion Mecanica",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria Mecatronica",
      "Ingenieria Quimica Ambiental",
      "Kinesiologia Y Rehabilitacion",
      "Licenciatura En Historia Y Geografia",
      "Licenciatura En Ingles",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura Con Mencion En Gestion Y Salud Familiar",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Castellano Y Comunicacion",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Fisica Y Matematica",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica",
      "Profesor De Educacion Fisica",
      "Programa De Prosecucion De Estudios Educacion Parvularia",
      "Programa De Prosecucion De Estudios Educacion Parvularia Vespertina",
      "Programa De Prosecucion De Estudios Pedagogia En Educacion Basica",
      "Programa De Prosecucion De Estudios Pedagogia En Educacion Diferencial",
      "Programa De Prosecucion De Estudios Profesor De Educacion Fisica",
      "Psicologia",
      "Quimico Laboratorista",
      "Tecnico Universitario Mecanico En Mantencion De Maquinaria Industrial Y Minera",
      "Tecnologia Medica / Imagenologia Y Fisica Medica",
      "Tecnologia Medica / Oftalmologia Y Optometria",
      "Tecnologia Medica /laboratorio Clinico, Hematologia Y Banco De Sangre",
      "Trabajo Social",
      "Agronomia",
      "Antropologia",
      "Contador Auditor-contador Publico",
      "Derecho",
      "Diseño Multimedia",
      "Educacion Parvularia",
      "Educacion Parvularia Vespertina",
      "Enfermeria",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Comercial",
      "Ingenieria Comercial Con Mencion En Gestion Y Comercio Exterior",
      "Ingenieria De Ejecucion Electrica",
      "Ingenieria De Ejecucion Electronica",
      "Ingenieria De Ejecucion Mecanica",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria Mecatronica",
      "Ingenieria Quimica Ambiental",
      "Kinesiologia Y Rehabilitacion",
      "Licenciatura En Historia Y Geografia",
      "Licenciatura En Ingles",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura Con Mencion En Gestion Y Salud Familiar",
      "Pedagogia En Biologia Y Ciencias Naturales",
      "Pedagogia En Castellano Y Comunicacion",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Fisica Y Matematica",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Pedagogia En Matematica",
      "Profesor De Educacion Fisica",
      "Programa De Prosecucion De Estudios Educacion Parvularia",
      "Programa De Prosecucion De Estudios Educacion Parvularia Vespertina",
      "Programa De Prosecucion De Estudios Pedagogia En Educacion Basica",
      "Programa De Prosecucion De Estudios Pedagogia En Educacion Diferencial",
      "Programa De Prosecucion De Estudios Profesor De Educacion Fisica",
      "Psicologia",
      "Quimico Laboratorista",
      "Tecnico Universitario Mecanico En Mantencion De Maquinaria Industrial Y Minera",
      "Tecnologia Medica / Imagenologia Y Fisica Medica",
      "Tecnologia Medica / Oftalmologia Y Optometria",
      "Tecnologia Medica /laboratorio Clinico, Hematologia Y Banco De Sangre",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad De Valparaiso",
    carreras: [
      "Administracion Hotelera Y Gastronomica",
      "Administracion Publica",
      "Arquitectura",
      "Auditoria",
      "Biologia Marina",
      "Cine",
      "Derecho",
      "Diseño",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Gestion En Turismo Y Cultura",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil Biomedica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Oceanica",
      "Ingenieria Comercial",
      "Ingenieria En Construccion",
      "Ingenieria En Estadistica",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria En Negocios Internacionales",
      "Kinesiologia",
      "Licenciatura En Ciencias Mencion En Biologia O En Quimica",
      "Licenciatura En Fisica Con Mencion Astronomia O Mencion Ciencias Atmosfericas O Mencion Computacion Cientifica",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Filosofia",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Matematicas O Licenciatura En Matematicas",
      "Pedagogia En Musica",
      "Plan Continuidad Arquitectura",
      "Plan Especial De Titulacion De Ingenieria Civil Industrial",
      "Plan Especial De Titulacion De La Carrera De Administracion De Negocios Internacionales",
      "Plan Especial De Titulacion De La Carrera De Auditoria",
      "Psicologia",
      "Quimica Y Farmacia",
      "Sociologia",
      "Teatro",
      "Tecnologia Medica",
      "Trabajo Social",
      "Administracion Hotelera Y Gastronomica",
      "Administracion Publica",
      "Arquitectura",
      "Auditoria",
      "Biologia Marina",
      "Cine",
      "Derecho",
      "Diseño",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Gestion En Turismo Y Cultura",
      "Ingenieria Ambiental",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil Biomedica",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Oceanica",
      "Ingenieria Comercial",
      "Ingenieria En Construccion",
      "Ingenieria En Estadistica",
      "Ingenieria En Informacion Y Control De Gestion",
      "Ingenieria En Negocios Internacionales",
      "Kinesiologia",
      "Licenciatura En Ciencias Mencion En Biologia O En Quimica",
      "Licenciatura En Fisica Con Mencion Astronomia O Mencion Ciencias Atmosfericas O Mencion Computacion Cientifica",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Filosofia",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Matematicas O Licenciatura En Matematicas",
      "Pedagogia En Musica",
      "Plan Continuidad Arquitectura",
      "Plan Especial De Titulacion De Ingenieria Civil Industrial",
      "Plan Especial De Titulacion De La Carrera De Administracion De Negocios Internacionales",
      "Plan Especial De Titulacion De La Carrera De Auditoria",
      "Psicologia",
      "Quimica Y Farmacia",
      "Sociologia",
      "Teatro",
      "Tecnologia Medica",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad De Viña Del Mar",
    carreras: [
      "Administracion De Negocios Turisticos Y Hoteleros",
      "Administracion Publica",
      "Agronomia",
      "Arquitectura",
      "Cine Y Comunicacion Audiovisual",
      "Contador Auditor",
      "Derecho",
      "Diseño",
      "Educacion Basica",
      "Educacion Parvularia",
      "Enfermeria",
      "Entrenador Deportivo",
      "Fonoaudiologia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Comercial",
      "Ingenieria En Construccion",
      "Ingenieria En Gestion De Negocios Internacionales",
      "Ingenieria En Medio Ambiente Y Recursos Naturales",
      "Ingenieria En Prevencion De Riesgos Y Gestion Ambiental",
      "Kinesiologia",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia",
      "Odontologia",
      "Pedagogia De Educacion Diferencial Mencion Discapacidad Intelectual",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Ingles",
      "Periodismo",
      "Psicologia",
      "Psicopedagogia",
      "Relaciones Publicas",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Administracion De Negocios Turisticos Y Hoteleros",
      "Administracion Publica",
      "Agronomia",
      "Arquitectura",
      "Cine Y Comunicacion Audiovisual",
      "Contador Auditor",
      "Derecho",
      "Diseño",
      "Educacion Basica",
      "Educacion Parvularia",
      "Enfermeria",
      "Entrenador Deportivo",
      "Fonoaudiologia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Comercial",
      "Ingenieria En Construccion",
      "Ingenieria En Gestion De Negocios Internacionales",
      "Ingenieria En Medio Ambiente Y Recursos Naturales",
      "Ingenieria En Prevencion De Riesgos Y Gestion Ambiental",
      "Kinesiologia",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia",
      "Odontologia",
      "Pedagogia De Educacion Diferencial Mencion Discapacidad Intelectual",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Ingles",
      "Periodismo",
      "Psicologia",
      "Psicopedagogia",
      "Relaciones Publicas",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Del Bio-bio",
    carreras: [
      "Arquitectura",
      "Bachillerato En Ciencias",
      "Contador Publico Y Auditor",
      "Diseño Grafico",
      "Diseño Industrial",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Automatizacion",
      "Ingenieria Civil En Industrias De La Madera",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Electronica",
      "Ingenieria De Ejecucion En Mecanica",
      "Ingenieria En Alimentos",
      "Ingenieria En Construccion",
      "Ingenieria En Recursos Naturales",
      "Ingenieria Estadistica",
      "Nutricion Y Dietetica",
      "Pedagogia En Castellano Y Comunicacion",
      "Pedagogia En Ciencias Naturales Mencion Biologia O Fisica O Quimica",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Matematica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Psicologia",
      "Tecnico De Nivel Superior En Contabilidad",
      "Tecnico Universitario En Administracion",
      "Tecnico Universitario En Computacion E Informatica",
      "Trabajo Social",
      "Arquitectura",
      "Bachillerato En Ciencias",
      "Contador Publico Y Auditor",
      "Diseño Grafico",
      "Diseño Industrial",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil En Automatizacion",
      "Ingenieria Civil En Industrias De La Madera",
      "Ingenieria Civil En Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Quimica",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion En Administracion De Empresas",
      "Ingenieria De Ejecucion En Computacion E Informatica",
      "Ingenieria De Ejecucion En Electricidad",
      "Ingenieria De Ejecucion En Electronica",
      "Ingenieria De Ejecucion En Mecanica",
      "Ingenieria En Alimentos",
      "Ingenieria En Construccion",
      "Ingenieria En Recursos Naturales",
      "Ingenieria Estadistica",
      "Nutricion Y Dietetica",
      "Pedagogia En Castellano Y Comunicacion",
      "Pedagogia En Ciencias Naturales Mencion Biologia O Fisica O Quimica",
      "Pedagogia En Educacion Fisica",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Matematica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Geografia",
      "Pedagogia En Ingles",
      "Psicologia",
      "Tecnico De Nivel Superior En Contabilidad",
      "Tecnico Universitario En Administracion",
      "Tecnico Universitario En Computacion E Informatica",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Del Desarrollo",
    carreras: [
      "Arquitectura",
      "Bachillerato En Ciencias Juridicas Y Humanidades",
      "Bachillerato En Ingenieria Comercial",
      "Bachillerato En Psicologia",
      "Ciencia Politica Y Politicas Publicas",
      "Cine",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Civil En Mineria",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Ejecucion En Administracion",
      "Ingenieria En Ejecucion En Administracion Mencion Marketing Y Finanzas Aplicadas",
      "Kinesiologia",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia",
      "Odontologia",
      "Pedagogia Basica Mencion En Ingles",
      "Pedagogia En Educacion De Parvulos",
      "Periodismo",
      "Programa De Formacion Pedagogica Para Licenciados Y Profesionales",
      "Psicologia",
      "Publicidad",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Arquitectura",
      "Bachillerato En Ciencias Juridicas Y Humanidades",
      "Bachillerato En Ingenieria Comercial",
      "Bachillerato En Psicologia",
      "Ciencia Politica Y Politicas Publicas",
      "Cine",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Civil En Mineria",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Ejecucion En Administracion",
      "Ingenieria En Ejecucion En Administracion Mencion Marketing Y Finanzas Aplicadas",
      "Kinesiologia",
      "Medicina",
      "Nutricion Y Dietetica",
      "Obstetricia",
      "Odontologia",
      "Pedagogia Basica Mencion En Ingles",
      "Pedagogia En Educacion De Parvulos",
      "Periodismo",
      "Programa De Formacion Pedagogica Para Licenciados Y Profesionales",
      "Psicologia",
      "Publicidad",
      "Tecnologia Medica",
      "Terapia Ocupacional",
    ],
  },
  {
    institucion: "Universidad Diego Portales",
    carreras: [
      "Arquitectura",
      "Artes Visuales",
      "Bachillerato En Ciencias Sociales Y Humanidades",
      "Ciencia Politica",
      "Contador Auditor",
      "Contador Auditor-contador Publico",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Ingenieria Civil En Informatica Y Telecomunicaciones",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Control De Gestion",
      "Ingenieria En Industria Y Logistica",
      "Ingenieria En Informatica Y Gestion",
      "Kinesiologia",
      "Licenciatura En Historia",
      "Literatura Creativa",
      "Medicina",
      "Obstetricia Y Neonatologia",
      "Odontologia",
      "Pedagogia En Educacion Diferencial Con Mencion En Desarrollo Cognitivo",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Periodismo",
      "Psicologia",
      "Publicidad",
      "Sociologia",
      "Tecnologia Medica",
      "Arquitectura",
      "Artes Visuales",
      "Bachillerato En Ciencias Sociales Y Humanidades",
      "Ciencia Politica",
      "Contador Auditor",
      "Contador Auditor-contador Publico",
      "Derecho",
      "Diseño",
      "Enfermeria",
      "Ingenieria Civil En Informatica Y Telecomunicaciones",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Control De Gestion",
      "Ingenieria En Industria Y Logistica",
      "Ingenieria En Informatica Y Gestion",
      "Kinesiologia",
      "Licenciatura En Historia",
      "Literatura Creativa",
      "Medicina",
      "Obstetricia Y Neonatologia",
      "Odontologia",
      "Pedagogia En Educacion Diferencial Con Mencion En Desarrollo Cognitivo",
      "Pedagogia En Educacion General Basica",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Historia Y Ciencias Sociales",
      "Pedagogia En Ingles",
      "Pedagogia En Lengua Castellana Y Comunicacion",
      "Periodismo",
      "Psicologia",
      "Publicidad",
      "Sociologia",
      "Tecnologia Medica",
    ],
  },
  {
    institucion: "Universidad Finis Terrae",
    carreras: [
      "Actuacion",
      "Arquitectura",
      "Artes Visuales",
      "Auditoria",
      "Ciencias De La Familia",
      "Derecho",
      "Direccion Y Gestion De Artes Culinarias",
      "Diseño",
      "Educacion Basica",
      "Educacion Parvularia",
      "Enfermeria",
      "Historia",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica Y Telecomunicaciones",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Informacion Y Control De Gestion",
      "Kinesiologia",
      "Literatura",
      "Medicina",
      "Nutricion Y Dietetica",
      "Odontologia",
      "Periodismo",
      "Psicologia",
      "Publicidad",
      "Actuacion",
      "Arquitectura",
      "Artes Visuales",
      "Auditoria",
      "Ciencias De La Familia",
      "Derecho",
      "Direccion Y Gestion De Artes Culinarias",
      "Diseño",
      "Educacion Basica",
      "Educacion Parvularia",
      "Enfermeria",
      "Historia",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica Y Telecomunicaciones",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Informacion Y Control De Gestion",
      "Kinesiologia",
      "Literatura",
      "Medicina",
      "Nutricion Y Dietetica",
      "Odontologia",
      "Periodismo",
      "Psicologia",
      "Publicidad",
    ],
  },
  {
    institucion: "Universidad Gabriela Mistral",
    carreras: [
      "Animacion Y Diseño Digital",
      "Derecho",
      "Enfermeria",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Licenciatura En Fonoaudiologia",
      "Licenciatura En Kinesiologia",
      "Licenciatura En Nutricion Y Dietetica",
      "Licenciatura En Terapia Ocupacional",
      "Plan Especial Ingenieria Civil Industrial",
      "Psicologia",
      "Animacion Y Diseño Digital",
      "Derecho",
      "Enfermeria",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Licenciatura En Fonoaudiologia",
      "Licenciatura En Kinesiologia",
      "Licenciatura En Nutricion Y Dietetica",
      "Licenciatura En Terapia Ocupacional",
      "Plan Especial Ingenieria Civil Industrial",
      "Psicologia",
    ],
  },
  {
    institucion: "Universidad La Republica",
    carreras: [
      "Administracion Publica",
      "Administracion Publica - Continuidad",
      "Contador Publico Auditor",
      "Contador Publico Auditor - Continuidad Tns",
      "Curso De Actualizacion Para Egresados De Derecho",
      "Derecho",
      "Enfermeria",
      "Ingenieria Civil En Minas - Continuidad Ingenieros",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Industrial - Continuidad Ingenieros",
      "Ingenieria Civil Industrial - Continuidad Ingenieros No Afin",
      "Ingenieria Civil Industrial - Continuidad Tns",
      "Ingenieria Civil Industrial - Continuidad Y Segundo Titulo",
      "Ingenieria Comercial",
      "Ingenieria Comercial - Continuidad Ingenieros Y Tns",
      "Ingenieria De Ejecucion En Construccion - Continuidad",
      "Ingenieria De Ejecucion En Electricidad - Continuidad Para Tns",
      "Ingenieria De Ejecucion En Geomensura - Continuidad",
      "Ingenieria De Ejecucion En Mecanica - Continuidad",
      "Ingenieria De Ejecucion Industrial - Continuidad",
      "Ingenieria En Administracion De Empresas - Continuidad",
      "Ingenieria En Comercio Internacional - Continuidad Y Segundo Titulo",
      "Ingenieria En Ejecucion En Electricidad - Continuidad Tns",
      "Ingenieria En Ejecucion En Minas - Continuidad Tns",
      "Ingenieria En Logistica Y Transporte",
      "Ingenieria En Logistica Y Transporte - Continuidad Y Segundo Titulo",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Ingenieria En Recursos Naturales",
      "Kinesiologia",
      "Licenciatura En Educacion",
      "Licenciatura En Educacion Inclusiva Mencion Investigacion Didactica Y Diversificacion Del Curriculo Y La Enseñanza",
      "Licenciatura En Estudios Religiosos",
      "Licenciatura En Trabajo Social",
      "Pedagogia En Educacion Diferencial Mencion Desarrollo Cognitivo",
      "Pedagogia En Educacion Diferencial Mencion Desarrollo Cognitivo - Continuidad Tns",
      "Pedagogia En Educacion Diferencial Mencion Desarrollo Cognitivo - Segundo Titulo",
      "Pedagogia En Educacion Diferencial Mencion Trastornos De Audicion Y Especifico Del Lenguaje",
      "Pedagogia En Educacion Diferencial Mencion Trastornos De Audicion Y Lenguaje",
      "Pedagogia En Educacion Diferencial Mencion Trastornos De Audicion Y Lenguaje - Continuidad Tns",
      "Pedagogia En Educacion Diferencial Mencion Trastornos De Audicion Y Lenguaje - Segundo Titulo",
      "Pedagogia En Educacion Fisica Para Educacion Basica Y Media",
      "Pedagogia En Educacion General Basica Mencion Trastornos Del Aprendizaje",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Educacion Parvularia - Continuidad Tns",
      "Periodismo",
      "Psicologia",
      "Psicopedagogia Mencion Dinamica De Propension Al Aprendizaje",
      "Tecnico De Nivel Superior En Contabilidad",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Educacion Parvularia Y Primer Ciclo De Educacion Basica",
      "Tecnico De Nivel Superior En Electricidad",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Gestion Publica Y Municipal",
      "Tecnico De Nivel Superior En Mantenimiento Industrial Mencion Energias Renovables",
      "Tecnico De Nivel Superior En Minas",
      "Tecnico De Nivel Superior En Preparacion Fisica Para La Salud, Deporte Y Recreacion Mencion Adulto Mayor",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Trabajo Social - Continuidad Tns",
      "Administracion Publica",
      "Administracion Publica - Continuidad",
      "Contador Publico Auditor",
      "Contador Publico Auditor - Continuidad Tns",
      "Curso De Actualizacion Para Egresados De Derecho",
      "Derecho",
      "Enfermeria",
      "Ingenieria Civil En Minas - Continuidad Ingenieros",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Industrial - Continuidad Ingenieros",
      "Ingenieria Civil Industrial - Continuidad Ingenieros No Afin",
      "Ingenieria Civil Industrial - Continuidad Tns",
      "Ingenieria Civil Industrial - Continuidad Y Segundo Titulo",
      "Ingenieria Comercial",
      "Ingenieria Comercial - Continuidad Ingenieros Y Tns",
      "Ingenieria De Ejecucion En Construccion - Continuidad",
      "Ingenieria De Ejecucion En Electricidad - Continuidad Para Tns",
      "Ingenieria De Ejecucion En Geomensura - Continuidad",
      "Ingenieria De Ejecucion En Mecanica - Continuidad",
      "Ingenieria De Ejecucion Industrial - Continuidad",
      "Ingenieria En Administracion De Empresas - Continuidad",
      "Ingenieria En Comercio Internacional - Continuidad Y Segundo Titulo",
      "Ingenieria En Ejecucion En Electricidad - Continuidad Tns",
      "Ingenieria En Ejecucion En Minas - Continuidad Tns",
      "Ingenieria En Logistica Y Transporte",
      "Ingenieria En Logistica Y Transporte - Continuidad Y Segundo Titulo",
      "Ingenieria En Prevencion De Riesgos Y Medio Ambiente",
      "Ingenieria En Recursos Naturales",
      "Kinesiologia",
      "Licenciatura En Educacion",
      "Licenciatura En Educacion Inclusiva Mencion Investigacion Didactica Y Diversificacion Del Curriculo Y La Enseñanza",
      "Licenciatura En Estudios Religiosos",
      "Licenciatura En Trabajo Social",
      "Pedagogia En Educacion Diferencial Mencion Desarrollo Cognitivo",
      "Pedagogia En Educacion Diferencial Mencion Desarrollo Cognitivo - Continuidad Tns",
      "Pedagogia En Educacion Diferencial Mencion Desarrollo Cognitivo - Segundo Titulo",
      "Pedagogia En Educacion Diferencial Mencion Trastornos De Audicion Y Especifico Del Lenguaje",
      "Pedagogia En Educacion Diferencial Mencion Trastornos De Audicion Y Lenguaje",
      "Pedagogia En Educacion Diferencial Mencion Trastornos De Audicion Y Lenguaje - Continuidad Tns",
      "Pedagogia En Educacion Diferencial Mencion Trastornos De Audicion Y Lenguaje - Segundo Titulo",
      "Pedagogia En Educacion Fisica Para Educacion Basica Y Media",
      "Pedagogia En Educacion General Basica Mencion Trastornos Del Aprendizaje",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Educacion Parvularia - Continuidad Tns",
      "Periodismo",
      "Psicologia",
      "Psicopedagogia Mencion Dinamica De Propension Al Aprendizaje",
      "Tecnico De Nivel Superior En Contabilidad",
      "Tecnico De Nivel Superior En Educacion Diferencial",
      "Tecnico De Nivel Superior En Educacion Parvularia",
      "Tecnico De Nivel Superior En Educacion Parvularia Y Primer Ciclo De Educacion Basica",
      "Tecnico De Nivel Superior En Electricidad",
      "Tecnico De Nivel Superior En Enfermeria",
      "Tecnico De Nivel Superior En Gestion Publica Y Municipal",
      "Tecnico De Nivel Superior En Mantenimiento Industrial Mencion Energias Renovables",
      "Tecnico De Nivel Superior En Minas",
      "Tecnico De Nivel Superior En Preparacion Fisica Para La Salud, Deporte Y Recreacion Mencion Adulto Mayor",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Trabajo Social - Continuidad Tns",
    ],
  },
  {
    institucion: "Universidad Los Leones",
    carreras: [
      "Administracion En Recursos Humanos",
      "Administracion Publica",
      "Ingenieria Comercial",
      "Licenciatura En Artes Visuales",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Psicologia",
      "Sociologia",
      "Trabajo Social",
      "Administracion En Recursos Humanos",
      "Administracion Publica",
      "Ingenieria Comercial",
      "Licenciatura En Artes Visuales",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Psicologia",
      "Sociologia",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Mayor",
    carreras: [
      "Administracion Publica",
      "Agronomia",
      "Animacion Digital",
      "Arquitectura",
      "Bachillerato En Ciencias",
      "Bachillerato En Ciencias De La Salud",
      "Bachillerato En Humanidades",
      "Biotecnologia",
      "Cine",
      "Composicion Musical",
      "Contador Publico Auditor",
      "Contador Publico Y Auditor",
      "Data Science",
      "Derecho",
      "Diseño Mencion Industrial/grafico/ambientes",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Administracion",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Construccion",
      "Ingenieria En Informatica",
      "Ingenieria En Logistica",
      "Ingenieria En Medio Ambiente Y Sustentabilidad",
      "Ingenieria En Prevencion De Riesgo",
      "Ingenieria Forestal",
      "Ingenieria Industrial",
      "Interprete Musical Superior En Canto Lirico",
      "Interprete Musical Superior En Canto Popular",
      "Interprete Musical Superior En Clarinete",
      "Interprete Musical Superior En Contrabajo",
      "Interprete Musical Superior En Corno",
      "Interprete Musical Superior En Fagot",
      "Interprete Musical Superior En Flauta Traversa",
      "Interprete Musical Superior En Guitarra Clasica",
      "Interprete Musical Superior En Oboe",
      "Interprete Musical Superior En Percusion Mencion Musica De Camara Y Orquesta",
      "Interprete Musical Superior En Piano",
      "Interprete Musical Superior En Trombon Mencion Musica De Camara Y Orquesta",
      "Interprete Musical Superior En Trompeta Mencion Musica De Camara Y Orquesta",
      "Interprete Musical Superior En Tuba Mencion Musica De Camara Y Orquesta",
      "Interprete Musical Superior En Viola",
      "Interprete Musical Superior En Violin",
      "Interprete Musical Superior En Violoncello",
      "Kinesiologia",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Artes Musicales Para La Educacion Basica Y Educacion Media",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica, Deportes Y Recreacion Para Educacion Basica Y Media",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Ingles Para Educacion Basica Y Media",
      "Periodismo",
      "Psicologia",
      "Psicopedagogia",
      "Sociologia",
      "Teatro",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Turismo Sustentable",
      "Administracion Publica",
      "Agronomia",
      "Animacion Digital",
      "Arquitectura",
      "Bachillerato En Ciencias",
      "Bachillerato En Ciencias De La Salud",
      "Bachillerato En Humanidades",
      "Biotecnologia",
      "Cine",
      "Composicion Musical",
      "Contador Publico Auditor",
      "Contador Publico Y Auditor",
      "Data Science",
      "Derecho",
      "Diseño Mencion Industrial/grafico/ambientes",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil En Computacion E Informatica",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Administracion",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Construccion",
      "Ingenieria En Informatica",
      "Ingenieria En Logistica",
      "Ingenieria En Medio Ambiente Y Sustentabilidad",
      "Ingenieria En Prevencion De Riesgo",
      "Ingenieria Forestal",
      "Ingenieria Industrial",
      "Interprete Musical Superior En Canto Lirico",
      "Interprete Musical Superior En Canto Popular",
      "Interprete Musical Superior En Clarinete",
      "Interprete Musical Superior En Contrabajo",
      "Interprete Musical Superior En Corno",
      "Interprete Musical Superior En Fagot",
      "Interprete Musical Superior En Flauta Traversa",
      "Interprete Musical Superior En Guitarra Clasica",
      "Interprete Musical Superior En Oboe",
      "Interprete Musical Superior En Percusion Mencion Musica De Camara Y Orquesta",
      "Interprete Musical Superior En Piano",
      "Interprete Musical Superior En Trombon Mencion Musica De Camara Y Orquesta",
      "Interprete Musical Superior En Trompeta Mencion Musica De Camara Y Orquesta",
      "Interprete Musical Superior En Tuba Mencion Musica De Camara Y Orquesta",
      "Interprete Musical Superior En Viola",
      "Interprete Musical Superior En Violin",
      "Interprete Musical Superior En Violoncello",
      "Kinesiologia",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia Y Puericultura",
      "Odontologia",
      "Pedagogia En Artes Musicales Para La Educacion Basica Y Educacion Media",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica, Deportes Y Recreacion Para Educacion Basica Y Media",
      "Pedagogia En Educacion Parvularia",
      "Pedagogia En Ingles Para Educacion Basica Y Media",
      "Periodismo",
      "Psicologia",
      "Psicopedagogia",
      "Sociologia",
      "Teatro",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Turismo Sustentable",
    ],
  },
  {
    institucion: "Universidad Metropolitana De Ciencias De La Educacion",
    carreras: [
      "Kinesiologia",
      "Licenciatura En Educacion Con Mencion En Aleman Y Pedagogia En Aleman",
      "Licenciatura En Educacion Con Mencion En Castellano Y Pedagogia En Castellano",
      "Licenciatura En Educacion Con Mencion En Frances Y Pedagogia En Frances",
      "Licenciatura En Educacion Con Mencion En Historia O Geografia Y Pedagogia En Historia, Geografia Y Educacion Civica",
      "Licenciatura En Educacion Con Mencion En Ingles Y Pedagogia En Ingles",
      "Licenciatura En Educacion En Biologia Y Pedagogia En Biologia Mencion Ciencias Naturales, O Informatica Educativa, O Estadistica Educacional, O Educacion En Tecnologia, O Educacion En Astronomia",
      "Licenciatura En Educacion En Fisica Y Pedagogia En Fisica Mencion Ciencias Naturales, O Informatica Educativa, O Estadistica Educacional, O Educacion En Tecnologia, O Educacion En Astronomia",
      "Licenciatura En Educacion En Quimica Y Pedagogia En Quimica Mencion Ciencias Naturales, O Informatica Educativa, O Estadistica Educacional, O Educacion En Tecnologia, O Educacion En Astronomia",
      "Licenciatura En Educacion Matematica Y Pedagogia En Matematica Mencion Ciencias Naturales, O Informatica Educativa, O Estadistica Educacional, O Educacion En Tecnologia, O Educacion En Astronomia",
      "Licenciatura En Educacion Y Pedagogia En Artes Visuales",
      "Licenciatura En Educacion Y Pedagogia En Educacion Basica Con Mencion En Matematica, O En Lenguaje Y Comunicacion, O En Ciencias Naturales, O En Ciencias Sociales, O En Primer Ciclo (santiago)",
      "Licenciatura En Educacion Y Pedagogia En Educacion Diferencial Especialidad Discapacidad Mental",
      "Licenciatura En Educacion Y Pedagogia En Educacion Diferencial Especialidad Problemas De Audicion Y Lenguaje",
      "Licenciatura En Educacion Y Pedagogia En Educacion Diferencial Especialidad Problemas De La Vision",
      "Licenciatura En Educacion Y Pedagogia En Educacion Fisica, Deportes Y Recreacion (damas)",
      "Licenciatura En Educacion Y Pedagogia En Educacion Fisica, Deportes Y Recreacion (varones)",
      "Licenciatura En Educacion Y Pedagogia En Educacion Parvularia Con Mencion En Ingles, O Educacion Comunitaria, O En Integracion Curricular, O En Educacion Artistica",
      "Licenciatura En Educacion Y Pedagogia En Filosofia",
      "Licenciatura En Educacion Y Pedagogia En Musica",
      "Licenciatura En Musica Y Direccion De Agrupaciones Musicales Instrumentales",
      "Kinesiologia",
      "Licenciatura En Educacion Con Mencion En Aleman Y Pedagogia En Aleman",
      "Licenciatura En Educacion Con Mencion En Castellano Y Pedagogia En Castellano",
      "Licenciatura En Educacion Con Mencion En Frances Y Pedagogia En Frances",
      "Licenciatura En Educacion Con Mencion En Historia O Geografia Y Pedagogia En Historia, Geografia Y Educacion Civica",
      "Licenciatura En Educacion Con Mencion En Ingles Y Pedagogia En Ingles",
      "Licenciatura En Educacion En Biologia Y Pedagogia En Biologia Mencion Ciencias Naturales, O Informatica Educativa, O Estadistica Educacional, O Educacion En Tecnologia, O Educacion En Astronomia",
      "Licenciatura En Educacion En Fisica Y Pedagogia En Fisica Mencion Ciencias Naturales, O Informatica Educativa, O Estadistica Educacional, O Educacion En Tecnologia, O Educacion En Astronomia",
      "Licenciatura En Educacion En Quimica Y Pedagogia En Quimica Mencion Ciencias Naturales, O Informatica Educativa, O Estadistica Educacional, O Educacion En Tecnologia, O Educacion En Astronomia",
      "Licenciatura En Educacion Matematica Y Pedagogia En Matematica Mencion Ciencias Naturales, O Informatica Educativa, O Estadistica Educacional, O Educacion En Tecnologia, O Educacion En Astronomia",
      "Licenciatura En Educacion Y Pedagogia En Artes Visuales",
      "Licenciatura En Educacion Y Pedagogia En Educacion Basica Con Mencion En Matematica, O En Lenguaje Y Comunicacion, O En Ciencias Naturales, O En Ciencias Sociales, O En Primer Ciclo (santiago)",
      "Licenciatura En Educacion Y Pedagogia En Educacion Diferencial Especialidad Discapacidad Mental",
      "Licenciatura En Educacion Y Pedagogia En Educacion Diferencial Especialidad Problemas De Audicion Y Lenguaje",
      "Licenciatura En Educacion Y Pedagogia En Educacion Diferencial Especialidad Problemas De La Vision",
      "Licenciatura En Educacion Y Pedagogia En Educacion Fisica, Deportes Y Recreacion (damas)",
      "Licenciatura En Educacion Y Pedagogia En Educacion Fisica, Deportes Y Recreacion (varones)",
      "Licenciatura En Educacion Y Pedagogia En Educacion Parvularia Con Mencion En Ingles, O Educacion Comunitaria, O En Integracion Curricular, O En Educacion Artistica",
      "Licenciatura En Educacion Y Pedagogia En Filosofia",
      "Licenciatura En Educacion Y Pedagogia En Musica",
      "Licenciatura En Musica Y Direccion De Agrupaciones Musicales Instrumentales",
    ],
  },
  {
    institucion: "Universidad Miguel De Cervantes",
    carreras: [
      "Auditoria",
      "Ciencia Politica Y Administracion Publica",
      "Derecho",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Recursos Humanos",
      "Ingenieria En Marketing",
      "Licenciatura En Educacion",
      "Psicologia",
      "Trabajo Social",
      "Auditoria",
      "Ciencia Politica Y Administracion Publica",
      "Derecho",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Recursos Humanos",
      "Ingenieria En Marketing",
      "Licenciatura En Educacion",
      "Psicologia",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Pedro De Valdivia",
    carreras: [
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria En Minas",
      "Kinesiologia",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Psicologia",
      "Tecnologia Medica",
      "Derecho",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria En Minas",
      "Kinesiologia",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Psicologia",
      "Tecnologia Medica",
    ],
  },
  {
    institucion: "Universidad San Sebastian",
    carreras: [
      "Administracion Publica",
      "Arquitectura",
      "Bachillerato En Ciencias De La Salud",
      "Bioquimica",
      "Contador Auditor",
      "Derecho",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Energia Y Sustentabilidad Ambiental",
      "Ingenieria En Gestion De Expediciones Y Ecoturismo",
      "Ingenieria En Gestion En Tecnologias De La Informacion",
      "Ingenieria En Logistica Y Transporte",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Tecnologias De La Informacion Y Comunicaciones",
      "Ingenieria Industrial",
      "Kinesiologia",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia",
      "Odontologia",
      "Pedagogia De Educacion Media En Historia Y Geografia",
      "Pedagogia De Educacion Media En Ingles",
      "Pedagogia De Educacion Media En Lenguaje Y Comunicacion",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica",
      "Programa De Formacion Pedagogica Para Licenciados Y/o Profesionales",
      "Psicologia",
      "Quimica Y Farmacia",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Administracion Publica",
      "Arquitectura",
      "Bachillerato En Ciencias De La Salud",
      "Bioquimica",
      "Contador Auditor",
      "Derecho",
      "Educacion Parvularia",
      "Enfermeria",
      "Fonoaudiologia",
      "Ingenieria Civil",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Energia Y Sustentabilidad Ambiental",
      "Ingenieria En Gestion De Expediciones Y Ecoturismo",
      "Ingenieria En Gestion En Tecnologias De La Informacion",
      "Ingenieria En Logistica Y Transporte",
      "Ingenieria En Prevencion De Riesgos",
      "Ingenieria En Tecnologias De La Informacion Y Comunicaciones",
      "Ingenieria Industrial",
      "Kinesiologia",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Medicina",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Obstetricia",
      "Odontologia",
      "Pedagogia De Educacion Media En Historia Y Geografia",
      "Pedagogia De Educacion Media En Ingles",
      "Pedagogia De Educacion Media En Lenguaje Y Comunicacion",
      "Pedagogia En Educacion Basica",
      "Pedagogia En Educacion Diferencial",
      "Pedagogia En Educacion Fisica",
      "Programa De Formacion Pedagogica Para Licenciados Y/o Profesionales",
      "Psicologia",
      "Quimica Y Farmacia",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Santo Tomas",
    carreras: [
      "Agronomia",
      "Agronomia (plan Especial)",
      "Agronomia Programa De Continuidad De Estudios",
      "Bachillerato En Ciencias",
      "Biotecnologia",
      "Ciencias Del Deporte Y Actividad Fisica",
      "Contador Publico Y Auditor",
      "Derecho",
      "Educacion Diferencial",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Industrial, Programa Continuidad De Estudios",
      "Ingenieria Comercial",
      "Ingenieria Comercial, Continuidad De Estudios",
      "Ingenieria En Control De Gestion",
      "Kinesiologia",
      "Licenciatura En Educacion, Continuidad De Estudios",
      "Licenciatura En Educacion, Programa Cerrado Continuidad De Estudios",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Fisica",
      "Periodismo",
      "Periodismo, Programa De Continuidad De Estudios",
      "Programa De Licenciatura De Trabajo Social",
      "Programa Licenciatura En Trabajo Social",
      "Psicologia",
      "Relaciones Publicas",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Agronomia",
      "Agronomia (plan Especial)",
      "Agronomia Programa De Continuidad De Estudios",
      "Bachillerato En Ciencias",
      "Biotecnologia",
      "Ciencias Del Deporte Y Actividad Fisica",
      "Contador Publico Y Auditor",
      "Derecho",
      "Educacion Diferencial",
      "Enfermeria",
      "Fonoaudiologia",
      "Geologia",
      "Ingenieria Civil En Minas",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Industrial, Programa Continuidad De Estudios",
      "Ingenieria Comercial",
      "Ingenieria Comercial, Continuidad De Estudios",
      "Ingenieria En Control De Gestion",
      "Kinesiologia",
      "Licenciatura En Educacion, Continuidad De Estudios",
      "Licenciatura En Educacion, Programa Cerrado Continuidad De Estudios",
      "Medicina Veterinaria",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Fisica",
      "Periodismo",
      "Periodismo, Programa De Continuidad De Estudios",
      "Programa De Licenciatura De Trabajo Social",
      "Programa Licenciatura En Trabajo Social",
      "Psicologia",
      "Relaciones Publicas",
      "Tecnologia Medica",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Sek",
    carreras: [
      "Administracion Publica",
      "Ciencias De La Actividad Fisica Y Del Deporte",
      "Derecho",
      "Diseño Y Desarrollo De Videojuegos",
      "Enfermeria",
      "Ingenieria Comercial",
      "Ingenieria En Ciberseguridad",
      "Ingenieria En Computacion E Informatica",
      "Kinesiologia",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Nutricion Y Dietetica",
      "Obstetricia Y Neonatologia",
      "Plan De Continuidad De Estudios En Ingenieria Comercial",
      "Psicologia",
      "Terapia Ocupacional",
      "Trabajo Social",
      "Administracion Publica",
      "Ciencias De La Actividad Fisica Y Del Deporte",
      "Derecho",
      "Diseño Y Desarrollo De Videojuegos",
      "Enfermeria",
      "Ingenieria Comercial",
      "Ingenieria En Ciberseguridad",
      "Ingenieria En Computacion E Informatica",
      "Kinesiologia",
      "Licenciatura En Educacion",
      "Licenciatura En Trabajo Social",
      "Nutricion Y Dietetica",
      "Obstetricia Y Neonatologia",
      "Plan De Continuidad De Estudios En Ingenieria Comercial",
      "Psicologia",
      "Terapia Ocupacional",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Tecnica Federico Santa Maria",
    carreras: [
      "Arquitectura",
      "Construccion Civil",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Metalurgia",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Civil Quimica",
      "Ingenieria Civil Telematica",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion Electronica",
      "Ingenieria De Ejecucion En Control E Instrumentacion Industrial",
      "Ingenieria De Ejecucion En Gestion De La Calidad",
      "Ingenieria De Ejecucion En Gestion Industrial",
      "Ingenieria De Ejecucion En Gestion Y Control Ambiental",
      "Ingenieria De Ejecucion En Proyectos Estructurales",
      "Ingenieria De Ejecucion En Software",
      "Ingenieria De Ejecucion Quimico Mencion Control",
      "Ingenieria Electrica",
      "Ingenieria En Aviacion Comercial",
      "Ingenieria En Construccion",
      "Ingenieria En Diseño De Productos",
      "Ingenieria En Fabricacion Y Diseño Industrial",
      "Ingenieria En Mantenimiento Industrial",
      "Ingenieria En Mecanica Industrial",
      "Ingenieria En Prevencion De Riesgos Laborales Y Ambientales",
      "Ingenieria En Proyectos De Ingenieria",
      "Ingenieria Informatica",
      "Licenciatura Astrofisica",
      "Licenciatura En Ciencias Mencion Fisica",
      "Licenciatura En Ciencias Mencion Quimica",
      "Quimico",
      "Tecnico Universitario Dibujante Proyectista",
      "Tecnico Universitario En Automatizacion Y Control",
      "Tecnico Universitario En Construccion",
      "Tecnico Universitario En Control Medio Ambiente",
      "Tecnico Universitario En Diseño Y Produccion Industrial En Moldes Y Matrices",
      "Tecnico Universitario En Electricidad",
      "Tecnico Universitario En Electronica",
      "Tecnico Universitario En Energias Renovables",
      "Tecnico Universitario En Gestion De Calidad En Alimentos",
      "Tecnico Universitario En Informatica",
      "Tecnico Universitario En Mantenimiento Aeronautico",
      "Tecnico Universitario En Mantenimiento Industrial",
      "Tecnico Universitario En Mecanica Automotriz",
      "Tecnico Universitario En Mecanica Industrial",
      "Tecnico Universitario En Mineria Y Metalurgia",
      "Tecnico Universitario En Prevencion De Riesgos",
      "Tecnico Universitario En Proyecto Y Diseño Mecanico",
      "Tecnico Universitario En Proyectos De Ingenieria",
      "Tecnico Universitario En Quimica Con Mencion En Quimica Analitica",
      "Tecnico Universitario En Quimica Con Mencion En Quimica Industrial",
      "Tecnico Universitario En Robotica Y Mecatronica",
      "Tecnico Universitario En Telecomunicaciones Y Redes",
      "Arquitectura",
      "Construccion Civil",
      "Ingenieria Civil",
      "Ingenieria Civil Ambiental",
      "Ingenieria Civil De Minas",
      "Ingenieria Civil Electrica",
      "Ingenieria Civil Electronica",
      "Ingenieria Civil Industrial",
      "Ingenieria Civil Informatica",
      "Ingenieria Civil Matematica",
      "Ingenieria Civil Mecanica",
      "Ingenieria Civil Metalurgia",
      "Ingenieria Civil Plan Comun",
      "Ingenieria Civil Quimica",
      "Ingenieria Civil Telematica",
      "Ingenieria Comercial",
      "Ingenieria De Ejecucion Electronica",
      "Ingenieria De Ejecucion En Control E Instrumentacion Industrial",
      "Ingenieria De Ejecucion En Gestion De La Calidad",
      "Ingenieria De Ejecucion En Gestion Industrial",
      "Ingenieria De Ejecucion En Gestion Y Control Ambiental",
      "Ingenieria De Ejecucion En Proyectos Estructurales",
      "Ingenieria De Ejecucion En Software",
      "Ingenieria De Ejecucion Quimico Mencion Control",
      "Ingenieria Electrica",
      "Ingenieria En Aviacion Comercial",
      "Ingenieria En Construccion",
      "Ingenieria En Diseño De Productos",
      "Ingenieria En Fabricacion Y Diseño Industrial",
      "Ingenieria En Mantenimiento Industrial",
      "Ingenieria En Mecanica Industrial",
      "Ingenieria En Prevencion De Riesgos Laborales Y Ambientales",
      "Ingenieria En Proyectos De Ingenieria",
      "Ingenieria Informatica",
      "Licenciatura Astrofisica",
      "Licenciatura En Ciencias Mencion Fisica",
      "Licenciatura En Ciencias Mencion Quimica",
      "Quimico",
      "Tecnico Universitario Dibujante Proyectista",
      "Tecnico Universitario En Automatizacion Y Control",
      "Tecnico Universitario En Construccion",
      "Tecnico Universitario En Control Medio Ambiente",
      "Tecnico Universitario En Diseño Y Produccion Industrial En Moldes Y Matrices",
      "Tecnico Universitario En Electricidad",
      "Tecnico Universitario En Electronica",
      "Tecnico Universitario En Energias Renovables",
      "Tecnico Universitario En Gestion De Calidad En Alimentos",
      "Tecnico Universitario En Informatica",
      "Tecnico Universitario En Mantenimiento Aeronautico",
      "Tecnico Universitario En Mantenimiento Industrial",
      "Tecnico Universitario En Mecanica Automotriz",
      "Tecnico Universitario En Mecanica Industrial",
      "Tecnico Universitario En Mineria Y Metalurgia",
      "Tecnico Universitario En Prevencion De Riesgos",
      "Tecnico Universitario En Proyecto Y Diseño Mecanico",
      "Tecnico Universitario En Proyectos De Ingenieria",
      "Tecnico Universitario En Quimica Con Mencion En Quimica Analitica",
      "Tecnico Universitario En Quimica Con Mencion En Quimica Industrial",
      "Tecnico Universitario En Robotica Y Mecatronica",
      "Tecnico Universitario En Telecomunicaciones Y Redes",
    ],
  },
  {
    institucion: "Universidad Tecnologica De Chile Inacap",
    carreras: [
      "Contador Auditor",
      "Enfermeria",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Informatica",
      "Ingenieria En Sonido",
      "Ingenieria Industrial",
      "Kinesiologia",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Media Mencion Artes Musicales",
      "Psicopedagogia",
      "Trabajo Social",
      "Contador Auditor",
      "Enfermeria",
      "Ingenieria Comercial",
      "Ingenieria En Administracion De Empresas",
      "Ingenieria En Informatica",
      "Ingenieria En Sonido",
      "Ingenieria Industrial",
      "Kinesiologia",
      "Nutricion Y Dietetica",
      "Pedagogia En Educacion Media Mencion Artes Musicales",
      "Psicopedagogia",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Universidad Tecnologica Metropolitana",
    carreras: [
      "Administracion Publica",
      "Arquitectura",
      "Bachillerato En Ciencias De La Ingenieria",
      "Bibliotecologia Y Documentacion",
      "Contador Publico Auditor",
      "Dibujante Proyectista",
      "Diseño En Comunicacion Visual",
      "Diseño Industrial",
      "Ingenieria Civil En Ciencia De Datos",
      "Ingenieria Civil En Computacion Mencion Informatica",
      "Ingenieria Civil En Electronica",
      "Ingenieria Civil En Mecanica",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil En Prevencion De Riesgos Y Medio Ambiente",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Administracion Agroindustrial",
      "Ingenieria En Biotecnologia",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Construccion",
      "Ingenieria En Geomensura",
      "Ingenieria En Gestion Turistica",
      "Ingenieria En Industria Alimentaria",
      "Ingenieria En Informatica",
      "Ingenieria En Quimica",
      "Ingenieria Industrial",
      "Quimica Industrial",
      "Trabajo Social",
      "Administracion Publica",
      "Arquitectura",
      "Bachillerato En Ciencias De La Ingenieria",
      "Bibliotecologia Y Documentacion",
      "Contador Publico Auditor",
      "Dibujante Proyectista",
      "Diseño En Comunicacion Visual",
      "Diseño Industrial",
      "Ingenieria Civil En Ciencia De Datos",
      "Ingenieria Civil En Computacion Mencion Informatica",
      "Ingenieria Civil En Electronica",
      "Ingenieria Civil En Mecanica",
      "Ingenieria Civil En Obras Civiles",
      "Ingenieria Civil En Prevencion De Riesgos Y Medio Ambiente",
      "Ingenieria Civil Industrial",
      "Ingenieria Comercial",
      "Ingenieria En Administracion Agroindustrial",
      "Ingenieria En Biotecnologia",
      "Ingenieria En Comercio Internacional",
      "Ingenieria En Construccion",
      "Ingenieria En Geomensura",
      "Ingenieria En Gestion Turistica",
      "Ingenieria En Industria Alimentaria",
      "Ingenieria En Informatica",
      "Ingenieria En Quimica",
      "Ingenieria Industrial",
      "Quimica Industrial",
      "Trabajo Social",
    ],
  },
  {
    institucion: "Otros",
    carreras: [
      "Otros",
    ]
  },
];

