import {
  SHOW_ALERT_ADMIN,
  COMENZAR_DESCARGA_USUARIO,
  DESCARGA_USUARIO_EXITO,
  DESCARGA_USUARIO_EXITO_INIT,
  DESCARGA_USUARIO_ERROR,
  DETENER_CARGA_USUARIO,
  OBTENER_USUARIO_ELIMINAR,
  USUARIO_ELIMINADO_ERROR,
  USUARIO_ELIMINADO_EXITO,
} from "../../types/typesAdmin";

import clientAxios from "../../../config/axios";

const showAlert = (data) => ({
  type: SHOW_ALERT_ADMIN,
  payload: data,
});



//OBTENER USUARIOS
export function obtenerUsersAction({ skip, query }) {
  return async (dispatch) => {
    dispatch(descargaUsuario());
    try {
      const respuesta = await clientAxios.put(
        `/api/usuarios/detalle/usuarios/${skip}`,
        query
      );
      setTimeout(() => {

        if (respuesta.data.length === 0 && skip !== 0) {
          dispatch(detenerCargaUsuario());
        } else {
          if (skip === 0) {
            dispatch(descargaExitoInitUsuario(respuesta.data));
          } else {
            for (let i = 0; i < respuesta.data.length; i++) {
              dispatch(descargaExitoUsuario(respuesta.data[i]));
            }
          }
        }
      }, 500);
    } catch (error) {
      console.log(error);
      dispatch(descargaErrorUsuario());
    }
  };
}

export function obtenerUsersEmpresaAction({ skip},idEmpresa) {
  return async (dispatch) => {
    dispatch(descargaUsuario());
    try {
      const respuesta = await clientAxios.get(
        `/api/usuarioEmpresa/obtener/perfiles/${idEmpresa}`
      );
      setTimeout(() => {

        if (respuesta.data.length === 0 && skip !== 0) {
          dispatch(detenerCargaUsuario());
        } else {
          if (skip === 0) {
            dispatch(descargaExitoInitUsuario(respuesta.data));
          } else {
            for (let i = 0; i < respuesta.data.length; i++) {
              dispatch(descargaExitoUsuario(respuesta.data[i]));
            }
          }
        }
      }, 500);
    } catch (error) {
      console.log(error);
      dispatch(descargaErrorUsuario());
    }
  };
}

const descargaUsuario = () => ({
  type: COMENZAR_DESCARGA_USUARIO,
});
const detenerCargaUsuario = () => ({
  type: DETENER_CARGA_USUARIO,
});
const descargaExitoUsuario = (data) => ({
  type: DESCARGA_USUARIO_EXITO,
  payload: data,
});
const descargaExitoInitUsuario = (data) => ({
  type: DESCARGA_USUARIO_EXITO_INIT,
  payload: data,
});
const descargaErrorUsuario = () => ({
  type: DESCARGA_USUARIO_ERROR,
});

//ELIMINAR
export function eliminarUsuarioAction(id) {
  return async (dispatch) => {
    dispatch(obtenerEliminarUsuario(id));
    try {
      await clientAxios.delete(`/api/usuarios/delete/usuario/${id}`);
      dispatch(eliminarUsuarioExito());
      dispatch(
        showAlert({ show: true, msg: "Cuenta de usuario eliminada.", type: "success" })
      );
    } catch (error) {
      console.log(error);
      dispatch(eliminarUsuarioError());
      dispatch(
        showAlert({ show: true, msg: error.response.data.msg, type: "error" })
      );
    }
  };
}

export function eliminarUsuarioEmpresaAction(id) {
  return async (dispatch) => {
    dispatch(obtenerEliminarUsuario(id));
    try {
      await clientAxios.delete(`/api/usuarioEmpresa/${id}`);
      dispatch(eliminarUsuarioExito());
      dispatch(
        showAlert({ show: true, msg: "Cuenta de usuario eliminada.", type: "success" })
      );
    } catch (error) {
      console.log(error);
      dispatch(eliminarUsuarioError());
      dispatch(
        showAlert({ show: true, msg: error.response.data.msg, type: "error" })
      );
    }
  };
}

const obtenerEliminarUsuario = (id) => ({
  type: OBTENER_USUARIO_ELIMINAR,
  payload: id,
});

const eliminarUsuarioExito = () => ({
  type: USUARIO_ELIMINADO_EXITO,
});

const eliminarUsuarioError = () => ({
  type: USUARIO_ELIMINADO_ERROR,
});
