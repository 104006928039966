import React from "react";
import Moment from 'react-moment';
import { useSelector } from "react-redux";
import "./HeaderHome.css";



const HeaderHome = () => {

  const usuario = useSelector((state) => state.auth.usuario);

  let lastLogin;
  if (usuario) {
    lastLogin = usuario.lastLogin;
  }

  //console.log(usuario)

  return (
    <div className="cont-header-home-b">
      <div className="cont-left-header-home">
        <p style={{ color: "#187ce2", margin: "0" }}>Home</p>
      </div>
      <div className="cont-right-header-home">
        {/* <SearchBar /> */}
      </div>
      {usuario ? <p className="last-login" style={{ color: "#bbb" }}>Último login: <Moment format="YYYY/MM/DD HH:mm:ss" className="last-login">{usuario ? lastLogin : null}</Moment></p> : null}
    </div>
  );
};

export default HeaderHome;
