//Auth and Register
export const REGISTRO_EXITOSO = "REGISTRO_EXITOSO";
export const REGISTRO_ERROR = "REGISTRO_ERROR";
export const COMENZAR_OBTENER_USUARIO = "COMENZAR_OBTENER_USUARIO";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";
export const ERROR_EMAIL = "ERROR_EMAIL";
export const RESET_EMAIL = "RESET_EMAIL";
export const ERROR_PASSWORD = "ERROR_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const INICIO_LOGIN = "INICIO_LOGIN";
export const COMENZAR_REGISTRO = "COMENZAR_REGISTRO";
export const SUBIR_IMAGEN = "SUBIR_IMAGEN";
export const COMENZAR_EDICION_USUARIO = "COMENZAR_EDICION_USUARIO";
export const USUARIO_EDITADO_EXITO = "USUARIO_EDITADO_EXITO";
export const USUARIO_EDITADO_ERROR = "USUARIO_EDITADO_ERROR";
export const USUARIO_EDITADO_EXITO_PASS = "USUARIO_EDITADO_EXITO_PASS";
export const OBTENER_USUARIO_ERROR = "OBTENER_USUARIO_ERROR";
//trabajos
export const AGREGAR_TRABAJO = "AGREGAR_TRABAJO";
export const AGREGAR_TRABAJO_EXITO = "AGREGAR_TRABAJO_EXITO";
export const AGREGAR_TRABAJO_ERROR = "AGREGAR_TRABAJO_ERROR";
export const COMENZAR_DESCARGA_TRABAJO = "COMENZAR_DESCARGA_TRABAJO";
export const DESCARGA_TRABAJO_EXITO = "DESCARGA_TRABAJO_EXITO";
export const DESCARGA_TRABAJO_ERROR = "DESCARGA_TRABAJO_ERROR";
export const OBTENER_TRABAJO_EDITAR = "OBTENER_TRABAJO_EDITAR";
export const COMENZAR_EDICION_TRABAJO = "COMENZAR_EDICION_TRABAJO";
export const TRABAJO_EDITADO_EXITO = "TRABAJO_EDITADO_EXITO";
export const TRABAJO_EDITADO_ERROR = "TRABAJO_EDITADO_ERROR";
export const OBTENER_TRABAJO_ELIMINAR = "OBTENER_TRABAJO_ELIMINAR";
export const TRABAJO_ELIMINADO_EXITO = "TRABAJO_ELIMINADO_EXITO";
export const TRABAJO_ELIMINADO_ERROR = "TRABAJO_ELIMINADO_ERROR";
//alerts
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDDEN_ALERT = "HIDDEN_ALERT";
//estudios
export const AGREGAR_ESTUDIO = "AGREGAR_ESTUDIO";
export const AGREGAR_ESTUDIO_EXITO = "AGREGAR_ESTUDIO_EXITO";
export const AGREGAR_ESTUDIO_ERROR = "AGREGAR_ESTUDIO_ERROR";
export const COMENZAR_DESCARGA_ESTUDIO = "COMENZAR_DESCARGA_ESTUDIO";
export const DESCARGA_ESTUDIO_EXITO = "DESCARGA_ESTUDIO_EXITO";
export const DESCARGA_ESTUDIO_ERROR = "DESCARGA_ESTUDIO_ERROR";
export const OBTENER_ESTUDIO_EDITAR = "OBTENER_ESTUDIO_EDITAR";
export const COMENZAR_EDICION_ESTUDIO = "COMENZAR_EDICION_ESTUDIO";
export const ESTUDIO_EDITADO_EXITO = "ESTUDIO_EDITADO_EXITO";
export const ESTUDIO_EDITADO_ERROR = "ESTUDIO_EDITADO_ERROR";
export const OBTENER_ESTUDIO_ELIMINAR = "OBTENER_ESTUDIO_ELIMINAR";
export const ESTUDIO_ELIMINADO_EXITO = "ESTUDIO_ELIMINADO_EXITO";
export const ESTUDIO_ELIMINADO_ERROR = "ESTUDIO_ELIMINADO_ERROR";
//certificados
export const AGREGAR_CERT = "AGREGAR_CERT";
export const AGREGAR_CERT_EXITO = "AGREGAR_CERT_EXITO";
export const AGREGAR_CERT_ERROR = "AGREGAR_CERT_ERROR";
export const COMENZAR_DESCARGA_CERT = "COMENZAR_DESCARGA_CERT";
export const DESCARGA_CERT_EXITO = "DESCARGA_CERT_EXITO";
export const DESCARGA_CERT_ERROR = "DESCARGA_CERT_ERROR";
export const OBTENER_CERT_EDITAR = "OBTENER_CERT_EDITAR";
export const COMENZAR_EDICION_CERT = "COMENZAR_EDICION_CERT";
export const CERT_EDITADO_EXITO = "CERT_EDITADO_EXITO";
export const CERT_EDITADO_ERROR = "CERT_EDITADO_ERROR";
export const OBTENER_CERT_ELIMINAR = "OBTENER_CERT_ELIMINAR";
export const CERT_ELIMINADO_EXITO = "CERT_ELIMINADO_EXITO";
export const CERT_ELIMINADO_ERROR = "CERT_ELIMINADO_ERROR";
//ADN-ZAP
export const AGREGAR_ADN = "AGREGAR_ADN";
export const AGREGAR_ADN_EXITO = "AGREGAR_ADN_EXITO";
export const AGREGAR_ADN_ERROR = "AGREGAR_ADN_ERROR";
export const COMENZAR_DESCARGA_ADN = "COMENZAR_DESCARGA_ADN";
export const DESCARGA_ADN_EXITO = "DESCARGA_ADN_EXITO";
export const DESCARGA_ADN_ERROR = "DESCARGA_ADN_ERROR";
export const OBTENER_ADN_EDITAR = "OBTENER_ADN_EDITAR";
export const COMENZAR_EDICION_ADN = "COMENZAR_EDICION_ADN";
export const ADN_EDITADO_EXITO = "ADN_EDITADO_EXITO";
export const ADN_EDITADO_ERROR = "ADN_EDITADO_ERROR";
export const OBTENER_ADN_ELIMINAR = "OBTENER_ADN_ELIMINAR";
export const ADN_ELIMINADO_EXITO = "ADN_ELIMINADO_EXITO";
export const ADN_ELIMINADO_ERROR = "ADN_ELIMINADO_ERROR";
//OFERTAS LABORALES
export const COMENZAR_DESCARGA_OFER_LABORAL = "COMENZAR_DESCARGA_OFER_LABORAL";
export const DESCARGA_OFER_LABORAL_EXITO = "DESCARGA_OFER_LABORAL_EXITO";
export const DESCARGA_OFER_LABORAL_ERROR = "DESCARGA_OFER_LABORAL_ERROR";
export const COMENZAR_FILTRAR_OF = "COMENZAR_FILTRAR_OF";
export const FILTRAR_EXITO_OF = "FILTRAR_EXITO_OF";
export const DETENER_CARGA_OF = "DETENER_CARGA_OF";
export const FILTRAR_ERROR_OF = "FILTRAR_ERROR_OF";
export const INIT_OBTENER_OF = "INIT_OBTENER_OF";
export const FILTRAR_EXITO_OF_INIT = "FILTRAR_EXITO_OF_INIT";

//POSTULACIONES
export const CREAR_POSTULACION = "CREAR_POSTULACION";
export const CREAR_POSTULACION_EXITO = "CREAR_POSTULACION_EXITO";
export const CREAR_POSTULACION_ERROR = "CREAR_POSTULACION_ERROR";
export const OBTENER_POSTULACION = "OBTENER_POSTULACION";
export const OBTENER_POSTULACION_EXITO = "OBTENER_POSTULACION_EXITO";
export const OBTENER_POSTULACION_ERROR = "OBTENER_POSTULACION_ERROR";
export const OBTENER_POST_ELIMINAR = "OBTENER_CERT_ELIMINAR";
export const POST_ELIMINADO_EXITO = "POST_ELIMINADO_EXITO";
export const POST_ELIMINADO_ERROR = "POST_ELIMINADO_ERROR";
