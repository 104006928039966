import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import "./alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import clienteAxios from "../../../config/axios";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? "#FF861B"
        : "#FF861B",
    color:"white"
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  cardPricingOriginal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
    marginTop: "-7px"
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};


export default function CardPlan(props) {
  const classes = useStyles();
  const { setOpenModal, setHidden, setStep, setTipoPlan } = props;
  const [items, setItems] = useState([]);
  const [planes, setPlanes] = useState([]);

  React.useEffect(() => {
    clienteAxios.get("/api/planes/obtenerPlanes").then((response) => {
      setPlanes(response.data);
    });
  }, []);
  const handleClose = (tier) => {
    setTipoPlan(tier);
    // setOpenModal(false);
    // setHidden(false);
    setStep("one");
  };

  useEffect(() => {
    const items2 = planes.map((tier, index) => (
      <div className="item-plan" data-value={index}>
        <Grid item key={tier.nombrePlan} xs={11} sm={11} md={11}>
          <Card>
            <CardHeader
              title={tier.nombrePlan}
              // subheader={tier.tipoPlan}
              titleTypographyProps={{ align: "center" }}
              subheaderTypographyProps={{ align: "center" }}
              action={tier.nombrePlan === "Avanzado" ? null : null}
              className={classes.cardHeader}
            />
            <CardContent>
              <div className={classes.cardPricing}>
                <Typography component="h2" variant="h4" className="h2naranja">
                  {tier.valorPlan==="0UF"?(<p className="margin0">PLAN <br/>GRATIS</p> ) :tier.valorPlan}
                </Typography>
              </div>
              {tier.valorPlanOriginal ? 
                <div className={classes.cardPricingOriginal}>
                  <Typography component="h7" variant="h7" className="h2naranja">
                    <span class="tachado">
                      {tier.valorPlanOriginal===""?"":tier.valorPlanOriginal}
                    </span>
                    <br/>{tier.detallePlan}
                  </Typography>
                </div>
                : ''}
              <ul>
                <Typography component="li" variant="subtitle1" align="center">
                  Límite de Publicaciones: 
                  {tier.limitePublicaciones == 0 ?
                    'Ilimitado'
                    :tier.limitePublicaciones }
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                  Duración : {tier.duracionPlan} {tier.duracionTipo}
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                  Número  de usuarios: 
                  {tier.numeroUsuarios >= 1000 ?
                    'Ilimitado'
                    :tier.numeroUsuarios }
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                  Descarga de CVs:&nbsp;
                  {tier.limiteDescargaCv == 'SI' && tier.limiteCvs == 0 ?
                    'Ilimitado'
                    :   tier.limiteCvs > 0 && tier.limiteCvs!=5000 ? 
                        tier.limiteCvs
                      : tier.limiteDescargaCv }
                </Typography>
                <Typography component="li" variant="subtitle1" align="center">
                  Difusión en Redes de Reclutamiento: {tier.difusionRedes}
                </Typography>
              </ul>
            </CardContent>
            <CardActions>
              {/*tier._id === "1" ? */}
                <Button
                fullWidth
                variant="contained"
                className="boton-naranja"
                color="primary"
                onClick={() => {
                  setTipoPlan(tier);
                  //setStep("one");
                  handleClose(tier);
                  //(window.location.href = window.location.origin+'/login-empresas/'+tier.urlPlan)
                }}
              >
                Solicitar
              </Button>
              {/*}:

                <Button
                href="https://zaptalent.cl/contacto/"
                target="_blank"
                fullWidth
                variant="contained"
                className="boton-naranja"
                color="primary">Solicitar</Button>
            }*/}
              
            </CardActions>
          </Card>
        </Grid>
      </div>
    ));
    setItems(items2);
  }, [planes]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md" className="planes-zap" component="main">
        <div className="container-titulo-planes">
          <Typography component="h2" variant="h3" color="white">
            PLANES
          </Typography>
        </div>
      
        <Grid container alignItems="center" maxHeight="600px">
          <AliceCarousel
            mouseTracking
            items={items}
            responsive={responsive}
            controlsStrategy="alternate"
            autoWidth={true}
          />
        </Grid>
        <Footer />
      </Container>
    </React.Fragment>
  );
}
