export const actEmpresa = [
  {
    Seleccionar: "Administracion",
  },
  {
    Seleccionar: "AgroIndustrial",
  },
  {
    Seleccionar: "Agropecuaria",
  },
  {
    Seleccionar: "Alimenticia",
  },
  {
    Seleccionar: "Arquitectura",
  },
  {
    Seleccionar: "Arte",
  },
  {
    Seleccionar: "Automotriz",
  },
  {
    Seleccionar: "Aérea",
  },
  {
    Seleccionar: "Banca / Finanzas",
  },
  {
    Seleccionar: "Biotecnologia",
  },
  {
    Seleccionar: "Call Center",
  },
  {
    Seleccionar: "Comercio",
  },
  {
    Seleccionar: "Comunicaciones",
  },
  {
    Seleccionar: "Construcción",
  },
  {
    Seleccionar: "Consultora",
  },
  {
    Seleccionar: "Consumo masivo",
  },
  {
    Seleccionar: "Correo",
  },
  {
    Seleccionar: "Defensa",
  },
  {
    Seleccionar: "Diseño",
  },
  {
    Seleccionar: "Editorial",
  },
  {
    Seleccionar: "Educación",
  },
  {
    Seleccionar: "Energia",
  },
  {
    Seleccionar: "Entretenimiento",
  },
  {
    Seleccionar: "Farmacéutica",
  },
  {
    Seleccionar: "Ferroviaria",
  },
  {
    Seleccionar: "Financiera",
  },
  {
    Seleccionar: "Forestal ",
  },
  {
    Seleccionar: "Ganaderia",
  },
  {
    Seleccionar: "Gastronomia",
  },
  {
    Seleccionar: "Gobierno",
  },
  {
    Seleccionar: "Higiene y Perfumería",
  },
  {
    Seleccionar: "Holding",
  },
  {
    Seleccionar: "Hoteleria",
  },
  {
    Seleccionar: "Imprenta",
  },
  {
    Seleccionar: "Industrial",
  },
  {
    Seleccionar: "Investigación",
  },
  {
    Seleccionar: "Informatica / Tecnología",
  },
  {
    Seleccionar: "inmobiliaria",
  },
  {
    Seleccionar: "Internet",
  },
  {
    Seleccionar: "Jurídica",
  },
  {
    Seleccionar: "Laboratorio",
  },
  {
    Seleccionar: "Manufactura",
  },
  {
    Seleccionar: "Medio Ambiente",
  },
  {
    Seleccionar: "Medios",
  },
  {
    Seleccionar: "Metalmecánica",
  },
  {
    Seleccionar: "Mineria / Petróleo / Gas",
  },
  {
    Seleccionar: "ONG",
  },
  {
    Seleccionar: "Optica",
  },
  {
    Seleccionar: "Papelera",
  },
  {
    Seleccionar: "Pesca",
  },
  {
    Seleccionar: "Plásticos",
  },
  {
    Seleccionar: "Publicidad / Marketing / RRPP",
  },
  {
    Seleccionar: "Quimica",
  },
  {
    Seleccionar: "Salud",
  },
  {
    Seleccionar: "Sector público",
  },
  {
    Seleccionar: "Seguridad",
  },
  {
    Seleccionar: "Seguros",
  },
  {
    Seleccionar: "Supermercado",
  },
  {
    Seleccionar: "Tabacalera",
  },
  {
    Seleccionar: "Telecomunicaciones",
  },
  {
    Seleccionar: "Textil",
  },
  {
    Seleccionar: "Transporte",
  },
  {
    Seleccionar: "Turismo",
  },
  {
    Seleccionar: "Otros",
  },
];
