import React, { useState, forwardRef, useEffect } from "react";
import "./Card.css";
import imguser from "../../../../resources/ZAPTalent-Icon-Empty.svg";
import { IconButton, MobileStepper } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import {
  Visibility,
  GetApp,
  ArrowBackIos,
  ArrowForwardIos,
  VisibilityOff,
} from "@material-ui/icons";
import { Tooltip } from "../../../../components";
import { useSelector, useDispatch } from "react-redux";
import { DialogCVPDF } from "../../../../componentsEmp";
import clientAxios from "../../../../config/axios";
import Swal from 'sweetalert2';
import { changeLeidoPostulanteAction } from "../../../../redux/actions/actions-emp/postuladosAction";

const Card = ({ data, setOpenModal, setDataUser, setOpen, setIdUser, props, setOpen1,descargasDisponibles }) => {
  const {
    nombres,
    apellidos,
    imageURL,
    profesion,
    anosZap,
    adns,
    leido,
    cvURL,
    id_post,
    idAviso
  } = data;
  
  const usuarioemp = useSelector((state) => state.authEmp.usuario);
  const [activeStep, setActiveStep] = useState(0);
  const [idUserGenerar, setIdUserGenerar] = useState(null);
  const [openGenerar, setOpenGenerar] = useState(false);
  const [cantDescargasEmpAct, setCantDescargasEmpAct] = useState(-1);
  const dispatch = useDispatch();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const verUsuario = () => {
    data.leido = true;
    setDataUser(data);
    setOpenModal(true);
    actualizarLeido();
  };

  const actualizarLeido = () =>{
    //console.log(id_post);
    dispatch(changeLeidoPostulanteAction(id_post));
  }

  const generarCV = async() => {
    if(usuarioemp && usuarioemp.plan.limiteDescargaCv == "SI" && usuarioemp.plan.limiteCvs>0)
    {
      if(usuarioemp && cantDescargasEmpAct && descargasDisponibles <= 0        
        || usuarioemp && usuarioemp.plan.limiteDescargaCv == "NO" ){
          Swal.fire('Atención!', 'Disculpe, ya no cuenta con disponibilidad para descarga. Puede contactar con nuestros ejecutivos para aumentar su plan, llamando al +56 9 7667 2851 o al correo contacto@zaptalent.cl', 'warning');
          //alert('Disculpe, ya no cuenta con disponibilidad para descarga. Puede contactar con nuestros ejecutivos para aumentar su plan, llamando al +56 9 7667 2851 o al correo contacto@zaptalent.cl');
        }else{
        setIdUserGenerar(data._id);
        setOpenGenerar(true);
      }
    }else {
      //Swal.fire('Atención!', 'Disculpe, ya no cuenta con disponibilidad para descarga. Puede contactar con nuestros ejecutivos para aumentar su plan, llamando al +56 9 7667 2851 o al correo contacto@zaptalent.cl', 'warning');
      setIdUserGenerar(data._id);
      setOpenGenerar(true);
    }
  };

  const setOpenActualizarDescargas = async(value) =>{
    if (value){
      getDescargasEmpresa();      
      setOpen1(false);
      data.leido = true;
      setDataUser(data);
    }
  }
  //NO ES
  const descargarCV = async() => {
    const fd = new FormData();
    fd.append("idUsuario", usuarioemp._id);
    fd.append("idPostulacion",id_post);
    fd.append("idAviso",idAviso);

    const result = await clientAxios.put(`/api/empresas/${usuarioemp.idemp}/descargas`, fd);
    setIdUserGenerar(data._id);
    setOpenGenerar(true);
  }

  useEffect(() => {
    //if (cantDescargasEmpAct==-1)
    //  getDescargasEmpresa();
  }, [cantDescargasEmpAct]);

  const getDescargasEmpresa = async() => {
    try {
      
      if (cantDescargasEmpAct==-1)
      {
        let _id = usuarioemp.idemp;
        let respuesta = await clientAxios.get(`/api/empresas/${_id}/total/descargas`);
        respuesta = respuesta.data;
        setCantDescargasEmpAct(respuesta.totalDescargas);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
  <>
  <DialogCVPDF
            open={openGenerar}
            setOpen={setOpenGenerar}
            setIdUser={setIdUser}
            idUser={idUserGenerar}
            setOpen1={setOpenActualizarDescargas}
            cvURL={cvURL}
            idPostulacion={id_post}
            idAviso={idAviso}
          />
          <div className="card-eco-sap-emp">
      <div className="foto-card-eco-sap-emp">
        {imageURL ? (
          <div>
            <img src={imageURL} alt="userimage" />
          </div>
        ) : (
          <div>
            <img
              style={{
                width: "84px",
                height: "84px",
                marginBottom: "-25px",
                marginTop: "-25px",
              }}
              src={imguser}
              alt="userimage"
            />
          </div>
        )}
      </div>
      <div
        style={{
          padding: "0 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "130px",
        }}
      >
        <p className="p1">
          {nombres} {apellidos}
        </p>
        <p className="p2">{profesion ? profesion.name : null}</p>
        <div className="anos-exp-eco-sap-card">
          <p>
            {anosZap === "1" ? anosZap + " año" : anosZap + " años"} de
            experiencia
          </p>
        </div>
      </div>
      <div>
        <div
          className="swip-home-emp"
          style={{ minHeight: "55px", width: "200px" }}
        >
          <div className="top-swip-home-emp">
            <div className="left-swip-home-emp">
              <IconButton
                className="btn-swip-home-emp"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <ArrowBackIos className="icon-swip-home-emp" />
              </IconButton>
            </div>
            <div className="center-swip-home-emp">
              {adns ? (
                adns.length === 0 ? (
                  <div className="no-posee-card-table-home-emp">
                    <p>No posee</p>
                  </div>
                ) : (
                  <Modulos activeStep={activeStep} data={adns} />
                )
              ) : null}
            </div>
            <div className="right-swip-home-emp">
              <IconButton
                className="btn-swip-home-emp"
                onClick={handleNext}
                disabled={activeStep === Math.ceil(adns.length / 3) - 1}
              >
                <ArrowForwardIos className="icon-swip-home-emp" />
              </IconButton>
            </div>
          </div>
          <div className="stepper-user-home-emp">
            <MobileStepperCustom
              activeStep={activeStep}
              lenghtArray={adns.length}
            />
          </div>
        </div>
      </div>
      <div className="bottom-card-eco-sap-emp">
        <Tooltip title="Ver usuario" placement="top">
          <IconButton className="btn-eco-sap-emp" onClick={verUsuario}>
            <Visibility />
          </IconButton>
        </Tooltip> 

        <Tooltip title="Descargar Curriculum Vitae" placement="top"> 
          {(usuarioemp && usuarioemp.plan.cvPostulantes==="N")?
              <IconButton className="btn-info-user-emp" onClick={() => generarCV()}>
              <GetApp style={{ color: "white" }} />
            </IconButton>
            :
              <IconButton
            
                className="btn-eco-sap-emp"
                target="_blank"
                rel="noopener noreferrer"
                // style={{ opacity: "0.5" }}
                //href={cvURL}
                download
                onClick={() => descargarCV()}
              >
                
                <GetApp style={{ color: "white" }} />
              </IconButton>
            }  
        </Tooltip>

      </div>
      {leido !== undefined ? (
        !leido ? (
          <Tooltip title="No leído" placement="top">
            <div className="cont-estado-no-leido">
              <VisibilityOff className="icon-view-no-leido" />
            </div>
          </Tooltip>
        ) : null
      ) : null}
    </div>

  </>
      );
};

export default Card;

const Modulos = ({ data, activeStep }) => {
  const [activo, setActivo] = useState(true);
  let arrayModulos = [];
  const longitudPedazos = 3;

  for (let i = 0; i < data.length; i += longitudPedazos) {
    let trozo = data.slice(i, i + longitudPedazos);
    arrayModulos.push(trozo);
  }

  return (
    <SwipeableViews index={activeStep}>
      {arrayModulos
        ? arrayModulos.map((item, index) => (
            <div key={index} className="swip-cont-home-emp">
              {item.map((item, index) => (
                <Tooltip key={index} title={item.desc}>
                  <div
                    className={
                      activo ? "swip-item-home-emp" : "wip-item-home-emp-inac "
                    }
                  >
                    <p
                      className={
                        item && item.name && item.name.length > 2 ? "name-submod-large" : null
                      }
                    >
                      {item.name}
                    </p>
                  </div>
                </Tooltip>
              ))}
            </div>
          ))
        : null}
    </SwipeableViews>
  );
};

const MobileStepperCustom = forwardRef((props, ref) => {
  const { activeStep, lenghtArray } = props;

  return (
    <MobileStepper
      ref={ref}
      variant="dots"
      steps={Math.ceil(lenghtArray / 3)}
      position="static"
      activeStep={activeStep}
      className="dots-one-adn"
    />
  );
});
