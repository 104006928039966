import React from "react";
import { useSelector } from "react-redux";
import { IconButton, ListItem } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import logo from "../../../../resources/img/ZAPTalent-Logotipo-Vertical-Original.svg";
import imageOffice from "../../../../resources/img/zaptalent-office.png";
import clientAxios from "../../../../config/axios";
import "./ModalConfirm.css";

const ModalConfirm = ({ setView }) => {

  const { usuario } = useSelector((state) => state.auth);

  const finishEnrollment = async (accept) => {
    await clientAxios.put(`/api/usuarios/completar-perfil/${usuario._id}`, {
      completarPerfil: accept,
    });
    setView("viewFinished")
  }

  return (
    <div className="contentModal">
      <div className="btn-close-registro">
        <IconButton onClick={() => console.log("cerrar modal")}>
          <Close />
        </IconButton>
      </div>
      <div className="top-reg-A">
        <img src={logo} alt="logo" className="logo-login-re" />
        <img
          src={imageOffice}
          className="contentModal-image "
          alt="Imagen office"
        />
        <p className="p-descp">
          ¿Estás de acuerdo en que completemos tu perfil por ti con la información
          que está en tu CV?
        </p>
        <div className="btnControls">
          <ListItem onClick={async () => await finishEnrollment(false)} button className="btn-step-one-emp btnModal red">
            <p>No estoy de acuerdo</p>
          </ListItem>
          <ListItem
            button
            className="btn-step-one-emp btnModal"
            onClick={async() => await finishEnrollment(true)}
          >
            <p>Si, estoy de acuerdo</p>
          </ListItem>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
