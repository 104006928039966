export const modulos = 
[
	{
		modulo: "ABAP",
		desc: "Programación SAP",
		isChecked: false,
		nivel: "",
		name: "ABAP",
	},
	{
		modulo: "APO",
		desc: "Demand Planning",
		isChecked: false,
		nivel: "",
		name: "APO",
	},
	{
		modulo: "ARIBA",
		desc: "Gestión de gastos",
		isChecked: false,
		nivel: "",
		name: "ARIBA",
	},
	{
		modulo: "B1",
		desc: "Business one",
		isChecked: false,
		nivel: "",
		name: "B1",
	  },
	  {
		modulo: "BASIS",
		desc: "Administración de Servidores y Bases de Datos",
		isChecked: false,
		nivel: "",
		name: "BASIS",
	},
	{
		modulo: "BI",
		desc: "Bussiness Intelligence",
		isChecked: false,
		nivel: "",
		name: "BI",
	},
	{
		modulo: "BO/BOBJ",
		desc: "Objetos de Negocios",
		isChecked: false,
		nivel: "",
		name: "BO/BOBJ",
	},
	{
		modulo: "BPC",
		desc: "Planificación y Consolidación",
		isChecked: false,
		nivel: "",
		name: "BPC",
	},
	{
		modulo: "BW",
		desc: "Bussiness wareHouse",
		isChecked: false,
		nivel: "",
		name: "BW",
    },
	{
		modulo: "CO",
		desc: "Gestión de costes",
		isChecked: false,
		nivel: "",
		name: "CO",
	},
	{
		modulo: "Commodity Management",
		desc: "Commodity Management",
		isChecked: false,
		nivel: "",
		name: "Commodity Management",
	},
	{
		modulo: "CPI",
		desc: "Base para realizar integraciones",
		isChecked: false,
		nivel: "",
		name: "CPI",
	},
	{
		modulo: "CRM",
		desc: "Gestión de Clientes",
		isChecked: false,
		nivel: "",
		name: "CRM",
	},
	{
		modulo: "CS",
		desc: "Servicios al cliente",
		isChecked: false,
		nivel: "",
		name: "CS",
	},
	{
		modulo: "Datos maestros",
		desc: "Datos para la ejecución de un negocio",
		isChecked: false,
		nivel: "",
		name: "Datos maestros",
	},
	{
		modulo: "EAM",
		desc: "Activos Empresariales",
		isChecked: false,
		nivel: "",
		name: "EAM",
	},
	{
		modulo: "EHS",
		desc: "Medio ambiente, salud y seguridad",
		isChecked: false,
		nivel: "",
		name: "EHS",
	},
	{
		modulo: "EWMS",
		desc: "EWNS",
		isChecked: false,
		nivel: "",
		name: "EWMS",
	},
	{
		modulo: "FI",
		desc: "Finanzas",
		isChecked: false,
		nivel: "",
		name: "FI",
	},
	{
		modulo: "FIORI",
		desc: "Desarrollo para Dispositivos Moviles",
		isChecked: false,
		nivel: "",
		name: "FIORI",
	},
	{
		modulo: "FX",
		desc: "Administración flexible de bienes inmuebles",
		isChecked: false,
		nivel: "",
		name: "FX",
	},
	{
		modulo: "GRC",
		desc: "Gobernanza, riesgo y cumplimiento",
		isChecked: false,
		nivel: "",
		name: "GRC",
	},
	{
		modulo: "HANA",
		desc: "HANA",
		isChecked: false,
		nivel: "",
		name: "HANA",
	},
	{
		modulo: "HCM",
		desc: "Gestión de recursos humanos",
		isChecked: false,
		nivel: "",
		name: "HCM",
	},
	{
		modulo: "MDM",
		desc: "Gestión de Datos Maestros",
		isChecked: false,
		nivel: "",
		name: "MDM",
	},
	{
		modulo: "MM",
		desc: "Gestión de materiales",
		isChecked: false,
		nivel: "",
		name: "MM",
	},
	{
		modulo: "NETWEAVER",
		desc: "Diseño de Portales para Empresas",
		isChecked: false,
		nivel: "",
		name: "NETWEAVER",
	},
	{
		modulo: "PI",
		desc: "Proceso de Integración",
		isChecked: false,
		nivel: "",
		name: "PI",
	},
	{
		modulo: "PM",
		desc: "Mantenimiento del centro",
		isChecked: false,
		nivel: "",
		name: "PM",
	},
	{
		modulo: "PP",
		desc: "Control y planificación de la producción",
		isChecked: false,
		nivel: "",
		name: "PP",
	},
	{
		modulo: "PS",
		desc: "Sistema de Proyecto",
		isChecked: false,
		nivel: "",
		name: "PS",
	},
	{
		modulo: "QM",
		desc: "Gestión de calidad",
		isChecked: false,
		nivel: "",
		name: "QM",
	},
	{
		modulo: "RE",
		desc: "Publicaciones periódicas “normales”",
		isChecked: false,
		nivel: "",
		name: "RE",
	},
	{
		modulo: "Roles y perfiles",
		desc: "Códigos para las transacciones",
		isChecked: false,
		nivel: "",
		name: "Roles y perfiles",
	},
	{
		modulo: "SAP Collections Management",
		desc: "SAP Collections Management",
		isChecked: false,
		nivel: "",
		name: "SAP Collections Management",
	},
	{
		modulo: "SAP IBP",
		desc: "Integrated Business Planning",
		isChecked: false,
		nivel: "",
		name: "SAP IBP",
	},
	{
		modulo: "SAP Security",
		desc: "Seguridad SAP",
		isChecked: false,
		nivel: "",
		name: "SAP Security",
	},
	{
		modulo: "SCM",
		desc: "Relación de Empresa con Proveedores",
		isChecked: false,
		nivel: "",
		name: "SCM",
	},
	{
		modulo: "SD",
		desc: "Ventas y distribución",
		isChecked: false,
		nivel: "",
		name: "SD",
	},
	{
		modulo: "SOLMAN",
		desc: "SOLMAN",
		isChecked: false,
		nivel: "",
		name: "SOLMAN",
	},
	{
		modulo: "SRM",
		desc: "Gestión de relaciones con Proveedores",
		isChecked: false,
		nivel: "",
		name: "SRM",
	},
	{
		modulo: "SUCCESS FACTORS",
		desc: "Desarrollo de Personas",
		isChecked: false,
		nivel: "",
		name: "SUCCESS FACTORS",
	},
	{
		modulo: "TR",
		desc: " Gestión de Tesorería",
		isChecked: false,
		nivel: "",
		name: "TR",
	},
	{
		modulo: "TM",
		desc: " Transportation Management",
		isChecked: false,
		nivel: "",
		name: "TM",
	},
	{
		modulo: "TRM",
		desc: "Treasury and Risk Management",
		isChecked: false,
		nivel: "",
		name: "TRM",
	},
	{
		modulo: "UI5",
		desc: "Conjunto de librerías Javascript para el desarrollo y diseño de aplicaciones",
		isChecked: false,
		nivel: "",
		name: "UI5",
	},
	{
		modulo: "VMS",
		desc: "Vehicle Management System",
		isChecked: false,
		nivel: "",
		name: "VMS",
	},
	{
		modulo: "VSS",
		desc: "Vehicle Sales and Service",
		isChecked: false,
		nivel: "",
		name: "VSS",
	},
	{
		modulo: "WM (Warehouse Management)",
		desc: "WM (Warehouse Management)",
		isChecked: false,
		nivel: "",
		name: "WM (Warehouse Management)",
	}
]