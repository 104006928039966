import React, { useState, useEffect } from "react";
import clientAxios from "../../../../config/axios";
import "./datosPersonales.css";
import { useHistory, useParams } from "react-router-dom";
import {
  CustomInput,
  CustomSelectB,
  IconButton as CustomIconButton,
  Button
} from "../../../../components";
import { MenuItem, LinearProgress, ListItem } from "@material-ui/core";
import { naciones } from "../../../../assets/nacionalidades";
import { regiones } from "../../../../assets/regiones";
import Swal from 'sweetalert2';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";

const DatosPersonales = () => {

  const initialState = {
    rut: '',
    nombres: '',
    apellidos: '',
    phone: '',
    email: '',
    fechaNacimiento: '',
    ecivil: '',
    sexo: '',
    nacion: [],
    region: [],
    comuna: [],
    direccion: '',
    pretencion: '',
    moneda: ''

  }

  const initialErrors = {
    rut: '',
    nombres: '',
    apellidos: '',
    phone: '',
    email: '',
    fechaNacimiento: '',
    ecivil: '',
    sexo: '',
    nacion: [],
    region: [],
    comuna: [],
    direccion: '',
    pretencion: '',
    moneda: ''
  }

  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);
  const [fechaNac, setFechaNac] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  const comunas = regiones.find((item) => item.region === inputs.region);

  const changeInit = (value) => {
    setFechaNac(value);
    setInputs({
      ...inputs,
      fechaNacimiento: value
    });
  };


  const updateFormValue = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value
    });

    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const updateFechaNacimiento = (fecha) => {

    setInputs({
      ...inputs,
      fechaNacimiento: fecha
    });
  }

  const updateMoneda = (moneda) => {
    setInputs({
      ...inputs,
      moneda: moneda
    });
  }


  var loading = false;

  const updateUser = (e) => {
    e.preventDefault();
    loading = true;
    clientAxios.put('/api/usuarios/' + id, inputs)
      .then(resp => {
        Swal.fire('Listo!', 'Datos actualizados', 'success');
        loading = false;
      }).catch(err => {
        for (let field in err.response.data.errors) {
          setErrors({
            ...errors,
            [field]: err.response.data.errors[field].message
          });
        }
      });

  }


  useEffect(() => {
    clientAxios.get(`/api/usuarios/${id}`)
      .then(res => {
        setInputs(res.data)
        setFechaNac(res.data.fechaNacimiento.replace(/-/g, '\/').replace(/T.+/, ''))
      }).catch(err => console.log(err));
  }, []);

  const back = (e) => {
    history.push('/admin/users');
  }

  const setMonedaSelectNivel = (e) => {
    updateMoneda(e.nativeEvent.target.outerText);
  }

  return (
    <>
      {inputs.rut ? (
        <CustomInput type="text" name="rut" value={inputs.rut} onChange={updateFormValue} label="Rut" />
      ) : (inputs.passport ? (
        <CustomInput type="text" name="passport" value={inputs.passport} onChange={updateFormValue} label="Pasaporte" />
      ):(inputs.dni ? (
        <CustomInput type="text" name="dni" value={inputs.dni} onChange={updateFormValue} label="Dni" />
      ):''))}
      
      {errors.rut && <span style={{ color: 'red' }}>{errors.rut}</span>}

      <CustomInput type="text" name="nombres" value={inputs.nombres} onChange={updateFormValue} label="Nombres" />
      {errors.nombres && <span style={{ color: 'red' }}>{errors.nombres}</span>}

      <CustomInput type="text" name="apellidos" value={inputs.apellidos} onChange={updateFormValue} required label="Apellidos" />
      {errors.apellidos && <span style={{ color: 'red' }}>{errors.apellidos}</span>}

      <CustomInput type="text" name="phone" value={inputs.phone} onChange={updateFormValue} required label="Telefono" />
      {errors.phone && <span style={{ color: 'red' }}>{errors.phone}</span>}

      <CustomInput type="text" name="email" value={inputs.email} onChange={updateFormValue} required label="Email" />
      {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}

      <br />
      <br />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>

        <KeyboardDatePicker
          error={errors.fechaNacimiento}
          size="small"
          label="Fecha nacimiento"
          fullWidth
          openTo="year"
          value={fechaNac}
          format="dd/MM/yyyy"
          maxDate={new Date()}
          onChange={(newValue) => changeInit(newValue)}
          InputProps={{
            className: "input-date-picker-inicio",
            readOnly: true,
          }}
          className="date-picker-inicio"
          InputLabelProps={{ className: "input-label-date-form" }}
        />


      </MuiPickersUtilsProvider>

      <CustomSelectB
        label="Estado Civil"
        value={inputs.ecivil}
        name="ecivil"
        onChange={updateFormValue}
        defaultValue={inputs.ecivil}
      >
        <MenuItem className="custom-menu-item" value="Soltero/a">
          Soltero/a
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Casado/a">
          Casado/a
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Viudo/a">
          Viudo/a
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Divorciado/a">
          Divorciado/a
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Conviviente civil">
          Conviviente civil
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Separado">
          Separado
        </MenuItem>
      </CustomSelectB>

      <CustomSelectB
        label="Sexo"
        size="small"
        onChange={updateFormValue}
        helpertext="Selecciona un sexo"
        value={inputs.sexo}
        name="sexo"
      >
        <MenuItem className="custom-menu-item" value="Masculino">
          Masculino
        </MenuItem>
        <MenuItem className="custom-menu-item" value="Femenino">
          Femenino
        </MenuItem>
      </CustomSelectB>

      <CustomSelectB
        label="Nacionalidad"
        value={inputs.nacion}
        onChange={updateFormValue}
        name="nacion"
      >
        {naciones.map((item, index) => (
          <MenuItem key={index} className="custom-menu-item" value={item}>
            {item}
          </MenuItem>
        ))}
      </CustomSelectB>

      <CustomSelectB
        label="Región"
        helpertext="Seleccione una región"
        value={inputs.region}
        onChange={updateFormValue}
        name="region"
      >
        {regiones.map((item, index) => (
          <MenuItem className="custom-menu-item" key={index} value={item.region}>
            {item.region}
          </MenuItem>
        ))}
      </CustomSelectB>

      <CustomSelectB
        label="Comuna"
        size="small"
        onChange={updateFormValue}
        value={inputs.comuna}
        helpertext="Seleccione una comuna"
        name="comuna"
      >
        {comunas ? (
          comunas.comunas.map((item, index) => (
            <MenuItem className="custom-menu-item" key={index} value={item}>
              {item}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="">Seleccione una región</MenuItem>
        )}
      </CustomSelectB>

      <CustomInput type="text" name="direccion" value={inputs.direccion} onChange={updateFormValue} required label="Dirección" />
      {errors.direccion && <span style={{ color: 'red' }}>{errors.direccion}</span>}

      <CustomInput type="text" name="pretencion" value={inputs.pretencion} onChange={updateFormValue} required label="Pretencion renta" />
      {errors.pretencion && <span style={{ color: 'red' }}>{errors.pretencion}</span>}

      <CustomSelectB
        label="Moneda"
        size="small"
        onChange={setMonedaSelectNivel}
        value={inputs.moneda}
        helpertext="Seleccione una moneda"
        name="moneda"
      >
          <MenuItem value="CLP">CLP</MenuItem>
          <MenuItem value="USD">USD</MenuItem>
      </CustomSelectB>

      <br />
      <br />
      <div className="bottom-form-estudios">
        <ListItem button className="btn-form-estudio" onClick={updateUser}>
          <p style={{ color: "white" }}>Guardar</p>
        </ListItem>
      </div>
      {loading ? (
        <>
          <div className="overlay-loading"></div>
          <div className="linear-progres-global">
            <LinearProgress className="progres-editar-perfil" />
          </div>
        </>
      ) : null}

    </>
  );
}

export default DatosPersonales;