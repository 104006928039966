import React, { useState } from "react";
import { Modal, Fade, Backdrop, makeStyles } from "@material-ui/core";
import One from "../addSM/One";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const CustomModal = (props) => {
  const classes = useStyles();

  const {
    setData,
    setOpenModal,
    openModal,
    dataEditar,
  } = props;
  const [view, setView] = useState("default");
  const usuario = useSelector((state) => state.auth.usuario);
  /*const [submodulos, setSubmodulos] = useState(
    dataEditar ? dataEditar.submodulos : null
  );
  const [submodulosuser, setSubmodulosUser] = useState(
    usuario ? usuario.submodulos : null
  );*/
  const cancelarEditar = () => {
    setOpenModal(false);
    setTimeout(() => {
      setData(null);
      setView("default");
    }, 500);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={() => cancelarEditar()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
           <One
            setView={setView}
            dataEditar={[]}
            arraySubMod={[]}
            setArraySubMod={[]}
            setDataEditar={setData}
            //submodulos={submodulos}
            //setSubmodulos={setSubmodulos}
            //submodulosuser={submodulosuser}
          />
      </Fade>
    </Modal>
  );
};

export default CustomModal;
