import React from "react";
import "./SectionSeven.css";

const SectionSeven = () => {
  return (
    <div className="cont-section-seven" id="section-seven">
      <div className="sub-section-seven">
        <div>
          <h1>Loren Ipsum is simply dummy</h1>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Atque
            veritatis accusantium ipsum eos pariatur quod. Exercitationem sint
            praesentium mollitia ut?
          </p>
        </div>
        <div>
          <h1>Loren Ipsum is simply dummy</h1>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Atque
            veritatis accusantium ipsum eos pariatur quod. Exercitationem sint
            praesentium mollitia ut?
          </p> 
        </div>
        <div>
          <h1>Loren Ipsum is simply dummy</h1>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Atque
            veritatis accusantium ipsum eos pariatur quod. Exercitationem sint
            praesentium mollitia ut?
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionSeven;
