import _ from 'lodash';
import React, { useCallback, useEffect, useState } from "react";
import { modulos } from "../../../../assets/modulos";
import {
  ListItem,
  Snackbar,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import "./styles.css";
import { Alert } from "@material-ui/lab";
import { CloudUpload } from "@material-ui/icons";
import { useSelector } from 'react-redux';

const LEVEL_INIT_VALUE = 'No Maneja';

const One = (props) => {
  const { setView, dataEditar, setDataEditar } = props;
  const { adns } = useSelector((state) => state.adn);
  const nombreModulosRepetidos = adns.map((i) => i.name);
  // get modulos no creados
  const modulosNoRepeat = _.filter(modulos, (i) => !nombreModulosRepetidos.includes(i.modulo));
  const [selectModulo, setSelectModulo] = useState(null);
  const [selectModuloLabelValue, setSelectModuloLabelValue] = useState("");
  const [idCertificacion, setIdCertificacion] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [nivel, setNivel] = useState('');
  const [desc, setDesc] = useState('');
  const [_alert, setAlert] = useState(false);
  const [msg, setMsg] = useState("");
  //const [subModulos, setSubModulos] = useState([]);
  //const [dataSelectSubModulos, setDataSelectSubModulos] = useState([]);

  const [file, setFile] = useState(
    dataEditar ? (dataEditar.adnURL ? dataEditar.adnURL : null) : null
  );

  
  const fileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  // INIT 
  useEffect(() => {
      if(_.isEmpty(dataEditar))
      return;

      setSelectModulo(dataEditar);
      setDesc(dataEditar.desc);
      setIdCertificacion(dataEditar.idcert);
      setObservaciones(dataEditar.obs)
      setNivel(dataEditar.nivel)

      //buscar modulo
      /*let udpateData =  dataEditar.submodulos;
      const { submodulos } = modulos.find((item) => item?.modulo === dataEditar?.name);

      // poblar data previamente guardada
      const updateSubmodules = _.map(submodulos, (item) => {
        let index = _.findIndex(udpateData, (i) => i?.submodulo === item.submodulo || i?.name === item.submodulo);
        if(index >= 0){
          return {...item, nivel: udpateData[index].nivel, obs: udpateData[index]?.obs ? udpateData[index]?.obs : ''}
        }
        return item;
      });

      setDataSelectSubModulos(updateSubmodules);*/

  }, [dataEditar]);
 

  const handleClose = () => {
    setAlert(false);
  };

  const handleSubmitForm = (e) => validateForm();

  const validateForm = () => {
    let selectValue = false;
    modulosNoRepeat.filter((product, i)=>{
      if (product.isChecked){
        selectValue = true;
      }
    });

    if (!selectValue && _.isEmpty(dataEditar)) {
      setAlert(true);
      setMsg("Debes seleccionar al menos un módulo");
    } else {
      setAlert(false);
      setMsg("");
      sendForm();
    }
  };

  //const validateSubModulo = () => _.isEmpty(_.find(dataSelectSubModulos, (item) => item.nivel !== LEVEL_INIT_VALUE));

  const setValueSelectModulos = (e) => {
    setSelectModuloLabelValue(e.target.value);
    setSelectModulo({
      name: e.target.value,
      desc: e.nativeEvent.target.outerText,
      id: "",
      obs: "",
      nivel: "",
      file: "",
    });

    // set submodules
    /*const updateSubModulos = _.find(modulos, { 'modulo': e.target.value });
    if(updateSubModulos){
      setSubModulos(updateSubModulos.submodulos);
    }else{
      setSubModulos([]);
    }*/
  };

  const setValueSelectNivel = (e) => {
    setNivel(e.nativeEvent.target.outerText);
  }

  /*const setInputSubMod = (text, submod) => {
    const updateItem = dataSelectSubModulos.map((item, index) => ({
      ...item,
      obs: (item.submodulo === submod) ? text : (item?.obs) ? item.obs : '',
    }))
    setDataSelectSubModulos(updateItem);
  };*/

  /*const setSelectSubMod = (nivel, submod) => {
    const updateItem = dataSelectSubModulos.map((item, index) => {
      const name = item?.submodulo ? item.submodulo : item.name;
      const updateNivel = (name === submod) ? nivel : item.nivel ? item.nivel  : LEVEL_INIT_VALUE;
      return {
        ...item,
        nivel: updateNivel
      }
    });
    setDataSelectSubModulos(updateItem);
  };*/

  const sendForm = useCallback(() => {
    //const submodulos = dataSelectSubModulos;
    const modulosChecked = modulosNoRepeat.filter((modulo)=> modulo.isChecked==true);
    let data = {modulosList: modulosChecked, type:'Lista'};

    if(_.isEmpty(dataEditar)){
      //console.log(data);
      setDataEditar(data);
    }


    if(!_.isEmpty(dataEditar)){
      data = {
        ...dataEditar,
        //submodulos: _.filter(submodulos, (i) => i.nivel !== LEVEL_INIT_VALUE && !!i.nivel),
        adnURL: file,
        name: selectModulo.name,
        desc: selectModulo.desc,
        nivel:nivel,
        idcert: idCertificacion,
        obs: observaciones,
  
      };
      setDataEditar(data);
    }
    setView("default");
  }, [idCertificacion, observaciones,nivel, file, selectModulo]);//, dataSelectSubModulos

  const addItem = (selectedValue) => {
    //let i = e.target.value;
    
    modulosNoRepeat.filter((product, i)=>{

      if (product.modulo == selectedValue){
        modulosNoRepeat[i].isChecked = true;  

        return;
      }
   });

   console.log(modulosNoRepeat);
  }


  const setValueSelectNivelModulo = (e, modulo) => {
    setNivel(e);

    modulosNoRepeat.filter((product, i)=>{

      if (e == 'Nivel' && product.modulo == modulo){
        modulosNoRepeat[i].isChecked = false;
        document.getElementById(i).checked = false;
      }else if (e != 'Nivel' && product.modulo == modulo) {
        modulosNoRepeat[i].isChecked = true;
        document.getElementById(i).checked = true;
      }
      if (product.modulo == modulo){
        modulosNoRepeat[i].nivel = e;
        return;
      }
 
    });

  }
  /*const getNivel = (index) => {
    const nivel = dataSelectSubModulos[index]?.nivel
    return (nivel || LEVEL_INIT_VALUE);
  }

  const getModuleName = (item) => item?.name ? item.name : item.submodulo;*/

  return (
    <div className="cont-four-adn-formeditar-edit">
      <div className="cont-one-adn-edit cont-four-adn-editar-edit">
        <div className="stepper-one-adn">


          <h1>{_.isEmpty(dataEditar) ? '¿En qué eres consultor?' : 'Editar Módulo SAP'}</h1>
          

             {_.isEmpty(dataEditar) ? (
                <>{/*<p value="">Seleccione un módulo SAP</p>*/}
                <div className="cont-a-convenir-actual" style={{height: '450px', overflowY: 'scroll', display: 'flex', alignItems: 'flex-start', flex: 'auto'}}>
                  <>
                    <table>
                      <tbody>
                        {modulosNoRepeat.map((item, index) => <>
                          <tr key={index}>
                            <td><input
                              type="checkbox"
                              name={item.modulo}
                              value={item.modulo}
                              id={index}

                              onChange={(e) => addItem(e.target.value)} />
                              <label>{item.modulo + ' - ' + item.desc}</label>
                            </td>
                            <td>
                              <Select
                                id = {"item_"+item.modulo}
                                style={{ fontSize: '12px', color: '#7d7f80', padding: '5px 0 5px 5px', marginTop: "0px", width: "100%", minWidth: "103px" }}
                                //value={nivel}
                                defaultValue={"Nivel"}
                                onChange={(e) => setValueSelectNivelModulo(e.target.value, item.modulo)}
                              >
                                <MenuItem value={"Nivel"}>
                                  Nivel
                                </MenuItem>
                                <MenuItem value="No Maneja">No Maneja</MenuItem>
                                <MenuItem value="Básico">Básico</MenuItem>
                                <MenuItem value="Medio">Medio</MenuItem>
                                <MenuItem value="Avanzado">Avanzado</MenuItem>
                              </Select>
                            </td>
                          </tr>
                        </>
                        )}
                      </tbody>
                    </table>
                  </>
                </div></>





         ) :  <><p className='p1-card-adn-desc-left'>
                  {selectModulo?.name}
                </p>
                <Select
                      style={{ marginTop: "12px", width: "100%" }}
                      value={nivel}
                      displayEmpty 
                      onChange={setValueSelectNivel}
                    >
                        <MenuItem value="" disabled>
                          Nivel
                        </MenuItem>
                        <MenuItem value="No Maneja">No Maneja</MenuItem>
                        <MenuItem value="Básico">Básico</MenuItem>
                        <MenuItem value="Medio">Medio</MenuItem>
                        <MenuItem value="Avanzado">Avanzado</MenuItem>
                  </Select>
              </>
              }


              

              
              <TextField
                type="hidden"
                style={{ marginTop: "0px", width: "100%" }}
                id="standard-basic"
                value={idCertificacion}
                onChange={(e) => setIdCertificacion(e.target.value)}
              />

              {/*<TextField
                style={{ marginTop: "0px", width: "100%" }}
                id="standard-basic"
                label="Observaciones"
                value={observaciones}
                onChange={(e) => setObservaciones(e.target.value)}
                    />*/}
              
              {/*<div className="cont-btn-añadir-submod">
                <div style={{ marginTop: "22px", width: "100%" }}>
                  <input
                    type="file"
                    id={`upload-file-edit-module`}
                    onChange={(e) => fileChange(e)}
                    style={{ display: "none" }}
                    name="adnURL"
                    accept="application/pdf, image/png, .jpeg, .jpg, image/gif, .doc, .docx"
                  />
                  <TextField
                    fullWidth
                    size="small"
                    id="input-with-icon-textfield"
                    value={file ? (file.name ? file.name : file) : null}
                    defaultValue="Selecciona un archivo"
                    InputLabelProps={{
                      className: "input-file-estudios",
                    }}
                    InputProps={{
                      readOnly: true,
                      className: "input-file-estudios",
                      endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                              <label
                                htmlFor={`upload-file-edit-module`}
                                style={{ cursor: "pointer" }}
                              >
                                <CloudUpload className="icon-file-estudios" />
                              </label>
                            </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>*/}

            </div>
          

        {/* <div className={dataSelectSubModulos.length > 0 ? 'cont-sub-modulos' : 'cont-sub-modulos hide'}>
          {dataSelectSubModulos.map((item, index) =>
               <div className="cont-sub-modulos-box" key={index}>
                <p className="cont-sub-modulos-mod">{getModuleName(item)}</p>
                <p className="cont-sub-modulos-descp">{item.desc}</p>
                <Select
                  style={{
                    marginTop: "10px",
                    width: "50%",
                    textAlign: "center",
                  }}
                  value={getNivel(index)}
                  onChange={(e) => {
                    const name = item?.submodulo ? item.submodulo : item.name;
                    setSelectSubMod(e.target.value, name)
                  }
                    
                  }
                >
                  <MenuItem value="" disabled>
                    Nivel
                  </MenuItem>
                  <MenuItem value={LEVEL_INIT_VALUE}>No Maneja</MenuItem>
                  <MenuItem value="Basico">Básico</MenuItem>
                  <MenuItem value="Medio">Medio</MenuItem>
                  <MenuItem value="Avanzado">Avanzado</MenuItem>
                </Select>
                <TextField
                  className='comments-input'
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    textAlign: "center",
                  }}
                  value={dataSelectSubModulos[index].obs}
                  placeholder="Comentarios (opcional)"
                  onChange={(e) =>
                    setInputSubMod(e.target.value, item.submodulo, item.desc)
                  }
                />
              </div>
        
          )}
        </div> */}

        <div className="cont-btns-one-adn-edit">
          <ListItem
            button
            className="btn-adnzap-modal"
            style={{ color: "white" }}
            onClick={handleSubmitForm}
          >
            <p>Guardar</p>
          </ListItem>
        </div>
        <Snackbar open={_alert} onClose={handleClose} autoHideDuration={6000}>
          <Alert severity="error">{msg}</Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default One;
