import React, { useRef, useState } from "react";
import "./Table.css";
import Header from "./header/Header";
import Item from "./item/Item";
import { useContainerDimensions } from "../../../hooks/useResize";
import Loader from "react-loader-spinner";
import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const Table = ({
  usuarios,
  totalEmpresas,
  setOpenEditPerfil,
  setDataEditar,
  setOpenViewEmp,
  setDataView,
  setDataAddPerfil,
  setIdEliminar,
  setOpenModalEliminar,
  handleScroll,
  loading,
  value,
  setValue,
  setQuery,
  _switch,
  setSkip,
  setSwitch,
  query,
  setOpenModalRep,
  step,
  setStep,
  setUsuarioSelected,
  setOpenAddPerfil,
  usuarioemp
}) => {
  const refContentTable = useRef();

  const { height } = useContainerDimensions(refContentTable);

  return (
    <div className="cont-table-home-admin">
      <div className="table-center-empresa" ref={refContentTable}>
        <p class="p1-profile">Perfiles asociados a cuenta: </p>
        <div
          className="sub-item"
          style={{ maxHeight: height }}
          onScroll={handleScroll}
        >
          {usuarios.map((item, index) => (
            <Item
              key={index}
              data={item}
              setOpenEditPerfil={setOpenEditPerfil}
              setDataEditar={setDataEditar}
              setOpenViewEmp={setOpenViewEmp}
              setDataView={setDataView}
              setOpenAddPerfil={setOpenAddPerfil}
              setDataAddPerfil={setDataAddPerfil}
              setIdEliminar={setIdEliminar}
              setOpenModalEliminar={setOpenModalEliminar}
              setOpenModalRep={setOpenModalRep}
              setStep={setStep}
              setUsuarioSelected={setUsuarioSelected}
              usuarioemp={usuarioemp}
            />
          ))}
          {loading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader
                type="Oval"
                color="#00BFFF"
                height={50}
                width={50}
                visible={loading}
              //  timeout={3000} //3 secs
              />
            </div>
          )}
        </div>
      </div>
      {
        /*
        <div className="table-bottom">
          <div>

            <p>{totalEmpresas} Cuentas Empresas</p>

          </div>
        </div>*/
      }
    </div>
  );
};

export default Table;
