import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Modal, Fade, Backdrop, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { One, Five } from "../steps";
import { agregarEmpresaAction } from "../../../redux/actions/actions-admin/empresasAction";
import clienteAxios from "../../../config/axios";
import CardPlan from "../cardPlan/CardPlan";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const CustomModalEmpresa = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    setOpenModal,
    openModal,
    setHidden,
    plan
  } = props;

  const [view, setView] = useState("plan");

  //reg-a
  const [passport, setPassport] = useState("");
  const [phone, setPhone] = useState("");
  const [ecivil, setEcivil] = useState("");
  const [nacion, setNacion] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRpassword] = useState("");
  const [sexo, setSexo] = useState("");
  const [inputRut, setInputRut] = useState(true);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  //reg-b
  const [region, setRegion] = useState("");
  const [comuna, setComuna] = useState("");
  const [direccion, setDireccion] = useState("");
  const [pais, setPais] = useState("");
  //reg-c
  const [consultor, setConsultor] = useState("");
  const [anosExp, setAnosExp] = useState("");
  const [anosZap, setAnosZap] = useState("");
  const [pretencion, setPretencion] = useState("");
  const [fileCV, setfileCV] = useState("");
  const [step, setStep] = useState("plan");
  //STEP ONE
  const [image, setImage] = useState({
    preimage: null,
    name: "",
  });
  const [file, setFile] = useState(null);
  const [tipoPlan, setTipoPlan] = useState({});
  const [razonSocial, setRazonSocial] = useState("");
  const [rut, setRut] = useState("");
  const [giro, setGiro] = useState("");
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaTermino, setFechaTermino] = useState(null);
  const [resena, setResena] = useState("");
  //STEP TWO
  const [direcciones, setDirecciones] = useState([]);
  //STEP THREE
  const [telefonos, setTelefonos] = useState([]);
  //STEP FOUR
  const [perfiles, setPerfiles] = useState([]);
  //guardar aviso
  const [loading, setLoading] = useState(false);
  const [numSave, setNumSave] = useState(0);


  const handleClose = (event) => {
    //console.log(event);
    if (!event)
    {
      setOpenModal(false);
      setHidden(false);
      setRut("");
      setPassport("");
      setEcivil("");
      setNacion("");
      setFechaNacimiento(null);
      setPassword("");
      setRpassword("");
      setRegion("");
      setComuna("");
      setDireccion("");
      setPais("");
      setConsultor("");
      setAnosExp("");
      setAnosZap("");
      setSexo("");
      setTimeout(() => {
        setView("plan");
      }, 300);
    }/*else{
      window.location.href = window.location.origin+'/login-empresas/';
    }*/
  };

  const guardarEmpresa = async () => {
    setLoading(true);
    const logoURL = file;
    try {
      const idemp = await dispatch(
        agregarEmpresaAction({
          logoURL,
          tipoPlan:tipoPlan._id,
          razonSocial,
          rut,
          giro,
          fechaInicio,
          fechaTermino,
          resena,
          direcciones,
          telefonos,
        })
      );
      console.log(idemp);
      if (idemp) {
        for (let i = 0; i < perfiles.length; i++) {
          setNumSave(i + 1);
          perfiles[i].email = perfiles[i].email.toLocaleLowerCase();
          await clienteAxios.post("/api/usuarioEmpresa", {
            perfil: perfiles[i],
            esCuentaPrincipal : true,
            idemp,
          });
        }
        setTimeout(() => {
          setLoading(false);
          setStep("five");
        }, 1000);
      }else{setLoading(false);}
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  useEffect(() => {
    if (plan){
      handleClose();
      setStep("one");
      setOpenModal(true);
      setHidden(true);
      setTipoPlan(plan);
      //setView("one");
    }
  }, [plan]);
  

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      // open={true}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
         
          {step === "plan" ? (
            <CardPlan
            setOpenModal={setOpenModal}
            setHidden={setHidden}
            setView={setView}
            setStep={setStep}
            setTipoPlan={setTipoPlan}
          />
          
        ) :step === "one" ? (
          <One
            setStep={setStep}
            tipoPlan={tipoPlan}
            setTipoPlan={setTipoPlan}
            razonSocial={razonSocial}
            setRazonSocial={setRazonSocial}
            rut={rut}
            setRut={setRut}
            giro={giro}
            setGiro={setGiro}
            fechaInicio={fechaInicio}
            setFechaInicio={setFechaInicio}
            fechaTermino={fechaTermino}
            setFechaTermino={setFechaTermino}
            resena={resena}
            setResena={setResena}
            image={image}
            setImage={setImage}
            file={file}
            setFile={setFile}
            setOpenModal={setOpenModal}
            setHidden={setHidden}
            direcciones={direcciones}
            setDirecciones={setDirecciones}
            telefonos={telefonos}
            setTelefonos={setTelefonos}

            perfiles={perfiles}
            setPerfiles={setPerfiles}
            numSave={numSave}
            guardarEmpresa={guardarEmpresa}
            loading={loading}
          />
        ) : step === "five" ? (
          <Five 
            setStep={setStep}
            setOpenModal={setOpenModal}
            setHidden={setHidden} 
          />
        ) : null}
      </Fade>
    </Modal>
  );
};

export default CustomModalEmpresa;
