import React, { useState, useEffect } from "react";
import "./Filtro.css";
import { CustomSelect, Button, OutInput } from "../../../../components";
import { MenuItem } from "@material-ui/core";
import { HighlightOff, FilterList } from "@material-ui/icons";
import { modulos } from "../../../../assets/modulos";
//import { regiones } from "../../../../assets/regiones";
import { actEmpresa } from "../../../../assets/actEmpresa";
import { estudios_data as carreras } from "../../../../assets/carreras";
import { naciones } from "../../../../assets/nacionalidades";

const Filtro = ({
  value,
  setQuery,
  query,
  setSkip,
  skip,
  _switch,
  setSwitch,
  dataFiltro,
  idaviso,
}) => {
  const [anuncio, setAnuncio] = useState("");
  const [tipoConsultor, setTipoConsultor] = useState("");
  const [_modulo, setModulos] = useState("");
  const [carrera, setCarrera] = useState("");
  const [nivel, setNivel] = useState(null);
  const [industria, setIndustria] = useState("");
  const [sexo, setSexo] = useState("");
  const [nacion, setNacion] = useState("");
  //const [region, setRegion] = useState("");
  const [anosExpMin, setAnosExpMin] = useState("");
  const [anosExpMax, setAnosExpMax] = useState("");
  const [pretencionMin, setPretencionMin] = useState("");
  const [pretencionMax, setPretencionMax] = useState("");

  //const comunas = regiones.find((item) => item.region === region);
  const [moneda, setMoneda] = useState("");

  const filtrar = () => {
    
    if (tipoConsultor) query.tipoConsultor = tipoConsultor;
    if (_modulo) query.modulo = _modulo;
    if (carrera) query.carrera = carrera;
    if (nivel) query.nivel = nivel;
    if (industria) query.industria = industria;
    if (sexo) query.sexo = sexo;
    //if (comuna) query.comuna = comuna;
    //if (region) query.region = region;
    if (nacion) query.nacion = nacion;
    if (anosExpMin) query.anosExpMin = anosExpMin;
    if (anosExpMax) query.anosExpMax = anosExpMax;
    if (pretencionMin) query.pretencionMin = pretencionMin;
    if (pretencionMax) query.pretencionMax = pretencionMax;
    if (moneda) query.moneda = moneda;

    setSkip(0);
    skip = 0;
    if (skip > 0) {
      setSkip(0);
    } else {
      setSwitch(!_switch);
    }
  };
  let data_carrera = carreras.find((item) => item.institucion === "Otros");

  const limpiarFiltros = () => {
    setQuery({});
    setAnuncio("");
    setTipoConsultor("");
    setModulos("");
    setCarrera("");
    setNivel(null);
    setIndustria("");
    setSexo("");
    //setComuna("");
    setNacion("");
    setAnosExpMin("");
    setAnosExpMax("");
    setPretencionMin("");
    setPretencionMax("");
    setMoneda("");
    setSkip(0);
    setSwitch(!_switch);
    return;
  };
  const setIdAviso = (id) => {
    setQuery({ ...query, _id: id });
  };

  return (
    <div className="cont-filtro-eco-sap-emp">
      <div className="title-filtros-eco-sap">
        <h1>Filtros</h1>
      </div>
      {value === 1 ? (
        <div className="item">
          <p>Anuncio</p>
          <CustomSelect
            onChange={setAnuncio}
            value={anuncio}
            size="small"
            placeholder="Selecciona un anuncio"
          >
            <MenuItem
              value=""
              className="custom-menu-item"
              onClick={() => setIdAviso(null)}
            >
              <em>Ninguno</em>
            </MenuItem>
            {dataFiltro.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  className="custom-menu-item"
                  value={item.titulo}
                  onClick={() => setIdAviso(item._id)}
                >
                  {item.titulo + " #"}
                  <span style={{ textTransform: "uppercase" }}>
                    {item._id && item._id.slice(18)}
                  </span>
                </MenuItem>
              );
            })}
          </CustomSelect>
        </div>
      ) : null}

      <div className="item">
        <p>ADN-SAP</p>
       
        <div className="item-doble-filtros-of">
          <div style={{ flex: 1 }}>
            <CustomSelect
              placeholder="Módulo"
              size="small"
              onChange={setModulos}
              value={_modulo}
              name="consultor"
            >
              {modulos.map((item, index) => (
                <MenuItem
                  key={index}
                  className="custom-menu-item"
                  value={item.modulo}
                >
                  {item.modulo}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        </div>
      </div>

      <div className="item">
        <div className="item-doble-filtros-of">
          <div style={{ flex: 1 }}>
            <CustomSelect
              placeholder="Nivel"
              size="small"
              name="nivel"
              value={nivel}
              onChange={setNivel}>
              <MenuItem value="" disabled className="custom-menu-item">
                Nivel
              </MenuItem>
              <MenuItem value="No Maneja" className="custom-menu-item">No Maneja</MenuItem>
              <MenuItem value="Básico" className="custom-menu-item">Básico</MenuItem>
              <MenuItem value="Medio" className="custom-menu-item">Medio</MenuItem>
              <MenuItem value="Avanzado" className="custom-menu-item">Avanzado</MenuItem>
            </CustomSelect>
          </div>
        </div>
      </div>

      <div className="item">
        <p>Industria</p>
        <CustomSelect
          size="small"
          placeholder="Selecciona"
          onChange={setIndustria}
          value={industria}
        >
          {actEmpresa.map((item, index) => (
            <MenuItem
              key={index}
              className="custom-menu-item"
              value={item.Seleccionar}
            >
              {item.Seleccionar}
            </MenuItem>
          ))}
        </CustomSelect>
      </div>

      <div className="item">
        <p>Género</p>
        <CustomSelect
          size="small"
          placeholder="Selecciona"
          onChange={setSexo}
          value={sexo}
        >
          <MenuItem className="custom-menu-item" value="Masculino">
            Masculino
          </MenuItem>
          <MenuItem className="custom-menu-item" value="Femenino">
            Femenino
          </MenuItem>
        </CustomSelect>
      </div>

      <div className="item">
        <p>Pretensión de renta</p>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, paddingRight: "5px" }}>
            <OutInput
              label="Rango mínimo"
              funcOnChange={setPretencionMin}
              value={pretencionMin}
              name="anosExp"
              size="small"
              type="number"
            />
          </div>
          <div style={{ flex: 1, paddingLeft: "5px" }}>
            <OutInput
              label="Rango maximo"
              funcOnChange={setPretencionMax}
              defaultValue={pretencionMax}
              value={pretencionMax}
              name="anosExp"
              size="small"
              type="number"
            />
          </div>
        </div>
      </div>

      <div className="item">
          <div style={{ flex: 1, paddingRight: "5px" }}>
            <CustomSelect
                size="small"
                name="moneda"
                placeholder="Moneda"
                value={moneda}
                onChange={setMoneda}>
                <MenuItem className="custom-menu-item" value="CLP">CLP</MenuItem>
                <MenuItem className="custom-menu-item" value="USD">USD</MenuItem>
            </CustomSelect>
          </div>

        </div>

      <div className="item">
        <p>Nacionalidad</p>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, paddingRight: "5px" }}>
            <CustomSelect
              placeholder="Nacionalidad"
              size="small"
              onChange={setNacion}
              helpertext="Selecciona una nacionalidad"
              value={nacion}
              name="nacion"
            >
              {naciones.map((item, index) => (
                <MenuItem className="custom-menu-item" value={item}>
                  {item}
                </MenuItem>
              ))}
            </CustomSelect>

          </div>
        </div>
      </div>

      <div className="item-bottom">
        <Button
          variant="contained"
          color="secondary"
          onClick={limpiarFiltros}
          endIcon={<HighlightOff style={{ color: "white" }} />}
        >
          <p style={{ margin: 0, color: "white" }}>Limpiar</p>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={filtrar}
          endIcon={<FilterList />}
        >
          <p style={{ margin: 0 }}>Filtrar</p>
        </Button>
      </div>
    </div>
  );
};

export default Filtro;
