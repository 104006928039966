import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Modal, Fade, Backdrop, makeStyles } from "@material-ui/core";
import {
  registrarUsuarioAction,
  subirCVAction,
} from "../../../../redux/actions/authAction";
import { useDispatch } from "react-redux";
import { RegistroA, ModalConfirm, ModalFinished } from "../";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const CustomModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    setOpenModal,
    openModal,
    setHidden,
    setNombres,
    setApellidos,
    setEmail,
    nombres,
    apellidos,
    emailA,
  } = props;

  const [view, setView] = useState("A");

  //reg-a
  const [rut, setRut] = useState("");
  const [passport, setPassport] = useState("");
  const [dni, setDni] = useState("");
  const [phone, setPhone] = useState("");
  const [ecivil, setEcivil] = useState("");
  const [nacion, setNacion] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRpassword] = useState("");
  const [sexo, setSexo] = useState("");
  const [inputRut, setInputRut] = useState(true);
  const [inputPassport, setInputPassport] = useState(true);
  const [inputDni, setInputDni] = useState(true);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  //reg-b
  const [region, setRegion] = useState("");
  const [comuna, setComuna] = useState("");
  const [direccion, setDireccion] = useState("");
  //reg-c
  const [consultor, setConsultor] = useState("");
  const [anosExp, setAnosExp] = useState("");
  const [anosZap, setAnosZap] = useState("");
  const [pretencion, setPretencion] = useState("");
  const [fileCV, setfileCV] = useState("");
  const [moneda,setMoneda]= useState("CLP");

  const handleClose = (event) => {
    if (!event)
    {
      setOpenModal(false);
      setHidden(false);
      setRut("");
      setPassport("");
      setDni("");
      setNombres("");
      setApellidos("");
      setPhone("");
      setEmail("");
      setEcivil("");
      setNacion("");
      setFechaNacimiento(null);
      setPassword("");
      setRpassword("");
      setRegion("");
      setComuna("");
      setDireccion("");
      setConsultor("");
      setAnosExp("");
      setAnosZap("");
      setSexo("");
      setTimeout(() => {
        setView("A");
      }, 300);
    }
  };

  const registrarUsuario = () => {
    // handleClose();
    const email = emailA.toLocaleLowerCase();
    dispatch(
      registrarUsuarioAction({
        rut,
        passport,
        dni,
        nombres,
        apellidos,
        phone,
        email,
        ecivil,
        nacion,
        password,
        sexo,
        region,
        comuna,
        direccion,
        consultor,
        anosExp,
        anosZap,
        fechaNacimiento,
        pretencion,
        moneda,
      })
    ).then((res) => {
      if (res.dato) {
        var decoded = jwt_decode(res.token);
        dispatch(
          subirCVAction({
            _id: decoded.usuario.id,
            cvURL: fileCV,
            type: "document",
          })
        );
        setView("ModalConfirm");
      }
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      // open={true}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        {view === "A" ? (
          <RegistroA
            setView={setView}
            rut={rut}
            passport={passport}
            dni={dni}
            nombres={nombres}
            apellidos={apellidos}
            phone={phone}
            email={emailA}
            password={password}
            rpassword={rpassword}
            setRut={setRut}
            setPassport={setPassport}
            setDni={setDni}
            setNombres={setNombres}
            setApellidos={setApellidos}
            setPhone={setPhone}
            setEmail={setEmail}
            setPassword={setPassword}
            setRpassword={setRpassword}
            handleClose={handleClose}
            inputRut={inputRut}
            setInputRut={setInputRut}
            inputPassport={inputPassport}
            setInputPassport={setInputPassport}
            inputDni={inputDni}
            setInputDni={setInputDni}
            registrarUsuario={registrarUsuario}
            fileCV={fileCV}
            setfileCV={setfileCV}
            nacion={nacion}
            setNacion={setNacion}
            fechaNacimiento={fechaNacimiento}
            setFechaNacimiento={setFechaNacimiento}
            pretencion={pretencion}
            setPretencion={setPretencion}
            moneda={moneda}
            setMoneda={setMoneda}
          />
        ) : view === "ModalConfirm" ? (
          <ModalConfirm setView={setView} />
        ) : view === "viewFinished" ? (
          <ModalFinished handleClose={handleClose} />
        ) : null}
      </Fade>
    </Modal>
  );
};

export default CustomModal;
