import React, { forwardRef, useState, useEffect } from "react";
import "./One.css";
import {
  Button,
  Tooltip,
  IconButton as CustomIconButton,
  CustomInput,
  CustomSelectB,
} from "../../../../components";
import {
  LinearProgress,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  CircularProgress,
} from "@material-ui/core";

import { Publish, Close, Add, PhoneIphone } from "@material-ui/icons";
import imgUser from "../../../../resources/ZAPTalent-Icon-Empty.svg";
import { formatRut, RutFormat, validateRut } from "@fdograph/rut-utilities";
import clientAxios from "../../../../config/axios";
import moment from "moment";
import Card from "./card/Card";
import shortid from "shortid";
import Perfil from "./perfil/Perfil";
import validator from "validator";

const useStyles = makeStyles({
  iconButton: {
    "&.MuiIconButton-root": {
      position: "absolute",
      top: 0,
      backgroundColor: "#000",
    },
  },
});

const One = forwardRef(
  (
    {
      setStep,
      closeModal,
      razonSocial,
      setRazonSocial,
      rut,
      setRut,
      giro,
      setGiro,
      fechaInicio,
      setFechaInicio,
      fechaTermino,
      setFechaTermino,
      resena,
      setResena,
      tipoPlan,
      setTipoPlan,
      image,
      setImage,
      file,
      setFile,
      setOpenModal,
      setHidden,
      direcciones,
      setDirecciones,
      telefonos,
      setTelefonos,
      perfiles,
      setPerfiles,
      numSave,
      guardarEmpresa,
      loading,
    },
    ref
  ) => {
    const classes = useStyles();

    const [fechaMsg, setFechaMsg] = useState("");
    const [rutmsg, setRutMsg] = useState("");
    const [razonSocialMsg, setRazonSocialMsg] = useState("");

    //ERRORES
    const [resenaError, setResenaError] = useState(false);
    const [rutError, setRutError] = useState(false);
    const [giroError, setGiroError] = useState(false);
    const [inicioError, setInicioError] = useState(false);
    const [terminoError, setTerminoError] = useState(false);
    const [tipoPlanError, setTipoPlanError] = useState(false);
    const [razonSocialError, setRazonSocialError] = useState(false);
    const [initDefault, setInitDefault] = useState(true);
    const [_switch, setSwitch] = useState(false);

    const [cargaErrores, setCargaErrores] = useState(false);
    const [errores, setErrores] = useState([]);
    const [errores2, setErrores2] = useState([]);
    const inicio = moment().hours(0).minutes(0).seconds(0).format();
    //const termino = moment().add(tipoPlan.duracionPlan, "months").format();

    useEffect(() => {
      setFechaInicio(inicio);

      if (tipoPlan._id !== '6'){
        setFechaTermino(moment(new Date()).add(tipoPlan.duracionPlan, "months").format());
      }else{
        setFechaTermino(moment(new Date()).add(tipoPlan.duracionPlan, "days").format());
      }
    })
    

    const handleClose = () => {
      setOpenModal(false);
      setHidden(false);
      setStep("plan");
    };

    const formatRut2 = () => {
      try {
        document.getElementById("input-rut-empresa-admin").value = rut;
      } catch (error) {
        console.log(error);
      }
    };

    const changeRut = (e) => {
      if (rutError === true) {
        setRutError(false);
      }
      //setRut(formatRut(e.target.value, RutFormat.DOTS_DASH));
      setRut(e.target.value);
    };

    const fileChange = (e) => {
      if (e.target.value) {
        setImage({
          preimage: URL.createObjectURL(e.target.files[0]),
          name: e.target.value,
        });
        setFile(e.target.files[0]);
      }
    };
    //VALIDACION
    const validacion = async () => {
      setErrores([]);
      setFechaInicio(inicio);
      
      if (tipoPlan._id !== '6'){
        setFechaTermino(moment().add(tipoPlan.duracionPlan, "months").format());
      }else{
        setFechaTermino(moment(new Date()).add(tipoPlan.duracionPlan, "days").format());
      }
      await mapearDatos();
      nextStep();
      setInitDefault(false);
    };

    useEffect(() => {
      if (initDefault === false) {
        if (
          resenaError ||
          rutError ||
          giroError ||
          inicioError ||
          terminoError ||
          razonSocialError
        ) {
          return;
        } else {
        }
      }
    }, [_switch]);

    const mapearDatos = async () => {
      const _rut = await clientAxios.put("/api/empresas/validar/rut", { rut });

      if (razonSocial.trim() === "") {
        setRazonSocialMsg("Razon social no puede estar vacia");
        setRazonSocialError(true);
        errores.push("Razon social no puede estar vacia");
      }

      //const rutvalidado = validateRut(rut.toLocaleLowerCase());
      if (rut.trim() === "") {
        console.log('1');
        setRutMsg("RUT / Número Identificación empresa no puede estar vacio");
        setRutError(true);
        errores.push("RUT / Número Identificación empresa no puede estar vacio");
      } /*else if (rutvalidado === false) {
        setRutMsg("El rut no es valido");
        setRutError(true);
        errores.push("El rut no es valido");
      }*/ else if (_rut.data._rut === true) {
        console.log('2');
        setRutMsg("RUT / Número Identificación empresa ya se encuentra registrado");
        setRutError(true);
        errores.push("RUT / Número Identificación empresa ya se encuentra registrado");
      }
      if (giro.trim() === "") {
        console.log('3');
        setGiroError(true);
        errores.push("giro no puede estar vacio");
      }
      if (resena.trim() === "") {
        console.log('4');
        setResenaError(true);
        errores.push("resena no puede estar vacio");
      }

      direcciones.map((item) => {
        if (item.direccion === "") {
          console.log('5');
          errores.push(item.id);
        } else if (item.pais === "") {
          console.log('6');
          errores.push(item.id);
        }/*else if (item.region === "") {
          errores.push(item.id);
        } else if (item.comuna === "") {
          errores.push(item.id);
        }*/
      });
      telefonos.map((item) => {
        if (item.telefono === "" || item.telefono.length >13) {
          console.log('6');
          errores.push(item.id);
        }
      });
      setCargaErrores(true);

      /*let rutvalidadoPerfil = await validateRut(
        perfiles[0].rut.toLocaleLowerCase()
      );*/
      const _email = await clientAxios.put(
        "/api/usuarioEmpresa/validar/email",
        {
          email: perfiles[0].email.toLocaleLowerCase(),
        }
      );
      const _rutPerfil = await clientAxios.put("/api/usuarioEmpresa/validar/rut", {
        rut: perfiles[0].rut,
      });
      const emailv = await validator.isEmail(perfiles[0].email);
      if (perfiles[0].rut.trim() === "") {
        console.log('6');
        errores.push(perfiles[0].id);
      }
      /*if (rutvalidadoPerfil === false) {
        errores.push(perfiles[0].id);
      }*/
      if (perfiles[0].nombres === "") {
        console.log('7');
        errores.push(perfiles[0].id);
      }
      if (perfiles[0].apellidos === "") {
        console.log('');
        errores.push(perfiles[0].id);
      }
      if (perfiles[0].email.trim() === "") {
        console.log('');
        errores.push(perfiles[0].id);
      }
      if (emailv === false) {
        console.log('');
        errores.push(perfiles[0].id);
      }
      if (_email.data._email === true) {
        console.log('');
        errores.push(perfiles[0].id);
      }
      if (_rutPerfil.data._rut === true) {
        console.log('');
        errores.push(perfiles[0].id);
      }
      
      if (perfiles[0].password.trim() === "") {
        console.log('');
        errores.push(perfiles[0].id);
      }
      if (perfiles[0].password.length < 6) {
        console.log('');
        errores.push(perfiles[0].id);
      }
      if (perfiles[0].telefono.length > 15) {
        console.log('');
        errores.push(perfiles[0].id);
      }
      if (perfiles[0].fechaInicio === null) {
        console.log('');
        errores.push(perfiles[0].id);
      }
      if (perfiles[0].fechaTermino === null) {
        console.log('');
        errores.push(perfiles[0].id);
      }
      /*if (
        Date.parse(perfiles[0].fechaInicio) >
        Date.parse(perfiles[0].fechaTermino)
      ) {
        console.log('');
        errores.push(perfiles[0].id);
      }*/
      setCargaErrores(false);
      setErrores2(errores);
    };

    const nextStep = async () => {
      if (errores.length === 0 && !cargaErrores) {
        console.log("guardando empresas");
        guardarEmpresa();
      }
    };

    const addDireccion = () => {
      const container = document.getElementById("cont-direccion-add-emp-admin");
      setDirecciones([
        ...direcciones,
        { id: shortid(), region: "", comuna: "", direccion: "", pais: "" },
      ]);
    };

    const addTelefono = () => {
      setTelefonos([...telefonos, { id: shortid(), telefono: "" }]);
    };

    const addPerfil = () => {
      const container = document.getElementById("cont-perfil-add-emp-admin");
      setPerfiles([
        ...perfiles,
        {
          id: shortid(),
          tipoPerfil: "",
          rut: "",
          nombres: "",
          apellidos: "",
          email: "",
          password: "",
          fechaInicio: null,
          fechaTermino: null,
        },
      ]);
    };

    useEffect(() => {
      if (direcciones.length === 0) {
        addDireccion();
      }
    }, []);

    useEffect(() => {
      if (telefonos.length === 0) {
        addTelefono();
      }
    }, []);

    useEffect(() => {
      if (perfiles.length === 0) {
        addPerfil();
      }
    }, []);
    return (
      <>
        {loading ? <CircularProgress /> : null}
        <div className="one-add-emp-admin" ref={ref} hidden={loading}>
          <div className="top">
            <p className="p1">Crear empresa</p>
            <div className="img-add-emp-admin">
              {image.preimage ? (
                <div className="cont-img-add-new-emp">
                  <img
                    className="img-one-add-emp"
                    alt="user-img"
                    src={image.preimage}
                  ></img>
                </div>
              ) : (
                <div className="cont-img-add-new-emp">
                  <img
                    src={imgUser}
                    className="img-one-add-emp"
                    alt="user-imge"
                  />
                </div>
              )}
              <>
                <input
                  type="file"
                  id="button-file-logo-admin"
                  onChange={(e) => fileChange(e)}
                  style={{ display: "none" }}
                  accept="image/*"
                />

                <Tooltip title="Subir logo">
                  <IconButton className="btn-upload-logo-admin">
                    <label
                      htmlFor="button-file-logo-admin"
                      className="label-btn-add-photo"
                    >
                      <div
                        style={{
                          width: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#187ce2",
                          borderRadius: "50%",
                        }}
                      >
                        <Publish
                          style={{
                            width: "40px",
                          }}
                        />
                      </div>
                    </label>
                  </IconButton>
                </Tooltip>
              </>
            </div>
          </div>
          <div className="center">
            <div className="item">
              <CustomInput
                label="Razón Social"
                helpertext={razonSocialMsg}
                error={razonSocialError}
                type="text"
                size="large"
                value={razonSocial}
                onChange={(e) => {
                  setRazonSocialError(false);
                  setRazonSocial(e.target.value);
                }}
              />
            </div>
            <div className="item">
              <CustomInput
                label="RUT / Número Identificación Empresa"
                id="input-rut-empresa-admin"
                error={rutError}
                helpertext={rutmsg}
                type="text"
                //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                // value={rut}
                onChange={changeRut}
                //onBlur={formatRut2}
              />
            </div>
            <div className="item">
              <CustomInput
                label="Giro"
                helpertext="Introduzca un número válido"
                error={giroError}
                type="text"
                value={giro}
                onChange={(e) => {
                  setGiroError(false);
                  setGiro(e.target.value);
                }}
              />
            </div>
            <div className="item">
              <TextField
                fullWidth
                id="resena-add-empresa-admin"
                label="Reseña de la empresa"
                name="resena"
                type="text"
                error={resenaError}
                value={resena}
                onChange={(e) => {
                  setResenaError(false);
                  setResena(e.target.value);
                }}
                // defaultValue={reflogros}
                helperText={resenaError ? "No puede estar vacio" : null}
                multiline
                rows={2}
                InputLabelProps={{ className: "multiline-form-estudios" }}
                InputProps={{ className: "multiline-form-estudios" }}
              />
            </div>
            <p className="p2">Dirección</p>
            <div className="item">
              {direcciones &&
                direcciones.map((item, index) => (
                  <Card
                    key={index}
                    num={index + 1}
                    data={item}
                    direcciones={direcciones}
                    setDirecciones={setDirecciones}
                    errores2={errores2}
                    origin='registroEmp'
                  />
                ))}
            </div>
            <p className="p2">Teléfono</p>
            <div className="item">
              {
                <>
                  {telefonos.map((item, index) => (
                    <Telefono
                      key={index}
                      data={item}
                      setTelefonos={setTelefonos}
                      telefonos={telefonos}
                      errores2={errores2}
                      num={index + 1}
                    />
                  ))}
                </>
              }
            </div>
            <p className="p1">Datos del titular de la cuenta</p>
            <div className="item" id="cont-perfil-add-emp-admin">
              {
                <>
                  {perfiles.map((item, index) => (
                    <Perfil
                      key={index}
                      data={item}
                      perfiles={perfiles}
                      setPerfiles={setPerfiles}
                      errores2={errores2}
                      tipoPlan={tipoPlan}
                      origen="login"
                    />
                  ))}
                </>
              }
            </div>
          </div>

          <div className="bottom">
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={validacion}>
              Guardar
            </Button>
          </div>
          {loading ? (
            <>
              <div className="overlay-loading"></div>
              <div className="linear-progres-global">
                <LinearProgress className="progres-editar-perfil" />
              </div>
            </>
          ) : null}
          <div className="cont-icon-close-formulario">
            <CustomIconButton
              bg="close"
              size="small"
              customcolor="close"
              onClick={handleClose}
            >
              <Close className="icon-close" />
            </CustomIconButton>
          </div>
        </div>
      </>
    );
  }
);

const Telefono = ({ data, setTelefonos, telefonos, errores2, num }) => {
  const { id, telefono } = data;
  const classes = useStyles();
  const [errorphone, setErrorPhone] = useState(false);
  const [phone, setPhone] = useState(telefono);
  const [phonemsg, setPhoneMsg] = useState("");
  const [initDefault, setInitDefault] = useState(true);

  const handleDelete = async () => {
    if (telefonos.length === 1) {
      return;
    } else {
      await setTelefonos(telefonos.filter((i) => i.id !== id));
    }
  };
  const changeTelefono = (e) => {
    telefonos.map((item) => {
      if (item.id === id) {
        item.telefono = e.target.value;
      }
    });

    setPhone(e.target.value);
  };

  useEffect(() => {
    if (initDefault === false) {
      if (errores2.length > 0) {
        const valor = errores2.filter((item) => item === data.id);

        if (valor.length > 0) {
          setPhoneMsg("Verifique la longitud de su número de teléfono");
          setErrorPhone(true);
          document.getElementById(id).scrollIntoView();
        } else {
          setErrorPhone(false);
        }
      }
    }
    setInitDefault(false);
  }, [errores2]);

  return (
    <div className="telefono-add-emp-admin" id={id}>
      <PhoneIphone fontSize="small" className={classes.icon} />
      <span className="plus">+</span>
      {/* <FormControl fullWidth size="small" error={errorphone}>
    
        <Input
          // value={phone}
          defaultValue={phone}
          onChange={(e) => {
            setErrorPhone(false);
            changeTelefono(e);
          }}
          endAdornment={
            <IconButton size="small" onClick={handleDelete}>
              <Close fontSize="small" />
            </IconButton>
          }
          name="textmask"
          id={id}
          // inputComponent={InputMaskCustom}
        />
        {errorphone ? (
          <FormHelperText className="helper-text-custom-input">
            {phonemsg}
          </FormHelperText>
        ) : null}
      </FormControl> ${num}*/}

      <CustomInput
        label={`Teléfono (+56900000000)`}
        Tooltip
        helpertext={phonemsg}
        error={errorphone}
        type="number"
        defaultValue={phone}
        value={phone}
        onChange={(e) => {
          setErrorPhone(false);
          changeTelefono(e);
        }}
        endAdornment={
          <IconButton size="small" onClick={handleDelete}>
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default One;
