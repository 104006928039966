//auth
export const OBTENER_USUARIO_EMP = "OBTENER_USUARIO_EMP";
export const LOGIN_EXITOSO_EMP = "LOGIN_EXITOSO_EMP";
export const LOGIN_ERROR_EMP = "LOGIN_ERROR_EMP";
export const CERRAR_SESION_EMP = "CERRAR_SESION_EMP";
export const INICIO_LOGIN_EMP = "INICIO_LOGIN_EMP";
export const OBTENER_USUARIO_ERROR_EMP = "OBTENER_USUARIO_ERROR_EMP";
export const ERROR_EMAIL_EMP = "ERROR_EMAIL_EMP";
export const ERROR_PASSWORD_EMP = "ERROR_PASSWORD_EMP";
export const SHOW_ALERT_EMP = "SHOW_ALERT_EMP";
export const HIDDEN_ALERT_EMP = "HIDDEN_ALERT_EMP";
export const RESET_EMAIL_EMP = " RESET_EMAIL_EMP";
export const RESET_PASSWORD_EMP = "RESET_PASSWORD_EMP";
export const OBTENER_USUARIO_EMP_ACT="OBTENER_USUARIO_EMP_ACT";
//Avisos
export const AGREGAR_AVISO = "AGREGAR_AVISO";
export const AGREGAR_AVISO_EXITO = "AGREGAR_AVISO_EXITO";
export const AGREGAR_AVISO_ERROR = "AGREGAR_AVISO_ERROR";
export const COMENZAR_DESCARGA_AVISO = "COMENZAR_DESCARGA_AVISO";
export const DESCARGA_AVISO_EXITO = "DESCARGA_AVISO_EXITO";
export const DESCARGA_AVISO_EXITO_INIT = "DESCARGA_AVISO_EXITO_INIT";
export const DESCARGA_AVISO_ERROR = "DESCARGA_AVISO_ERROR";
export const OBTENER_AVISO_EDITAR = "OBTENER_AVISO_EDITAR";
export const COMENZAR_EDICION_AVISO = "COMENZAR_EDICION_AVISO";
export const AVISO_EDITADO_EXITO = "AVISO_EDITADO_EXITO";
export const AVISO_EDITADO_ERROR = "AVISO_EDITADO_ERROR";
export const OBTENER_AVISO_ELIMINAR = "OBTENER_AVISO_ELIMINAR";
export const AVISO_ELIMINADO_EXITO = "AVISO_ELIMINADO_EXITO";
export const AVISO_ELIMINADO_ERROR = "AVISO_ELIMINADO_ERROR";
export const DETENER_CARGA_AVISO = "DETENER_CARGA_AVISO";
//postulados
export const COMENZAR_DESCARGA_POSTULADOS = "COMENZAR_DESCARGA_POSTULADOS";
export const DESCARGA_POSTULADOS_EXITO = "DESCARGA_POSTULADOS_EXITO";
export const DESCARGA_POSTULADOS_EXITO_INIT = "DESCARGA_POSTULADOS_EXITO_INIT";
export const DESCARGA_POSTULADOS_ERROR = "DESCARGA_POSTULADOS_ERROR";
//info user
export const OBTENER_USER_USER_INFO = "OBTENER_USER_USER_INFO";
export const OBTENER_ESTUDIO_USER_INFO = "OBTENER_ESTUDIO_USER_INFO";
export const OBTENER_TRABAJO_USER_INFO = "OBTENER_TRABAJO_USER_INFO";
export const OBTENER_CERTIFICADO_USER_INFO = "OBTENER_CERTIFICADO_USER_INFO";
export const OBTENER_ADN_USER_INFO = "OBTENER_ADN_USER_INFO";
export const OBTENER_EXITO_USER_INFO = " OBTENER_EXITO_USER_INFO";
export const OBTENER_ESTUDIO_EXITO_USER_INFO =
  "OBTENER_ESTUDIO_EXITO_USER_INFO";
export const OBTENER_TRABAJO_EXITO_USER_INFO =
  "OBTENER_TRABAJO_EXITO_USER_INFO";
export const OBTENER_CERT_EXITO_USER_INFO = "OBTENER_CERT_EXITO_USER_INFO";
export const OBTENER_ADN_EXITO_USER_INFO = "OBTENER_ADN_EXITO_USER_INFO";
export const RESET_DATA_USER_INFO = "RESET_DATA_USER_INFO";
export const RESET_DATA_USER_INFO_B = "RESET_DATA_USER_INFO_B";
export const COMENZAR_OBTENER_POST_ID_EMPRESA =
  "COMENZAR_OBTENER_POST_ID_EMPRESA";
export const OBTENER_POSTULACIONES_ID_EMPRESA =
  "OBTENER_POSTULACIONES_ID_EMPRESA";
/**LEIDO */
export const POSTULANTE_LEIDO_EXITO = "POSTULANTE_LEIDO_EXITO";
/**TOTAL DE USUARIOS */
export const OBTENER_TOTAL_USUARIOS = "OBTENER_TOTAL_USUARIOS";
/**TOTAL DE AVISOS */
export const OBTENER_TOTAL_AVISOS = "OBTENER_TOTAL_AVISOS";
/**TOTAL DE POSTULANTES */
export const OBTENER_TOTAL_POSTULANTES = "OBTENER_TOTAL_POSTULANTES";
/**TOTAL DE NO LEIDOS */
export const OBTENER_TOTAL_NO_LEIDOS = "OBTENER_TOTAL_NO_LEIDOS";
/**TOTAL USUARIOS NUEVOS POR DIA */
export const OBTENER_TOTAL_USUARIOS_DIAS = "OBTENER_TOTAL_USUARIOS_DIAS";
