import React, { useState } from "react";
import "./style.css";
import { Close } from "@material-ui/icons";
import { Dialog, Button, makeStyles, IconButton } from "@material-ui/core";
import PdfDocument from "./PdfDocument";
import Document from "./Document";
import clientAxios from "../../config/axios";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  button: {
    padding: "10px 30px ",
  },
  iconButton: {
    position: "absolute",
    top: 5,
    right: 5,
    width: 35,
    height: 35,
  },
});
const DialogProgress = ({ open, setOpen, idUser, setIdUser, open1, setOpen1, cvURL, idPostulacion, idAviso }) => {
  const datos = "Esta es los datos del componente Padre al componente Hijo."
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [data, setData] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const usuarioemp = useSelector((state) => state.authEmp.usuario);
  
  const handleClose = async() => {
    setRequesting(false);
    setError(false);
    setData(null);
    setIdUser(null);
    setOpen(false);
   
    const fd = new FormData();
    //SI ES
    fd.append("idUsuario", usuarioemp._id);
    fd.append("idPostulacion",idPostulacion);
    fd.append("idAviso",idAviso);
    
    await clientAxios.put(`/api/empresas/${usuarioemp.idemp}/descargas`, fd);
    setOpen1(true);

  //console.log(cvURL);
  if(cvURL){
    const link = document.createElement('a');
    link.href = cvURL;
    link.download = cvURL;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  };

  const fetchData = async () => {
    //Validar cantidad de CVS
    try {
      setRequesting(true);

      const respuesta = await clientAxios.get(`/api/usuarios/${idUser}`);
      const respuesta1 = await clientAxios.get(`/api/estudios/${idUser}`);
      const respuesta2 = await clientAxios.get(`/api/trabajos/${idUser}`);
      const respuesta3 = await clientAxios.get(`/api/certificacion/${idUser}`);
      const respuesta4 = await clientAxios.get(`/api/adnsap/${idUser}`);

      if (respuesta.data) {
        setData({
          usuario: respuesta.data,
          estudios: respuesta1.data,
          trabajos: respuesta2.data,
          certificados: respuesta3.data,
          adns: respuesta4.data,
        });
      }

      setRequesting(false);
    } catch (error) {
      setRequesting(false);
      setError(true);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
    >
      <div className="dialog-pdfcv-eco-emp">
        <IconButton className={classes.iconButton} onClick={handleClose}>
          <Close />
        </IconButton>
        {!requesting && !data && !error && (
          <div className="item-1">
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={fetchData}
            >
              Generar Curriculum Vitae
            </Button>
          </div>
        )}
        {requesting && (
          <div className="item-1">
            <p>Generando curriculum ...</p>
          </div>
        )}
        {data && !requesting && !error && (
          <PdfDocument
            title="CV-PDF"
            handleClose={handleClose}
            document={<Document data={data} />}
          />
        )}
        {!requesting && error && (
          <>
            <div className="item-1-error-pdf-sv">
              <p className="error-pdf-cvusuario">Ha habido un error</p>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={fetchData}
              >
                Intentar nuevamente
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default DialogProgress;
