import React from "react";
import "./Header.css";
import { makeStyles } from "@material-ui/core";
import { Tooltip } from "../../../../components";
import { IconButton } from "@material-ui/core";
import { Edit, Delete, SendRounded, Description, Warning } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#187ce2",
    color: "white",
    height: "200px",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.2)",
    "&:hover": {
      backgroundColor: "#105296",
    },
  },

  iconButton: {
    backgroundColor: "#187ce2",
    color: "white",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.2)",
    transition: "0.5s all ease-in-out",
    "&:hover": {
      backgroundColor: "#105296",
      transition: "0.5s all ease-in-out",
      transform: "scale(1.1)",
    },
  }
});

const Header = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className="header-home-admin">
        <p>{props.userName}</p>

        {props ? (
          props.cvURL ? (
            <Tooltip title="Ver CV.">
              <IconButton
                className={classes.iconButton}
                href={props.cvURL}
                target="_blank"
                fontSize="small"
              >
                <Description />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Sin CV.">
              <IconButton
                className={classes.iconButton}
                fontSize="small"
              >
                <Warning />
              </IconButton>
            </Tooltip>
          )
        ) : null}
      </div>
    </>
  );
};

export default Header;
