//auth
export const OBTENER_USUARIO_ADMIN = "OBTENER_USUARIO_ADMIN";
export const LOGIN_EXITOSO_ADMIN = "LOGIN_EXITOSO_ADMIN";
export const LOGIN_ERROR_ADMIN = "LOGIN_ERROR_ADMIN";
export const CERRAR_SESION_ADMIN = "CERRAR_SESION_ADMIN";
export const INICIO_LOGIN_ADMIN = "INICIO_LOGIN_ADMIN";
export const OBTENER_USUARIO_ERROR_ADMIN = "OBTENER_USUARIO_ERROR_ADMIN";
export const ERROR_EMAIL_ADMIN = "ERROR_EMAIL_ADMIN";
export const ERROR_PASSWORD_ADMIN = "ERROR_PASSWORD_ADMIN";
export const SHOW_ALERT_ADMIN = "SHOW_ALERT_ADMIN";
export const HIDDEN_ALERT_ADMIN = "HIDDEN_ALERT_ADMIN";
export const RESET_EMAIL_ADMIN = " RESET_EMAIL_ADMIN";
export const RESET_PASSWORD_ADMIN = "RESET_PASSWORD_ADMIN";
//Empresas
export const AGREGAR_EMPRESA = "AGREGAR_EMPRESA";
export const AGREGAR_EMPRESA_EXITO = "AGREGAR_EMPRESA_EXITO";
export const AGREGAR_EMPRESA_ERROR = "AGREGAR_EMPRESA_ERROR";
export const COMENZAR_DESCARGA_EMPRESA = "COMENZAR_DESCARGA_EMPRESA";
export const DESCARGA_EMPRESA_EXITO = "DESCARGA_EMPRESA_EXITO";
export const DESCARGA_EMPRESA_EXITO_INIT = "DESCARGA_EMPRESA_EXITO_INIT";
export const DESCARGA_EMPRESA_ERROR = "DESCARGA_EMPRESA_ERROR";
export const OBTENER_EMPRESA_EDITAR = "OBTENER_EMPRESA_EDITAR";
export const COMENZAR_EDICION_EMPRESA = "COMENZAR_EDICION_EMPRESA";
export const EMPRESA_EDITADO_EXITO = "EMPRESA_EDITADO_EXITO";
export const EMPRESA_EDITADO_ERROR = "EMPRESA_EDITADO_ERROR";
export const OBTENER_EMPRESA_ELIMINAR = "OBTENER_EMPRESA_ELIMINAR";
export const EMPRESA_ELIMINADO_EXITO = "EMPRESA_ELIMINADO_EXITO";
export const EMPRESA_ELIMINADO_ERROR = "EMPRESA_ELIMINADO_ERROR";
export const DETENER_CARGA_EMPRESA = "DETENER_CARGA_EMPRESA";

//Usuarios
export const AGREGAR_USUARIO = "AGREGAR_USUARIO";
export const AGREGAR_USUARIO_EXITO = "AGREGAR_USUARIO_EXITO";
export const AGREGAR_USUARIO_ERROR = "AGREGAR_USUARIO_ERROR";
export const COMENZAR_DESCARGA_USUARIO = "COMENZAR_DESCARGA_USUARIO";
export const DESCARGA_USUARIO_EXITO = "DESCARGA_USUARIO_EXITO";
export const DESCARGA_USUARIO_EXITO_INIT = "DESCARGA_USUARIO_EXITO_INIT";
export const DESCARGA_USUARIO_ERROR = "DESCARGA_USUARIO_ERROR";
export const OBTENER_USUARIO_EDITAR = "OBTENER_USUARIO_EDITAR";
export const COMENZAR_EDICION_USUARIO = "COMENZAR_EDICION_USUARIO";
export const USUARIO_EDITADO_EXITO = "USUARIO_EDITADO_EXITO";
export const USUARIO_EDITADO_ERROR = "USUARIO_EDITADO_ERROR";
export const OBTENER_USUARIO_ELIMINAR = "OBTENER_USUARIO_ELIMINAR";
export const USUARIO_ELIMINADO_EXITO = "USUARIO_ELIMINADO_EXITO";
export const USUARIO_ELIMINADO_ERROR = "USUARIO_ELIMINADO_ERROR";
export const DETENER_CARGA_USUARIO = "DETENER_CARGA_USUARIO";